/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { patientStatus } from 'helpers';
import Table from 'components/ReactTable';

// import components below
import FormUI from 'components/UI/FormUI';
import TableUI from 'components/UI/TableUI';
import moment from 'moment';
import { selectPatientItem, showPatient } from 'reducers/patientReducer';

// import assets below
import './Table.scss';
import { ReactComponent as ReceiptSVG } from 'assets/svg/receipt.svg';
import { ReactComponent as PesSignoSVG } from 'assets/svg/peso-sign.svg';
import { ReactComponent as ClipBoardSVG } from 'assets/svg/clipboard.svg';
import { ReactComponent as UserCircle } from 'assets/svg/user_circle.svg';
import { ReactComponent as WriteSVG } from 'assets/svg/writing-green.svg';
import { selectBillingData } from '../../../../../reducers/billingReducer';
import { ReactComponent as CancelSVG } from 'assets/svg/circle-xmark-red.svg';
import { ReactComponent as ThreeDotsSVG } from 'assets/svg/three-dots-svgrepo-com.svg';

// main component
const BillingTable = (props) => {
  const { setShowProfile, setState } = props;

  // eslint-disable-next-line prefer-const
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const EditProfile = (item) => {
    setShowProfile((prevState) => {
      return {
        ...prevState,
        profileId: item?.id,
      };
    });

    dispatch(showPatient(item.id));
    navigate('/dashboard/outpatient-department/edit-patient-profile', { replace: true });
  };

  const state = useSelector(selectBillingData);

  const viewDischarge = (id) => {

    setShowProfile({ isShowView: true, profileId: id });
  };

  const viewBilling = (id, billingId) => setShowProfile({ isShowView: true, profileId: id, billingId: billingId });

  useEffect(() => {
    // console.log('TABLE', state);
  }, [state]);

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Timestamp', accessor: 'createdAt' },
      { Header: 'Patient ID', accessor: 'id' },
      { Header: 'Patient Name', accessor: 'full_name' },
      { Header: 'Patient Class', accessor: 'patient_origin' },
      { Header: 'Status', accessor: 'patient_status' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      state?.map((dd) => ({
        id: dd?.patientId,
        mrn: dd?.MRN,
        full_name: dd?.full_name,
        patient_origin: dd?.patient_origin,
        patient_status: patientStatus(dd?.patient_status),
        createdAt: moment(dd?.createdAt).format('MMM-DD-YYYY hh:mm A'),
        status: patientStatus(dd?.patient_status),
      })),
    [state],
  );
  const handleTableAction = (eventKey, row) => {
    // const row = state.find((dd) => dd.id === id);
    const { laboratory_result } = row;

    const action = {
      complete: () => { },
      view: () => { },
      remove: () => { },
      cancel: () => { },
    };

    action[eventKey]();
  };

  return (
    <div className='roomReservationTable'>
      <div className='roomReservationTable'>
        <Table
          data={tableData}
          columns={tableColumns}
          customAction={(id) => {
            const row = state.find((dd) => dd.patientId === id);
            return (
              <><ReceiptSVG
                className='p-1'
                onClick={() => viewDischarge(id)} />
                <PesSignoSVG className='p-1' onClick={() => viewBilling(id, row.active_billing_id)} />
                <ClipBoardSVG /></>
            );
          }}
          pagination={['BOTTOM']}
        />
      </div>
    </div>
  );
};

export default BillingTable;
