/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-key */
/* eslint-disable camelcase */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
import { Container, Row, Col, Table, Form, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

// import components below
import LayoutReport from 'components/LayoutReport';
import TableUI from 'components/UI/TableUI';
import FormUI from 'components/UI/FormUI';
import './style.scss';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { useState, useEffect } from 'react';
import Admission from './components/admission';
import Discharge from './components/discharge';
import TransferIn from './components/transfer-in';
import TransferOut from './components/transfer-out';
import Deaths from './components/deaths';
import moment from 'moment';

const DailyCensus = () => {
  const input = useParams();

  const [dailyCensus, setDailyCensus] = useState('');
  const [remainingFromYesterday, setRemainingFromYesterday] = useState('');
  const [admissions, setAdmissions] = useState('');
  const [transferIns, setTransferIns] = useState('');
  const [totalOf123, setTotalOf123] = useState('');
  const [discharges, setDischarges] = useState('');
  const [transferOuts, setTransferOuts] = useState('');
  const [deaths, setDeaths] = useState('');
  const [totalOf567, setTotalOf567] = useState('');
  const [remainingAt12Midnight, setRemainingAt12Midnight] = useState('');
  const [totalInpatientDays, setTotalInpatientDays] = useState('');
  const [reportedBy, setReportedBy] = useState('');
  const [date, setDate] = useState('');
  const [service, setService] = useState('');
  const [endingMidnightDate, setEndingMidnightDate] = useState('');
  const [hospitalName] = useState('');
  const [floor, setFloor] = useState('');

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/report/daily_census`).then((response) => {
      console.log('census data: ', response.data.data);
      setDailyCensus(response.data.data);
    }, []);
  }, []);

  const params = {
    report_type: 'daily-census',
    remainingFromYesterday: remainingFromYesterday,
    admissions: admissions,
    transferIns: transferIns,
    totalOf123: totalOf123,
    discharges: discharges,
    transferOuts: transferOuts,
    deaths: deaths,
    totalOf567: totalOf567,
    remainingAt12Midnight: remainingAt12Midnight,
    totalInpatientDays: totalInpatientDays,
    reportedBy: reportedBy,
    date: date,
    service: service,
    hospitalName: dailyCensus.daily_census?.name_of_hospital,
    endingMidnightDate: endingMidnightDate,
    floor: floor,
  };

  const generatePdf = () => {
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/report/create-pdf`, params)
      .then((response) => {
        axios
          .get(`${process.env.REACT_APP_API_BASE_URL}/report/fetch-pdf`, {
            params: { file_name: response.data },
            responseType: 'blob',
          })
          .then((res) => {
            const pdfBlob = new Blob([res.data], { type: 'application/pdf' });

            saveAs(pdfBlob, 'Daily Census.pdf');
          });
      });
  };

  const redirectBackToPreviousPage = () => {
    window.history.back();
  };

  return (
    <LayoutReport pageTitle='Daily Census'>
      <row className='operation-report'>
        <Row className='header'>
          <h1>Daily Census</h1>
        </Row>
        <Container>
          <Row className='body'>
            <FormUI>
              <Row>
                <Col>
                  <Form.Group className='mb-3'>
                    <Form.Label>Name of Hospital</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter Here'
                      disabled
                      value={process.env.REACT_APP_HOSPITAL_NAME}
                    />
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group className='mb-3'>
                    <Form.Label>Service</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter Here'
                      onChange={(event) => {
                        setService(event.target.value);
                      }}
                      value={dailyCensus.daily_census?.service}
                      disabled
                    />
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group className='mb-3'>
                    <Form.Label>Floor/Section</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter Here'
                      onChange={(event) => {
                        setFloor(event.target.value);
                      }}
                      value={dailyCensus.daily_census?.floor_section}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col lg={3}>
                  <Form.Group className='mb-3'>
                    <Form.Label>For the 24-hour ending midnight of</Form.Label>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Control
                    type='date'
                    style={{ maxWidth: '240px' }}
                    onChange={(event) => {
                      setEndingMidnightDate(event.target.value);
                    }}
                    value={moment(
                      dailyCensus?.daily_census?.ending_midnight_of,
                      'MM/DD/YYYY',
                    ).format('YYYY-MM-DD')}
                    disabled
                  />
                </Col>
              </Row>
            </FormUI>
            <Row className='mb-3 mt-3'>
              <div className='mt-3'>
                <TableUI>
                  <Table>
                    <Admission></Admission>
                    <Discharge></Discharge>
                    <TransferIn></TransferIn>
                    <TransferOut></TransferOut>
                    <Deaths></Deaths>
                  </Table>
                  <center>
                    <label className='fw-bold'>CENSUS SUMMARY FOR THE DAY </label>
                  </center>

                  <Row>
                    <Col lg={8}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Label>
                          <label>1. Remaining from yesterday's midnight report:</label>
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col lg={3}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Control
                          type='text'
                          placeholder='Enter here'
                          onChange={(event) => {
                            setRemainingFromYesterday(event.target.value);
                          }}
                          value={
                            dailyCensus?.daily_census?.remaining_from_yesterday_midnight_report
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={8}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Label>
                          <label>2. Admissions:</label>
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col lg={3}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Control
                          type='text'
                          placeholder='Enter here'
                          onChange={(event) => {
                            setAdmissions(event.target.value);
                          }}
                          value={dailyCensus?.daily_census?.summary_admissions}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={8}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Label>
                          <label>3. Transfers IN from other floors:</label>
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col lg={3}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Control
                          type='text'
                          placeholder='Enter here'
                          onChange={(event) => {
                            setTransferIns(event.target.value);
                          }}
                          value={dailyCensus?.daily_census?.summary_transfer_from_other_floor}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={8}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Label>
                          <label>4. Total of 1,2 and 3:</label>
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col lg={3}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Control
                          type='text'
                          placeholder='Enter here'
                          onChange={(event) => {
                            setTotalOf123(event.target.value);
                          }}
                          value={dailyCensus?.daily_census?.total_of_1_2_and_3}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={8}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Label>
                          <label>5. Discharges (Alive) this census day:</label>
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col lg={3}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Control
                          type='text'
                          placeholder='Enter here'
                          onChange={(event) => {
                            setDischarges(event.target.value);
                          }}
                          value={dailyCensus?.daily_census?.discharged_alive}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={8}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Label>
                          <label>6. Transfers out to other floors:</label>
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col lg={3}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Control
                          type='text'
                          placeholder='Enter here'
                          onChange={(event) => {
                            setTransferOuts(event.target.value);
                          }}
                          value={dailyCensus?.daily_census?.summary_transfer_to_other_floor}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={8}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Label>
                          <label>7. Deaths:</label>
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col lg={3}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Control
                          type='text'
                          placeholder='Enter here'
                          onChange={(event) => {
                            setDeaths(event.target.value);
                          }}
                          value={dailyCensus?.daily_census?.summary_deaths}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={8}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Label>
                          <label>8. Total of 5,6 and 7:</label>
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col lg={3}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Control
                          type='text'
                          placeholder='Enter here'
                          onChange={(event) => {
                            setTotalOf567(event.target.value);
                          }}
                          value={dailyCensus?.daily_census?.total_of_5_6_and_7}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={8}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Label>
                          <label>9. Remaining at 12:00 midnight (4) minus (8):</label>
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col lg={3}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Control
                          type='text'
                          placeholder='Enter here'
                          onChange={(event) => {
                            setRemainingAt12Midnight(event.target.value);
                          }}
                          value={dailyCensus?.daily_census?.remaining_at_12_midnight}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={8}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Label>
                          <label>10. Total inpatient service days:</label>
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col lg={3}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Control
                          type='text'
                          placeholder='Enter here'
                          onChange={(event) => {
                            setTotalInpatientDays(event.target.value);
                          }}
                          value={dailyCensus?.daily_census?.total_inpatient_service_days}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={8}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Label>
                          <label>Report Prepared By:</label>
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col lg={3}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Control
                          type='text'
                          placeholder='Enter here'
                          onChange={(event) => {
                            setReportedBy(event.target.value);
                          }}
                          value={dailyCensus?.daily_census?.reported_by}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={8}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Label>
                          <label>Date:</label>
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col lg={3}>
                      <Form.Group className='mt-3 mb-3'>
                        <Form.Control
                          type='date'
                          placeholder='Enter here'
                          onChange={(event) => {
                            setDate(event.target.value);
                          }}
                          value={moment(dailyCensus?.daily_census?.date, 'MMMM, DD, YYYY').format(
                            'YYYY-MM-DD',
                          )}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </TableUI>
                <div className='d-flex justify-content-end mt-5'>
                  {typeof input.type !== 'string' ? (
                    <Button className='me-3' variant='save' onClick={generatePdf}>
                      GENERATE PDF
                    </Button>
                  ) : (
                    ''
                  )}

                  {typeof input.type !== 'string' ? (
                    <Button className='me-3' variant='save' onClick={generatePdf}>
                      Update DOH
                    </Button>
                  ) : (
                    ''
                  )}

                  <Button className='' variant='clear' onClick={redirectBackToPreviousPage}>
                    CANCEL
                  </Button>
                </div>
              </div>
            </Row>
          </Row>
        </Container>
      </row>
    </LayoutReport>
  );
};

export default DailyCensus;
