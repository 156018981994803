/* eslint-disable multiline-comment-style */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import axios from 'axios';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Modal, Form, FormControl } from 'react-bootstrap';

// components
import FormUI from 'components/UI/FormUI';

// import assets below
import './style.scss';
import { selectPatientItem, showPatient } from 'reducers/patientReducer';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

// main component
const ModalProcedure = ({ showProfile, setShowProfile }) => {
  const dispatch = useDispatch();

  const patientProfile = useSelector(selectPatientItem);

  const [rooms, setRooms] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [doctorList, setDoctorList] = useState([]);
  const [userData, setUserData] = useState({
    price: 0,
    remarks: '',
    isMajor: '',
    ctp_code: '',
    procedure: '',
    patientId: null,
    major_minor: '',
    procedure_description: '',
  });

  const loadRooms = (input) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterRooms(input));
      }, 1000);
    });
  };

  const resetState = () => {
    setUserData({
      procedure: '',
      ctp_code: '',
      procedure_description: '',
      remarks: '',
      major_minor: '',
      roomId: '',
    });
  };

  const handelRoom = (roomId) => {
    setUserData({
      ...userData,
      roomId: roomId,
    });
  };

  const loadDoctors = (input) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterDoctors(input));
      }, 1000);
    });
  };

  const filterRooms = (input) => {
    return rooms.filter((i) => {
      return i.value.toLowerCase().includes(input.toLowerCase());
    });
  };

  const handleChange = (key) => (e) => {
    setUserData({
      ...userData,
      [key]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    await axios
      .post('/requisition/procedure', userData)
      .then((response) => {
        if (response.data.error) {
          alert(response.data.error.message);
        } else {
          toast.success('Successfully created procedure');
          resetState();
          setUserData({
            ...userData,
            procedure: '',
            ctp_code: '',
            procedure_description: '',
            remarks: '',
            major_minor: '',
            patientId: patientProfile.id,
          });

          dispatch(showPatient(showProfile.profileId));
        }
      })
      .catch((err) => {
        err.response.data.errors.map((item) => {
          toast.warning(item?.msg);
        });
      });
  };

  const handleDoctor = (id) => {
    setUserData({ ...userData, userId: id });
  };

  const filterDoctors = (input) => {
    return doctorList.filter((i) => {
      return i.value.toLowerCase().includes(input.toLowerCase());
    });
  };

  const loadProcedures = (input) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterProcedures(input));
      }, 1000);
    });
  };

  const handleProcedure = (label, value, code, price, isMajor) => {
    const getMajorMinor = () => {
      switch (code) {
        case 'OPERA15934':
        case 'OPERA15940':
          return 'Minor';
        case 'OPERA20245':
        case 'OPERA26200':
        case 'OPERA26210':
        case 'OPERA26236':
        case 'OPERA31540':
        case 'OPERA31541':
        case 'OPERA42140':
        case 'OPERA46230':
        case 'OPERA46320':
        case 'OPERA53250':
        case 'OPERA68500':
        case 'OPERA68520':
        case 'OPERA69120':
          return 'Major';
        default:
          break;
      }
    };

    setUserData({
      ...userData,
      procedure: label,
      ctp_code: code,
      price: price,
      major_minor: getMajorMinor(),
      isMajor: isMajor,
    });
  };

  const filterProcedures = (input) => {
    return procedures.filter((i) => {
      return i.value.toLowerCase().includes(input.toLowerCase());
    });
  };

  useEffect(() => {
    /** Get Patients for auto complete suggestion */
    axios
      .get(`${process.env.REACT_APP_API_BASE_ACL}/api/users?roles[]=Doctor`)
      .then((response) => {
        const options = response.data?.data?.map((data) => ({
          id: data?.id,
          value: data?.attributes.full_name,
          label: data?.attributes.full_name,
        }));
        setDoctorList(options);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, [userData]);

  useEffect(() => {
    if (patientProfile) {
      setUserData({ ...userData, patientId: patientProfile.id });
    }
  }, [patientProfile]);

  useEffect(() => {
    axios
      .get('/public/procedures', userData)
      .then((response) => {
        const options = response.data?.map((data) => ({
          id: data?.id,
          value: data?.procedure,
          label: data?.procedure,
          code: data?.ctp_code,
          price: data?.price,
          isMajor: data?.isMajor,
        }));
        setProcedures(options);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get('/room', {
        params: {
          type: ['operating room', 'delivery room'],
        },
      })
      .then((response) => {
        const options = response?.data?.data.room_list?.map((data) => ({
          id: data?.id,
          value: data?.type,
          label: data?.type + ' - ' + data?.floor + ' floor - ' + data?.room,
          // label: data?.type,
          floor: data?.floor,
          room: data?.room,
          bed: data?.bed,
          price: data?.price,
          isMajor: data?.isMajor,
        }));
        setRooms(options);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Modal
      size='lg'
      show={showProfile.procedure}
      onHide={() =>
        setShowProfile((prevState) => {
          return {
            ...prevState,
            procedure: false,
          };
        })
      }
      aria-labelledby='Lab Request Modal'
      className='add-procedure-modal'
    >
      <Modal.Body>
        <div className='add-procedure-request'>
          <div className='d-flex header'>
            <p>PROCEDURE</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => {
                setShowProfile((prevState) => {
                  return { ...prevState, procedure: false };
                });
                resetState();
              }}
            />
          </div>
        </div>

        <FormUI>
          <Form className='procedure-form'>
            <Row>
              <Col md={6}>
                <Form.Group className='mb-3'>
                  <Form.Label>Procedure</Form.Label>
                  <div style={{ width: '100%', margin: '0 auto' }}>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      // value={diagnosis}
                      loadOptions={loadProcedures}
                      onChange={(e) =>
                        handleProcedure(e.label, e.value, e.code, e.price, e.isMajor)
                      }
                      placeholder='Search Procedure'
                      styles={selectStyles}
                    />
                  </div>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Physician Name</Form.Label>
                  <div style={{ width: '100%', margin: '0 auto' }}>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      // value={diagnosis}
                      loadOptions={loadDoctors}
                      onChange={(e) => handleDoctor(e.id)}
                      placeholder='Search Doctor'
                      styles={selectStyles}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <Form.Group className='mb-4'>
                  <Form.Label>CPT Code</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-grey'
                    placeholder='Enter Here'
                    aria-readonly
                    readOnly
                    value={userData.ctp_code}
                  />
                </Form.Group>
              </Col>

              <Col sm={5}>
                <Form.Group className='mb-4'>
                  <Form.Label>Major/ Minor?</Form.Label>
                  <Form.Select
                    onChange={handleChange('major_minor')}
                    value={userData.major_minor}
                    disabled
                  >
                    <option hidden>-Select-</option>
                    <option value='Major'>Major</option>
                    <option value='Minor'>Minor</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Procedure Description</Form.Label>
                  <FormControl
                    as='textarea'
                    rows={2}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.procedure_description}
                    onChange={handleChange('procedure_description')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={5}>
                <Form.Group className='mb-4'>
                  <Form.Label>Room Request</Form.Label>
                  <div style={{ width: '100%', margin: '0 auto' }}>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      // value={diagnosis}
                      loadOptions={loadRooms}
                      onChange={(e) => handelRoom(e.id)}
                      placeholder='Search Room'
                      styles={selectStyles}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Remarks</Form.Label>
                  <FormControl
                    as='textarea'
                    rows={2}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.remarks}
                    onChange={handleChange('remarks')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-4 buttons-cont'>
                  <Button
                    className='add-button'
                    variant='primary'
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    Add
                  </Button>
                  <Button
                    className='cancel-button'
                    vvariant='secondary'
                    onClick={() => {
                      resetState();
                      setShowProfile((prevState) => {
                        return { ...prevState, procedure: false };
                      });
                    }}
                  >
                    Cancel
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

ModalProcedure.defaultProps = {
  showProfile: () => {},
  setShowProfile: () => {},
};

ModalProcedure.propTypes = {
  showProfile: PropTypes.func,
  setShowProfile: PropTypes.func,
};

const selectStyles = {
  control: (styles) => ({
    ...styles,
    padding: '0.45em',
    color: '#404040',
    borderColor: '#c8c8c8',
    borderRadius: '10px',
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: '100',
  }),
};

export default ModalProcedure;
