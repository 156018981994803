/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

// Packages
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Row, Col, Button, Modal, Form, Table } from 'react-bootstrap';

// Components
import FormUI from 'components/UI/FormUI';
import TableUI from 'components/UI/TableUI';

// import assets below
import './style.scss';
import { ReactComponent as CirclePlus } from 'assets/svg/circle-plus.svg';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import { ReactComponent as RedXMark } from 'assets/svg/circle-xmark-red.svg';

// main component
const CreateAdjustmentModal = ({ showProfile, setShowProfile }) => {
  const modalSampleData = [
    {
      id: 1,
      item_code: 'MED0001',
      item_name: 'MED0001',
      UOM: 'Piece',
      item_group: 'Medical Supplies',
      unit_price: '12.00',
      on_hand: '400',
    },
    {
      id: 2,
      item_code: 'MED0001',
      item_name: 'MED0001',
      UOM: 'Piece',
      item_group: 'Medical Supplies',
      unit_price: '12.00',
      on_hand: '300',
    },
    {
      id: 3,
      item_code: 'MED0001',
      item_name: 'MED0001',
      UOM: 'Piece',
      item_group: 'Medical Supplies',
      unit_price: '12.00',
      on_hand: '100',
    },
  ];

  const modalSecondSampleData = [
    {
      id: 1,
      item_code: 'MED0001',
      item_name: 'Elastic Bandage',
      UOM: 'Piece',
      quantity: 100,
      stock_adjustment_reason: 'Consigment Out (-)',
      remarks: 'Consigment by Andrew E',
    },
    {
      id: 2,
      item_code: 'MED0001',
      item_name: 'Elastic Bandage',
      UOM: 'Piece',
      quantity: 100,
      stock_adjustment_reason: 'Consigment Out (-)',
      remarks: 'Consigment by Andrew E',
    },
  ];

  return (
    <Modal
      size='lg'
      show={showProfile.showCreateNewAdjustment}
      onHide={() =>
        setShowProfile((prevState) => {
          return {
            ...prevState,
            showCreateNewAdjustment: false,
          };
        })
      }
      aria-labelledby='CREATE NEW ADJUSTMENT'
      className='create-adjustment-modal'
    >
      <Modal.Body>
        <div className='create-adjustment-request'>
          <div className='d-flex header'>
            <p>CREATE NEW ADJUSTMENT</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => {
                setShowProfile((prevState) => {
                  return { ...prevState, showCreateNewAdjustment: false };
                });
              }}
            />
          </div>
        </div>

        <FormUI>
          <Form className='adjustment-form'>
            <Row>
              <Col sm={3}>
                <Form.Group className='mb-2'>
                  <Form.Label>Item Code/Name</Form.Label>
                  <Form.Select>
                    <option>Main Warehouse</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group className='mb-2'>
                  <Form.Label>Item Group</Form.Label>
                  <Form.Select>
                    <option>- Select -</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col>
                <div className='buttonWrapper mb-2'>
                  <Button variant='search'>GO</Button>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <TableUI>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Item Code</th>
                        <th>Item Name</th>
                        <th>UOM</th>
                        <th>Item Group</th>
                        <th>Unit Price</th>
                        <th>On Hand</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {modalSampleData &&
                        modalSampleData.map((data) => {
                          return (
                            <tr key={data.code}>
                              <td>{data.item_code}</td>
                              <td>{data.item_name}</td>
                              <td>{data.UOM}</td>
                              <td>{data.item_group}</td>
                              <td>{data.unit_price}</td>
                              <td>{data.on_hand}</td>
                              <td>
                                <CirclePlus style={{ width: '1.5em' }} />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </TableUI>
              </Col>
            </Row>

            <Row className='mb-4 mt-4'>
              <Col className='adjustment-item-list'>
                <h6 className='mb-2'>List of Items for Adjustment</h6>

                <TableUI>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Item Code</th>
                        <th>Item Name</th>
                        <th>UOM</th>
                        <th>Quantity</th>
                        <th>Stock Adjustment Reason</th>
                        <th>Remarks</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {modalSecondSampleData &&
                        modalSecondSampleData.map((data) => {
                          return (
                            <tr key={data.id}>
                              <td>{data.item_code}</td>
                              <td>{data.item_name}</td>
                              <td>{data.UOM}</td>
                              <td>{data.quantity}</td>
                              <td>{data.stock_adjustment_reason}</td>
                              <td>{data.remarks}</td>
                              <td>
                                <RedXMark style={{ width: '1.5em' }} />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </TableUI>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-2 buttons-cont'>
                  <Button className='create-adjustment' variant='primary'>
                    CREATE ADJUSTMENT
                  </Button>
                  <Button
                    className='cancel-button'
                    onClick={() => {
                      setShowProfile((prevState) => {
                        return { ...prevState, showCreateNewAdjustment: false };
                      });
                    }}
                  >
                    Cancel
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

CreateAdjustmentModal.defaultProps = {
  showProfile: {},
  setShowProfile: () => {},
};

CreateAdjustmentModal.propTypes = {
  showProfile: PropTypes.object,
  setShowProfile: PropTypes.func,
};

export default CreateAdjustmentModal;
