/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Modal, Form, FormControl, InputGroup } from 'react-bootstrap';

// import assets below
import './style.scss';
import FormUI from 'components/UI/FormUI';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';
import { useDispatch, useSelector } from 'react-redux';
import { selectPatientItem, showPatient } from 'reducers/patientReducer';
import axios from 'axios';
import { toast } from 'react-toastify';
import { selectPatientVisitDetails } from 'reducers/medicalRecordReducer';

// main component
const ModalAssessmentEdit = ({ showModal, setShowModal, showProfile, setShowProfile }) => {
  const [id, setId] = useState('');
  const [userData, setUserData] = useState({
    RN: false,
    RPN: false,
    LPN: false,
    acute: false,
    prognosis: '',
    chronic: false,
    homemaker: false,
    palliative: false,
    convalescent: false,
    is_infectious: false,
    current_diagnosis: '',
    past_medical_history: '',
    surgical_procedures_and_dates: '',
    pertinentSigns: [],
  });

  const [hasEdit, setEditable] = useState(true);

  const [item, setItem] = useState({ condition_classified_as: '', another: 'another' });

  const { condition_classified_as } = item;

  const [levelOfCare, setLevelOfCare] = useState({ level_of_care: '', another: 'another' });

  const { level_of_care } = levelOfCare;

  const handleIsInfectious = (e) => {
    e.persist();
    setItem((prevState) => ({
      ...prevState,
      is_infectious: e.target.value,
    }));
    setUserData({ ...userData, is_infectious: e.target.value });
  };

  const handleChangeRadio = (e) => {
    e.persist();
    setItem((prevState) => ({
      ...prevState,
      condition_classified_as: e.target.value,
    }));
    setUserData({ ...userData, condition_classified_as: e.target.value });
  };

  const handleChangeRadioLevelOfCare = (e) => {
    e.persist();
    setLevelOfCare((prevState) => ({
      ...prevState,
      level_of_care: e.target.value,
    }));
    setUserData({ ...userData, level_of_care: e.target.value });
  };

  // const patientProfile = useSelector(selectPatientItem);
  const patientProfile = useSelector(selectPatientVisitDetails);
  const patientMedicalInfo = patientProfile?.medical_information;

  const getValue = () => {
    console.log('USER DATA', userData);
    patientMedicalInfo !== null && setUserData(patientMedicalInfo);
  };

  const handleChange = (key) => (e) => {
    if (e.target.type === 'checkbox') {
      if (e.target.checked) {
        setUserData({
          ...userData,
          [key]: true,
        });
      } else {
        setUserData({
          ...userData,
          [key]: false,
        });
      }

      return;
    }

    setUserData({
      ...userData,
      [key]: e.target.value,
    });
  };

  // Submit data here
  const handleSubmit = async () => {
    userData.patientId = id;

    await axios
      .post('/medical_information', userData)
      .then((response) => {
        toast.success('Saved Medical Information');
        setTimeout(() => {
          setShowModal((prevState) => {
            return {
              ...prevState,
              isShowEdit: false,
            };
          });
        }, 1500);
      })
      .catch((err) => {
        err.response.data.errors.map((item) => {
          toast.warning(item?.msg);
        });
      });
    /*

    */
  };

  const handleMultipleCheckbox = (e) => {
    const { name, value, checked } = e.target;

    if (checked) {
      // eslint-disable-next-line prefer-const
      let newData = {
        name: name,
      };

      if (name === 'pain' || name === 'others') {
        newData.text = value;
      }

      const finalData = userData?.pertinentSigns?.concat([newData]);

      setUserData((prev) => {
        return {
          ...prev,
          pertinentSigns: finalData,
        };
      });

      return;
    }

    const minusData = userData?.pertinentSigns.filter((d) => d?.name !== name);

    setUserData((prev) => {
      return {
        ...prev,
        pertinentSigns: minusData,
      };
    });
  };

  const checkForValue = (type) => {
    if (!userData?.pertinent_signs_and_symptoms) return;

    return userData?.pertinent_signs_and_symptoms.some((data) => data?.details === type);
  };

  const handleChangePertinenSigns = (e) => {
    const { name, value } = e.target;

    const newData = userData?.pertinentSigns.map((dd) => {
      if (dd?.name === name) {
        return {
          ...dd,
          text: value,
        };
      }

      return dd;
    });

    setUserData((prev) => {
      return {
        ...prev,
        pertinentSigns: newData,
      };
    });
  };

  useEffect(() => {
    setId(showProfile.profileId);
    /* axios
      .get(`/medical_information/${showProfile.profileId}`)
      .then((response) => {
        setUserData((prev) => ({
          ...prev,
          prognosis: response.data.data?.prognosis,
          is_infectious: response.data.data?.is_infectious,
          level_of_care: response.data.data?.level_of_care,
          family_history: response.data.data?.family_history,
          current_diagnosis: response.data.data?.current_diagnosis,
          past_medical_history: response.data.data?.past_medical_history,
          personal_social_history: response.data.data?.personal_social_history,
          condition_classified_as: response.data.data?.condition_classified_as,
          surgical_procedures_and_dates: response.data.data?.surgical_procedures_and_dates,
        }));
      }, [])
      .catch((err) => {
        console.log(err);
      }, []); */
  }, [patientProfile, showProfile]);

  /* useEffect(() => {
    console.log('userData', userData);
  }, [userData]); */

  return (
    <Modal
      size='lg'
      onEnter={() => getValue()}
      show={showModal.isShowEdit}
      onHide={() =>
        setShowModal((prevState) => {
          return {
            ...prevState,
            isShowEdit: false,
          };
        })
      }
      aria-labelledby='Assessment Edit'
      className='assessment-edit-modal'
    >
      <Modal.Body>
        <div className='assessment-edit'>
          <div className='d-flex header'>
            <p>CURRENT MEDICAL INFORMATION</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => {
                setShowModal((prevState) => {
                  return {
                    ...prevState,
                    isShowEdit: false,
                  };
                });
                // setShowProfile({ profileId: null });
              }}
            />
          </div>
        </div>

        <FormUI>
          <Form className='edit-form'>
            <Row>
              <Col md={10}>
                <Form.Group className='mb-4 inline-group'>
                  <Form.Label>Current Diagnosis:</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-grey'
                    placeholder='Enter Here'
                    value={userData?.current_diagnosis}
                    onChange={handleChange('current_diagnosis')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={10}>
                <Form.Group className='mb-4 inline-group'>
                  <Form.Label>Prognosis:</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-grey'
                    placeholder='Enter Here'
                    value={userData?.prognosis}
                    onChange={handleChange('prognosis')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={10}>
                <Form.Group className='mb-4 inline-group'>
                  <Form.Label>Surgical Procedures and Dates:</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-grey'
                    placeholder='Enter Here'
                    value={userData?.surgical_procedures_and_dates}
                    onChange={handleChange('surgical_procedures_and_dates')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Past Medical History</Form.Label>
                  <FormControl
                    as='textarea'
                    rows={1}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData?.past_medical_history}
                    onChange={handleChange('past_medical_history')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Family History</Form.Label>
                  <FormControl
                    as='textarea'
                    rows={1}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData?.family_history}
                    onChange={handleChange('family_history')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Personal Social History</Form.Label>
                  <FormControl
                    as='textarea'
                    rows={1}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData?.personal_social_history}
                    onChange={handleChange('personal_social_history')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mb-4 check-boxes-cont'>
                  <Form.Label>
                    Is Infectious:<span className='asterisk'> *</span>
                  </Form.Label>
                  <Form.Check
                    value='Yes'
                    type='radio'
                    aria-label='radio 1'
                    label='Yes'
                    onChange={handleIsInfectious}
                    checked={userData?.is_infectious === 'Yes'}
                  />
                  <Form.Check
                    value='No'
                    type='radio'
                    aria-label='radio 2'
                    label='No'
                    onChange={handleIsInfectious}
                    checked={userData?.is_infectious === 'No'}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mb-4 check-boxes-cont'>
                  <Form.Label>
                    Condition Classified as:<span className='asterisk'> *</span>
                  </Form.Label>
                  <Form.Check
                    value='Acute'
                    type='radio'
                    aria-label='radio 1'
                    label='Acute'
                    onChange={handleChangeRadio}
                    checked={userData?.condition_classified_as === 'Acute'}
                  />
                  <Form.Check
                    value='Chronic'
                    type='radio'
                    aria-label='radio 2'
                    label='Chronic'
                    onChange={handleChangeRadio}
                    checked={userData?.condition_classified_as === 'Chronic'}
                  />
                  <Form.Check
                    value='Convalescent'
                    type='radio'
                    aria-label='radio 2'
                    label='Convalescent'
                    onChange={handleChangeRadio}
                    checked={userData?.condition_classified_as === 'Convalescent'}
                  />
                  <Form.Check
                    value='Palliative'
                    type='radio'
                    aria-label='radio 2'
                    label='Palliative'
                    onChange={handleChangeRadio}
                    checked={userData?.condition_classified_as === 'Palliative'}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mb-4 multiple-checkbox-container'>
                  <Form.Label>Pertinent signs and Symptoms on Admission</Form.Label>

                  <div className='checkbox-inputs'>
                    <div className='checkbox-column'>
                      <Form.Check
                        type='checkbox'
                        name='alteredMentalSensorium'
                        label='Altered Mental Sensorium'
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('alteredMentalSensorium')}
                      />

                      <Form.Check
                        type='checkbox'
                        name='abominalPain'
                        label='Abdominal cramp/pain'
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('abominalPain')}
                      />

                      <Form.Check
                        type='checkbox'
                        name='anorexia'
                        label='Anorexia'
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('anorexia')}
                      />

                      <Form.Check
                        type='checkbox'
                        name='bleedingGums'
                        label='Bleeding gums'
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('bleedingGums')}
                      />

                      <Form.Check
                        type='checkbox'
                        name='bodyWeakness'
                        label='Body weakness'
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('bodyWeakness')}
                      />

                      <Form.Check
                        type='checkbox'
                        name='blurringOfVision'
                        label='Blurring of vision'
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('blurringOfVision')}
                      />

                      <Form.Check
                        type='checkbox'
                        name='chestPainDiscomfort'
                        label='Chest pain/discomfort'
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('chestPainDiscomfort')}
                      />

                      <Form.Check
                        type='checkbox'
                        name='constipation'
                        label='Constipation'
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('constipation')}
                      />

                      <Form.Check
                        name='cough'
                        label='Cough'
                        type='checkbox'
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('cough')}
                      />
                    </div>

                    <div className='checkbox-column'>
                      <Form.Check
                        type='checkbox'
                        name='diarrhea'
                        label='Diarrhea'
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('diarrhea')}
                      />

                      <Form.Check
                        type='checkbox'
                        name='dizziness'
                        label='Dizziness'
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('dizziness')}
                      />

                      <Form.Check
                        type='checkbox'
                        name='dysphagia'
                        label='Dysphagia'
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('dysphagia')}
                      />

                      <Form.Check
                        type='checkbox'
                        name='dyspnea'
                        label='Dyspnea'
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('dyspnea')}
                      />

                      <Form.Check
                        type='checkbox'
                        name='dysuria'
                        label='Dysuria'
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('dysuria')}
                      />

                      <Form.Check
                        type='checkbox'
                        name='epistaxis'
                        label='Epistaxis'
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('epistaxis')}
                      />

                      <Form.Check
                        type='checkbox'
                        name='fever'
                        label='Fever'
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('fever')}
                      />

                      <Form.Check
                        type='checkbox'
                        name='frequencyOfUrination'
                        label='Frequency of urination '
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('frequencyOfUrination')}
                      />

                      <Form.Check
                        type='checkbox'
                        name='headache'
                        label='Headache'
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('headache')}
                      />
                    </div>

                    <div className='checkbox-column'>
                      <Form.Check
                        type='checkbox'
                        name='hematemesis'
                        label='Hematemesis'
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('hematemesis')}
                      />

                      <Form.Check
                        type='checkbox'
                        name='hematuria'
                        label='Hematuria'
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('hematuria')}
                      />

                      <Form.Check
                        type='checkbox'
                        name='hemoptysis'
                        label='Hemoptysis'
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('hemoptysis')}
                      />

                      <Form.Check
                        type='checkbox'
                        name='irritability'
                        label='Irritability'
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('irritability')}
                      />

                      <Form.Check
                        type='checkbox'
                        name='jaundice'
                        label='Jaundice'
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('jaundice')}
                      />

                      <Form.Check
                        type='checkbox'
                        name='lowerExtrem1tyEdema'
                        label='Lower extrem1ty edema'
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('lowerExtrem1tyEdema')}
                      />

                      <Form.Check
                        type='checkbox'
                        name='myalgia'
                        label='Myalgia'
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('myalgia')}
                      />

                      <Form.Check
                        type='checkbox'
                        name='orthopnea'
                        label='Orthopnea'
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('orthopnea')}
                      />

                      <div className='with-textbox'>
                        <Form.Check
                          name='pain'
                          label='Pain'
                          type='checkbox'
                          checked={checkForValue('pain')}
                          onChange={handleMultipleCheckbox}
                        />

                        <FormControl
                          name='pain'
                          className='textbox'
                          disabled={!checkForValue('pain')}
                          onChange={handleChangePertinenSigns}
                          value={userData?.pertinentSigns?.pain?.text}
                        />
                      </div>
                    </div>

                    <div className='checkbox-column'>
                      <Form.Check
                        type='checkbox'
                        name='palpitations'
                        label='Palpitations'
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('palpitations')}
                      />

                      <Form.Check
                        type='checkbox'
                        name='seizures'
                        label='Seizures'
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('seizures')}
                      />

                      <Form.Check
                        type='checkbox'
                        name='skinRashes'
                        label='Skin rashes'
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('skinRashes')}
                      />

                      <Form.Check
                        type='checkbox'
                        name='stool'
                        label='Stool, bloody/black tarry/mucoid'
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('stool')}
                      />

                      <Form.Check
                        type='checkbox'
                        name='sweating'
                        label='Sweating'
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('sweating')}
                      />

                      <Form.Check
                        type='checkbox'
                        name='urgency'
                        label='Urgency'
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('urgency')}
                      />

                      <Form.Check
                        type='checkbox'
                        name='vomiting'
                        label='Vomiting'
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('vomiting')}
                      />

                      <Form.Check
                        type='checkbox'
                        name='weightLoss'
                        label='Weight loss'
                        onChange={handleMultipleCheckbox}
                        checked={checkForValue('weightLoss')}
                      />

                      <div className='with-textbox'>
                        <Form.Check
                          name='others'
                          label='Others'
                          type='checkbox'
                          checked={checkForValue('others')}
                          onChange={handleMultipleCheckbox}
                        />

                        <FormControl
                          name='others'
                          className='textbox'
                          disabled={!checkForValue('others')}
                          onChange={handleChangePertinenSigns}
                          value={userData?.pertinentSigns?.others?.text}
                        />
                      </div>
                    </div>
                  </div>
                </Form.Group>
              </Col>
            </Row>

            {/* <Row>
              <Col>
                <Form.Group className='mb-4 check-boxes-cont'>
                  <Form.Label>
                    Level of care Recommended:<span className='asterisk'> *</span>
                  </Form.Label>
                  <Form.Check
                    value='RN'
                    type='radio'
                    aria-label='radio 1'
                    label='RN'
                    onChange={handleChangeRadioLevelOfCare}
                    checked={userData?.level_of_care === 'RN'}
                  />
                  <Form.Check
                    value='RPN'
                    type='radio'
                    aria-label='radio 2'
                    label='RPN'
                    onChange={handleChangeRadioLevelOfCare}
                    checked={userData?.level_of_care === 'RPN'}
                  />
                  <Form.Check
                    value='LPN'
                    type='radio'
                    aria-label='radio 2'
                    label='LPN'
                    onChange={handleChangeRadioLevelOfCare}
                    checked={userData?.level_of_care === 'LPN'}
                  />
                  <Form.Check
                    value='Homemaker'
                    type='radio'
                    aria-label='radio 2'
                    label='Homemaker'
                    onChange={handleChangeRadioLevelOfCare}
                    checked={userData?.level_of_care === 'Homemaker'}
                  />
                </Form.Group>
              </Col>
            </Row> */}

            <Row>
              <Col>
                <Form.Group className='mt-4 buttons-cont'>
                  <Button
                    className='add-button'
                    variant='primary'
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    className='cancel-button'
                    vvariant='secondary'
                    onClick={() => {
                      setShowModal((prevState) => {
                        return {
                          ...prevState,
                          isShowEdit: false,
                        };
                      });
                    }}
                  >
                    Cancel
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAssessmentEdit;
