/* eslint-disable multiline-comment-style */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { conformsTo } from 'lodash';
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Form,
  Table,
  Button,
  Popover,
  Accordion,
  FormControl,
  OverlayTrigger,
} from 'react-bootstrap';

import PatientSelection from './PatientSelection';
import ConditionalRender from 'components/ConditionalRender';
import PrintContent from './PrintComponents/DismemberedBodyParts';
import { selectActivePatients } from 'reducers/medicalRecordReducer';
import useCreateRecordResponse from './hooks/useCreateRecordResponse';
import { getPatients, selectPatientData } from 'reducers/patientReducer';
import {
  getMedicalForms,
  getMedicalRecordID,
  getMedicalFormsbyID,
  createDismembermentForm,
} from 'reducers/thunks/medicalRecordThunks';

const DismemberedBodyParts = ({ setShowScreen, setViewForm, viewForm, setIsEdit, isEdit }) => {
  const dispatch = useDispatch();

  const printRef = useRef(null);

  const logs = useSelector(selectActivePatients);
  const patientSelection = useSelector(selectPatientData);

  const [isAdd, setIsAdd] = useState(false);
  const [checked, setChecked] = useState(false);
  const [defaultData, setDefaultData] = useState();
  const [validated, setValidated] = useState(false);
  const [showPatientSelection, setShowPatientSelection] = useState(false);
  const [trigger, setTrigger] = useState({ trigger: false, patientID: false, recordID: false });
  const [formData, setFormData] = useState({
    name_of_hospital: '',
    address: '',
    cert_no: '',

    part_of_body_A: '',
    laterality_A: '',
    part_of_body_B: '',
    laterality_B: '',
    part_of_body_C: '',
    laterality_C: '',
    accident_date_time: '',
    operation_date_time: '',
    remarks: '',
    manner_type: '',
    indication: '',

    place_of_amputation_hospital: '',
    place_of_amputation_clinic: '',
    place_of_amputation_institution: '',
    place_of_amputation_address_in_case_of_accident: '',

    amputee_fname: '',
    amputee_mname: '',
    amputee_lname: '',
    amputee_sex: '',
    amputee_age: '',
    amputee_citizenship: '',
    amputee_occupation: '',
    amputee_house_no: '',
    amputee_street: '',
    amputee_brgy: '',
    amputee_city_municipality: '',

    certification_signature: '',
    certification_name_in_print: '',
    certification_title_or_position: '',
    certification_address: '',
    certification_date: '',

    informat_signature: '',
    informat_name_in_print: '',
    informat_relation_to_amputee: '',
    informat_address: '',

    prepared_by_signature: '',
    prepared_by_name_in_print: '',
    prepared_by_title_or_position: '',
    prepared_by_address: '',
    prepared_by_date: '',
    manner_of_disposal: '',

    permit_number: '',
    permit_date_issued: '',
    permit_issued_by: '',
    name_and_address_of_cemetery_or_cremator: '',

    patientId: Math.random(),
    type: 'cert_of_dismembered_body_part',
  });

  const isAdding = isAdd === true;
  const isEditing = isEdit === true;
  const isNotEditing = isEdit === false;

  // For Edit
  const saveChanges = async () => {
    setTrigger({ ...trigger, trigger: true, recordID: true });

    const res = await dispatch(
      createDismembermentForm({
        ...formData,
        method: 'put',
        type: 'cert_of_dismembered_body_part',
      }),
    );

    if (res.payload.status === 'Success') {
      setIsEdit(false);
      setDefaultData(formData);

      return;
    }

    toast.error('Record is not Created/Updated');
  };

  const handleCheck = (key, e) => {
    if (checked) {
      setFormData({
        ...formData,
        [key]: e.target.value,
      });
      handleChange();
    }
    return;
  };

  const handleChange = (key) => (e) => {
    setFormData((prev) => {
      return { ...prev, [key]: e.target.value };
    });

    return;
  }; // For Add

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (!form.checkValidity() === false) {
      setTrigger({ ...trigger, trigger: true });
      setIsEdit(false);
      dispatch(createDismembermentForm({ ...formData, method: 'post' }));
      // toast.success('Successfully created record.');
      setIsAdd(false);
      setValidated(false);
      return;
    }
    toast.warning('Fill up the  fields');
    setValidated(true);
  };

  const handleShowPatientSelection = () => {
    setShowPatientSelection(true);
    return;
  };

  const handleClosePatientSelection = () => {
    setShowPatientSelection(false);
    return;
  };

  const checkKey = (key) => {
    if (key === 'age') {
      return 'number';
    } else if (key === 'confined_start_date' || key === 'confined_end_date') {
      return 'date';
    } else {
      return 'text';
    }
  };

  const textBox = (key) => {
    return (
      <FormControl
        type={`${key === 'age' ? 'number' : 'text'}`}
        disabled={!isEdit}
        style={{ width: `${isEdit ? '' : `${formData[key]?.length}ch`}` }}
        className={`${isEdit ? 'editable' : 'non-editable'} ${key === 'age' ? 'age' : ''}`}
        value={formData[key]}
        onChange={handleChange(key)}
      />
    );
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    if (viewForm?.patientID) {
      setTrigger({ ...trigger, patientID: true });
      const patientid = viewForm?.patientID;
      dispatch(getMedicalFormsbyID(patientid));
    }
  }, []);

  useEffect(() => {
    dispatch(getPatients());
    if (Object.keys(viewForm.data).length === 0) {
      setIsAdd(true);
      setIsEdit(true);
      return;
    }

    // for refactor: externalize below axios code in to thunk
    const recordId = viewForm?.data?.id;
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/medical_records/form/${recordId}`)
      .then((response) => {
        // console.log('record info: ', response.data?.data?.mr_cert_of_dismembered_body_part);
        setFormData(response.data?.data?.mr_cert_of_dismembered_body_part);
        setDefaultData(response.data?.data?.mr_cert_of_dismembered_body_part);
      })
      .catch((error) => console.log('error fetching record info: ', error));
  }, []);

  useEffect(() => {
    const {
      isSuccess,
      isFailed,
      isFetchViewSuccess,
      isFetchRecordSuccess,
      patientID,
      medicalRecordID,
      successData,
    } = logs;

    useCreateRecordResponse({
      isSuccess,
      isFailed,
      isFetchViewSuccess,
      isFetchRecordSuccess,
      patientID,
      medicalRecordID,
      trigger,
      setTrigger,
      setIsEdit,
      setShowScreen,
      dispatch,
    });

    /* if (isFetchRecordSuccess && trigger.recordID) {
      const data = medicalRecordID.mr_cert_of_dismembered_body_part;
      setFormData(data);
    } */
  }, [logs]);

  return (
    <>
      <div style={{ display: 'none' }}>
        <PrintContent ref={printRef} data={formData} viewFormType={viewForm?.type} />
      </div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className='mb-4'>
          <Col className='first-row'>
            <Form.Group className='input-group'>
              <FormControl
                disabled={!isEdit}
                placeholder='Enter Here'
                value={formData?.name_of_hospital}
                onChange={handleChange('name_of_hospital')}
                className='text-center'
              />
              <Form.Label>Name of Healthcare Facility</Form.Label>
            </Form.Group>

            <Form.Group className='input-group'>
              <FormControl
                disabled={!isEdit}
                placeholder='Enter Here'
                value={formData?.address}
                onChange={handleChange('address')}
                className='text-center'
              />
              <Form.Label>Address</Form.Label>
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col className='second-row'>
            <Form.Group className='input-group'>
              <Form.Label>Certificate No.</Form.Label>
              <FormControl
                disabled={!isEdit}
                placeholder='Enter Here'
                value={formData?.cert_no}
                onChange={handleChange('cert_no')}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col className='third-row'>
            <h2>{viewForm?.type}</h2>
          </Col>
        </Row>

        <Row className='mb-2'>
          <Col className='bordered-container'>
            <div className='first-column'>
              <h5>1. PART/S OF THE BODY</h5>

              <Form.Group className='input-group'>
                <Form.Label>A.</Form.Label>
                <FormControl
                  disabled={!isEdit}
                  placeholder='Enter Here'
                  value={formData?.part_of_body_A}
                  onChange={handleChange('part_of_body_A')}
                />
              </Form.Group>

              <Form.Group className='input-group'>
                <Form.Label>B.</Form.Label>
                <FormControl
                  disabled={!isEdit}
                  placeholder='Enter Here'
                  value={formData?.part_of_body_B}
                  onChange={handleChange('part_of_body_B')}
                />
              </Form.Group>

              <Form.Group className='input-group'>
                <Form.Label>C.</Form.Label>
                <FormControl
                  disabled={!isEdit}
                  placeholder='Enter Here'
                  value={formData?.part_of_body_C}
                  onChange={handleChange('part_of_body_C')}
                />
              </Form.Group>
            </div>
            <div className='second-column'>
              <h5>2. LATERALITY</h5>

              <Form.Group className='checkbox'>
                <Form.Label>A.</Form.Label>

                <div className='selections'>
                  <Form.Check
                    disabled={!isEdit}
                    type='checkbox'
                    id='left'
                    label='LEFT'
                    value='left'
                    checked={formData?.laterality_A?.toLowerCase() === 'left' ? true : false}
                    onChange={handleChange('laterality_A')}
                  />
                  <Form.Check
                    disabled={!isEdit}
                    type='checkbox'
                    id='right'
                    label='RIGHT'
                    value='right'
                    checked={formData?.laterality_A?.toLowerCase() === 'right' ? true : false}
                    onChange={handleChange('laterality_A')}
                  />
                </div>
              </Form.Group>

              <Form.Group className='checkbox'>
                <Form.Label>B.</Form.Label>

                <div className='selections'>
                  <Form.Check
                    disabled={!isEdit}
                    type='checkbox'
                    id='left'
                    label='LEFT'
                    value='left'
                    checked={formData?.laterality_B?.toLowerCase() === 'left' ? true : false}
                    onChange={handleChange('laterality_B')}
                  />
                  <Form.Check
                    disabled={!isEdit}
                    type='checkbox'
                    id='right'
                    label='RIGHT'
                    value='right'
                    checked={formData?.laterality_B?.toLowerCase() === 'right' ? true : false}
                    onChange={handleChange('laterality_B')}
                  />
                </div>
              </Form.Group>

              <Form.Group className='checkbox'>
                <Form.Label>C.</Form.Label>

                <div className='selections'>
                  <Form.Check
                    disabled={!isEdit}
                    type='checkbox'
                    id='left'
                    label='LEFT'
                    value='left'
                    checked={formData?.laterality_C?.toLowerCase() === 'left' ? true : false}
                    onChange={handleChange('laterality_C')}
                  />
                  <Form.Check
                    disabled={!isEdit}
                    type='checkbox'
                    id='right'
                    label='RIGHT'
                    value='right'
                    checked={formData?.laterality_C?.toLowerCase() === 'right' ? true : false}
                    onChange={handleChange('laterality_C')}
                  />
                </div>
              </Form.Group>
            </div>
            <div className='third-column'>
              <h5>3. MANNER / CAUSE OF AMPUTATION</h5>

              <Form.Group className='row-cont'>
                <Form.Group className='date-time input-group'>
                  <Form.Label>Accident, specify ( Date / Time )</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    type='datetime-local'
                    placeholder='Enter Here'
                    onChange={handleChange('accident_date_time')}
                    value={formData?.accident_date_time}
                  />
                </Form.Group>

                <Form.Group className='type input-group'>
                  <Form.Label>Type:</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.manner_type}
                    onChange={handleChange('manner_type')}
                  />
                </Form.Group>
              </Form.Group>

              <Form.Group className='row-cont'>
                <Form.Group className='date-time input-group'>
                  <Form.Label>Operation ( Date / Time )</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    type='datetime-local'
                    placeholder='Enter Here'
                    value={formData?.operation_date_time}
                    onChange={handleChange('operation_date_time')}
                  />
                </Form.Group>

                <Form.Group className='type input-group'>
                  <Form.Label>Indication:</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.indication}
                    onChange={handleChange('indication')}
                  />
                </Form.Group>
              </Form.Group>

              <Form.Group className='row-cont'>
                <Form.Group className='date-time input-group'>
                  <Form.Label>Others, specify</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    type='text'
                    placeholder='Enter Here'
                    value={formData?.remarks}
                    onChange={handleChange('remarks')}
                  />
                </Form.Group>
              </Form.Group>
            </div>
          </Col>
        </Row>

        <Row className='mb-2'>
          <Col className='bordered-container'>
            <div className='first-column align-items-start'>
              <h5>PLACE OF APUTATION</h5>

              <div className='one-row'>
                <Form.Group className='input-group'>
                  <Form.Label>Name of Hospital</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.place_of_amputation_hospital}
                    onChange={handleChange('place_of_amputation_hospital')}
                  />
                </Form.Group>

                <Form.Group className='input-group'>
                  <Form.Label>Clinic</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.place_of_amputation_clinic}
                    onChange={handleChange('place_of_amputation_clinic')}
                  />
                </Form.Group>

                <Form.Group className='input-group'>
                  <Form.Label>Institution</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.place_of_amputation_institution}
                    onChange={handleChange('place_of_amputation_institution')}
                  />
                </Form.Group>
              </div>

              <div className='one-row'>
                <Form.Group className='input-group'>
                  <Form.Label>Address in case of accident</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.place_of_amputation_address_in_case_of_accident}
                    onChange={handleChange('place_of_amputation_address_in_case_of_accident')}
                  />
                </Form.Group>
              </div>
            </div>
          </Col>
        </Row>

        <Row className='mb-2'>
          <Col className='bordered-container'>
            <div className='first-column align-items-start'>
              <h5>AMPUTEE</h5>

              <div className='one-row'>
                <Form.Group className='input-group'>
                  <Form.Label>First Name</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.amputee_fname}
                    // onChange={handleChange('amputee_fname')}
                    onClick={handleShowPatientSelection}
                    required
                  />
                </Form.Group>

                <Form.Group className='input-group'>
                  <Form.Label>Middle Name</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.amputee_mname}
                    // onChange={handleChange('amputee_mname')}
                    onClick={handleShowPatientSelection}
                    required
                  />
                </Form.Group>

                <Form.Group className='input-group'>
                  <Form.Label>Last Name</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.amputee_lname}
                    // onChange={handleChange('amputee_lname')}
                    onClick={handleShowPatientSelection}
                    required
                  />
                </Form.Group>
              </div>

              <div className='one-row'>
                <Form.Group className='input-group sex'>
                  <Form.Label>Sex</Form.Label>

                  <div className='sex-selection'>
                    <Form.Check
                      disabled={!isEdit}
                      type='radio'
                      name='sex'
                      label='Male'
                      value='Male'
                      checked={formData?.amputee_sex?.toLowerCase() === 'male' ? true : false}
                      onChange={handleChange('amputee_sex')}
                      required
                    />
                    <Form.Check
                      disabled={!isEdit}
                      type='radio'
                      name='sex'
                      label='Female'
                      value='Female'
                      checked={formData?.amputee_sex?.toLowerCase() === 'female' ? true : false}
                      onChange={handleChange('amputee_sex')}
                      required
                    />
                    <Form.Check
                      disabled={!isEdit}
                      type='radio'
                      name='sex'
                      label='Unknown'
                      value='Unknown'
                      checked={formData?.amputee_sex?.toLowerCase() === 'unknown' ? true : false}
                      onChange={handleChange('amputee_sex')}
                      required
                    />
                  </div>
                </Form.Group>

                <Form.Group className='input-group age'>
                  <Form.Label>Age</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    type='number'
                    placeholder='Enter Here'
                    value={formData?.amputee_age}
                    onChange={handleChange('amputee_age')}
                    required
                  />
                </Form.Group>

                <Form.Group className='input-group citizenship'>
                  <Form.Label>Citizenship</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.amputee_citizenship}
                    onChange={handleChange('amputee_citizenship')}
                    required
                  />
                </Form.Group>

                <Form.Group className='input-group occupation'>
                  <Form.Label>Occupation</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.amputee_occupation}
                    onChange={handleChange('amputee_occupation')}
                    required
                  />
                </Form.Group>
              </div>

              <div className='one-row'>
                <Form.Group className='input-group'>
                  <Form.Label>House No.,</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.amputee_house_no}
                    onChange={handleChange('amputee_house_no')}
                    required
                  />
                </Form.Group>

                <Form.Group className='input-group'>
                  <Form.Label>Street</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.amputee_street}
                    onChange={handleChange('amputee_street')}
                    required
                  />
                </Form.Group>

                <Form.Group className='input-group'>
                  <Form.Label>Barangay</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.amputee_brgy}
                    onChange={handleChange('amputee_brgy')}
                    required
                  />
                </Form.Group>

                <Form.Group className='input-group'>
                  <Form.Label>City/Municipality</Form.Label>
                  <FormControl
                    disabled={!isEdit}
                    placeholder='Enter Here'
                    value={formData?.amputee_city_municipality}
                    onChange={handleChange('amputee_city_municipality')}
                    required
                  />
                </Form.Group>
              </div>
            </div>
          </Col>
        </Row>

        <Row className='mb-2'>
          <Col className='bordered-container'>
            <div className='first-column large-width align-items-start'>
              <h5>
                CERTIFICATION
                <span>
                  I hereby certify that the foregoing particulars are correct as near as same be
                  ascertained.
                </span>
              </h5>

              <Form.Group className='input-group'>
                <Form.Label>Signature</Form.Label>
                <FormControl
                  disabled={!isEdit}
                  placeholder='Enter Here'
                  value={formData?.certification_signature}
                  onChange={handleChange('certification_signature')}
                  required
                />
              </Form.Group>

              <Form.Group className='input-group'>
                <Form.Label>Name in Print</Form.Label>
                <FormControl
                  disabled={!isEdit}
                  placeholder='Enter Here'
                  value={formData?.certification_name_in_print}
                  onChange={handleChange('certification_name_in_print')}
                  required
                />
              </Form.Group>

              <Form.Group className='input-group'>
                <Form.Label>Title or Position</Form.Label>
                <FormControl
                  disabled={!isEdit}
                  placeholder='Enter Here'
                  value={formData?.certification_title_or_position}
                  onChange={handleChange('certification_title_or_position')}
                />
              </Form.Group>

              <Form.Group className='input-group'>
                <Form.Label>Address</Form.Label>
                <FormControl
                  disabled={!isEdit}
                  placeholder='Enter Here'
                  value={formData?.certification_address}
                  onChange={handleChange('certification_address')}
                />
              </Form.Group>

              <Form.Group className='input-group'>
                <Form.Label>Date</Form.Label>
                <FormControl
                  disabled={!isEdit}
                  type='date'
                  placeholder='Enter Here'
                  value={formData?.certification_date}
                  onChange={handleChange('certification_date')}
                  required
                />
              </Form.Group>
            </div>
          </Col>
        </Row>

        <Row className='mb-2'>
          <Col className='bordered-container gap-10'>
            <div className='first-column align-items-start'>
              <h5>INFORMAT</h5>

              <Form.Group className='input-group'>
                <Form.Label>Signature</Form.Label>
                <FormControl
                  disabled={!isEdit}
                  placeholder='Enter Here'
                  value={formData?.informat_signature}
                  onChange={handleChange('informat_signature')}
                />
              </Form.Group>

              <Form.Group className='input-group'>
                <Form.Label>Name in Print</Form.Label>
                <FormControl
                  disabled={!isEdit}
                  placeholder='Enter Here'
                  value={formData?.informat_name_in_print}
                  onChange={handleChange('informat_name_in_print')}
                />
              </Form.Group>

              <Form.Group className='input-group'>
                <Form.Label>Relationship to Amputee</Form.Label>
                <FormControl
                  disabled={!isEdit}
                  placeholder='Enter Here'
                  value={formData?.informat_relation_to_amputee}
                  onChange={handleChange('informat_relation_to_amputee')}
                />
              </Form.Group>

              <Form.Group className='input-group'>
                <Form.Label>Address</Form.Label>
                <FormControl
                  disabled={!isEdit}
                  placeholder='Enter Here'
                  value={formData?.informat_address}
                  onChange={handleChange('informat_address')}
                />
              </Form.Group>
            </div>

            <div className='second-column align-items-start'>
              <h5>PREPARED BY</h5>

              <Form.Group className='input-group'>
                <Form.Label>Signature</Form.Label>
                <FormControl
                  disabled={!isEdit}
                  placeholder='Enter Here'
                  value={formData?.prepared_by_signature}
                  onChange={handleChange('prepared_by_signature')}
                />
              </Form.Group>

              <Form.Group className='input-group'>
                <Form.Label>Name in Print</Form.Label>
                <FormControl
                  disabled={!isEdit}
                  placeholder='Enter Here'
                  value={formData?.prepared_by_name_in_print}
                  onChange={handleChange('prepared_by_name_in_print')}
                />
              </Form.Group>

              <Form.Group className='input-group'>
                <Form.Label>Title or Position</Form.Label>
                <FormControl
                  disabled={!isEdit}
                  placeholder='Enter Here'
                  value={formData?.prepared_by_title_or_position}
                  onChange={handleChange('prepared_by_title_or_position')}
                />
              </Form.Group>

              <Form.Group className='input-group'>
                <Form.Label>Address</Form.Label>
                <FormControl
                  disabled={!isEdit}
                  placeholder='Enter Here'
                  value={formData?.prepared_by_address}
                  onChange={handleChange('prepared_by_address')}
                />
              </Form.Group>

              <Form.Group className='input-group'>
                <Form.Label>Date</Form.Label>
                <FormControl
                  disabled={!isEdit}
                  type='date'
                  placeholder='Enter Here'
                  value={formData?.prepared_by_date}
                  onChange={handleChange('prepared_by_date')}
                />
              </Form.Group>
            </div>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col className='bordered-container gap-10'>
            <div className='first-column align-items-start'>
              <h5>MANNER OF DISPOSAL</h5>

              <Form.Group className='checkbox'>
                <Form.Label>A.</Form.Label>

                <div className='selections'>
                  <Form.Check
                    disabled={!isEdit}
                    type='checkbox'
                    id='Burial'
                    label='Burial'
                    value='Burial'
                    checked={
                      formData?.manner_of_disposal?.toLowerCase() === 'burial' ? true : false
                    }
                    onChange={handleChange('manner_of_disposal')}
                  />
                </div>
              </Form.Group>

              <Form.Group className='checkbox'>
                <Form.Label>B.</Form.Label>

                <div className='selections'>
                  <Form.Check
                    disabled={!isEdit}
                    type='checkbox'
                    id='Cremation'
                    label='Cremation'
                    value='Cremation'
                    checked={
                      formData?.manner_of_disposal?.toLowerCase() === 'cremation' ? true : false
                    }
                    onChange={handleChange('manner_of_disposal')}
                  />
                </div>
              </Form.Group>

              <Form.Group className='checkbox'>
                <Form.Label>C.</Form.Label>

                <div className='selections'>
                  <Form.Check
                    disabled={!isEdit}
                    type='checkbox'
                    id='OTHERS ( Specify )'
                    label='OTHERS ( Specify )'
                    value='Others'
                    checked={
                      formData?.manner_of_disposal?.toLowerCase() === 'others' ? true : false
                    }
                    onChange={handleChange('manner_of_disposal')}
                  />
                </div>
              </Form.Group>
            </div>

            <div className='second-column align-items-start'>
              <h5>BURIAL / CREMATION PERMIT</h5>

              <Form.Group className='input-group'>
                <Form.Label>Number</Form.Label>
                <FormControl
                  disabled={!isEdit}
                  placeholder='Enter Here'
                  value={formData?.permit_number}
                  onChange={handleChange('permit_number')}
                />
              </Form.Group>

              <Form.Group className='input-group'>
                <Form.Label>Date Issued</Form.Label>
                <FormControl
                  disabled={!isEdit}
                  type='date'
                  placeholder='Enter Here'
                  value={formData?.permit_date_issued}
                  onChange={handleChange('permit_date_issued')}
                />
              </Form.Group>

              <Form.Group className='input-group'>
                <Form.Label>Issued By</Form.Label>
                <FormControl
                  disabled={!isEdit}
                  placeholder='Enter Here'
                  value={formData?.permit_issued_by}
                  onChange={handleChange('permit_issued_by')}
                />
              </Form.Group>
            </div>
          </Col>
        </Row>

        <Row className='mb-3'>
          <Col className='name'>
            <Form.Group className='input-group'>
              <Form.Label>Name and Address of Cemetery or Cremator</Form.Label>
              <FormControl
                disabled={!isEdit}
                placeholder='Enter Here'
                value={formData?.name_and_address_of_cemetery_or_cremator}
                onChange={handleChange('name_and_address_of_cemetery_or_cremator')}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className=''>
          <Col className='form-buttons'>
            {/* Buttons to show for Add */}
            <ConditionalRender
              condition={isAdding}
              renderIf={
                <>
                  <Button type='submit' className='save' disabled={trigger.trigger}>
                    CREATE RECORD
                  </Button>
                  <Button
                    onClick={() => {
                      setIsEdit(false);
                      setShowScreen(0);
                    }}
                    className='cancel'
                  >
                    CANCEL
                  </Button>
                </>
              }
            />

            {/* Buttons to show for Edit/View */}
            <ConditionalRender
              condition={!isAdding}
              renderIf={
                <>
                  <ConditionalRender
                    condition={isNotEditing}
                    renderIf={
                      <>
                        <Button className='print' onClick={handlePrint}>
                          PRINT CERTIFICATE
                        </Button>
                        <Button
                          onClick={() => {
                            setIsEdit(true);
                          }}
                          className='edit'
                        >
                          EDIT CERTIFICATE
                        </Button>
                      </>
                    }
                  />

                  <ConditionalRender
                    condition={isEditing}
                    renderIf={
                      <>
                        <Button
                          onClick={() => {
                            saveChanges();
                          }}
                          disabled={trigger.trigger}
                          className='save'
                        >
                          SAVE CHANGES
                        </Button>
                        <Button
                          onClick={() => {
                            setIsEdit(false);
                            setFormData(defaultData);
                          }}
                          className='cancel'
                        >
                          CANCEL
                        </Button>
                      </>
                    }
                  />
                </>
              }
            />
          </Col>
        </Row>
      </Form>
      <PatientSelection
        showPatientSelection={showPatientSelection}
        handleClosePatientSelection={handleClosePatientSelection}
        patientSelection={patientSelection}
        fields={formData}
        setFields={setFormData}
      />
    </>
  );
};

export default DismemberedBodyParts;
