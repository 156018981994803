/* eslint-disable camelcase */
/* eslint-disable prefer-const */
/* eslint-disable arrow-parens */
/* eslint-disable multiline-comment-style */
/* eslint-disable space-before-function-paren */

// import core & vendor packages below
import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row, Col, Form } from 'react-bootstrap';

// import components below
import Moment from 'moment';
import DenyModal from './DenyModal';
import ApproveModal from './AcceptModal';
import Table from 'components/ReactTable';
import ViewModal from './ViewModal/ViewRequestModal';
import UseItemList from 'pages/Inventory/hooks/UseItemList';
import UseStatusAction from 'pages/Inventory/hooks/UseStatusAction';
import DenyItemModal from '../../RefillRequest/components/DenyItemModal';
import {
  selectGroups,
  getrefillGroups,
  selectrefillRequest,
  denyrequesteditem,
  getReorderRequest,
  selectreorderRequestItem,
  selectreorderRequestData,
} from 'reducers/refillRequest';

// import assets below
import './Table.scss';

// main component
const InventoryFilterTable = ({ state, setState, setShowProfile, showProfile }) => {
  const refillRequestState = useSelector(selectrefillRequest);
  const {
    forSubmitRequestReorderlogs,
    approverequesteditemLogs,
    denyrequesteditemLogs,
    getReorderRequestlogs,
  } = refillRequestState;
  const dispatch = useDispatch();
  const groups = useSelector(selectGroups);
  const datafetch = useSelector(selectreorderRequestData);
  const items = useSelector(selectreorderRequestItem);
  const [data, setData] = useState([]);
  const [active, setActive] = useState('all');
  const [filter, setFilter] = useState({ search: '', date: '' });

  const [trigger, setTrigger] = useState({
    forSubmitRequestReorderTrigger: false,
    approverequesteditemTrigger: false,
    denyrequesteditemLogsTrigger: false,
    getRefillRequestTrigger: false,
  });

  let filterList = [
    { id: 1, key: 'all', name: 'All Open' },
    {
      id: 2,
      key: 'for approval',
      name: 'For Approval',
    },
    {
      id: 3,
      key: 'requested',
      name: 'Requested',
    },
    {
      id: 4,
      key: 'denied',
      name: 'Rejected',
    },
    {
      id: 5,
      key: 'completed',
      name: 'Completed',
    },
  ];

  const handleOnClickFilter = (status) => {
    let filteredData = datafetch;

    if (status !== 'all') {
      filteredData = datafetch?.filter((dd) => dd.attributes.status === status);
    }

    setData(filteredData);
    setActive(status);
  };

  const tableAction = (id) => (
    <div className='action-button'>
      <Button
        className='view-item'
        onClick={() => {
          setShowProfile((prevState) => {
            return { ...prevState, isShowViewRequestModal: true };
          });
          viewItemHandler(id);
        }}
      >
        View Item
      </Button>
      <Button className='print'>Print</Button>
    </div>
  );

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Request Date/Time', accessor: 'requested_at' }, // accessor is the "key" in the data
      { Header: 'Request No.', accessor: 'request_number' },

      { Header: 'Requested By', accessor: 'requested_by' },
      { Header: 'No. of Items', accessor: 'number_of_items' },
      { Header: 'Requested Status', accessor: 'status' },
      { Header: 'Action', accessor: 'action' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      data
        ? data?.map((dd) => ({
            id: dd?.id,
            requested_at: Moment(dd?.attributes?.requested_at).format('MMMM Do YYYY, h:mm:ss a'),
            request_number: dd?.attributes?.request_number,

            requested_by: dd?.attributes?.requested_by,
            number_of_items: dd?.attributes?.number_of_items,
            status: UseStatusAction(dd?.attributes?.status),
            action: tableAction(dd?.id),
          }))
        : null,
    [data],
  );

  const viewItemHandler = (id) => {
    const row = data.find((dd) => dd.id === id);

    setState((prev) => {
      return { ...prev, itemID: row };
    });

    const { restockID } = UseItemList(row, items);

    setState((prev) => {
      return { ...prev, item_list: restockID, filtered_item_list: restockID };
    });
  };
  const getRefillRequestList = () => {
    setTrigger((prev) => {
      return { ...prev, approverequesteditemTrigger: true };
    });
    dispatch(getReorderRequest({ restock_type: 'reorder' }));
    dispatch(getrefillGroups());
  };

  // on viewModal deny action button
  const denyRequestedItemHandler = (remark) => {
    const params = {
      restock_id: state?.itemID?.id,
      requested_item: state?.requested_item_id,
      remarks: remark,
    };
    setTrigger((prev) => {
      return { ...prev, denyrequesteditemLogsTrigger: true };
    });
    dispatch(denyrequesteditem(params));
  };

  const resetfilter = () => {
    dispatch(getReorderRequest({ restock_type: 'reorder' }));
  };

  const datefilter = () => {
    if (filter.search !== '' || filter.date !== '') {
      dispatch(
        getReorderRequest({ restock_type: 'reorder', search: filter.search, date: filter.date }),
      );
      return;
    }
  };

  useEffect(() => {
    getRefillRequestList();
  }, []);

  useEffect(() => {
    const { isSuccess } = getReorderRequestlogs;

    if (isSuccess) {
      setData(datafetch);
      setTrigger((prev) => {
        return { ...prev, getRefillRequestlogs: false };
      });
    }
  }, [getReorderRequestlogs]);

  useEffect(() => {
    setData(datafetch);
    if (state?.itemID) {
      const row = datafetch.find((dd) => dd.id === state.itemID?.id);

      setState((prev) => {
        return { ...prev, itemID: row };
      });
    }
    handleOnClickFilter(state.table_active_status || 'all');
  }, [datafetch]);

  // modal approve requested item
  useEffect(() => {
    const { isSuccess } = approverequesteditemLogs;

    if (isSuccess && trigger.approverequesteditemTrigger) {
      dispatch(getReorderRequest({ restock_type: 'reorder' }));
      setTrigger((prev) => {
        return { ...prev, approverequesteditemTrigger: false };
      });
    }
  }, [approverequesteditemLogs]);

  // modal deny requested item
  useEffect(() => {
    if (denyrequesteditemLogs.isSuccess && trigger.denyrequesteditemLogsTrigger) {
      setTrigger((prev) => {
        return { ...prev, denyrequesteditemLogsTrigger: false };
      });
      dispatch(getReorderRequest({ restock_type: 'reorder' }));
    }
  }, [denyrequesteditemLogs]);

  useEffect(() => {
    if (state.item_list) {
      const { restockID } = UseItemList(state.itemID, items);

      setState((prev) => {
        return { ...prev, item_list: restockID, filtered_item_list: restockID };
      });
    }
  }, [items]);

  useEffect(() => {
    const { isSuccess, error } = forSubmitRequestReorderlogs;
    if (isSuccess && trigger.forSubmitRequestReorderTrigger) {
      dispatch(getReorderRequest({ restock_type: 'reorder' }));
      toast.success('Successfully submitted!');
      setShowProfile((prev) => {
        return { ...prev, isAcceptModal: false, isShowViewRequestModal: false, isDenyModal: false };
      });
      setTrigger((prev) => {
        return { ...prev, forSubmitRequestReorderTrigger: false };
      });
    }

    if (error) {
      toast.warning(error);
      setShowProfile((prev) => {
        return { ...prev, isAcceptModal: false, isShowViewRequestModal: false };
      });
    }
  }, [forSubmitRequestReorderlogs]);

  return (
    <>
      <Row className='mb-4'>
        <Col sm={2}>
          <Form.Group>
            <Form.Label>Request No.</Form.Label>
            <Form.Control
              type='text'
              className='bg-white'
              placeholder='Search Here'
              value={filter.search}
              onChange={(e) =>
                setFilter((prev) => {
                  return { ...prev, search: e.target.value };
                })
              }
            />
          </Form.Group>
        </Col>
        <Col sm={2}>
          <Form.Group>
            <Form.Label>Request Date</Form.Label>
            <Form.Control
              type='date'
              value={filter.date}
              className='bg-white'
              onChange={(e) =>
                setFilter((prev) => {
                  return { ...prev, date: e.target.value };
                })
              }
            />
          </Form.Group>
        </Col>
        <Col>
          <div className='buttonWrapper mb-4'>
            <Button variant='search' onClick={datefilter}>
              APPLY
            </Button>

            <Button onClick={resetfilter} variant='clear'>
              RESET
            </Button>
          </div>
        </Col>
      </Row>
      <div className='inventory-table'>
        <Table
          data={tableData}
          logs={getReorderRequestlogs}
          columns={tableColumns}
          filter={{
            top: (
              <div className='buttons' style={{ flex: 'none', whiteSpace: 'nowrap' }}>
                {filterList.map((list) => (
                  <Button
                    key={list.key}
                    className={list.key === active ? 'active' : ''}
                    onClick={() => handleOnClickFilter(list.key)}
                  >
                    {list.name}
                  </Button>
                ))}
              </div>
            ),
          }}
          pagination={['BOTTOM']}
        />
      </div>
      {/* Approve restock item modal  */}
      <ViewModal
        showProfile={showProfile}
        setShowProfile={setShowProfile}
        state={state}
        items={items}
        groups={groups}
        setState={setState}
        setTrigger={setTrigger}
      />
      <ApproveModal
        showProfile={showProfile}
        setShowProfile={setShowProfile}
        state={state}
        setTrigger={setTrigger}
      />
      <DenyModal
        showProfile={showProfile}
        setShowProfile={setShowProfile}
        state={state}
        setTrigger={setTrigger}
      />
      {/* deny requested item modal  */}
      <DenyItemModal
        showProfile={showProfile}
        setShowProfile={setShowProfile}
        denyRequestedItemHandler={denyRequestedItemHandler}
      />
      {/* <Row>
     <Col className='mt-4 button-wrapper'>
       <Button
         onClick={() => {
           setSettings((prevState) => {
             return {
               ...prevState,
               showModal: true,
               forApprove: true,
             };
           });
         }}
         className='approve'
       >
         Approve
       </Button>
       <Button
         onClick={() => {
           setSettings((prevState) => {
             return {
               ...prevState,
               showModal: true,
               forApprove: false,
             };
           });
         }}
         className='reject'
       >
         Reject
       </Button>
     </Col>
   </Row> */}
    </>
  );
};

export default InventoryFilterTable;
