/* eslint-disable */
// import core & vendor packages below
import moment from 'moment';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

// import components below
import FormUI from 'components/UI/FormUI';
import ConditionalRender from 'components/ConditionalRender';
import { checkStringPermission } from 'helpers/filteredPermissions';
import { selectPatientItem, showPatient } from 'reducers/patientReducer';
import PatientProfileTabs from 'components/FormPatientProfile/components/Tabs.jsx';

// import assets below
import './style.scss';
import { ReactComponent as UserSVG } from 'assets/svg/user.svg';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark2.svg';
import QRCode from 'react-qr-code';

// main component
const ViewDoctorProfile = ({ showProfile, setShowProfile }) => {
  const dispatch = useDispatch();

  const patientProfile = useSelector(selectPatientItem);

  useEffect(() => {
    dispatch(showPatient(showProfile.profileId));
  }, []);

  return (
    <div className='NursingPatientProfile'>
      <div className='d-flex justify-content-end'>
        <CircleXMark
          style={{ width: '2em', cursor: 'pointer' }}
          onClick={() => setShowProfile({ isShowProfile: false, profileId: null })}
        />
      </div>

      <FormUI>
        <div className='avatar__container'>
          <div className={'d-flex align-items-center'}>
            {patientProfile?.id && (
              <QRCode
                size={150}
                style={{ height: 'auto', marginRight: '10px' }}
                value={`${patientProfile?.id}`}
              />
            )}
            {patientProfile?.filename ? (
              <div className='svg__container'>
                <img
                  src={`${process.env.REACT_APP_API_BASE_URL}/files/${patientProfile?.filename}`}
                  style={{ 'border-radius': '50%', 'width': '100%', 'height': '100%' }}
                ></img>
              </div>
            ) : (
              <div className='svg__container'>
                <UserSVG />
              </div>
            )}
          </div>
          <h2 className='mt-4 text-uppercase'>
            <span className='patient__lastName fw-bold'>{patientProfile?.last_name}</span>,{' '}
            {patientProfile?.first_name}
          </h2>
          <div className='patient__details'>
            <h5 className='patient__number mt-1'>
              <span>Patient ID:</span> {patientProfile?.id}
            </h5>
            {patientProfile?.is_newborn && <span className='patient__newborn mt-1'>New Born</span>}
          </div>
          <div className='patient__info mt-1'>
            <span>
              {patientProfile?.age} / {patientProfile?.sex}
            </span>
            <span>{moment(patientProfile?.date_of_birth).format('MMMM-DD-YYYY')}</span>
          </div>
        </div>

        <div className='profile_buttons d-flex justify-content-center'>
          <Button
            onClick={() => {
              setShowProfile((prevState) => {
                return {
                  ...prevState,
                  labRequest: true,
                };
              });
            }}
            className='btn btn-click me-3'
          >
            <small>DIAGNOSTIC REQUEST</small>
          </Button>

          <Button
            onClick={() => {
              setShowProfile((prevState) => {
                return {
                  ...prevState,
                  procedure: true,
                };
              });
            }}
            className='btn btn-click me-3'
          >
            <small>Procedure</small>
          </Button>

          <Button
            onClick={() => {
              setShowProfile((prevState) => {
                return {
                  ...prevState,
                  medication: true,
                };
              });
            }}
            className='btn btn-click me-3'
          >
            <small>Medication</small>
          </Button>

          <Button
            onClick={() => {
              setShowProfile((prevState) => {
                return {
                  ...prevState,
                  isShowDiagnosis: true,
                };
              });
            }}
            className='btn btn-click me-3'
          >
            <small>Diagnosis</small>
          </Button>

          <Button
            onClick={() => {
              setShowProfile((prevState) => {
                return {
                  ...prevState,
                  note: true,
                };
              });
            }}
            className='btn btn-click me-3'
          >
            <small>Add Note</small>
          </Button>
        </div>

        <div className='formPatientProfile__banner'>
          <Row>
            <Col lg='3'>
              <div className='p-3'>
                <h6>Allergies:</h6>
                <div className='d-flex flex-column'>
                  {patientProfile?.patient_allergies.length > 0 ? (
                    patientProfile?.patient_allergies?.map((item) => {
                      return <span className='fw-bold'> {item?.allergy}</span>;
                    })
                  ) : (
                    <span className='fw-bold'>N/A</span>
                  )}
                </div>
              </div>
            </Col>
            <Col lg='6'>
              <div className='d-flex flex-column align-items-between h-100 p-3'>
                <Row>
                  <Col>
                    <div className='mt-2'>
                      <span>Location: </span>{' '}
                      <span className='fw-bold'>
                        {patientProfile?.assigned_room
                          ? patientProfile?.assigned_room?.room?.room
                          : '-'}
                      </span>
                    </div>
                  </Col>
                  <Col>
                    <div className='mt-2'>
                      <span>Admission Date: </span>{' '}
                      <span className='fw-bold'>
                        <span>
                          {patientProfile?.admission_date
                            ? moment(patientProfile?.admission_date).format('MMMM-DD-YYYY')
                            : '-'}
                        </span>
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className='mt-2'>
                      <span>Visit Number: </span>
                      <span className='fw-bold'>
                        {patientProfile?.active_visit_number
                          ? patientProfile?.active_visit_number
                          : '-'}
                      </span>
                    </div>
                  </Col>
                  <Col>
                    <div className='mt-2'>
                      <span>Length of Stay: </span>{' '}
                      <span className='fw-bold'>
                        {showProfile.admissionDays ? showProfile?.admissionDays : '-'}
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg='3'>
              <div className='p-3'>
                <h6>Doctors:</h6>
                <div className='d-flex flex-column'>
                  {patientProfile?.assigned_doctors.length > 0 ? (
                    patientProfile?.assigned_doctors?.map((item) => {
                      return (
                        <span className='fw-bold'>
                          {' '}
                          Dr. {item?.user?.first_name} {item?.user?.last_name}
                        </span>
                      );
                    })
                  ) : (
                    <span className='fw-bold'>N/A</span>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <PatientProfileTabs
          permissionType={'Doctor'}
          showProfile={showProfile}
          setShowProfile={setShowProfile}
        />
      </FormUI>
    </div>
  );
};

ViewDoctorProfile.defaultProps = {
  showProfile: () => {},
  setShowProfile: () => {},
};

ViewDoctorProfile.propTypes = {
  showProfile: PropTypes.func,
  setShowProfile: PropTypes.func,
};

export default ViewDoctorProfile;
