/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { Row, Col } from 'react-bootstrap';

// import assets below
import './PatientProfileTab.scss';
import { useSelector } from 'react-redux';
import { selectPatientVisitDetails } from 'reducers/medicalRecordReducer';

// main component
const PatientProfileTab = () => {
  const patientProfile = useSelector(selectPatientVisitDetails);

  return (
    <div className='patientProfileTab'>
      <div className='patientProfile__header'>
        <h4>Patient Demographics</h4>
        <Row>
          <Col>
            <div className='patientProfile__content'>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Nationality</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>{patientProfile?.nationality}</span>
                </Col>
              </Row>
              z
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Civil Status</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>{patientProfile?.civil_status}</span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Religion</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>{patientProfile?.religion}</span>
                </Col>
              </Row>
            </div>
          </Col>
          <Col>
            <div className='patientProfile__content'>
              <h5>Alias / Fictitious Name</h5>
              {/* <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Prefix / Title</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>{patientProfile?.alias_prefix}</span>
                </Col>
              </Row> */}
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>First Name</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>{patientProfile?.alias_first_name}</span>
                </Col>
              </Row>
              {/* <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Middle Name</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>{patientProfile?.alias_middle_name}</span>
                </Col>
              </Row> */}
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Last Name</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>{patientProfile?.alias_last_name}</span>
                </Col>
              </Row>
              {/* <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Suffix</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>{patientProfile?.alias_suffix}</span>
                </Col>
              </Row> */}
            </div>
          </Col>
        </Row>
      </div>
      <div className='patientProfile__header'>
        <h4>Patient Address Details</h4>
        <Row>
          <Col>
            <div className='patientProfile__content'>
              <h5>Present Address</h5>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Country</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_present_address?.present_country}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>State / Province / Religion</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_present_address?.present_region}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>City</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_present_address?.present_city}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Zip Code</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_present_address?.present_zip_code}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Address Line 1</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_present_address?.present_address1}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Subdivision/Village</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_present_address?.present_address2}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Barangay</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_present_address?.present_brgy}
                  </span>
                </Col>
              </Row>
            </div>
          </Col>
          <Col>
            <div className='patientProfile__content'>
              <h5>Permanent Address</h5>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Country</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_permanent_address?.permanent_country}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>State / Province / Religion</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_permanent_address?.permanent_region}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>City</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_permanent_address?.permanent_city}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Zip Code</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_permanent_address?.permanent_zip_code}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Address Line 1</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_permanent_address?.permanent_address1}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Subdivision/Village</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_permanent_address?.permanent_address2}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Barangay</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_permanent_address?.permanent_brgy}
                  </span>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col>
          <div className='patientProfile__header'>
            <h4>Patient Contact Details</h4>
            <Row>
              <Col>
                {patientProfile?.patient_contact_details?.length > 0 ? (
                  patientProfile?.patient_contact_details.map((item, key) => {
                    return (
                      <div className='patientProfile__content'>
                        <Row>
                          <Col lg='5'>
                            <div className='wrapper'>
                              {key === 0 && <span>Contact Type</span>}
                              <span>:</span>
                            </div>
                          </Col>
                          <Col lg='auto'>
                            <span className='value'>{item.type}</span>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg='5'>
                            <div className='wrapper'>
                              {key === 0 && <span>Contact Details</span>}
                              <span>:</span>
                            </div>
                          </Col>
                          <Col lg='auto'>
                            <span className='value'>{item.details}</span>
                          </Col>
                        </Row>
                      </div>
                    );
                  })
                ) : (
                  <div className='patientProfile__content'>
                    <Row>
                      <Col lg='5'>
                        <div className='wrapper'>
                          <span>Contact Type</span>
                          <span>:</span>
                        </div>
                      </Col>
                      <Col lg='auto'>
                        <span className='value'>N/A</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg='5'>
                        <div className='wrapper'>
                          <span>Contact Details</span>
                          <span>:</span>
                        </div>
                      </Col>
                      <Col lg='auto'>
                        <span className='value'>N/A</span>
                      </Col>
                    </Row>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Col>
        <Col>
          <div className='patientProfile__header'>
            <h4>Patient&#39;s Other Details</h4>
            <Row>
              <Col>
                {patientProfile?.patient_other_details?.length > 0 ? (
                  patientProfile?.patient_other_details.map((item, key) => {
                    return (
                      <div className='patientProfile__content'>
                        <Row>
                          <Col lg='5'>
                            <div className='wrapper'>
                              {key === 0 && <span>Patient Endorsement</span>}
                              <span>:</span>
                            </div>
                          </Col>
                          <Col lg='auto'>
                            <span className='value'>{item.endorsement}</span>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg='5'>
                            <div className='wrapper'>
                              {key === 0 && <span>Details</span>}
                              <span>:</span>
                            </div>
                          </Col>
                          <Col lg='auto'>
                            <span className='value'>{item.details}</span>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg='5'>
                            <div className='wrapper'>
                              {key === 0 && <span>Patient Other Details Status</span>}
                              <span>:</span>
                            </div>
                          </Col>
                          <Col lg='auto'>
                            <span className='value'>{item?.patientOtherDetailsStatus}</span>
                          </Col>
                        </Row>
                      </div>
                    );
                  })
                ) : (
                  <div className='patientProfile__content'>
                    <Row>
                      <Col lg='5'>
                        <div className='wrapper'>
                          <span>Patient Endorsement</span>
                          <span>:</span>
                        </div>
                      </Col>
                      <Col lg='auto'>
                        <span className='value'>N/A</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg='5'>
                        <div className='wrapper'>
                          <span>Details</span>
                          <span>:</span>
                        </div>
                      </Col>
                      <Col lg='auto'>
                        <span className='value'>N/A</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg='5'>
                        <div className='wrapper'>
                          <span>Patient Other Details Status</span>
                          <span>:</span>
                        </div>
                      </Col>
                      <Col lg='auto'>
                        <span className='value'>N/A</span>
                      </Col>
                    </Row>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <div className='patientProfile__header'>
        <h4>Patient&#39;s Identity Documents</h4>
        <Row>
          <Col>
            {patientProfile?.patient_identity_documents?.length > 0 ? (
              patientProfile.patient_identity_documents?.map((item, index) => {
                return (
                  <div className='patientProfile__content'>
                    <Row>
                      <Col lg='5'>
                        <div className='wrapper'>
                          <span>ID Type</span>
                          <span>:</span>
                        </div>
                      </Col>
                      <Col lg='auto'>
                        <span className='value'>{item.id_type}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg='5'>
                        <div className='wrapper'>
                          <span>ID Number</span>
                          <span>:</span>
                        </div>
                      </Col>
                      <Col lg='auto'>
                        <span className='value'>{item.id_number}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {/* <div className='fileUpload'>
                          <span className='fileUpload__title'>File Upload1</span>
                          <span className='fileUpload__fileName'></span>
                        </div> */}
                      </Col>
                    </Row>
                  </div>
                );
              })
            ) : (
              <div className='patientProfile__content'>
                <Row>
                  <Col lg='5'>
                    <div className='wrapper'>
                      <span>ID Type</span>
                      <span>:</span>
                    </div>
                  </Col>
                  <Col lg='auto'>
                    <span className='value'>N/A</span>
                  </Col>
                </Row>
                <Row>
                  <Col lg='5'>
                    <div className='wrapper'>
                      <span>ID Number</span>
                      <span>:</span>
                    </div>
                  </Col>
                  <Col lg='auto'>
                    <span className='value'>N/A</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {/* <div className='fileUpload'>
                      <span className='fileUpload__title'>File Upload2</span>
                      <span className='fileUpload__fileName'></span>
                    </div> */}
                  </Col>
                </Row>
              </div>
            )}
          </Col>
          <Col></Col>
        </Row>
      </div>
      <div className='patientProfile__header'>
        <h4>Contact In Case of Emergency</h4>
        <Row>
          <Col>
            <div className='patientProfile__content'>
              <h5>Contact Person Name</h5>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Prefix / Title</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>{patientProfile?.patient_emergency_contact?.prefix}</span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>First Name</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_emergency_contact?.first_name}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Middle Name</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_emergency_contact?.middle_name}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Last Name</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_emergency_contact?.last_name}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Suffix</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>{patientProfile?.patient_emergency_contact?.suffix}</span>
                </Col>
              </Row>
            </div>
          </Col>
          <Col>
            <div className='patientProfile__content'>
              <h5>Contact Details</h5>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Relationship</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_emergency_contact?.relationship}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Purpose</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_emergency_contact?.purpose}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Contact Number</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_emergency_contact?.contact_number}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>MRN / PIN</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>{patientProfile?.patient_emergency_contact?.MRN}</span>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <div className='patientProfile__header'>
        <h4>Patient Occupation Details</h4>
        <Row>
          <Col lg='6'>
            <div className='patientProfile__content'>
              <h5>Present Job</h5>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Occupation</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>{patientProfile?.patient_occupation?.occupation}</span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Industry</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>{patientProfile?.patient_occupation?.industry}</span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Employer</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>{patientProfile?.patient_occupation?.employer}</span>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <div className='patientProfile__header'>
        <h4>Family Details</h4>
        {patientProfile?.patient_families?.length > 0 ? (
          patientProfile?.patient_families?.map((item, index) => {
            return (
              <>
                <Row>
                  <Col>
                    <div className='patientProfile__content'>
                      <h5>Family Members Name</h5>
                      <Row>
                        <Col lg='5'>
                          <div className='wrapper'>
                            <span>Prefix / Title</span>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col lg='auto'>
                          <span className='value'>{item.prefix}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg='5'>
                          <div className='wrapper'>
                            <span>First Name</span>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col lg='auto'>
                          <span className='value'>{item.first_name}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg='5'>
                          <div className='wrapper'>
                            <span>Middle Name</span>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col lg='auto'>
                          <span className='value'>{item.middle_name}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg='5'>
                          <div className='wrapper'>
                            <span>Last Name</span>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col lg='auto'>
                          <span className='value'>{item.last_name}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg='5'>
                          <div className='wrapper'>
                            <span>Suffix</span>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col lg='auto'>
                          <span className='value'>{item.suffix}</span>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col>
                    <div className='patientProfile__content'>
                      <h5>Contact Details</h5>
                      <Row>
                        <Col lg='5'>
                          <div className='wrapper'>
                            <span>Relationship</span>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col lg='auto'>
                          <span className='value'>{item.relationship}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg='5'>
                          <div className='wrapper'>
                            <span>Purpose</span>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col lg='auto'>
                          <span className='value'>{item.purpose}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg='5'>
                          <div className='wrapper'>
                            <span>Contact Number</span>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col lg='auto'>
                          <span className='value'>{item.contact_number}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg='5'>
                          <div className='wrapper'>
                            <span>MRN / PIN</span>
                            <span>:</span>
                          </div>
                        </Col>
                        <Col lg='auto'>
                          <span className='value'>{item.MRN}</span>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </>
            );
          })
        ) : (
          <>
            <h4>Family Details</h4>
            <Row>
              <Col>
                <div className='patientProfile__content'>
                  <Row>
                    <Col lg='5'>
                      <div className='wrapper'>
                        <span>Prefix / Title</span>
                        <span>:</span>
                      </div>
                    </Col>
                    <Col lg='auto'>
                      <span className='value'>N/A</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg='5'>
                      <div className='wrapper'>
                        <span>First Name</span>
                        <span>:</span>
                      </div>
                    </Col>
                    <Col lg='auto'>
                      <span className='value'>N/A</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg='5'>
                      <div className='wrapper'>
                        <span>Middle Name</span>
                        <span>:</span>
                      </div>
                    </Col>
                    <Col lg='auto'>
                      <span className='value'>N/A</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg='5'>
                      <div className='wrapper'>
                        <span>Last Name</span>
                        <span>:</span>
                      </div>
                    </Col>
                    <Col lg='auto'>
                      <span className='value'>N/A</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg='5'>
                      <div className='wrapper'>
                        <span>Suffix</span>
                        <span>:</span>
                      </div>
                    </Col>
                    <Col lg='auto'>
                      <span className='value'>N/A</span>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col>
                <div className='patientProfile__content'>
                  <h5>Contact Details</h5>
                  <Row>
                    <Col lg='5'>
                      <div className='wrapper'>
                        <span>Relationship</span>
                        <span>:</span>
                      </div>
                    </Col>
                    <Col lg='auto'>
                      <span className='value'>N/A</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg='5'>
                      <div className='wrapper'>
                        <span>Purpose</span>
                        <span>:</span>
                      </div>
                    </Col>
                    <Col lg='auto'>
                      <span className='value'>N/A</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg='5'>
                      <div className='wrapper'>
                        <span>Contact Number</span>
                        <span>:</span>
                      </div>
                    </Col>
                    <Col lg='auto'>
                      <span className='value'>N/A</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg='5'>
                      <div className='wrapper'>
                        <span>MRN / PIN</span>
                        <span>:</span>
                      </div>
                    </Col>
                    <Col lg='auto'>
                      <span className='value'>N/A</span>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
      <div className='patientProfile__header'>
        <h4>PhilHealth Eligibility Form</h4>
        <Row>
          <Col>
            <div className='patientProfile__content'>
              <h5>Member&#39;s PhilHealth Information</h5>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Member&#39;s PhilHealth Identification No.</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>{patientProfile?.patient_phil_health?.MPIN}</span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>First Name</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>{patientProfile?.patient_phil_health?.first_name}</span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Middle Name</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>{patientProfile?.patient_phil_health?.middle_name}</span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Last Name</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>{patientProfile?.patient_phil_health?.last_name}</span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Suffix</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>{patientProfile?.patient_phil_health?.suffix}</span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Sex</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>{patientProfile?.patient_phil_health?.patient_sex}</span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Member Type</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>{patientProfile?.patient_phil_health?.member_type}</span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Member is the Patient</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_phil_health?.is_own_account === true ? 'YES' : 'NO'}
                  </span>
                </Col>
              </Row>
            </div>
          </Col>
          <Col>
            <div className='patientProfile__content'>
              <h5>Patient&#39;s Basic Information</h5>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Relationship to Member</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_phil_health?.relation_to_member}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>First Name</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_phil_health?.patient_first_name}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Middle Name</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_phil_health?.patient_middle_name}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Last Name</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_phil_health?.patient_last_name}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Suffix</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_phil_health?.patient_suffix}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Date of Birth</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>
                    {patientProfile?.patient_phil_health?.patient_date_of_birth}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Sex</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>{patientProfile?.patient_phil_health?.patient_sex}</span>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        {/* <Row className='mt-2'>
          <Col lg='6'>
            <div className='patientProfile__content'>
              <h5>Confinement Information</h5>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Admission Date</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>{patientProfile?.patient_phil_health?.admission_date}</span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Discharge Date</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>{patientProfile?.patient_phil_health?.discharge_date}</span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>ICD Codes</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>{patientProfile?.patient_phil_health?.actual_amount}</span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Total Amount</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>{patientProfile?.patient_phil_health?.actual_amount}</span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Total Amount Claimed</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>{patientProfile?.patient_phil_health?.amount_claimed}A</span>
                </Col>
              </Row>
              <Row>
                <Col lg='5'>
                  <div className='wrapper'>
                    <span>Final</span>
                    <span>:</span>
                  </div>
                </Col>
                <Col lg='auto'>
                  <span className='value'>{patientProfile?.patient_phil_health?.is_final === true ? 'YES' : 'NO'}</span>
                </Col>
              </Row>
            </div>
          </Col>
        </Row> */}
      </div>
    </div>
  );
};

export default PatientProfileTab;
