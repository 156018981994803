/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Col, Container, Form, Row, Table } from 'react-bootstrap';
import './index.scss';

const headerTitleStyle = {
  fontWeight: 'bold',
  color: 'black',
  fontSize: '16px',
};
const headerSubtitleStyle = {
  fontWeight: 'bold',
  color: 'black',
  fontSize: '14px',
  marginTop: '5px',
};
const PrintContent = React.forwardRef(({ data, viewFormType }, ref) => {
  const { REACT_APP_LOGO, REACT_APP_HOSPITAL_NAME, REACT_APP_HOSPITAL_STREET_ADDRESS } =
    process.env;
  const {
    name_of_hospital,
    address,
    cert_no,
    health_record_no,
    date,
    full_name,
    age,
    confined_start_date,
    confined_end_date,
    name_of_person_requesting,
    purpose,
    attending_physician,
  } = data;
  console.log(data);
  return (
    <div className='print_body' ref={ref}>
      <header>
        <div className='header_logo'>
          <img src={REACT_APP_LOGO} alt='print_logo' />
        </div>
        {/* Inline styling is used due to css class names .header_title and  .header_subtitle is not working at times */}
        <div style={headerTitleStyle}>{REACT_APP_HOSPITAL_NAME}</div>
        <div style={headerSubtitleStyle}>{REACT_APP_HOSPITAL_STREET_ADDRESS}</div>
      </header>
      <Table bordered>
        <thead>
          <tr>
            <th>{viewFormType}</th>
          </tr>
        </thead>
        <tbody>
          <Container>
            <Row className='mb-4'>
              <Col className='first-row'>
                <div className='input-group'>
                  <p>{name_of_hospital}</p>
                  <label>Name of Hospital</label>
                </div>
                <div className='input-group'>
                  <p>{address}</p>
                  <label>Address</label>
                </div>
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col className='second-row'>
                <div className='input-group'>
                  <label>Certificate No.</label>
                  <p>{cert_no}</p>
                </div>

                <div className='input-group'>
                  <label>Health Record No.</label>
                  <p>{health_record_no}</p>
                </div>

                <div className='input-group'>
                  <label>Date</label>
                  <p>{date}</p>
                </div>
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col className='third-row'>
                <h2>{viewFormType}</h2>
              </Col>
            </Row>

            <Row className='mb-2'>
              <Col>
                <p className='mb-3'>
                  This is to certify that {full_name}, {age} years old of {address} has been
                  confined in this hospital from {confined_start_date} to {confined_end_date}
                </p>

                <p className='mb-5'>
                  This certification is being issued at the request of {name_of_person_requesting}{' '}
                  for {purpose}
                </p>

                <div className='signature mb-4'>
                  <div className='attending-physician'>
                    <p>{attending_physician}</p>
                    <label>HIMD Head / Supervisor</label>
                  </div>
                </div>

                <p className='seal pb-4'>( NOT VALID WITHOUT SEAL )</p>
              </Col>
            </Row>
          </Container>
        </tbody>
      </Table>
    </div>
  );
});

PrintContent.displayName = 'PrintContent';

export default PrintContent;
