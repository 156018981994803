import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';

// Components below
import PatientTable from './components/Table';
import ModalDietType from './components/DietType';
import SearchPatientFilter from './components/Filter';
import { getPatients } from 'reducers/patientReducer';
import FormPatientProfile from './components/FormPatientProfile';

// Main component
const SearchPatient = () => {
  const [state, setState] = useState([]);
  const dispatch = useDispatch();

  const [showProfile, setShowProfile] = useState({
    isShowView: false,
    profileId: null,
    dietType: false,
  });

  useEffect(() => {
    // eslint-disable-next-line camelcase
    dispatch(getPatients({ patient_status: 'admitted' }));
  }, []);

  return (
    <div>
      {!showProfile.isShowView ? (
        <div className='searchPatient'>
          <SearchPatientFilter setState={setState} />

          <PatientTable state={state} setState={setState} setShowProfile={setShowProfile} />
        </div>
      ) : (
        <FormPatientProfile showProfile={showProfile} setShowProfile={setShowProfile} />
      )}

      <ModalDietType showProfile={showProfile} setShowProfile={setShowProfile} />
    </div>
  );
};

export default SearchPatient;
