import axios from 'axios';
import { useState } from 'react';
import { Button } from 'react-bootstrap';

// Components below
import FilterCardList from './components/CardList';
import RoomReservationTable from './components/Table';
import RoomReservationFilter from './components/Filter';
import ModalPatientRoomInformation from './components/Modal';
import ConditionalRender from 'components/ConditionalRender';
import FormPatientProfile from 'components/FormPatientProfile';

// Modals
import TransferRoom from './components/TransferRoom';

import './style.scss';

// Main component
const RoomReservation = () => {
  const [state, setState] = useState([]);
  const [allData, setAllData] = useState([]);

  const [showProfile, setShowProfile] = useState({
    setActive: '',
    profileId: null,
    isShowView: false,
    isModalShow: false,
    showTransfer: false,
  });

  const [query, setQuery] = useState({
    search: '',
    roomType: '',
    requestType: '',
  });

  const refreshData = () => {
    axios.get('/room_request?status=pending').then((response) => {
      setState(response.data.data);
      setAllData(response.data.data);
    }, []);
  };

  const isDefault = showProfile.isShowView === false;
  const isShowView = showProfile.isShowView === true;

  return (
    <>
      <div>
        <ConditionalRender
          condition={isDefault}
          renderIf={
            <div className='roomReservation'>
              <FilterCardList setState={setState} />

              <RoomReservationFilter
                query={query}
                state={state}
                allData={allData}
                setQuery={setQuery}
                setState={setState}
              />

              <div className='d-flex justify-content-between align-items-center'>
                <h4 className='mb-0 fw-bold'>Room Reservation List</h4>

                <Button
                  onClick={() => {
                    setShowProfile((prev) => {
                      return {
                        ...prev,
                        showTransfer: true,
                      };
                    });
                  }}
                  className='transfer-room'
                >
                  Transfer Room
                </Button>
              </div>

              <RoomReservationTable
                state={state}
                setState={setState}
                refreshData={refreshData}
                setShowProfile={setShowProfile}
                query={query}
                allData={allData}
              />
            </div>
          }
        />

        <ConditionalRender
          condition={isShowView}
          renderIf={
            <FormPatientProfile showProfile={showProfile} setShowProfile={setShowProfile} />
          }
        />
      </div>

      <ModalPatientRoomInformation showProfile={showProfile} setShowProfile={setShowProfile} />

      <TransferRoom
        showProfile={showProfile}
        refreshData={refreshData}
        setShowProfile={setShowProfile}
      />
    </>
  );
};

export default RoomReservation;
