import React, { useState } from 'react';
import PatientDisbursementTable from './components/Table';
import DisbursementModal from './components/Disbursement';
import SearchPatientFilter from './components/Filter';

const PatientForDisbursement = () => {
  const [searchFilter, setSearchFilter] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [profile, setProfile] = useState([]);
  return (
    <>
      <SearchPatientFilter setState={setSearchFilter} state={searchFilter} />
      <PatientDisbursementTable
        state={searchFilter}
        setShowModal={setShowModal}
        setProfile={setProfile}
      />
      <DisbursementModal
        showModal={showModal}
        setShowModal={setShowModal}
        profile={profile}
        setProfile={setProfile}
      />
    </>
  );
};

export default PatientForDisbursement;
