/* eslint-disable no-multi-spaces */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import _ from 'lodash';
import { InputGroup, FormControl, Form } from 'react-bootstrap';
import { FiSearch } from 'react-icons/fi';

// import components below
import FilterUI from 'components/UI/FilterUI';

// import assets below
import './Filter.scss';
// import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const RoomReservationFilter = ({ state, setQuery, query, allData, setState }) => {
  console.log('state on filter component: ', state);

  /* const requestTypeOptions = state.map((request) => {
    return {
      value: request?.request_type,
      label: request?.request_type,
      id: request?.id,
    };
  }); */

  const requestTypeOptions =  _
  .chain(allData)
  .uniqBy((request) => request?.request_type)
  .filter((request) => request?.request_type !== null)
  .map((request) => {
    return {
      value: request?.request_type,
      label: request?.request_type,
      id: request?.id,
    };
  })
  .value();

  const roomTypeOptions =  _
  .chain(allData)
  .uniqBy((request) => request?.room?.type)
  .filter((request) => request?.room !== null)
  .map((request) => {
    return {
      value: request?.room?.type,
      label: request?.room?.type,
      id: request?.id,
    };
  })
  .value();

  const handleChange = (e) => {
    const { id, value } = e.target;

    setQuery({
      ...query,
      [id]: value,
    });

    if (id === 'requestType') {
      const filteredSet = allData.filter((request) => (
        request?.request_type === value
      ));

      const listToUse = !value ? allData : filteredSet;
      
      const forDisplay = query?.roomType
        ? listToUse.filter((request) => request?.room?.type === query?.roomType)
        : filteredSet;
      
      setState(forDisplay);
      return;
    } 

    if (id === 'roomType') {
      const filteredSet = allData.filter((request) => (
        request?.room?.type === value
      ));

      const listToUse = !value ? allData : filteredSet;

      const forDisplay = query?.requestType !== ''
        ? listToUse.filter((request) => request?.request_type === query?.requestType)
        : filteredSet;

      setState(forDisplay);
      return;
    }
  };

  return (
    <div className='roomReservationFilter'>
      <FilterUI>
        <Form.Group className='mb-3 me-3'>
          <Form.Label>Search Patient</Form.Label>
          <InputGroup>
            <InputGroup.Text>
              <FiSearch size={30} />
            </InputGroup.Text>
            <FormControl id='search' onChange={handleChange} type='text' placeholder='Search here...' />
          </InputGroup>
        </Form.Group>

        <Form.Group className='mb-3 me-3'>
          <Form.Label>Request Type</Form.Label>
          <Form.Select onChange={handleChange} id='requestType'>
            <option value={''}>- Select -</option>
            {requestTypeOptions.map((request) => (
              <option key={request?.id} value={request?.value}>{request?.label}</option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className='mb-3 me-3'>
          <Form.Label>Room Type</Form.Label>
          <Form.Select onChange={handleChange} id='roomType'>
            <option value={''}>- Select -</option>
            {roomTypeOptions.map((request) => (
              <option key={request?.id} value={request?.value}>{request?.label}</option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className='mb-3 me-3'>
          <Form.Label>
            Admission/
            <br />
            Transfer Date From
          </Form.Label>
          <Form.Control type='date' placeholder='Enter email' />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>
            Admission/
            <br />
            Transfer Date To
          </Form.Label>
          <Form.Control type='date' placeholder='Enter email' />
        </Form.Group>
      </FilterUI>
    </div>
  );
};

export default RoomReservationFilter;
