/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// Packages
import axios from 'axios';
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';

// Components
import Filter from './Filter';
import Profile from './Profile';
import ArchiveTable from './Table';
import Status from 'constant/status';
import Tabs from 'constant/medicalRecordTabs';
import { selectAuth } from 'reducers/authReducer';
import FormPatientProfile from '../FormPatientProfile';
import ConditionalRender from 'components/ConditionalRender';
import { checkStringPermission } from 'helpers/filteredPermissions';

// Style
import './index.scss';

// Main Component
const Archive = () => {
  const [state, setState] = useState([]);
  const [showProfile, setShowProfile] = useState({
    isModalShow: false,
    isShowView: false,
    profileId: null,
    setActive: '',
  });
  const [showScreen, setShowScreen] = useState(0);
  const userData = useSelector(selectAuth);
  const { permissions } = userData;

  const isDefault = showScreen === 0;
  const isViewProfile = showScreen === 1;
  const isEditProfile = showScreen === 2;

  const isFilterEnabled = checkStringPermission(
    'Can Basic Search  via Patient ID & Name in Archive',
  );

  useEffect(() => {
    console.log('showProfile', showProfile);
  }, [showProfile]);

  useEffect(() => {
    axios
      .get('/patient', {
        params: {
          patient_status: [Status.DISCHARGED],
        },
      })
      .then((response) => {
        setState(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  return (
    <div className='archive'>
      <ConditionalRender
        condition={isDefault}
        renderIf={
          <>
            <ConditionalRender
              condition={isFilterEnabled}
              renderIf={<Filter state={state} setState={setState} />}
            />

            <ArchiveTable
              state={state}
              setState={setState}
              setShowScreen={setShowScreen}
              showProfile={showProfile}
              setShowProfile={setShowProfile}
            />
          </>
        }
      />

      <ConditionalRender
        condition={isViewProfile}
        renderIf={
          <Profile
            showProfile={showProfile}
            setShowProfile={setShowProfile}
            setShowScreen={setShowScreen}
            setState={setState}
          />
        }
      />

      <ConditionalRender
        condition={isEditProfile}
        renderIf={
          <FormPatientProfile
            showProfile={showProfile}
            setShowScreen={setShowScreen}
            setState={setState}
          />
        }
      />
    </div>
  );
};

export default Archive;
