/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable multiline-comment-style */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { Row, Col, Form, Button, FormControl, Accordion } from 'react-bootstrap';

// import components
import FormUI from 'components/UI/FormUI';
import { integerCharFieldRules } from 'helpers/fieldValidations';

// import assets
import './index.scss';

// main component
const CreateRoom = ({ setState, showProfile, setShowProfile }) => {
  const [disabled, setDisabled] = useState(true);
  const [params, setParams] = useState({
    type: '',
    floor: '',
    room: '',
    bed: '',
    price: '',
    unit: '',
    status: 'available',
    pricing_scheme: 'available',
    isReserved: 'no',
    isPayWard: false,
  });

  const handleChange = (key) => (e) => {
    if (key === 'bed') {
      setParams({
        [key]: e.target.value.replace(/[0-9]/g, ''),
      });
    } else {
      setParams({
        ...params,
        [key]: e.target.value,
      });
    }

    if (
      params.type.length > 0 &&
      params.floor.length > 0 &&
      params.room.length > 0 &&
      params.bed.length > 0
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleSubmit = () => {
    const { inValidateInput, showValidationMsg } = integerCharFieldRules;

    if (inValidateInput(params.price)) {
      toast.warning(showValidationMsg('Price'));
      return;
    }

    axios
      .post('/room', params)
      .then((response) => {
        setParams({
          type: '',
          floor: '',
          room: '',
          bed: '',
          price: '',
          unit: '',
          status: 'available',
          pricing_scheme: '',
          isReserved: 'no',
        });
        toast.success('Successfully created!');

        axios.get('/room').then((response) => {
          setState(response.data.data.room_list);
        });
      })
      .catch((error) => {
        toast.warning('ERROR!');
      });
  };

  return (
    <FormUI>
      <div className='createroom-cont'>
        <h3>Create Room</h3>
        <Row>
          <Col>
            <Accordion defaultActiveKey={'0'} className='mb-3 advanced-search'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Request Details</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col className='mb-3'>
                      <Form.Group className='mb-3' style={{ width: '100%' }}>
                        <Form.Label>
                          Type<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Select onChange={handleChange('type')} value={params.type}>
                          <option hidden>-Select-</option>
                          <option value='Private'>Private</option>
                          <option value='Suite'>Suite</option>
                          <option value='Ward'>Ward</option>
                          <option value='Operating Room'>Operating Room</option>
                          <option value='Delivery Room'>Delivery Room</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col className='mb-3'>
                      <Form.Group className='mb-3' style={{ width: '100%' }}>
                        <Form.Label>
                          Floor<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Select onChange={handleChange('floor')} value={params.floor}>
                          <option>-Select-</option>
                          <option value='1st'>1st</option>
                          <option value='2nd'>2nd</option>
                          <option value='3rd'>3rd</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    {/* <Col className='mb-3'>
                      <Form.Group className='mb-3' style={{ width: '100%' }}>
                        <Form.Label>Unit*</Form.Label>
                        <Form.Select>
                          <option>-Select-</option>
                        </Form.Select>
                      </Form.Group>
                    </Col> */}
                  </Row>

                  <Row>
                    <Col className='mb-3'>
                      <Form.Group className='mb-3' style={{ width: '100%' }}>
                        <Form.Label>
                          Room<span className='asterisk'>*</span>
                        </Form.Label>
                        <FormControl
                          type='number'
                          className='bg-white'
                          placeholder='Enter Here'
                          onChange={handleChange('room')}
                          value={params.room}
                          onKeyDown={(evt) =>
                            ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col className='mb-3'>
                      <Form.Group className='mb-3' style={{ width: '100%' }}>
                        <Form.Label>
                          Bed<span className='asterisk'>*</span>
                        </Form.Label>
                        <FormControl
                          type='text'
                          className='bg-white'
                          placeholder='Enter Here'
                          onChange={handleChange('bed')}
                          value={params.bed}
                        />
                      </Form.Group>
                    </Col>
                    {/* <Col className='mb-3'>
                      <Form.Group className='mb-3' style={{ width: '100%' }}>
                        <Form.Label>Status*</Form.Label>
                        <Form.Select>
                          <option>-Select-</option>
                        </Form.Select>
                      </Form.Group>
                    </Col> */}
                  </Row>

                  <Row>
                    {/* <Col className='mb-3'>
                      <Form.Group className='mb-3' style={{ width: '100%' }}>
                        <Form.Label>Floor Pricing Scheme**</Form.Label>
                        <Form.Select>
                          <option>-Select-</option>
                        </Form.Select>
                      </Form.Group>
                    </Col> */}
                    <Col md={6} className='mb-3'>
                      <Form.Group className='mb-3' style={{ width: '100%' }}>
                        <Form.Label>
                          Is Pay Ward?<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Select onChange={handleChange('isPayWard')} value={params.isPayWard}>
                          <option>-Select-</option>
                          <option value={true}>YES</option>
                          <option value={false}>NO</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    {params.isPayWard === 'true' ? (
                      <Col md={6} className='mb-3'>
                        <Form.Group className='mb-3' style={{ width: '100%' }}>
                          <Form.Label>
                            Price<span className='asterisk'>*</span>
                          </Form.Label>
                          <FormControl
                            type='number'
                            value={params.price}
                            className='bg-white'
                            placeholder='Enter Here'
                            required={params.isPayWard}
                            onChange={handleChange('price')}
                            onKeyDown={(evt) =>
                              (evt.key === 'e' ||
                                evt.key === 'E' ||
                                evt.key === '-' ||
                                evt.key === '.') &&
                              evt.preventDefault()
                            }
                          />
                        </Form.Group>
                      </Col>
                    ) : (
                      <></>
                    )}
                    {/* <Col className='mb-3'>
                      <Form.Group className='mb-3' style={{ width: '100%' }}>
                        <Form.Label>Reserved?*</Form.Label>
                        <FormControl type='text' className='bg-white' placeholder='Enter Here' />
                      </Form.Group>
                    </Col> */}
                  </Row>

                  <Row>
                    <div className='buttonWrapper'>
                      <Button variant='search' onClick={() => handleSubmit()} disabled={disabled}>
                        CREATE ROOM
                      </Button>
                      <Button
                        variant='clear'
                        onClick={() => {
                          setShowProfile((prevState) => {
                            return {
                              ...prevState,
                              createRoom: false,
                              isDefault: true,
                            };
                          });
                        }}
                      >
                        CANCEL
                      </Button>
                    </div>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </div>
    </FormUI>
  );
};

CreateRoom.defaultProps = {
  showProfile: {},
  setState: () => {},
  setShowProfile: () => {},
};

CreateRoom.propTypes = {
  setState: PropTypes.func,
  showProfile: PropTypes.object,
  setShowProfile: PropTypes.func,
};

export default CreateRoom;
