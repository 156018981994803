/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Row, Col, Button, Form, FormControl } from 'react-bootstrap';

import Table from 'components/ReactTable';
import LeaveCreditsModal from './components/Modal';

import './index.scss';
import { ReactComponent as EyeSVG } from 'assets/svg/eye.svg';
import { ReactComponent as EditSVG } from 'assets/svg/pencil green.svg';
import { ReactComponent as PrintSVG } from 'assets/svg/print red.svg';

const state = [
  {
    designation: 'Nurse',
    employee_number: '001',
    department: 'Nursing Dept.',
    employee_name: 'Juan Dela Cruz',
    penalties: 'Sample Penalties',
    charges: [
      {
        id: 1,
        details: 'Sample Charge 1',
      },
      {
        id: 2,
        details: 'Sample Charge 2',
      },
      {
        id: 3,
        details: 'Sample Charge 3',
      },
    ],
  },
];

const index = ({ setShowScreen, setRequestData }) => {
  const [openModal, setOpenModal] = useState();

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Employee Number', accessor: 'employee_number' },
      { Header: 'Employee Name', accessor: 'employee_name' },
      { Header: 'Department', accessor: 'department' },
      { Header: 'Designation', accessor: 'designation' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      state?.map((dd) => ({
        data: dd,
        charges: dd?.charges,
        penalties: dd?.penalties,
        department: dd?.department,
        designation: dd?.designation,
        employee_name: dd?.employee_name,
        employee_number: dd?.employee_number,
      })),
    [state],
  );

  return (
    <div className='advances-table'>
      <div className='main-table'>
        <Table
          data={tableData}
          columns={tableColumns}
          pagination={['BOTTOM']}
          customAction={(id, data) => {
            return (
              <div className='advance-actions'>
                <EyeSVG
                  onClick={() => {
                    setShowScreen(2);
                    setRequestData(data);
                  }}
                />
                <EditSVG
                  onClick={() => {
                    setShowScreen(4);
                    setRequestData(data);
                  }}
                />
                <PrintSVG />
              </div>
            );
          }}
        />
      </div>

      <LeaveCreditsModal openModal={openModal} setOpenModal={setOpenModal} />
    </div>
  );
};

export default index;
