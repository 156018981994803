/* eslint-disable multiline-comment-style */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// Packages
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import TagsInput from 'react-tagsinput';
import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { useDispatch, useSelector } from 'react-redux';
import { getrefillGroups, selectGroups } from 'reducers/refillRequest';
import { Row, Col, Button, Modal, Form, FormControl, Accordion } from 'react-bootstrap';

// Components
import Table from 'components/ReactTable';
import FormUI from 'components/UI/FormUI';
import {
  resetLogState,
  uploadExcelFile,
  selectInventory,
  createInventoryItems,
  resetcreateInventoryItemLogs,
} from 'reducers/inventoryReducer';

// Assets
import './style.scss';
import 'react-tagsinput/react-tagsinput.css';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import { ReactComponent as CircleMinusSVG } from 'assets/svg/circle-minus.svg';

// main component
const CreateNewItemModal = ({ showProfile, setShowProfile, getInventoryItems }) => {
  const dispatch = useDispatch();
  const inventoryState = useSelector(selectInventory);
  const groupState = useSelector(selectGroups);
  const { createInventoryItemLogs, uploadExcelFileLogs } = inventoryState;

  const [itemData, setItemData] = useState({
    is_medicine: true,
  });
  const [tags, setTags] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [validated, setValidated] = useState(false);

  const handleChange = (key, e) => {
    if (key === 'group') {
      setItemData({
        ...itemData,
        [key]: { label: e.value, value: e.value },
      });
      return;
    }

    setItemData({
      ...itemData,
      [key]: e.target.value,
    });
    return;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (tags.length === 0) {
      toast.error('Please add tags');
      return;
    }

    if (form.checkValidity() !== false) {
      setItemList([
        ...itemList,
        {
          ...itemData,
          tags: tags,
          id: parseInt(itemData.code),
          code: parseInt(itemData.code),
          group: itemData?.group?.value,
        },
      ]);

      setItemData({
        is_medicine: true,
      });

      setValidated(false);
      setTags([]);
      return;
    }

    setValidated(true);
  };

  const saveItem = () => {
    dispatch(
      createInventoryItems({
        items: itemList,
      }),
    );
  };

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Item Code', accessor: 'code' }, // accessor is the "key" in the data
      { Header: 'Item Name', accessor: 'name' },
      { Header: 'Item Group', accessor: 'group' },
      { Header: 'Generic Name', accessor: 'generic_name' },
      { Header: 'Manufacturer', accessor: 'manufacturer' },
      { Header: 'Piece Price', accessor: 'piece_price' },
      { Header: 'Box Price', accessor: 'box_price' },
      { Header: 'Section', accessor: 'section' },
      { Header: 'Item Description', accessor: 'description' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      itemList?.map((dd) => ({
        id: dd?.id,
        code: dd?.code,
        name: dd?.name,
        group: dd?.group,
        generic_name: dd?.generic_name,
        manufacturer: dd?.manufacturer,
        piece_price: dd?.piece_price,
        box_price: dd?.box_price,
        description: dd?.description,
        section: dd?.section,
        created_at: dd?.created_at,
      })),
    [itemList],
  );

  const handleTableAction = (eventKey, id) => {
    const action = {
      remove: () => {
        const newItemList = itemList.filter((item) => item.id !== id);
        setItemList(newItemList);
      },
    };

    action[eventKey]();
  };

  const handleDownloadTemplate = () => {
    const url = `${process.env.REACT_APP_API_BASE_INVENTORY}/api/export/download-template`;

    window.open(url, '_blank').focus();
  };

  useEffect(() => {
    const { isLoading, isSuccess, isFailed, error } = createInventoryItemLogs;

    if (isSuccess) {
      toast.success('Successfully created!');

      setTimeout(() => {
        setShowProfile((prevState) => {
          return {
            ...prevState,
            showCreateItem: false,
          };
        });

        dispatch(resetcreateInventoryItemLogs());
      }, 200);

      getInventoryItems();
    }

    if (isFailed) {
      toast.error(error);
    }
  }, [createInventoryItemLogs]);

  useEffect(() => {
    dispatch(getrefillGroups());
  }, []);

  useEffect(() => {
    const { isLoading, isSuccess, isFailed, error, arrErrors } = uploadExcelFileLogs;

    if (isSuccess) {
      toast.success('Excel file successfully uploaded!');

      setTimeout(() => {
        setShowProfile((prevState) => {
          return {
            ...prevState,
            showCreateItem: false,
          };
        });
      }, 200);

      getInventoryItems();
      dispatch(resetLogState());
    }

    if (isFailed) {
      if (arrErrors.length > 0) {
        console.log('arrErrors', arrErrors);
        arrErrors.forEach((dd) => {
          const { errors } = dd;

          errors.forEach((err) => {
            toast.error(`(Col ${dd?.attribute}) (Row ${dd?.row}) (Val ${dd?.value}) - ${err}`, {
              autoClose: 10000,
            });
          });
        });
      } else {
        toast.error(error);
      }

      dispatch(resetLogState());
    }
  }, [uploadExcelFileLogs]);

  return (
    <Modal
      size='lg'
      show={showProfile.showCreateItem}
      onHide={(e) =>
        setShowProfile((prevState) => {
          return {
            ...prevState,
            showCreateItem: false,
          };
        })
      }
      aria-labelledby='Create New Item Modal'
      className='create-new-item-modal'
    >
      <Modal.Body>
        <div className='create-new-item-request'>
          <div className='d-flex header'>
            <p>CREATE NEW ITEM TO STORE</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={(e) => {
                setShowProfile((prevState) => {
                  return { ...prevState, showCreateItem: false };
                });
              }}
            />
          </div>
        </div>

        <FormUI>
          <Form
            className='create-item-form'
            noValidate
            validated={validated}
            onSubmit={(e) => handleSubmit(e)}
          >
            <Row className='mb-4'>
              <Col className='buttonsWrapper'>
                <Button onClick={handleDownloadTemplate} variant='primary'>
                  Download Template
                </Button>
                <div className='cont'>
                  <Form.Label
                    disabled={uploadExcelFileLogs?.isLoading === true}
                    htmlFor='upload-image'
                  >
                    Upload Excel File
                  </Form.Label>

                  <FormControl
                    type='file'
                    id='upload-image'
                    accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                    onChange={(e) => {
                      // eslint-disable-next-line prefer-const
                      let docuArr = [];

                      Array.from(e.target.files).forEach((file) => {
                        docuArr.push(file);
                      });

                      if (e?.target?.files[0]) {
                        const formData = new FormData();
                        formData.append('file', e?.target?.files[0]);
                        dispatch(uploadExcelFile(formData));
                      }
                    }}
                  />
                </div>
              </Col>
            </Row>

            <h5 className='fw-bold mb-3'>Item Information</h5>

            <Row>
              <Col className='d-flex align-center'>
                <Form.Group className='inline-group'>
                  <Form.Label className='is-medicine'>Is Medicine?</Form.Label>
                  <Form.Check
                    checked={itemData?.is_medicine}
                    onChange={(e) => {
                      setItemData((prev) => {
                        return {
                          ...prev,
                          is_medicine: e.target.checked,
                        };
                      });
                    }}
                    type='switch'
                    id='custom-switch'
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-2 inline-group'>
                  <Form.Label>Item Code</Form.Label>
                  <FormControl
                    required
                    type='number'
                    className='bg-white'
                    placeholder='0000000'
                    value={itemData?.code || ''}
                    onChange={(e) => handleChange('code', e)}
                    onKeyDown={(evt) =>
                      (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '.') &&
                      evt.preventDefault()
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mb-2 inline-group'>
                  <Form.Label>Acquisition Price</Form.Label>
                  <FormControl
                    required
                    type='number'
                    className='bg-white'
                    placeholder='Enter here'
                    value={itemData?.acquisition_price || ''}
                    onChange={(e) => handleChange('acquisition_price', e)}
                  />
                </Form.Group>

                <Form.Group className='mb-2 inline-group'>
                  <Form.Label>Item Name</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-white'
                    value={itemData?.name || ''}
                    onChange={(e) => handleChange('name', e)}
                    placeholder='Enter Here'
                    required
                  />
                </Form.Group>

                <Form.Group className='mb-2 inline-group'>
                  <Form.Label>Generic Name</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-white'
                    value={itemData?.generic_name || ''}
                    onChange={(e) => handleChange('generic_name', e)}
                    placeholder='Enter Here'
                    required
                  />
                </Form.Group>

                <Form.Group className='mb-2 inline-group'>
                  <Form.Label>Manufacturer</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-white'
                    value={itemData?.manufacturer || ''}
                    onChange={(e) => handleChange('manufacturer', e)}
                    placeholder='Enter Here'
                    required
                  />
                </Form.Group>
              </Col>

              <Col>
                <Form.Group className='mb-2 inline-group'>
                  <Form.Label>Piece Price</Form.Label>
                  <FormControl
                    required
                    type='number'
                    className='bg-white'
                    placeholder='0000000'
                    value={itemData?.piece_price || ''}
                    onChange={(e) => handleChange('piece_price', e)}
                    onKeyDown={(evt) =>
                      (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '.') &&
                      evt.preventDefault()
                    }
                  />
                </Form.Group>

                <Form.Group className='mb-2 inline-group'>
                  <Form.Label>Box Price</Form.Label>
                  <FormControl
                    required
                    type='number'
                    className='bg-white'
                    placeholder='0000000'
                    value={itemData?.box_price || ''}
                    onChange={(e) => handleChange('box_price', e)}
                    onKeyDown={(evt) =>
                      (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '.') &&
                      evt.preventDefault()
                    }
                  />
                </Form.Group>
                <Form.Group className='mb-2 inline-group'>
                  <Form.Label>Item Group</Form.Label>
                  <CreatableSelect
                    styles={customStyles}
                    value={itemData?.group || {}}
                    className='select-item-group bg-white'
                    onChange={(e) => {
                      handleChange('group', e);
                    }}
                    options={groupState.map((data) => {
                      return {
                        id: data?.id,
                        label: data?.attributes?.name,
                        value: data?.attributes?.name,
                      };
                    })}
                  />
                </Form.Group>

                <Form.Group className='mb-2 inline-group'>
                  <Form.Label>Section</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-white'
                    value={itemData?.section || ''}
                    onChange={(e) => handleChange('section', e)}
                    placeholder='Enter Here'
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mb-2 inline-group'>
                  <Form.Label className='item-description'>Item Description</Form.Label>
                  <FormControl
                    as='textarea'
                    rows={5}
                    className='bg-white'
                    value={itemData?.description || ''}
                    onChange={(e) => handleChange('description', e)}
                    placeholder='Enter Here'
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mb-2 inline-group'>
                  <Form.Label>Supplier Code</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-white'
                    value={itemData?.supplier_code || ''}
                    onChange={(e) => handleChange('supplier_code', e)}
                    placeholder='Enter Here'
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-2 inline-group'>
                  <Form.Label>Supplier Name</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-white'
                    value={itemData?.supplier_name || ''}
                    onChange={(e) => handleChange('supplier_name', e)}
                    placeholder='Enter Here'
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mb-2 inline-group'>
                  <Form.Label>Tags</Form.Label>
                  <TagsInput
                    value={tags}
                    onChange={(value) => {
                      setTags(value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col></Col>
            </Row>

            <Row>
              <Col className='buttonsWrapper-2 mt-3'>
                <Button type='submit' variant='secondary'>
                  ADD
                </Button>
                <Button variant='primary' onClick={() => setItemData({})}>
                  CLEAR
                </Button>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className='search-item'>
                  <Accordion className='advanced-search'>
                    <Accordion.Item eventKey='0'>
                      <Accordion.Header>Item List</Accordion.Header>
                      <Accordion.Body>
                        <Form.Group className='mb-2 inline-group'>
                          <Form.Select defaultValue=''>
                            <option value=''>Filter</option>
                          </Form.Select>
                        </Form.Group>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>

                <div className='search-table'>
                  <Table
                    data={tableData}
                    columns={tableColumns}
                    customAction={(id) => (
                      <div className='custom-actions'>
                        <Button onClick={() => handleTableAction('remove', id)}>
                          <CircleMinusSVG />
                        </Button>
                      </div>
                    )}
                    pagination={['BOTTOM']}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-5 buttons-cont'>
                  <Button
                    disabled={itemList.length === 0 || createInventoryItemLogs?.isLoading === true}
                    className='add-button'
                    variant='primary'
                    onClick={saveItem}
                  >
                    Create New Item
                  </Button>
                  <Button
                    className='cancel-button'
                    onClick={() =>
                      setShowProfile((prevState) => {
                        return {
                          ...prevState,
                          showCreateItem: false,
                        };
                      })
                    }
                  >
                    Cancel
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

CreateNewItemModal.defaultProps = {
  showProfile: {},
  setShowProfile: () => {},
};

CreateNewItemModal.propTypes = {
  showProfile: PropTypes.object,
  setShowProfile: PropTypes.func,
};

const customStyles = {
  control: () => ({
    height: '50px',
    display: 'flex',
    borderRadius: '10px',
    border: '1px solid #c8c8c8',
  }),
};

export default CreateNewItemModal;
