/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-const-assign */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable multiline-comment-style */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import axios from 'axios';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { Row, Col, Button, Modal, Form, FormControl, InputGroup, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import TableUI from 'components/UI/TableUI';
import { selectPatientItem, showPatient, selectPatient } from 'reducers/patientReducer';
import moment from 'moment';

// components
import FormUI from 'components/UI/FormUI';

// import assets below
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

// main component
const RoomInfoModal = ({ state, setState, roomInfo }) => {
    const dispatch = useDispatch();

    const patientProfile = useSelector(selectPatientItem);

    const [totalAdjustments, setTotalAdjustments] = useState(0);
    const [userData, setUserData] = useState({
        name_of_payor: '',
        amount: '',
        billId: '',
        patientId: '',
    });

    return (
        <Modal
            size='lg'
            show={state.viewRoom}
            onHide={() =>
                setState((prevState) => {
                    return {
                        ...prevState,
                        viewRoom: false,
                    };
                })
            }
            aria-labelledby='Lab Request Modal'
            className='add-procedure-modal'
        >
            <Modal.Body>
                <div className='add-procedure-request'>
                    <div className='d-flex header'>
                        <p>Room Information {state.roomId}/{roomInfo?.id}</p>

                        <CircleXMark
                            style={{ width: '2em', cursor: 'pointer' }}
                            onClick={() => {
                                setState((prevState) => {
                                    return { ...prevState, viewRoom: false };
                                });
                            }}
                        />
                    </div>
                </div>
                <FormUI>
                    <Form className='procedure-form'>

                        <div className='adjustments'>
                            <Row>
                                <Col>
                                    <div className='patientProfile__header'>
                                        <h5>Room Information</h5>
                                    </div>

                                    <Row>
                                        <Col lg='5'>
                                            <div className='wrapper'>
                                                <span>Type</span>
                                                <span>:</span>
                                            </div>
                                        </Col>
                                        <Col lg='auto'>
                                            <span className='value' style={{ color: '#006397' }}><b>{roomInfo?.type}</b></span>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg='5'>
                                            <div className='wrapper'>
                                                <span>Floor</span>
                                                <span>:</span>
                                            </div>
                                        </Col>
                                        <Col lg='auto'>
                                            <span className='value' style={{ color: '#006397' }}><b>{roomInfo?.floor}</b></span>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg='5'>
                                            <div className='wrapper'>
                                                <span>Room</span>
                                                <span>:</span>
                                            </div>
                                        </Col>
                                        <Col lg='auto'>
                                            <span className='value' style={{ color: '#006397' }}><b>{roomInfo?.room}</b></span>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg='5'>
                                            <div className='wrapper'>
                                                <span>Bed</span>
                                                <span>:</span>
                                            </div>
                                        </Col>
                                        <Col lg='auto'>
                                            <span className='value' style={{ color: '#006397' }}><b>{roomInfo?.bed}</b></span>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg='5'>
                                            <div className='wrapper'>
                                                <span>Price</span>
                                                <span>:</span>
                                            </div>
                                        </Col>
                                        <Col lg='auto'>
                                            <span className='value' style={{ color: '#006397' }}><b>Php {roomInfo?.price}</b></span>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col>
                                    <div className='patientProfile__header'>
                                        <h5>Patient Information</h5>
                                    </div>

                                    <Row>
                                        <Col lg='5'>
                                            <div className='wrapper'>
                                                <span>Full Name</span>
                                                <span>:</span>
                                            </div>
                                        </Col>
                                        <Col lg='auto'>
                                            <span className='value' style={{ color: '#006397' }}><b>{roomInfo?.assigned_room?.patient?.full_name}</b></span>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg='5'>
                                            <div className='wrapper'>
                                                <span>Age</span>
                                                <span>:</span>
                                            </div>
                                        </Col>
                                        <Col lg='auto'>
                                            <span className='value' style={{ color: '#006397' }}><b>{roomInfo?.assigned_room?.patient?.age}</b></span>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg='5'>
                                            <div className='wrapper'>
                                                <span>Sex</span>
                                                <span>:</span>
                                            </div>
                                        </Col>
                                        <Col lg='auto'>
                                            <span className='value' style={{ color: '#006397' }}><b>{roomInfo?.assigned_room?.patient?.sex}</b></span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </FormUI>
            </Modal.Body>
        </Modal>
    );
};

RoomInfoModal.defaultProps = {
    showProfile: () => { },
    setShowProfile: () => { },
};

RoomInfoModal.propTypes = {
    showProfile: PropTypes.func,
    setShowProfile: PropTypes.func,
};

export default RoomInfoModal;
