/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */ 
import React from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import './index.scss';

const headerTitleStyle = { 
  fontWeight: 'bold', 
  color: 'black', 
  fontSize: '16px', 
};
const headerSubtitleStyle = { 
  fontWeight: 'bold', 
  color: 'black', 
  fontSize: '14px', 
  marginTop: '5px', 
};
const PrintContent = React.forwardRef(({ data, viewFormType }, ref) => {
  const { REACT_APP_LOGO, REACT_APP_HOSPITAL_NAME, REACT_APP_HOSPITAL_STREET_ADDRESS } = process.env;
  const { 
      name_of_hospital, address, lname, 
      given_name, mname, gender,
      chief_complaint, present_illness,
      admitting_impression, hrn,
      age, attending_physician,
      location, history,
  } = data;
  return (
    <div className='print_body' ref={ref}> 
      <header>
          <div className='header_logo'>
            <img src={REACT_APP_LOGO} alt='print_logo' />
          </div>
          {/* Inline styling is used due to css class names .header_title and  .header_subtitle is not working at times */}
          <div style={headerTitleStyle}>{REACT_APP_HOSPITAL_NAME}</div>
          <div style={headerSubtitleStyle}>{REACT_APP_HOSPITAL_STREET_ADDRESS}</div>
      </header>
     <Table bordered>
        <thead>
          <tr>
            <th>{viewFormType}</th>
          </tr>
        </thead>
        <tbody>
          <Container>
            <Row className='mb-5'>
              <Col className='first-row'>
                <div className='input-group'>
                  <p>{name_of_hospital}</p>
                  <label>Name of Hospital</label>
                </div>

                <div className='input-group'>
                  <p>{address}</p>
                  <label>Address</label>
                </div>
              </Col>
            </Row>

            <Row className='mb-2'>
              <Col>
                <div className='input-group'>
                  <label>Last Name:</label>
                  <p>{lname}</p>
                </div>
              </Col>
              <Col>
                <div className='input-group'>
                  <label>Given Name:</label>
                  <p>{given_name}</p>
                </div>
              </Col>
              <Col>
                <div className='input-group'>
                  <label>Middle Name:</label>
                  <p>{mname}</p>
                </div>
              </Col>
              <Col>
                <div className='input-group'>
                  <label>HRN:</label>
                  <p>{hrn}</p>
                </div>
              </Col>
            </Row>

            <Row className='mb-5'>
              <Col>
                <div className='input-group'>
                  <label>Age</label>
                  <p>{age}</p>
                </div>
              </Col>
              <Col>
                <div className='input-group'>
                  <label>Gender</label>
                  <p>{gender}</p>
                </div>
              </Col>
              <Col>
                <div className='input-group'>
                  <label>WARD / ROOM / BED</label>
                  <p>{location}</p>
                </div>
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col className='third-row'>
                <h2>{viewFormType}</h2>
              </Col>
            </Row>

            <Row className='mb-5'>
              <Col>
              <table className='border-0'>
                <tbody>
                  <tr>
                    <td className='align-top'>CHIEF COMPLAINT</td>
                    <td className='textarea-table-td text-break align-top'>{chief_complaint}</td>
                  </tr>
                  <tr>
                    <td className='align-top'>PRESENT ILLNESS</td>
                    <td className='textarea-table-td text-break align-top'>{present_illness}</td>
                  </tr>
                  <tr>
                    <td className='align-top'>HISTORY</td>
                    <td className='textarea-table-td text-break align-top'>{history}</td>
                  </tr>
                  <tr>
                    <td className='align-top'>ADMITTING IMPRESSION</td>
                    <td className='textarea-table-td text-break align-top'>{admitting_impression}</td>
                  </tr>
                </tbody>
              </table>
              </Col>
            </Row>

            <Row className='mb-5'>
              <Col>
                <div className='inline-label input-group'>
                  <label>ATTENDING PHYSICIAN</label>

                  <div className='signature'>
                    <p className='name'>{attending_physician}</p>
                    <p className='over-printed-name'>Signature Over Printed Name</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </tbody>
      </Table>
    </div>
  );
}); 

PrintContent.displayName = 'PrintContent';

export default PrintContent; 