/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// Packages
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Row, Col, Button, Form, FormControl, InputGroup } from 'react-bootstrap';

// components below
import FormUI from 'components/UI/FormUI';
import RequestLaboratoryTable from './Table';
import ConditionalRender from 'components/ConditionalRender';
import { checkStringPermission } from 'helpers/filteredPermissions';

// CSS
import './style.scss';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const RequestLaboratory = () => {
  const [labRequests, setLabRequests] = useState([]);
  const [state, setState] = useState([]);
  const [showModal, setShowModal] = useState({
    isShowView: false,
    profileData: null,
  });
  const [userData, setUserData] = useState({
    patient_name: '',
    attending_physician: '',
    status: '',
  });

  const handleChange = (key) => (e) => {
    setUserData({
      ...userData,
      [key]: e.target.value,
    });
  };

  const onChangeHandler = (event) => {
    const searchedWord = event.target.value;
    const key = event.target.name;

    const newFilter = labRequests.filter((value) => {
      return value.patient?.full_name.toLowerCase().includes(searchedWord.toLowerCase());
    });

    if (searchedWord.length > 0) {
      setState(newFilter);
    } else {
      setState(labRequests);
    }

    setUserData({
      ...userData,
      [key]: searchedWord,
    });
  };

  const onChangeDoctorHandler = (event) => {
    const searchedWord = event.target.value;
    const key = event.target.name;

    const newFilter = labRequests.filter((value) => {
      return value.user?.full_name.toLowerCase().includes(searchedWord.toLowerCase());
    });

    if (searchedWord.length > 0) {
      setState(newFilter);
    } else {
      setState(labRequests);
    }

    setUserData({
      ...userData,
      [key]: searchedWord,
    });
  };

  const onChangeStatusHandler = (event) => {
    const searchedWord = event.target.value;
    const key = event.target.name;

    const newFilter = labRequests.filter((value) => {
      return value.status.toLowerCase().includes(searchedWord.toLowerCase());
    });

    if (searchedWord.length > 0) {
      setState(newFilter);
    } else {
      setState(labRequests);
    }

    setUserData({
      ...userData,
      [key]: searchedWord,
    });
  };

  const resetFilter = () => {
    setUserData({
      status: '',
      patient_name: '',
      attending_physician: '',
    });

    setState(labRequests);
  };

  useEffect(() => {
    axios
      .get('/requisition/laboratory')
      .then((response) => {
        setLabRequests(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  const canViewNursingPatientList = checkStringPermission('Can View Nursing Patient List');

  return (
    <FormUI>
      <div className='request-laboratory-assessment mt-4'>
        <Form>
          <Row className='mb-2'>
            <Col>
              <Form.Group className='basic-search'>
                <Form.Label>Patient Name</Form.Label>
                <InputGroup>
                  <InputGroup.Text className='bg-white'>
                    <MagnifyingGlassSVG />
                  </InputGroup.Text>
                  <FormControl
                    type='search'
                    name='patient_name'
                    className='bg-white'
                    placeholder='Enter Here'
                    onChange={onChangeHandler}
                    value={userData?.patient_name}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='mb-4'>
                <Form.Label>Attending Physician</Form.Label>
                <FormControl
                  type='text'
                  className='bg-white'
                  placeholder='Enter Here'
                  name='attending_physician'
                  onChange={onChangeDoctorHandler}
                  value={userData?.attending_physician}
                />
              </Form.Group>
            </Col>
            {/*            <Col>
              <Form.Group className=''>
                <Form.Label>Room / Bed</Form.Label>
                <Form.Select
                  aria-label='Doctor'
                  value={userData.room_bed}
                  onChange={handleChange('room_bed')}
                >
                  <option value='' hidden>
                    - Select -
                  </option>
                  <option value='private'>Private</option>
                  <option value='suite'>Suite</option>
                  <option value='ward'>Ward</option>
                  <option value='operating_room'>Operating Room</option>
                  <option value='delivery_room'>Delivery Room</option>
                </Form.Select>
              </Form.Group>
            </Col> */}
            <Col>
              <Form.Group className=''>
                <Form.Label>Status</Form.Label>

                <Form.Select
                  name='status'
                  aria-label='Location'
                  value={userData?.status}
                  onChange={onChangeStatusHandler}
                >
                  <option value='' hidden>
                    - Select -
                  </option>
                  <option value='PENDING'>Pending</option>
                  <option value='COMPLETED'>Completed</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='category-buttons'>
                <Button onClick={resetFilter} className='reset-button' variant='secondary'>
                  Reset
                </Button>
              </Form.Group>
            </Col>
          </Row>

          {/* <Row className='mb-3'>
            <Col>
              <Form.Group className='category-buttons'>
                <Button
                  onClick={() => {
                    // Update Data on Table?
                  }}
                  className='add-button'
                  variant='primary'
                  type='submit'
                >
                  Go
                </Button>
                <Button onClick={() => resetFilter()} className='reset-button' variant='secondary'>
                  Reset
                </Button>
              </Form.Group>
            </Col>
          </Row> */}
        </Form>

        <ConditionalRender
          condition={canViewNursingPatientList}
          renderIf={
            <>
              <h2 className='mb-3'>Nursing Patient List</h2>

              {/* Table */}
              <RequestLaboratoryTable
                state={state}
                setState={setState}
                setShowModal={setShowModal}
              />
            </>
          }
        />
      </div>
    </FormUI>
  );
};

export default RequestLaboratory;
