/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import axios from 'axios';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { Row, Col, Form, Button, Accordion, FormControl } from 'react-bootstrap';

import FormUI from 'components/UI/FormUI';

import './index.scss';

const CreateLabTestForm = ({ showProfile, setShowProfile, setState }) => {
  const [disabled, setDisabled] = useState(true);

  const [params, setParams] = useState({
    name: '',
    internal_external: '',
    price: '',
    isActive: '',
    type: '',
  });

  const handleChange = (key) => (e) => {
    setParams({
      ...params,
      [key]: e.target.value,
    });

    if (params.name.length > 0 && params.internal_external.length > 0 && params.price.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleSubmit = () => {
    axios
      .post('/lab_test', params)
      .then((response) => {
        setParams({
          name: '',
          internal_external: '',
          price: '',
          isActive: '',
          type: '',
        });
        toast.success('Successfully Created!');

        axios.get('/lab_test').then((response) => {
          setState(response.data.data);
        });
      })
      .catch((error) => {
        console.log('CREATE LAB ERROR HANDLER', error);
        error.response.data.errors.map((item) => {
          toast.warning(item.msg + ' ' + item.param);
        });
      });
  };

  return (
    <FormUI>
      <Form>
        <div className='createroom-cont'>
          <h3>Create Laboratory</h3>

          <Row>
            <Col>
              <Accordion defaultActiveKey={'0'} className='mb-3 advanced-search'>
                <Accordion.Item eventKey='0'>
                  <Accordion.Header>Request Details</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col className='mb-3'>
                        <Form.Group className='mb-3' style={{ width: '100%' }}>
                          <Form.Label>
                            Lab Test/Imaging Name<span className='asterisk'> *</span>
                          </Form.Label>
                          <FormControl
                            type='text'
                            className='bg-white'
                            placeholder='Enter Here'
                            onChange={handleChange('name')}
                            value={params.name}
                          />
                        </Form.Group>
                      </Col>
                      <Col className='mb-3'>
                        <Form.Group className='mb-3' style={{ width: '100%' }}>
                          <Form.Label>
                            Internal/External?<span className='asterisk'> *</span>
                          </Form.Label>
                          <Form.Select
                            onChange={handleChange('internal_external')}
                            value={params.internal_external}
                          >
                            <option hidden value=''>
                              -Select-
                            </option>
                            <option value='External'>External</option>
                            <option value='Internal'>Internal</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col className='mb-3'>
                        <Form.Group className='mb-3' style={{ width: '100%' }}>
                          <Form.Label>
                            Price<span className='asterisk'> *</span>
                          </Form.Label>
                          <Form.Control
                            type='number'
                            placeholder='Enter Price here'
                            onChange={handleChange('price')}
                            value={params.price}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col className='mb-3' sm={4}>
                        <Form.Group className='mb-3' style={{ width: '100%' }}>
                          <Form.Label>
                            Status<span className='asterisk'> *</span>
                          </Form.Label>
                          <Form.Select onChange={handleChange('isActive')} value={params.isActive}>
                            <option hidden value=''>
                              -Select-
                            </option>
                            <option value='1'>Active</option>
                            <option value='0'>Inactive</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col className='mb-3' sm={4}>
                        <Form.Group className='mb-3' style={{ width: '100%' }}>
                          <Form.Label>
                            Type<span className='asterisk'> *</span>
                          </Form.Label>
                          <Form.Select onChange={handleChange('type')} value={params.type}>
                            <option hidden value=''>
                              -Select-
                            </option>
                            <option value='LAB_TEST'>LAB TEST</option>
                            <option value='IMAGING_TEST'>IMAGING TEST</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <div className='buttonWrapper'>
                        <Button variant='search' onClick={() => handleSubmit()} disabled={disabled}>
                          CREATE LAB
                        </Button>
                        <Button
                          variant='clear'
                          onClick={() => {
                            setShowProfile((prevState) => {
                              return {
                                ...prevState,
                                showScreen: 1,
                              };
                            });
                          }}
                        >
                          CANCEL
                        </Button>
                      </div>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </div>
      </Form>
    </FormUI>
  );
};

CreateLabTestForm.defaultProps = {
  showProfile: {},
  setState: () => { },
  setShowProfile: () => { },
};

CreateLabTestForm.proptypes = {
  setState: PropTypes.func,
  showProfile: PropTypes.object,
  setShowProfile: PropTypes.func,
};

export default CreateLabTestForm;
