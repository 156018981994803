/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// import core & vendor packages below
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Row, Col, Form, Button, FormControl, InputGroup } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';
import { getPatient } from 'reducers/patientReducer';

// import assets below
import './Filter.scss';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const ClearanceFilter = ({ data, filter, setFilter, setFilteredData }) => {
  const dispatch = useDispatch();

  const handleChange = (key) => (e) => {
    setFilter({
      ...filter,
      [key]: e.target.value,
    });
  };

  const handleSubmit = () => {
    // dispatch(getPatient(filter));
    const newFilteredData = data.filter((patient) => (
        patient?.full_name.toLowerCase().includes(filter.patient_name.toLowerCase()) &&
        // patient?.physician_name?.toLowerCase().includes(filter.physician_name.toLowerCase()) &&
        patient?.admission_date?.includes(filter.date)
      ));
    setFilteredData(newFilteredData);
  };

  useEffect(() => {
    dispatch(getPatient());
  }, []);

  return (
    <div className='clearanceFilter mb-4'>
      <FormUI>
        <Row className='d-flex align-items-end'>
          <Col>
            <Form.Group className='mb-3'>
              <Form.Label>Date</Form.Label>
              <FormControl
                type='date'
                className='bg-white'
                placeholder='Enter Here'
                value={filter.date}
                onChange={handleChange('date')}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='mb-3'>
              <Form.Label>Patient Name</Form.Label>
              <InputGroup>
                <InputGroup.Text className='bg-white'>
                  <MagnifyingGlassSVG />
                </InputGroup.Text>
                <FormControl
                  type='search'
                  className='bg-white'
                  placeholder='Search here...'
                  value={filter.patient_name}
                  onChange={handleChange('patient_name')}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='mb-3'>
              <Form.Label>Physician Name</Form.Label>
              <InputGroup>
                <InputGroup.Text className='bg-white'>
                  <MagnifyingGlassSVG />
                </InputGroup.Text>
                <FormControl
                  type='search'
                  className='bg-white'
                  placeholder='Search here...'
                  value={filter.physician_name}
                  onChange={handleChange('physician_name')}
                />
              </InputGroup>
            </Form.Group>
          </Col>

          <Col>
            <div className='buttonWrapper mb-3'>
              <Button variant='search' onClick={handleSubmit}>
                Apply
              </Button>
            </div>
          </Col>
        </Row>
      </FormUI>
    </div>
  );
};

export default ClearanceFilter;
