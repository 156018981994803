import React from 'react';
import { Table } from 'react-bootstrap';

const PrintContent = React.forwardRef(({ viewFormType, children }, ref) => {
  const { REACT_APP_LOGO, REACT_APP_HOSPITAL_NAME, REACT_APP_HOSPITAL_STREET_ADDRESS } =
    process.env;

  return (
    <div className='print_body' ref={ref}>
      <header>
        <div className='header_logo'>
          <img src={REACT_APP_LOGO} alt='print_logo' />
        </div>
        <div className='header_title'>{REACT_APP_HOSPITAL_NAME}</div>
        <div className='header_subtitle'>{REACT_APP_HOSPITAL_STREET_ADDRESS}</div>
      </header>
      <Table bordered>
        <thead>
          <tr>
            <th>{viewFormType}</th>
          </tr>
        </thead>
        <tbody>
            {children}
        </tbody>
      </Table>
    </div>
  );
});

PrintContent.displayName = 'PrintContent';

export default PrintContent;