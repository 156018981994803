// import core & vendor packages below
import { Container, Row } from 'react-bootstrap';

// main components below
import Layout from 'components/Layout';
import CreateRoomRequestHeader from './PageHeader';
import FormCreateRoomRequest from 'components/FormCreateRoomRequest';

// main component
const CreateRoomRequest = () => {
  return (
    <Layout pageTitle='Admissions'>
      <CreateRoomRequestHeader />

      <Container fluid>
        <Row>
          <FormCreateRoomRequest />
        </Row>
      </Container>
    </Layout>
  );
};

export default CreateRoomRequest;
