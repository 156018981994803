/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Button,
  OverlayTrigger,
  Popover,
  Table,
  Pagination,
  Form,
} from 'react-bootstrap';
import { selectPatientData } from 'reducers/patientReducer';
import PaginationUI from 'components/UI/PaginationUI';
import TableUI from 'components/UI/TableUI';
import CartModal from './Modal';

import { ReactComponent as ThreeDotsSVG } from 'assets/svg/three-dots-svgrepo-com.svg';
import { ReactComponent as CartSVG } from 'assets/svg/cart-plus.svg';
import { ReactComponent as RedMark } from 'assets/svg/circle-xmark-red.svg';
import { ReactComponent as ArrowLeftSVG } from 'assets/svg/angle-left.svg';
import { ReactComponent as ArrowRightSVG } from 'assets/svg/angle-right.svg';
import moment from 'moment';

const OrderTable = ({ showProfile, setShowProfile }) => {
  const [modalShow, setModalShow] = useState(false);
  const data = useSelector(selectPatientData);

  return (
    <>
      <div className='medical-record-action-table'>
        <TableUI>
          <Row>
            <Col sm={7}>
              <div className='buttons mt-3'>
                <Button className='active'>Medication</Button>
                <Button className=''>Supply</Button>
              </div>
            </Col>
            <Col sm={5}>
              <div className='assesment-pagination mt-3'>
                <PaginationUI>
                  <span className='summary'>{2} Result</span>
                  <div className='selectWrapper'>
                    <Form.Label>Show: </Form.Label>
                    <Form.Select>
                      <option>10</option>
                      <option>15</option>
                      <option>20</option>
                      <option>25</option>
                      <option>30</option>
                    </Form.Select>
                  </div>
                  <Pagination>
                    <Pagination.Prev>
                      <ArrowLeftSVG />
                    </Pagination.Prev>
                    <Pagination.Item active>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                    <Pagination.Item>{3}</Pagination.Item>
                    <Pagination.Item>{4}</Pagination.Item>
                    <Pagination.Item>{5}</Pagination.Item>
                    <Pagination.Next>
                      <ArrowRightSVG />
                    </Pagination.Next>
                  </Pagination>
                </PaginationUI>
              </div>
            </Col>
          </Row>

          <Table responsive>
            <thead>
              <tr>
                <th>Medication Description</th>
                <th>Doze</th>
                <th>Route of Administration</th>
                <th>Times of Administration</th>
                <th>Unit of measure</th>
                <th>Unit price</th>
                <th>QTY</th>
                <th>Total Amount</th>
              </tr>
            </thead>
            <tbody>
              {showProfile?.profileData?.medications?.map((item) => (
                <tr key={item.id}>
                  <td>{moment(item?.createdAt).format('MMMM-DD-YYYY hh:mm A')}</td>
                  <td>{item.medication}</td>
                  <td>{item.doze}</td>
                  <td>{item.route_of_administration}</td>
                  <td>{item.times_of_administration}</td>
                  <td>{item.uom}</td>
                  <td>{item.unit_price}</td>
                  <td>{item.quantity}</td>
                  <td>{item.total_amount}</td>
                  {/* <td>
                    <OverlayTrigger
                      trigger='focus'
                      placement='bottom'
                      overlay={
                        <Popover id='popover-positioned-bottom'>
                          <Popover.Body className='d-flex justify-content-between p-0'>
                            <CartSVG
                              className='p-1 text-success'
                              style={{ width: '2em', cursor: 'pointer' }}
                              onClick={() => {
                                setModalShow((prevState) => {
                                  return {
                                    ...prevState,
                                    showOrderCart: true,
                                  };
                                });
                              }}
                            />
                            <RedMark
                              className='p-1 text-success'
                              style={{ width: '2em', cursor: 'pointer' }}
                            />
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <Button
                        variant='secondary'
                        className='popoverButton bg-transparent p-0 border-0'
                      >
                        <ThreeDotsSVG style={{ width: '1.5em' }} />
                      </Button>
                    </OverlayTrigger>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </TableUI>
      </div>

      <CartModal setModalShow={setModalShow} modalShow={modalShow} />
    </>
  );
};

export default OrderTable;
