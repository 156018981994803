/* eslint-disable */
// import core & vendor packages below
import React from 'react';
import PropTypes from 'prop-types';

import Table from 'components/ReactTable';
import ConditionalRender from 'components/ConditionalRender';
import { checkStringPermission } from 'helpers/filteredPermissions';

// main component
const PatientTable = ({ state, setShowProfile }) => {
  const tableColumns = React.useMemo(
    () => [
      { Header: 'MRN / PIN', accessor: 'mrn' }, // accessor is the "key" in the data
      { Header: 'Patient Name', accessor: 'name' },
      { Header: 'Sex', accessor: 'sex' },
      { Header: 'Date of Birth', accessor: 'date_of_birth' },
      { Header: 'Currently', accessor: 'currently' },
      { Header: 'Status', accessor: 'status' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      state?.map((dd) => ({
        id: dd?.id,
        mrn: dd?.MRN,
        name: dd?.full_name,
        sex: dd?.sex,
        date_of_birth: dd?.date_of_birth,
        currently: dd?.currently,
        status: dd?.patient_status,
      })),
    [state],
  );

  const handleTableAction = (eventKey, id) => {
    const row = state.find((dd) => dd.id === id);
    const action = {
      profile: () => {
        setShowProfile((prevState) => {
          return {
            ...prevState,
            isShowView: true,
            profileId: row?.id,
          };
        });
      },
      view: () => {},
      edit: () => {
        EditProfile(row);
      },
    };

    action[eventKey]();
  };

  return (
    <div className='search-patient-table'>
      <Table
        data={tableData}
        columns={tableColumns}
        actions={{
          hasProfile: checkStringPermission('Can view Patient List & Can view patient details'),
          hasEdit: true,
          hasDelete: true,
        }}
        onAction={handleTableAction}
        pagination={['BOTTOM']}
      />
    </div>
  );
};

PatientTable.defaultProps = {
  state: [],
  setShowProfile: () => {},
};

PatientTable.propTypes = {
  state: PropTypes.array,
  setShowProfile: PropTypes.func,
};

export default PatientTable;
