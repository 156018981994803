/* eslint-disable no-unused-vars */
import { useState } from 'react';

// import components below
import Layout from 'components/Layout';
import AuditLogsHeader from './components/PageHeader';
import AuditLogsTabs from './components/Tabs';

// import assets below
import './style.scss';

// main component
const AuditLogs = () => {
  const [activeTab, setActiveTab] = useState('Logs');
  return (
    <Layout pageTitle='Audit Logs'>
      <AuditLogsHeader active={activeTab} />
      <AuditLogsTabs onChange={setActiveTab} />
    </Layout>
  );
};

export default AuditLogs;
