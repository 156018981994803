import { useState } from 'react';
import PropTypes from 'prop-types';

// main components below
import LabTestTable from './components/Table';
import CreateLabFilter from './components/Filter';
import EditLabTestForm from './components/EditLab';
import CreateLabTestForm from './components/CreateLab';
import ConditionalRender from 'components/ConditionalRender';
import { checkStringPermission } from 'helpers/filteredPermissions';

// main component
const CreateLab = ({ handleTabOnChange }) => {
  const [state, setState] = useState([]);
  const [showProfile, setShowProfile] = useState({
    showScreen: 1,
    profileId: null,
    editValues: null,
  });

  const isDefault = showProfile.showScreen === 1;
  const isCreate = showProfile.showScreen === 2;
  const isEdit = showProfile.showScreen === 3;

  const canViewLabList = checkStringPermission('Can View Laboratory Test List in Procedure');

  return (
    <div>
      <ConditionalRender
        condition={isDefault}
        renderIf={
          <div className='create-lab'>
            <CreateLabFilter
              setState={setState}
              setShowProfile={setShowProfile}
              handleTabOnChange={handleTabOnChange}
            />

            <ConditionalRender
              condition={canViewLabList}
              renderIf={
                <LabTestTable state={state} setState={setState} setShowProfile={setShowProfile} />
              }
            />
          </div>
        }
      />

      <ConditionalRender
        condition={isCreate}
        renderIf={
          <CreateLabTestForm
            showProfile={showProfile}
            setShowProfile={setShowProfile}
            setState={setState}
          />
        }
      />

      <ConditionalRender
        condition={isEdit}
        renderIf={
          <EditLabTestForm
            showProfile={showProfile}
            setShowProfile={setShowProfile}
            setState={setState}
          />
        }
      />
    </div>
  );
};

CreateLab.defaultProps = {
  handleTabOnChange: () => {},
};

CreateLab.propTypes = {
  handleTabOnChange: PropTypes.func,
};

export default CreateLab;
