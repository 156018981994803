/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Table,
  Accordion,
  Button,
  OverlayTrigger,
  Popover,
  Row,
  Col,
  Form,
  FormControl,
} from 'react-bootstrap';

import DeathForm from './DeathForm';
import Confinement from './Confinement';
import LiveBirthForm from './LiveBirthForm';
import MedicalRecords from './MedicalRecords';
import OutpatientRecord from './OutpatientRecord';
import DischargeSummary from './DischargeSummary';
import SubpoenaDuceTecum from './SubpoenaDuceTecum';
import MedicalCertificate from './MedicalCertificate';
import DismemberedBodyParts from './DismemberedBodyParts';
import AdmisionAndDischarge from './AdmissionAndDischarge';
import MedicoLegalCertificate from './MedicoLegalCertificate';
import SubpoenaAdTestificandum from './SubpoenaAdTestificandum';
import CertificateOfFetalDeath from './CertificateOfFetalDeath';
import EmergencyTreatmentRecord from './EmergencyTreatmentRecord';
import HistoryAndPhysicalExamination from './HistoryAndPhysicalExamination';

import { ReactComponent as LeftArrowSVG } from 'assets/svg/chevron-left.svg';

const formTypes = [
  {
    type: 'CERTIFICATE OF CONFINEMENT',
    component: Confinement,
  },
  {
    type: 'MEDICAL CERTIFICATE',
    component: MedicalCertificate,
  },
  {
    type: 'MEDICO - LEGAL CERTIFICATE',
    component: MedicoLegalCertificate,
  },
  {
    type: 'CERTIFICATE OF DISMEMBERED BODY PART',
    component: DismemberedBodyParts,
  },
  {
    type: 'SUBPOENA AD TESTIFICANDUM',
    component: SubpoenaAdTestificandum,
  },
  {
    type: 'SUBPOENA DUCES TECUM',
    component: SubpoenaDuceTecum,
  },
  {
    type: 'MEDICAL RECORDS',
    component: MedicalRecords,
  },
  {
    type: 'OUTPATIENT RECORDS',
    component: OutpatientRecord,
  },
  {
    type: 'EMERGENCY TREATMENT RECORD',
    component: EmergencyTreatmentRecord,
  },
  {
    type: 'ADMISSION AND DISCHARGE RECORD',
    component: AdmisionAndDischarge,
  },
  {
    type: 'DISCHARGE SUMMARY',
    component: DischargeSummary,
  },
  {
    type: 'HISTORY AND PHYSICAL EXAMINATION',
    component: HistoryAndPhysicalExamination,
  },
  {
    type: 'LIVE BIRTH FORM',
    component: LiveBirthForm,
  },
  {
    type: 'DEATH FORM',
    component: DeathForm,
  },
  {
    type: 'FETAL DEATH FORM',
    component: CertificateOfFetalDeath,
  },
];

const ViewForm = ({ setShowScreen, viewForm, setViewForm }) => {
  const [isEdit, setIsEdit] = useState(false);

  if (!viewForm) {
    return;
  }

  return (
    <div className='view-form'>
      <Row className='mb-4'>
        <Col className='header'>
          <Button
            onClick={() => {
              setShowScreen(0);
            }}
          >
            <LeftArrowSVG />
            Back to Forms
          </Button>
          <h4>{viewForm?.type}</h4>
        </Col>
      </Row>

      <Row className='mb-2'>
        <Col className='form-cont'>
          {formTypes.map(({ type, component: Component }, idx) => {
            if (type === viewForm?.type) {
           
              return (
                <Component
                  key={idx}
                  isEdit={isEdit}
                  viewForm={viewForm}
                  setIsEdit={setIsEdit}
                  setViewForm={setViewForm}
                  setShowScreen={setShowScreen}
                />
              );
            }
          })}
        </Col>
      </Row>
    </div>
  );
};

export default ViewForm;
