/* eslint-disable */
// import core & vendor packages below
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from 'components/ReactTable';
import axios from 'axios';
import { toast } from 'react-toastify';

// import components below
import { selectPatientData } from 'reducers/patientReducer';

// import assets below
import './Table.scss';

// main component
const LabResults = ({ setShowModal, state, setState, setResult }) => {
  // const data = useSelector(selectPatientData);

  useEffect(() => {
    axios
      .get('/requisition/laboratory')
      .then((response) => {
        setState(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Patient ID', accessor: 'id' }, // accessor is the "key" in the data
      { Header: 'Patient Name', accessor: 'name' }, // accessor is the "key" in the data
      { Header: 'Internal/External?', accessor: 'internal_external' },
      { Header: 'Doctor Name', accessor: 'doctor_name' },
      { Header: 'Test Type', accessor: 'type' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      state?.map((dd) => ({
        id: dd?.id,
        // id: dd?.patientId, // fix https://highly-succeed-developers.atlassian.net/browse/SH-226
        name: dd?.patient?.full_name,
        internal_external: dd?.lab_test,
        doctor_name: 'Dr. ' + dd?.user?.first_name + ' ' + dd?.user?.last_name,
        type: dd?.lab_type,
      })),
    [state],
  );

  const handleTableAction = (eventKey, row) => {
    // const row = state.find((dd) => dd.id === id);
    const { laboratory_result } = row;

    const action = {
      complete: () => {
        const params = {
          id: row?.id,
          status: 'COMPLETED',
          patientId: row?.patientId,
        };
        axios
          .put(`/requisition/laboratory`, params)
          .then((response) => {
            toast.warning('Successfully completed!');
            axios.get('/requisition/laboratory').then((response) => {
              setState(response.data.data);
            });
          })
          .catch((error) => {
            console.log(error);
            toast.error('ERROR FETCHING LABORATORY RESULTS');
          });
      },
      view: () => {
        setShowModal((prevState) => {
          return {
            ...prevState,
            isViewResult: true,
          };
        });

        setResult((prevState) => {
          return {
            ...prevState,
            lab_test: laboratory_result?.lab_test,
            conducted_by: laboratory_result?.conducted_by,
            licensed_number: laboratory_result?.licensed_number,
            signed_by: laboratory_result?.signed_by,
            date_performed: laboratory_result?.date_performed,
            remarks: laboratory_result?.remarks,
            originalName: laboratory_result?.originalName,
            filename: laboratory_result?.filename,
          };
        });
      },
      remove: () => {
        axios
          .delete(`/requisition/laboratory/`)
          .then((response) => {
            toast.success('Successfully deleted!');
            axios.get('/requisition/laboratory').then((response) => {
              setState(response.data.data);
            });
          })
          .catch((error) => {
            console.log(error);
            toast.error('ERROR FETCHING LABORATORY RESULTS');
          });
      },
      cancel: () => {
        const params = {
          id: row?.id,
          status: 'CANCELLED',
          patientId: row?.patientId,
        };
        axios
          .put(`/requisition/laboratory`, params)
          .then((response) => {
            toast.warning('Successfully cancelled!');
            axios.get('/requisition/laboratory').then((response) => {
              setState(response.data.data);
            });
          })
          .catch((error) => {
            console.log(error);
            toast.error('ERROR FETCHING LABORATORY RESULTS');
          });
      },
    };

    action[eventKey]();
  };

  return (
    <div className='laboratory-result-table'>
      <Table
        data={tableData}
        columns={tableColumns}
        // actions={{ hasProfile: true, hasEdit: true, hasDelete: true }}
        // onAction={handleTableAction}
        customAction={(id) => {
          const row = state.find((dd) => dd.id === id);
          if (row?.laboratory_result) {
            return (
              <div className='custom-actions'>
                <span
                  onClick={() => handleTableAction('view', row)}
                  className='badge'
                  style={{ background: '#00A8FF', cursor: 'pointer' }}
                >
                  View
                </span>
                <span
                  onClick={() => handleTableAction('complete', row)}
                  className='badge'
                  style={{ background: '#02961C', cursor: 'pointer' }}
                >
                  Complete
                </span>
                <span
                  onClick={() => handleTableAction('cancel', row)}
                  className='badge'
                  style={{ background: '#FF0000', cursor: 'pointer' }}
                >
                  Cancel
                </span>
              </div>
            );
          } else {
            return (
              <>
                <span
                  onClick={() => {
                    setShowModal((prevState) => {
                      return {
                        ...prevState,
                        isAddDoctor: true,
                        profileData: row,
                        doctorId: row?.id,
                        labRequestId: row?.id,
                      };
                    });
                  }}
                  className='select-button'
                >
                  Upload
                </span>
                <span
                  onClick={() => handleTableAction('cancel', row)}
                  className='badge'
                  style={{ background: '#FF0000', cursor: 'pointer' }}
                >
                  Cancel
                </span>
              </>
            );
          }
        }}
        pagination={['BOTTOM']}
      />
    </div>
  );
};

export default LabResults;
