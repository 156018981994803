// import core & vendor packages below
import axios from 'axios';
import { useEffect, useState } from 'react';

// Components
import FilterCard from './Card';

// import assets below
import './CardList.scss';
import { ReactComponent as CleaningSVG } from 'assets/svg/cleaning.svg';
import { ReactComponent as BedAddSVG } from 'assets/svg/hospital-bed-add.svg';
import { ReactComponent as HospitalBedSVG } from 'assets/svg/hospital-bed.svg';
import { ReactComponent as ArrowSVG } from 'assets/svg/circle-chevron-right.svg';
import { ReactComponent as Arrow1SVG } from 'assets/svg/circle-chevron-right-1.svg';
import { ReactComponent as Arrow2SVG } from 'assets/svg/circle-chevron-right-2.svg';
import { ReactComponent as Arrow3SVG } from 'assets/svg/circle-chevron-right-3.svg';
import { ReactComponent as Arrow4SVG } from 'assets/svg/circle-chevron-right-4.svg';
import { ReactComponent as BedOccupiedSVG } from 'assets/svg/hospital-bed-occupied.svg';
import { ReactComponent as BedUnavailableSVG } from 'assets/svg/hospital-bed-unavailable.svg';

// main component
const FilterCardList = ({ state, setState }) => {
  const [allRooms, setAllRooms] = useState([]);
  const [occupiedRooms, setOccupiedRooms] = useState([]);
  const [availableRooms, setAvailableRooms] = useState([]);
  const [cleaningRooms, setCleaningRooms] = useState([]);
  const [unavailableRooms, setUnavailableRooms] = useState([]);

  useEffect(() => {
    axios
      .get('/room')
      .then((response) => {
        setAllRooms(response.data.data.all_rooms);
        setOccupiedRooms(response.data.data.occupied_rooms);
        setAvailableRooms(response.data.data.available_rooms);
        setCleaningRooms(response.data.data.for_cleaning_rooms);
        setUnavailableRooms(response.data.data.unavailable_rooms);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  const cardArray = [
    {
      title: 'See all beds',
      value: allRooms,
      icon: <HospitalBedSVG />,
      arrow: <ArrowSVG />,
      color: '#00A8FF',
    },
    {
      title: 'Occupied',
      value: occupiedRooms,
      icon: <BedOccupiedSVG />,
      arrow: <Arrow1SVG />,
      color: '#8CD684',
    },
    {
      title: 'Available',
      value: availableRooms,
      icon: <BedAddSVG />,
      arrow: <Arrow2SVG />,
      color: '#F5B868',
    },
    {
      title: 'For Cleaning',
      value: cleaningRooms,
      icon: <CleaningSVG />,
      arrow: <Arrow3SVG />,
      color: '#00DCFF',
    },
    {
      title: 'Unavailable',
      value: unavailableRooms,
      icon: <BedUnavailableSVG />,
      arrow: <Arrow4SVG />,
      color: '#FF7878',
    },
  ];

  return (
    <div className='filterCardList'>
      {cardArray.map((item, index) => {
        return <FilterCard key={index} object={item} state={state} setState={setState} />;
      })}
    </div>
  );
};

export default FilterCardList;
