const Links = [
  { label: 'Admission', path: '/dashboard/admissions' },
  { label: 'Doctors', path: '/dashboard/doctors' },
  { label: 'Nursing', path: '/dashboard/nursing' },
  { label: 'Billing', path: '/dashboard/billing' },
  { label: 'Pharmacy', path: '/dashboard/pharmacy' },
  { label: 'Dashboard', path: '/dashboard' },
  { label: 'Inventory', path: '/dashboard/inventory' },
  { label: 'Employees', path: '/dashboard/employees/list' },
  { label: 'Philhealth', path: '/dashboard/philhealth' },
  { label: 'Accounting', path: '/dashboard/accounting' },
  { label: 'Laboratory', path: '/dashboard/laboratory' },
  { label: 'Medical Records', path: '/dashboard/medical-records' },
  { label: 'Social Services', path: '/dashboard/social-services' },
  { label: 'Room Bed Management', path: '/dashboard/room-bed-management' },
];

export default Links;
