/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// import core & vendor packages below
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Button, FormControl, InputGroup, Accordion } from 'react-bootstrap';

// import components below
import FormUI from 'components/UI/FormUI';
import { getPatient } from 'reducers/patientReducer';
import { selectPatientData } from 'reducers/patientReducer';
import ConditionalRender from 'components/ConditionalRender';
import { checkStringPermission } from 'helpers/filteredPermissions';

// import assets below
import './Filter.scss';

import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';
// main component
const Filter = ({ setState }) => {
    const dispatch = useDispatch();

    const [patients, setPatients] = useState([]);

    const [word, setWord] = useState('');
    const [id, setId] = useState('');
    const [MRN, setMRN] = useState('');
    const [userName, setUsername] = useState('');

    // Search Patient by name
    const onChangeHandler = (event) => {
        const searchedWord = event.target.value;
        setWord(event.target.value);

        const newFilter = patients.filter((value) => {
            return (
                value.patient_full_name.toLowerCase().includes(searchedWord.toLowerCase())
            );
        });

        if (searchedWord.length > 0) {
            setState(newFilter);
        } else {
            setState(patients);
        }
    };

    // Search User full name
    const filterByUserFullName = (event) => {
        const searchedWord = event.target.value;
        setUsername(event.target.value);
        const newFilter = patients.filter((value) => {
            if (value?.user_full_name?.length > 0) {
                return value?.user_full_name?.toLowerCase()?.includes(searchedWord.toLowerCase());
            }
        });

        if (searchedWord.length > 0) {
            setState(newFilter);
        } else {
            setState(patients);
        }
    };

    // search by patient MRN
    const onChangeHandlerMRN = (event) => {
        const searchedWord = event.target.value;
        setMRN(event.target.value);

        const newFilter = patients.filter((value) => {
            return (
                value.MRN.toLowerCase().includes(searchedWord)
            );
        });

        if (searchedWord.length > 0) {
            setState(newFilter);
        } else {
            setState(patients);
        }
    };

    const resetFilter = () => {
        setState(patients);
        setWord('');
        setId('');
        setMRN('');
        setUsername('');
    };

    const data = useSelector(selectPatientData);

    const [filter, setFilter] = useState({
        MRN: '',
        full_name: '',
        patient_status: '',
        admission_date: '',
    });

    const handleChange = (key) => (e) => {
        setFilter({
            ...filter,
            [key]: e.target.value,
        });
    };

    useEffect(() => {
        dispatch(getPatient());

        axios
            .get('/history_log')
            .then((response) => {
                setPatients(response.data.data);
            }, [])
            .catch((err) => {
                console.log('AXIOS ERROR: ', err);
            });
    }, []);

    const canAdvanceSearch = checkStringPermission(
        'Can Basic Search Patient in Nursing (via Last Name or MRN/PIN)',
    );

    return (
        <div className='Filter'>
            <FormUI>
                {/* Basic Search */}
                <Row>
                    <Col>
                        <Form.Group className='mb-3 mt-3 basic-search'>
                            <Form.Label>Basic Search</Form.Label>
                            <InputGroup>
                                <InputGroup.Text className='bg-grey'>
                                    <MagnifyingGlassSVG />
                                </InputGroup.Text>
                                <FormControl
                                    type='search'
                                    className='bg-grey'
                                    placeholder='Search via Last Name or PIN…'
                                    value={filter.keyword}
                                    onChange={onChangeHandler}
                                />
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>

                <ConditionalRender
                    condition={canAdvanceSearch}
                    renderIf={
                        <Row>
                            <Col>
                                <Accordion className='mb-3 advanced-search'>
                                    <Accordion.Item eventKey='0'>
                                        <Accordion.Header>Advance Search</Accordion.Header>
                                        <Accordion.Body>
                                            <Row>
                                                <Col md={2}>
                                                    <Form.Group className='mb-4'>
                                                        <Form.Label>MRN / PIN</Form.Label>
                                                        <FormControl
                                                            type='text'
                                                            className='bg-white'
                                                            placeholder='Enter Here'
                                                            value={MRN}
                                                            onChange={onChangeHandlerMRN}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group className='mb-4' style={{ width: '100%' }}>
                                                        <Form.Label>User Full Name</Form.Label>
                                                        <FormControl
                                                            type='text'
                                                            className='bg-white'
                                                            placeholder='Enter Patient Name'
                                                            value={userName}
                                                            onChange={filterByUserFullName}
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                {/* <Col md={3}>
                      <Form.Group className='mb-4'>
                        <Form.Label>Date Admitted</Form.Label>
                        <FormControl
                          type='date'
                          className='bg-white'
                          placeholder='Enter Here'
                          value={admissionDate}
                          onChange={(event) => { setAdmissionDate(event.target.value); }}
                        />
                      </Form.Group>
                    </Col> */}
                                            </Row>

                                            <Row>
                                                <div className='buttonWrapper'>
                                                    {/* <Form.Check type={'checkbox'} id={`admitted`} label={`Currently Admitted`} />
                      <Form.Check type={'checkbox'} id={`er-patient`} label={`Currently an ER Patient`} /> */}
                                                    {/*
                      <Button variant='search' onClick={() => search()}>
                        SEARCH
                      </Button> */}

                                                    <Button variant='clear' onClick={() => resetFilter()}>
                                                        CLEAR
                                                    </Button>
                                                </div>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Col>
                        </Row>
                    }
                />
            </FormUI>
        </div>
    );
};

export default Filter;
