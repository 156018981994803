/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';

import { Accordion, Button, Col, Row } from 'react-bootstrap';

import { Link } from 'react-router-dom';

import AccordionUI from 'components/UI/AccordionUI';

import { ReactComponent as CirclePlusSVG } from '../../../assets/svg/circle-plus-white.svg';
import { ReactComponent as EyeSVG } from '../../../assets/svg/eye-icon.svg';

import './ReportUiList.scss';
import { useDispatch, useSelector } from 'react-redux';
import { createReport, selectReportItem } from 'reducers/reportReducer';
import axios from 'axios';
import { toast } from 'react-toastify';
import constant from 'constant';
import moment from 'moment';

const ReportUIList = (props) => {
  const { title, item, data, button, year } = props;

  const dispatch = useDispatch();

  const params = {
    type: constant.STAFFINGPATTERNS,
  };

  const add = async (type) => {
    toast.loading('Please wait...');
    const manualInput = [
      constant.GENERALINFORMATION,
      constant.EXPENSES,
      constant.REVENUES,
      constant.STAFFINGPATTERNS,
    ];

    const params = { year: year, type: type };
    if (!manualInput.includes(type)) {
      dispatch(createReport(params)).then((response) => {
        window.location.href = 'reports/view/' + type + '/' + response.payload.data.id;
      });
    } else {
      switch (type) {
        case constant.STAFFINGPATTERNS:
          await axios
            .post(`${process.env.REACT_APP_API_BASE_ACL}/api/reports`, {
              type: 'staffingPattern',
              year: year,
            })
            .then((response) => {
              toast.success('Success');
              window.location.href = 'reports/view/' + type + '/' + response.data.data.id;
            })
            .catch((error) => {
              toast.danger('SERVER ERROR!');
            });
          break;
        case constant.GENERALINFORMATION:
          await axios
            .post('report/general_information', params)
            .then((response) => {
              toast.success('Success');
              window.location.href = 'reports/view/' + type + '/' + response.data.data.id;
            })
            .catch((error) => {
              toast.danger('SERVER ERROR!');
            });
          break;
        default:
          window.location.href = 'reports/' + type + '/' + year;
          break;
      }
    }
  };

  return (
    <Row className='report-body'>
      <Col>
        <AccordionUI>
          <Accordion defaultActiveKey={item} alwaysOpen>
            <Accordion.Header>{title}</Accordion.Header>
            <Accordion.Body>
              <div className='button-list'>
                <Button
                  className='me-3 btn-light-green'
                  type='button'
                  onClick={() => add(button.type)}
                >
                  <CirclePlusSVG /> {button.name}
                </Button>
              </div>
              {data.map((item, index) => {
                if (
                  item.attributes?.type === 'staffing' ||
                  item.attributes?.type === 'staffingPattern'
                ) {
                  return (
                    <div className='list' key={index}>
                      STAFFING PATTERN REPORT{' '}
                      {moment(item.attributes?.created_at).format('MM-DD-YYYY h:mm A')}
                      <div className='icon'>
                        <Link to={'view/' + 'staffing' + '/' + item.id}>
                          <EyeSVG />
                        </Link>
                      </div>
                    </div>
                  );
                }
                return (
                  <div className='list' key={index}>
                    {item.name} {moment(item.createdAt).format('MM-DD-YYYY h:mm A')}
                    <div className='icon'>
                      <Link to={'view/' + item.type + '/' + item.id}>
                        <EyeSVG />
                      </Link>
                    </div>
                  </div>
                );
              })}
            </Accordion.Body>
          </Accordion>
        </AccordionUI>
      </Col>
    </Row>
  );
};

export default ReportUIList;
