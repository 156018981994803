/* eslint-disable */
// import core & vendor packages below
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';

// import components below
import WorkList from './WorkList';
import TabsUI from 'components/UI/TabsUI';
import SearchPatient from 'components/SearchPatient';
import InventoryPerDepartment from 'components/InventoryPerDepartment';
import Cashier from './Cashier';

// main component
const BillingTabs = ({ onChange }) => {
  const tabs = [
    // {
    //   title: 'Search Patient',
    //   component: SearchPatient,
    // },
    {
      title: 'Billing Worklist',
      component: WorkList,
    },
    {
      title: 'Cashier',
      component: Cashier,
    },
    // {
    //   title: 'Inventory',
    //   component: InventoryPerDepartment,
    // },
  ];

  return (
    <div className='admissionTabs'>
      <TabsUI>
        <Tabs defaultActiveKey={tabs[0].title} onSelect={(eventKey) => onChange(eventKey)}>
          {tabs.map(({ title, component: Component }, idx) => (
            <Tab key={idx} eventKey={title} title={title}>
              <Component departmentID={'9'} />
            </Tab>
          ))}
        </Tabs>
      </TabsUI>
    </div>
  );
};

BillingTabs.defaultProps = {
  onChange: () => {},
};

BillingTabs.propTypes = {
  onChange: PropTypes.func,
};

export default BillingTabs;
