/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

// Packages
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import TagsInput from 'react-tagsinput';
import { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Modal, Form, FormControl } from 'react-bootstrap';

// Components
import FormUI from 'components/UI/FormUI';
import { getrefillGroups, selectGroups } from 'reducers/refillRequest';
import { selectInventory, updateItem, resetUpdateState } from 'reducers/inventoryReducer';

// Assets
import './style.scss';
import 'react-tagsinput/react-tagsinput.css';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import ConditionalRender from 'components/ConditionalRender';

// main component
const EditItem = ({ showProfile, setShowProfile, getInventoryItems }) => {
  if (!showProfile?.rowData) return;

  const dispatch = useDispatch();
  const groupState = useSelector(selectGroups);
  const inventoryState = useSelector(selectInventory);
  const { updateItemLogs, updateItemData } = inventoryState;

  const [userData, setUserData] = useState({});

  const closeModal = () => {
    setShowProfile((prevState) => {
      return {
        ...prevState,
        editItem: false,
        showView: true,
      };
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUserData((prev) => ({ ...prev, [name]: value }));
  };

  const handleGroupChange = (e) => {
    setUserData((prev) => {
      return {
        ...prev,
        group: { label: e.value, value: e.value },
      };
    });

    return;
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    const params = {
      id: userData?.id,
      payload: {
        code: userData?.code,
        name: userData?.name,
        tags: userData?.tags,
        section: userData?.section,
        group: userData?.group?.value,
        max_stock: userData?.max_stock,
        min_stock: userData?.min_stock,
        description: userData?.description,
        storage_bin: userData?.storage_bin,
        is_medicine: userData?.is_medicine,
        generic_name: userData?.generic_name,
        manufacturer: userData?.manufacturer,
        supplier_name: userData?.supplier_name,
        supplier_code: userData?.supplier_code,
        re_order_point: userData?.re_order_point,
        is_locked: userData?.is_locked === 'true',
        unit_of_measure: userData?.unit_of_measure,
        acquisition_price: userData?.acquisition_price,
        average_consumption: userData?.average_consumption,
        estimated_time_arrival: userData?.estimated_time_arrival,
      },
    };

    dispatch(updateItem(params));
  };

  runOnFirstRender(dispatch);

  getDataFromRowData(setUserData, showProfile, groupState);

  runAfterSubmit(
    dispatch,
    showProfile,
    updateItemLogs,
    getInventoryItems,
    setShowProfile,
    updateItemData,
  );

  return (
    <Modal
      size='lg'
      onHide={closeModal}
      className='edit-item-modal'
      show={showProfile.editItem}
      aria-labelledby='ITEM DETAILS'
    >
      <Modal.Body>
        <div className='edit-item-request'>
          <div className='d-flex header'>
            <p>UPDATE ITEM</p>

            <CircleXMark style={{ width: '2em', cursor: 'pointer' }} onClick={closeModal} />
          </div>
        </div>

        <FormUI>
          <Form className='edit-item-form' onSubmit={handleUpdate}>
            <div className='item-header mb-4'>
              <h3>ITEM INFORMATION</h3>

              <div className='item-type'>
                <Form.Select
                  required
                  name='is_medicine'
                  onChange={handleChange}
                  value={userData?.is_medicine}
                >
                  <option value='' hidden>
                    Type
                  </option>
                  <option value='1'>Medicine</option>
                  <option value='0'>Supply</option>
                </Form.Select>
              </div>
            </div>

            <Row className='mb-3'>
              <Col>
                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Acquisition Price :</Form.Label>
                  <FormControl
                    required
                    type='number'
                    onChange={handleChange}
                    name='acquisition_price'
                    value={userData?.acquisition_price}
                  />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Item Code :</Form.Label>
                  <FormControl
                    required
                    name='code'
                    value={userData?.code}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Item Name :</Form.Label>
                  <FormControl
                    required
                    name='text'
                    value={userData?.name}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Generic Name :</Form.Label>
                  <FormControl
                    required
                    name='generic_name'
                    onChange={handleChange}
                    value={userData?.generic_name}
                  />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Manufacturer :</Form.Label>
                  <FormControl
                    required
                    name='manufacturer'
                    onChange={handleChange}
                    value={userData?.manufacturer}
                  />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Storage Bin :</Form.Label>
                  <FormControl
                    required
                    name='storage_bin'
                    onChange={handleChange}
                    value={userData?.storage_bin}
                  />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Average Consumption :</Form.Label>
                  <FormControl
                    required
                    onChange={handleChange}
                    name='average_consumption'
                    value={userData?.average_consumption}
                  />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Unit of Measure :</Form.Label>
                  <Form.Select
                    required
                    name='unit_of_measure'
                    onChange={handleChange}
                    value={userData?.unit_of_measure}
                  >
                    <option value='' hidden>
                      Select Here
                    </option>
                    <option value='piece'>Piece</option>
                    <option value='box'>Box</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Is Locked :</Form.Label>
                  <Form.Select
                    required
                    name='is_locked'
                    onChange={handleChange}
                    value={userData?.is_locked}
                  >
                    <option value='' hidden>
                      Select Here
                    </option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Supplier Code :</Form.Label>
                  <FormControl
                    required
                    name='supplier_code'
                    onChange={handleChange}
                    value={userData?.supplier_code}
                  />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Supplier Name :</Form.Label>
                  <FormControl
                    name='supplier_name'
                    onChange={handleChange}
                    value={userData?.supplier_name}
                  />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Section :</Form.Label>
                  <FormControl name='section' onChange={handleChange} value={userData?.section} />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Item Group :</Form.Label>
                  <CreatableSelect
                    styles={customStyles}
                    value={userData?.group || {}}
                    className='select-item-group bg-white'
                    onChange={handleGroupChange}
                    options={groupState.map((data) => {
                      return {
                        id: data?.id,
                        label: data?.attributes?.name,
                        value: data?.attributes?.name,
                      };
                    })}
                  />
                </Form.Group>

                <ConditionalRender
                  condition={userData?.tags && userData?.tags.length > 0}
                  renderIf={
                    <Form.Group className='mb-3 inline-group'>
                      <Form.Label>Tags :</Form.Label>
                      <TagsInput
                        value={userData?.tags || []}
                        onChange={(value) => {
                          setUserData((prev) => {
                            return {
                              ...prev,
                              tags: value,
                            };
                          });
                        }}
                      />
                    </Form.Group>
                  }
                />

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Min Stock :</Form.Label>
                  <FormControl
                    type='number'
                    name='min_stock'
                    onChange={handleChange}
                    value={userData?.min_stock}
                    onKeyDown={(evt) =>
                      (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '.') &&
                      evt.preventDefault()
                    }
                  />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Max Stock :</Form.Label>
                  <FormControl
                    type='number'
                    name='max_stock'
                    onChange={handleChange}
                    value={userData?.max_stock}
                    onKeyDown={(evt) =>
                      (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '.') &&
                      evt.preventDefault()
                    }
                  />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Estimated Time Arrival :</Form.Label>
                  <FormControl
                    name='estimated_time_arrival'
                    onChange={handleChange}
                    value={userData?.estimated_time_arrival}
                  />
                </Form.Group>

                <Form.Group className='mb-3 inline-group'>
                  <Form.Label>Re Order Point :</Form.Label>
                  <FormControl
                    name='re_order_point'
                    onChange={handleChange}
                    value={userData?.re_order_point}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mb-3'>
                  <Form.Label>Item Description :</Form.Label>
                  <FormControl
                    rows={5}
                    required
                    as='textarea'
                    name='description'
                    onChange={handleChange}
                    value={userData?.description}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-3 buttons-cont'>
                  <Button
                    type='submit'
                    variant='primary'
                    className='add-button'
                    disabled={updateItemLogs?.isLoading === true}
                  >
                    Update
                  </Button>
                  <Button className='cancel-button' onClick={closeModal}>
                    Cancel
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

const runOnFirstRender = (dispatch) => {
  useEffect(() => {
    dispatch(getrefillGroups());
  }, []);
};

const getDataFromRowData = (setUserData, showProfile, groupState) => {
  useEffect(() => {
    if (showProfile.rowData && showProfile.editItem && groupState) {
      const group = groupState.find((dd) => +dd.id === +showProfile?.rowData?.attributes?.group_id);

      setUserData({
        ...showProfile.rowData.attributes,
        id: showProfile?.rowData?.id,
        group: { id: group?.id, label: group?.attributes?.name, value: group?.attributes?.name },
      });
    }
  }, [showProfile, groupState]);
};

const runAfterSubmit = (
  dispatch,
  showProfile,
  updateItemLogs,
  getInventoryItems,
  setShowProfile,
  updateItemData,
) => {
  useEffect(() => {
    const { isSuccess, isFailed, error } = updateItemLogs;

    if (isSuccess && showProfile?.editItem) {
      toast.success('Successfully updated!');

      setTimeout(() => {
        setShowProfile((prev) => {
          return {
            ...prev,
            editItem: false,
            profileId: null,
            rowData: [],
          };
        });
      }, 200);

      dispatch(resetUpdateState());
      getInventoryItems();
    }

    if (isFailed) {
      toast.error(error);
      dispatch(resetUpdateState());
    }
  }, [updateItemLogs]);
};

const customStyles = {
  control: () => ({
    height: '50px',
    display: 'flex',
    borderRadius: '10px',
    border: '1px solid #c8c8c8',
  }),
};

EditItem.defaultProps = {
  showProfile: {},
  setShowProfile: () => {},
};

EditItem.propTypes = {
  showProfile: PropTypes.object,
  setShowProfile: PropTypes.func,
};

export default EditItem;
