/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
// import core & vendor packages below
import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Row, Col, Table, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

// import components below
import OrdersTable from './OrdersTable';
import SuppliesTable from './SuppliesTable';
import TableUI from 'components/UI/TableUI';
import DeleteOrderModal from './Modals/DeleteModal';
import ViewOrderModal from './Modals/ViewItemModal';
import { showPatient } from 'reducers/patientReducer';
import CreditMemorandum from './Modals/CreditMemorandum';
import ConfirmModal from 'components/Modals/ConfirmModal';
import { selectPatientItem } from 'reducers/patientReducer';

// import assets below
import './OrdersTab.scss';

// main component
const OrdersTab = ({ showProfile, setShowProfile }) => {
  const patientProfile = useSelector(selectPatientItem);

  const [showModal, setShowModal] = useState({
    cancelLab: false,
    credit_memorandum: false,
    rowData: null,
  });

  const dispatch = useDispatch();

  const handleTableAction = (eventKey, row, type) => {
    // const row = state.find((dd) => dd.id === id);
    const { id } = row;

    const action = {
      cancelProcedure: () => {
        const params = {
          id: id,
          status: 'CANCELLED',
          patientId: showProfile.profileId,
        };
        axios
          .put(`/requisition/procedure`, params)
          .then((response) => {
            dispatch(showPatient(showProfile.profileId));
            toast.warning('Successfully cancelled!');
          })
          .catch((error) => {
            console.log(error);
            toast.error('ERROR FETCHING LABORATORY RESULTS');
          });
      },
      cancelLab: () => {
        setShowModal((prev) => {
          return {
            ...prev,
            id: id,
            cancelLab: true,
            fields: [
              {
                label: 'reason',
                type: 'text',
              },
            ],
          };
        });
      },
      credit_memorandum: () => {
        setShowModal((prev) => {
          return {
            ...prev,
            id: id,
            rowData: row,
            credit_memorandum: true,
            type: type,
          };
        });
      },
    };

    action[eventKey]();
  };

  const closeModal = (type) => {
    setShowModal((prev) => {
      return { ...prev, [type]: false, fields: [], id: null };
    });
  };

  const confirmModal = (data, type) => {
    closeModal(type);

    if (type === 'cancelLab') {
      const params = {
        id: showModal?.id,
        status: 'CANCELLED',
        patientId: showProfile.profileId,
        reason: data?.reason,
      };
      axios
        .put(`/requisition/laboratory`, params)
        .then((response) => {
          dispatch(showPatient(showProfile.profileId));
          toast.warning('Successfully cancelled!');
        })
        .catch((error) => {
          console.log(error);
          toast.error('ERROR FETCHING LABORATORY RESULTS');
        });
    }
  };

  return (
    <div className='ordersTab'>
      <OrdersTable showProfile={showProfile} setShowModal={setShowModal} />

      <div className='patientProfile__header'>
        <h4>Medications</h4>
        <Row>
          <Col>
            <TableUI>
              <Table className='medicationsTable'>
                <thead>
                  <tr>
                    <th>Medication Description</th>
                    <th>Doze</th>
                    <th>Route of Administration</th>
                    <th>Times of Administration</th>
                    <th>Unit of measure</th>
                    <th>Unit price</th>
                    <th>QTY</th>
                    <th>Total Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {patientProfile?.medications &&
                    patientProfile?.medications.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.medication}</td>
                          <td>{item.doze}</td>
                          <td>{item.route_of_administration}</td>
                          <td>{item.times_of_administration}</td>
                          <td>{item.uom}</td>
                          <td>{item.unit_price}</td>
                          <td>{item.quantity}</td>
                          <td>{item.total_amount}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </TableUI>
          </Col>
        </Row>
      </div>
      {/* <div className='patientProfile__header'>
        <h4>Supplies</h4>
        <Row>
          <Col>
            <TableUI>
              <Table className='medicationsTable'>
                <thead>
                  <tr>
                    <th>Supply Description</th>
                    <th>Quantity</th>
                    <th>Priority</th>
                    <th>Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {patientProfile?.supplies &&
                    patientProfile?.supplies.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.supply}</td>
                          <td>{item.quantity}</td>
                          <td>{item.priority}</td>
                          <td>{item.notes}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </TableUI>
          </Col>
        </Row>
      </div> */}
      
      <SuppliesTable showProfile={showProfile} setShowModal={setShowModal} />

      <div className='patientProfile__header'>
        <h4>Procedures</h4>
        <Row>
          <Col>
            <TableUI>
              <Table className='medicationsTable'>
                <thead>
                  <tr>
                    <th>Surgery Code</th>
                    <th>Description</th>
                    <th>Remarks</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {patientProfile?.request_procedures &&
                    patientProfile?.request_procedures.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.ctp_code}</td>
                          <td>{item.procedure}</td>
                          <td>{item.remarks}</td>
                          <td>{item.status}</td>
                          <td>
                            {item?.status === 'CANCELLED' && (
                              <span
                                onClick={() =>
                                  handleTableAction('credit_memorandum', item, 'procedure')
                                }
                                className='badge'
                                style={{ background: '#06b10c', cursor: 'pointer' }}
                              >
                                Create Memorandum
                              </span>
                            )}
                            {item?.status === 'PENDING' && (
                              <span
                                onClick={() => handleTableAction('cancelProcedure', item, 'n/a')}
                                className='badge'
                                style={{ background: '#FF0000', cursor: 'pointer' }}
                              >
                                Cancel
                              </span>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </TableUI>
          </Col>
        </Row>
      </div>
      <div className='patientProfile__header'>
        <h4>Tests</h4>
        <Row>
          <Col>
            <TableUI>
              <Table className='medicationsTable'>
                <thead>
                  <tr>
                    <th>Internal/External?</th>
                    <th>Lab Type</th>
                    <th>Ordering Physician</th>
                    <th>Type of Specimen</th>
                    <th>Notes</th>
                    <th>Result</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {patientProfile?.laboratory_requests &&
                    patientProfile?.laboratory_requests.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.lab_test}</td>
                          <td>{item.lab_type}</td>
                          <td>
                            Dr. {item?.user?.first_name} {item?.user?.last_name}
                          </td>
                          <td>{item.specimen_type}</td>
                          <td>{item.notes}</td>
                          <td>
                            {' '}
                            {item?.laboratory_result?.filename ? (
                              <span className='value'>
                                <a
                                  href={`${process.env.REACT_APP_API_BASE_URL}/files/${item?.laboratory_result?.filename}`}
                                  target='_blank'
                                >
                                  View Result
                                </a>
                              </span>
                            ) : (
                              <span className='value' style={{ color: 'red' }}>
                                No file uploaded
                              </span>
                            )}
                          </td>
                          <td>{item.status}</td>
                          <td>
                            {item?.status === 'PENDING' && (
                              <span
                                onClick={() => handleTableAction('cancelLab', item, 'n/a')}
                                className='badge'
                                style={{ background: '#FF0000', cursor: 'pointer' }}
                              >
                                Cancel
                              </span>
                            )}
                            {item?.status === 'CANCELLED' && (
                              <span
                                onClick={() =>
                                  handleTableAction('credit_memorandum', item, 'laboratory')
                                }
                                className='badge'
                                style={{ background: '#00A8FF', cursor: 'pointer' }}
                              >
                                Credit Memorandum
                              </span>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </TableUI>
          </Col>
        </Row>
      </div>

      {/* Modals */}
      <ConfirmModal
        type={'cancelLab'}
        confirm={confirmModal}
        closeModal={closeModal}
        fields={showModal?.fields}
        openModal={showModal?.cancelLab}
      />

      <ViewOrderModal showModal={showModal} setShowModal={setShowModal} />
      <DeleteOrderModal
        showModal={showModal}
        showProfile={showProfile}
        setShowModal={setShowModal}
      />
      <CreditMemorandum showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default OrdersTab;
