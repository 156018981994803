/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

// Packages
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Row, Col, Button, Modal, Form, FormControl, InputGroup } from 'react-bootstrap';

// Components
import FormUI from 'components/UI/FormUI';

// Assets
import './style.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

// Main component
const AdjustmentModal = ({ showProfile, setShowProfile }) => {
  return (
    <Modal
      size='lg'
      show={showProfile.showAdjustmentModal}
      onHide={() =>
        setShowProfile((prevState) => {
          return {
            ...prevState,
            showAdjustmentModal: false,
          };
        })
      }
      aria-labelledby='CONFIRMATION'
      className='adjustment-modal'
    >
      <Modal.Body>
        <div className='adjustment-request'>
          <div className='d-flex header'>
            <p>CONFIRMATION</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => {
                setShowProfile((prevState) => {
                  return { ...prevState, showAdjustmentModal: false };
                });
              }}
            />
          </div>
        </div>

        <FormUI>
          <Form className='adjustment-form'>
            <Row>
              <Col>
                <div className='receiving-cont mt-4'>
                  <p>Are you sure you want to {showProfile.forApprove ? 'approve' : 'disapprove'} this item?</p>

                  <h6>
                    You must enter username and password to confirm for approval of this item.
                    <span>NOTE: Once {showProfile.forApprove ? 'approve' : 'disapprove'}, this item will {showProfile.forApprove ? '' : 'not'} be added to the system.</span>
                  </h6>

                  <Row className='w-100 mt-4'>
                    <Col sm={12}>
                      <Form.Group className='mb-4'>
                        <Form.Label>Username</Form.Label>
                        <FormControl type='text' className='bg-white' placeholder='Username Here' />
                      </Form.Group>
                    </Col>
                    <Col sm={12}>
                      <Form.Group className='mb-4'>
                        <Form.Label>Password</Form.Label>
                        <FormControl
                          type='password'
                          className='bg-white'
                          placeholder='Password Here'
                        />
                      </Form.Group>
                    </Col>
                    {!showProfile.forApprove && (
                      <Col sm={12}>
                        <Form.Group className='mb-4'>
                          <Form.Label>Reason to disapproved</Form.Label>
                          <FormControl
                            as='textarea'
                            className='bg-white'
                            placeholder='Enter Here'
                          />
                        </Form.Group>
                      </Col>
                    )}
                  </Row>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-2 buttons-cont'>
                  <Button
                    className={`${showProfile.forApprove ? 'green' : 'pure-red'}`}
                    variant='primary'
                    onClick={() => {
                      setShowProfile((prevState) => {
                        return { ...prevState, showAdjustmentModal: false };
                      });
                    }}
                  >
                    {showProfile.forApprove ? 'Approved' : 'Disapproved'}
                  </Button>
                  <Button
                    className='grey'
                    onClick={() => {
                      setShowProfile((prevState) => {
                        return { ...prevState, showAdjustmentModal: false };
                      });
                    }}
                  >
                    CANCEL
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

AdjustmentModal.defaultProps = {
  showProfile: {},
  setShowProfile: () => {},
};

AdjustmentModal.propTypes = {
  showProfile: PropTypes.object,
  setShowProfile: PropTypes.func,
};

export default AdjustmentModal;
