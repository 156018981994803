/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { toast } from 'react-toastify';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row, Col, Form, FormControl } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';

import PrintContent from './PrintComponents/HistoryAndPhysicalExamination';
import PatientSelection from './PatientSelection';
import ConditionalRender from 'components/ConditionalRender';
import useCreateRecordResponse from './hooks/useCreateRecordResponse';
import { getPatients, selectPatientData } from 'reducers/patientReducer';
import {
  getMedicalFormsbyID,
  selectActivePatients,
  createHistoryandPhysicalExamRecord,
} from 'reducers/medicalRecordReducer';
import './HistoryAndPhysicalExamination.scss';

const HistoryAndPhysicalExamination = ({ setShowScreen, viewForm, setIsEdit, isEdit }) => {
  const dispatch = useDispatch();

  const logs = useSelector(selectActivePatients);
  const patientSelection = useSelector(selectPatientData);

  const [isAdd, setIsAdd] = useState(false);
  const [defaultData, setDefaultData] = useState();
  const [validated, setValidated] = useState(false);
  const [showPatientSelection, setShowPatientSelection] = useState(false);
  const [trigger, setTrigger] = useState({ trigger: false, patientID: false, recordID: false });
  const [formData, setFormData] = useState({
    hrn: '',
    age: '',
    lname: '',
    mname: '',
    gender: '',
    address: '',
    history: '',
    location: '',
    patientId: '',
    given_name: '',
    chief_complaint: '',
    present_illness: '',
    name_of_hospital: '',
    admitting_impression: '',
    attending_physician: 'I',
    type: 'history_and_physical_examination',
  });

  const isEditing = isEdit === true;
  const isAdding = isAdd === true;
  const isNotEditing = isEdit === false;

  // For Add
  const addRecord = () => {
    return;
  };

  // For Edit
  const saveChanges = async () => {
    setTrigger({ ...trigger, trigger: true, recordID: true });

    const res = await dispatch(
      createHistoryandPhysicalExamRecord({
        ...formData,
        method: 'put',
        type: 'history_and_physical_examination',
      }),
    );
    // setViewForm({ ...viewForm, data: formData });

    if (res.payload.status === 'Success') {
      setIsEdit(false);
      setDefaultData(formData);

      return;
    }

    toast.error('Record is not Created/Updated');
  };

  const handleChange = (key) => (e) => {
    setFormData({
      ...formData,
      [key]: e.target.value,
    });

    return;
  };
  const textBox = (key) => {
    return (
      <FormControl
        type={`${key === 'age' ? 'number' : 'text'}`}
        disabled={!isEdit}
        style={{ width: `${isEdit ? '' : `${formData[key]?.length}ch`}` }}
        className={`${isEdit ? 'editable' : 'non-editable'} ${key === 'age' ? 'age' : ''}`}
        value={formData[key]}
        onChange={handleChange(key)}
      />
    );
  };
  // For Add

  const handleSubmit = (event) => {
    const form = event.currentTarget;

    event.preventDefault();
    event.stopPropagation();

    if (!form.checkValidity() === false) {
      setTrigger({ ...trigger, trigger: true });
      setIsEdit(false);
      dispatch(createHistoryandPhysicalExamRecord({ ...formData, method: 'post' }));
      setIsAdd(false);
      return;
    }

    toast.warning('Fill up the required fields');
    setValidated(true);
  };

  const handleShowPatientSelection = () => {
    setShowPatientSelection(true);
    return;
  };

  const handleClosePatientSelection = () => {
    setShowPatientSelection(false);
    return;
  };

  useEffect(() => {
    if (viewForm?.patientID) {
      setTrigger({ ...trigger, patientID: true });
      const patientid = viewForm?.patientID;
      dispatch(getMedicalFormsbyID(patientid));
    }
  }, []);

  useEffect(() => {
    dispatch(getPatients());

    if (Object.keys(viewForm.data).length === 0) {
      setIsAdd(true);
      setIsEdit(true);
      return;
    }
    // for refactor: externalize below axios code in to thunk
    const recordId = viewForm?.data?.id;
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/medical_records/form/${recordId}`)
      .then((response) => {
        setFormData({
          ...response.data?.data?.mr_outpatient_record,
          patientId: response.data?.data?.mr_history_and_physical_examination?.id,
          type: 'history_and_physical_examination',
        });

        setDefaultData({
          ...response.data?.data?.mr_outpatient_record,
          patientId: response.data?.data?.mr_history_and_physical_examination?.id,
          type: 'history_and_physical_examination',
        });
      })
      .catch((error) => console.log('error fetching record info: ', error));
  }, []);

  useEffect(() => {
    const {
      isFailed,
      isSuccess,
      patientID,
      medicalRecordID,
      isFetchViewSuccess,
      isFetchRecordSuccess,
    } = logs;

    useCreateRecordResponse({
      trigger,
      isFailed,
      dispatch,
      isSuccess,
      patientID,
      setIsEdit,
      setTrigger,
      setShowScreen,
      medicalRecordID,
      isFetchViewSuccess,
      isFetchRecordSuccess,
    });

    /* if (isFetchRecordSuccess && trigger.recordID) {
         const data = medicalRecordID.mr_history_and_physical_exam;
         setFormData(data);
       } */
  }, [logs]);

  const printRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onBeforePrint: () => console.log('before printing'),
  });
  return (
    <>
      <div style={{ display: 'none' }}>
        <PrintContent ref={printRef} data={formData} viewFormType={viewForm?.type} />
      </div>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        className='history-and-physical-examination'
      >
        <Row className='mb-5'>
          <Col className='first-row'>
            <Form.Group className='input-group'>
              <FormControl
                disabled={!isEdit}
                className='text-center'
                placeholder='Enter Here'
                value={formData?.name_of_hospital || ''}
                onChange={handleChange('name_of_hospital')}
              />
              <Form.Label>Name of Hospital</Form.Label>
            </Form.Group>

            <Form.Group className='input-group'>
              <FormControl
                disabled={!isEdit}
                className='text-center'
                placeholder='Enter Here'
                value={formData?.address || ''}
                onChange={handleChange('address')}
              />
              <Form.Label>Address</Form.Label>
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-2'>
          <Col>
            <Form.Group className='input-group'>
              <Form.Label>Last Name:</Form.Label>
              <FormControl
                required
                disabled={!isEdit}
                placeholder='Enter Here'
                value={formData?.lname || ''}
                // onChange={handleChange('lname')}
                onClick={handleShowPatientSelection}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='input-group'>
              <Form.Label>Given Name:</Form.Label>
              <FormControl
                required
                disabled={!isEdit}
                placeholder='Enter Here'
                value={formData?.given_name || ''}
                onClick={handleShowPatientSelection}
                // onChange={handleChange('given_name')}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='input-group'>
              <Form.Label>Middle Name:</Form.Label>
              <FormControl
                required
                disabled={!isEdit}
                placeholder='Enter Here'
                value={formData?.mname || ''}
                // onChange={handleChange('mname')}
                onClick={handleShowPatientSelection}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='input-group'>
              <Form.Label>HRN:</Form.Label>
              <FormControl
                disabled={!isEdit}
                placeholder='Enter Here'
                value={formData?.hrn || ''}
                onChange={handleChange('hrn')}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-5'>
          <Col>
            <Form.Group className='input-group'>
              <Form.Label>Age</Form.Label>
              <FormControl
                type='number'
                disabled={!isEdit}
                placeholder='Enter Here'
                value={formData?.age || ''}
                onChange={handleChange('age')}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='input-group'>
              <Form.Label>Gender</Form.Label>

              <Form.Select
                defaultValue=''
                disabled={!isEdit}
                className='gender'
                value={formData?.gender || ''}
                onChange={handleChange('gender')}
              >
                <option value='' hidden>
                  - Select -
                </option>
                <option value='male'>Male</option>
                <option value='female'>Female</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='input-group'>
              <Form.Label>WARD / ROOM / BED</Form.Label>
              <FormControl
                disabled={!isEdit}
                placeholder='Enter Here'
                value={formData?.location || ''}
                onChange={handleChange('location')}
              />
            </Form.Group>
          </Col>
          <Col></Col>
        </Row>

        <Row className='mb-4'>
          <Col className='third-row'>
            <h2>{viewForm?.type}</h2>
          </Col>
        </Row>

        <Row className='mb-5'>
          <Col className='main-box-container p-0'>
            <div className='box-cont'>
              <Row>
                <Col>
                  <Form.Group className='mb-4 input-group inline-label-textarea'>
                    <Form.Label>CHIEF COMPLAINT</Form.Label>
                    <FormControl
                      rows={5}
                      as='textarea'
                      disabled={!isEdit}
                      placeholder='Enter Here'
                      value={formData?.chief_complaint || ''}
                      onChange={handleChange('chief_complaint')}
                    />
                  </Form.Group>

                  <Form.Group className='mb-4 input-group inline-label-textarea'>
                    <Form.Label>PRESENT ILLNESS</Form.Label>
                    <FormControl
                      rows={5}
                      as='textarea'
                      disabled={!isEdit}
                      placeholder='Enter Here'
                      value={formData?.present_illness || ''}
                      onChange={handleChange('present_illness')}
                    />
                  </Form.Group>

                  <Form.Group className='mb-4 input-group inline-label-textarea'>
                    <Form.Label>HISTORY</Form.Label>
                    <FormControl
                      rows={5}
                      as='textarea'
                      disabled={!isEdit}
                      placeholder='Enter Here'
                      value={formData?.history || ''}
                      onChange={handleChange('history')}
                    />
                  </Form.Group>

                  <Form.Group className='input-group inline-label-textarea'>
                    <Form.Label>ADMITTING IMPRESSION</Form.Label>
                    <FormControl
                      rows={5}
                      as='textarea'
                      disabled={!isEdit}
                      placeholder='Enter Here'
                      value={formData?.admitting_impression || ''}
                      onChange={handleChange('admitting_impression')}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <Row className='mb-5'>
          <Col>
            <Form.Group className='inline-label input-group'>
              <Form.Label>ATTENDING PHYSICIAN</Form.Label>

              <div className='signature'>
                <FormControl
                  disabled={!isEdit}
                  placeholder='Enter Here'
                  value={formData?.attending_physician || ''}
                  onChange={handleChange('attending_physician')}
                />
                <p className='over-printed-name'>Signature Over Printed Name</p>
              </div>
            </Form.Group>
          </Col>
        </Row>

        <hr />

        <Row className=''>
          <Col className='form-buttons'>
            {/* Buttons to show for Add */}
            <ConditionalRender
              condition={isAdding}
              renderIf={
                <>
                  <Button type='submit' className='save' disabled={trigger.trigger}>
                    CREATE RECORD
                  </Button>
                  <Button
                    onClick={() => {
                      setIsEdit(false);
                      setShowScreen(0);
                    }}
                    className='cancel'
                  >
                    CANCEL
                  </Button>
                </>
              }
            />

            {/* Buttons to show for Edit/View */}
            <ConditionalRender
              condition={!isAdding}
              renderIf={
                <>
                  <ConditionalRender
                    condition={isNotEditing}
                    renderIf={
                      <>
                        <Button className='print' onClick={handlePrint}>
                          PRINT CERTIFICATE
                        </Button>
                        <Button
                          onClick={() => {
                            setIsEdit(true);
                          }}
                          className='edit'
                        >
                          EDIT CERTIFICATE
                        </Button>
                      </>
                    }
                  />

                  <ConditionalRender
                    condition={isEditing}
                    renderIf={
                      <>
                        <Button
                          onClick={() => {
                            saveChanges();
                          }}
                          className='save'
                          disabled={trigger.trigger}
                        >
                          SAVE CHANGES
                        </Button>
                        <Button
                          onClick={() => {
                            setIsEdit(false);
                            setFormData(defaultData);
                          }}
                          className='cancel'
                        >
                          CANCEL
                        </Button>
                      </>
                    }
                  />
                </>
              }
            />
          </Col>
        </Row>
      </Form>

      <PatientSelection
        fields={formData}
        setFields={setFormData}
        patientSelection={patientSelection}
        showPatientSelection={showPatientSelection}
        handleClosePatientSelection={handleClosePatientSelection}
      />
    </>
  );
};

export default HistoryAndPhysicalExamination;
