import { useState } from 'react';
// Components below
import FilterCardList from './components/CardList';
import BedManagementTable from './components/Table';
import BedManagementFilter from './components/Filter';

// Main component
const BedManagement = () => {
  const [state, setState] = useState([]);

  return (
    <div className='bedManagement'>
      <FilterCardList state={state} setState={setState} />

      <BedManagementFilter state={state} setState={setState} />

      <div className='d-flex justify-content-between align-items-center'>
        <h4 className='mb-0 fw-bold'>Room and Beds List</h4>
      </div>

      <BedManagementTable state={state} setState={setState} />
    </div>
  );
};

export default BedManagement;
