/* eslint-disable */
// import core & vendor packages below
import { useState } from 'react';
import PropTypes from 'prop-types';
// import { Tabs, Tab } from 'react-bootstrap';

// import TabsUI from 'components/UI/TabsUI';
import Tabs from 'components/Tabs';
import SearchPatient from './PatientWorklist';
import EligibilityCheck from './EligibilityCheck';
import Reports from './Reports';

import './Tabs.scss';

// main component
const PhilhealthTabs = ({ onChange }) => {
    const [activeTab, setActiveTab] = useState('Patient Worklist');
    const [tabState, setTabState] = useState();

    const handleTabOnChange = (eventKey) => {
        setActiveTab(eventKey);
        onChange(eventKey);
    };

    const options = [
        {
            title: 'Patient Worklist',
            eventKey: 'Patient Worklist',
            // eventKey: 'store-inventory',
            className: 'p-4',
            component: SearchPatient,
        },
        {
            title: 'Eligibility Check',
            eventKey: 'Eligibility Check',
            // eventKey: 'store-inventory',
            className: 'p-4',
            component: EligibilityCheck,
        },
        {
            title: 'Reports',
            eventKey: 'Reports',
            // eventKey: 'store-inventory',
            className: 'p-4',
            component: Reports,
        },
    ];

    const tabGroupName = 'PhilhealthTabs';
    const tabsProps = {
        activeKey: activeTab,
        defaultActiveKey: options[0].eventKey,
        onSelect: handleTabOnChange,
        tabKey: tabGroupName,
        options,
    };

    return (
        <div className={tabGroupName}>
            <Tabs {...tabsProps} />
        </div>
    );
};

PhilhealthTabs.defaultProps = {
    onChange: () => { },
};

PhilhealthTabs.propTypes = {
    onChange: PropTypes.func,
};

export default PhilhealthTabs;
