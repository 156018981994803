// import core & vendor packages below
import { Container, Row } from 'react-bootstrap';

// main components below
import Layout from 'components/Layout';
import CreateInpatientVisitHeader from './PageHeader';
import FormCreateInpatientVisit from './FormCreateInpatientVisit';

// main component
const CreateInpatientVisit = () => {
  return (
    <Layout pageTitle='Admissions'>
      <CreateInpatientVisitHeader />
      <Container fluid>
        <Row>
          <FormCreateInpatientVisit />
        </Row>
      </Container>
    </Layout>
  );
};

export default CreateInpatientVisit;
