// import core & vendor packages below
import { Container, Row } from 'react-bootstrap';

// main components below
import Layout from 'components/Layout';
import ViewPatientProfileHeader from './PageHeader';
import FormPatientProfile from 'components/FormPatientProfile';

// main component
const ViewPatientProfile = () => {
  return (
    <Layout pageTitle='Admissions'>
      <ViewPatientProfileHeader />
      <Container fluid>
        <Row>
          <FormPatientProfile />
        </Row>
      </Container>
    </Layout>
  );
};

export default ViewPatientProfile;
