/* eslint-disable no-unused-vars */
/* eslint-disable multiline-comment-style */
// import components below
import DashboardCard from './Card';

// import assets below
import './CardList.scss';

import { ReactComponent as UnionSVG } from 'assets/svg/union.svg';
import { ReactComponent as DoctorSVG } from 'assets/svg/doctor.svg';
import { ReactComponent as NurseSVG } from 'assets/svg/nurse.svg';
import { ReactComponent as RoomBedManagementSVG } from 'assets/svg/room_reservation.svg';
import { ReactComponent as EmergencyDepartmentSVG } from 'assets/svg/ambulance.svg';
import { ReactComponent as OutpatientDepartmentSVG } from 'assets/svg/outpatient_clinic.svg';
import { ReactComponent as LaboratorySVG } from 'assets/svg/laboratory.svg';
import { ReactComponent as PharmacySVG } from 'assets/svg/pharmacy.svg';
import { ReactComponent as InventorySVG } from 'assets/svg/inventory.svg';
import { ReactComponent as BillingSVG } from 'assets/svg/bill.svg';
import { ReactComponent as PhilhealthSVG } from 'assets/svg/driving_license.svg';
import { ReactComponent as SocialServicesSVG } from 'assets/svg/healthcare.svg';
// import { ReactComponent as ReportSVG } from 'assets/svg/report-icon.svg';
import { ReactComponent as ReportSVG } from 'assets/svg/bill.svg';
import { ReactComponent as ACLSvg } from 'assets/svg/ACL Icon.svg';
import { ReactComponent as EmployeeSVG } from 'assets/svg/Employee Icon.svg';
import { ReactComponent as AccountingSVG } from 'assets/svg/Accounting Icon.svg';
import { ReactComponent as MedicalSVG } from 'assets/svg/Medical Reports Icon.svg';

// main component
const DashboardCardList = () => {
  // array of modules
  const modules = [
    {
      title: 'Admission',
      link: '/dashboard/admissions',
      svg: <UnionSVG />,
      permission: 'Can View Admission Module',
    },
    {
      title: 'Doctor',
      link: '/dashboard/doctors',
      svg: <DoctorSVG />,
      permission: 'Can View Doctors Module',
    },
    {
      title: 'Nurse',
      link: '/dashboard/nursing',
      svg: <NurseSVG />,
      permission: 'Can View Nursing Module',
    },
    {
      title: 'Room & Bed Management',
      link: '/dashboard/room-bed-management',
      svg: <RoomBedManagementSVG />,
      permission: 'Can View Room & Bed Module',
    },
    {
      title: 'EMERGENCY DEPARTMENT',
      link: '/dashboard/emergency-department',
      svg: <EmergencyDepartmentSVG />,
      permission: 'Can View Emergency Department Module',
    },
    {
      title: 'OUTPATIENT DEPARTMENT',
      link: '/dashboard/outpatient-department',
      svg: <OutpatientDepartmentSVG />,
      permission: 'Can View Outpatient Department Module',
    },
    {
      title: 'LABORATORY',
      link: '/dashboard/laboratory',
      svg: <LaboratorySVG />,
      permission: 'Can View Laboratory Module',
    },
    {
      title: 'INVENTORY',
      link: '/dashboard/inventory',
      svg: <InventorySVG />,
      permission: 'Can View Inventory Module',
    },
    {
      title: 'BILLING',
      link: '/dashboard/billing',
      svg: <BillingSVG />,
      permission: 'Can View Billing Module',
    },
    {
      title: 'PHILHEALTH',
      link: '/dashboard/philhealth',
      svg: <PhilhealthSVG />,
      permission: 'Can View PhilHealth Module',
    },
    {
      title: 'Pharmacy',
      link: '/dashboard/pharmacy',
      svg: <PharmacySVG />,
      permission: 'Can View Pharmacy Module',
    },
    {
      title: 'REPORTS',
      link: '/dashboard/reports',
      svg: <ReportSVG />,
      permission: 'Can View Reports Module',
    },
    { title: 'ACL', link: '/dashboard/acl', svg: <ACLSvg />, permission: 'Can View ACL Module' },
    {
      title: 'Employee',
      link: '/dashboard/employees/list',
      svg: <EmployeeSVG />,
      permission: 'Can View Employees Module',
    },
    {
      title: 'Accounting',
      link: '/dashboard/accounting',
      svg: <AccountingSVG />,
      permission: 'Can View Accounting Module',
    },
    {
      title: 'Medical Records',
      link: '/dashboard/medical-records',
      svg: <MedicalSVG />,
      permission: 'Can View Medical Records Module',
    },
  ];

  return (
    <div className='dashboardCardList'>
      <div className='dashboardCardList__header'>
        <h4>Please Select Module</h4>
        <span>Welcome to {process.env.REACT_APP_NAME}</span>
      </div>
      <div className='dashboardCardList__body'>
        {modules.map((item, index) => {
          return (
            <DashboardCard
              key={index}
              svg={item.svg}
              link={item.link}
              title={item.title}
              permission={item.permission}
            />
          );
        })}
      </div>
    </div>
  );
};

export default DashboardCardList;
