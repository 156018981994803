/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// Packages
import axios from 'axios';
import { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  Form,
  FormControl,
  Pagination,
  Table,
  InputGroup,
} from 'react-bootstrap';

// components below
import ClearanceTable from './Table';
import FormUI from 'components/UI/FormUI';
import ModalViewLabRequests from './ViewRequests';
import ConditionalRender from 'components/ConditionalRender';
import { checkStringPermission } from 'helpers/filteredPermissions';

// CSS
import './style.scss';
import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

// main component
const Results = () => {
  const [data, setData] = useState([]);
  const [state, setState] = useState([]);
  const [requests, setRequests] = useState([]);
  const [showModal, setShowModal] = useState({
    isStatusShow: false,
    isAddDoctor: false,
    profileData: null,
    profileId: null,
    doctorId: null,
    labRequestId: null,
    isViewResult: false,
    showLabRequest: false,
  });
  const [userData, setUserData] = useState({
    search_physician: '',
    specialty_subspecialty: '',
  });

  const [doctors, setDoctors] = useState([]);
  const [searchedWord, setSearchedWord] = useState('');
  const [searchedWordPatient, setSearchedWordPatient] = useState('');

  /** filter by patient's name */
  const onChangePatientHandler = (event) => {
    setSearchedWordPatient(event.target.value);
    const searchedWordPatient = event.target.value;
    const newFilter = requests.filter((value) => {
      return value.patient.full_name.toLowerCase().includes(searchedWordPatient.toLowerCase());
    });

    if (searchedWordPatient.length > 0) {
      setState(newFilter);
    } else {
      setState(requests);
    }
  };

  const handleChange = (key) => (e) => {
    setUserData({
      ...userData,
      [key]: e.target.value,
    });
  };

  useEffect(() => {
    axios
      .get('/user')
      .then((response) => {
        setDoctors(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });

    axios
      .get('/clearance?type=LABORATORY')
      .then((response) => {
        setRequests(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  const canSearch = checkStringPermission('Can Search Patient in Clearance Request');
  const canView = checkStringPermission('Can View Patient List in Clearance Request');

  return (
    <>
      <FormUI>
        <div className='add-room-cont mt-4'>
          <ConditionalRender
            condition={canSearch}
            renderIf={
              <>
                <Form>
                  <h3>Search Patient</h3>
                  <br></br>

                  <Row>
                    <Col md={5}>
                      <Form.Group className='basic-search'>
                        <Form.Label>Patient Name</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter Here'
                          onChange={onChangePatientHandler}
                          value={searchedWordPatient}
                        />
                      </Form.Group>
                    </Col>

                    {/* <Col md={2}>
                                <Form.Group className='category-buttons'>
                                    <Button
                                        onClick={() => {
                                            // Update Data on Table?
                                        }}
                                        className='add-button'
                                        variant='primary'
                                    >
                                        Search
                                    </Button>
                                </Form.Group>
                            </Col> */}
                  </Row>
                </Form>
                <br></br>
                <br></br>
              </>
            }
          />

          <ConditionalRender
            condition={canView}
            renderIf={
              <>
                <h2 className='mb-3'>Patient List</h2>
                <ClearanceTable
                  setShowModal={setShowModal}
                  state={state}
                  setState={setState}
                  setData={setData}
                />
              </>
            }
          />
        </div>
      </FormUI>
      <ModalViewLabRequests showModal={showModal} setShowModal={setShowModal} data={data} />
    </>
  );
};

export default Results;
