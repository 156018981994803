/* eslint-disable camelcase */
/* eslint-disable space-before-function-paren */
/* eslint-disable prefer-const */
// import core & vendor packages
import Moment from 'moment';
import { toast } from 'react-toastify';
import ForIssuance from './ForIssuance';
import { Button } from 'react-bootstrap';
import DenyItemModal from './DenyItemModal';
import DenyRequestModal from './DenyRequestModal';
import React, { useEffect, useState } from 'react';
import ApproveModal from './ApproveModal/ApproveModal';
import { useSelector, useDispatch } from 'react-redux';
import ViewRequestModal from './ViewRequestModal/ViewRequestModal';

// import components
import Table from 'components/ReactTable';

// import assets
import './Table.scss';
import {
  getRefillRequest,
  selectrefillRequest,
  selectrefillRequestData,
  selectrefillRequestItem,
  denyRequest,
  approveRequest,
  denyrequesteditem,
  selectGroups,
  getrefillGroups,
  forIssuanceApprove,
} from 'reducers/refillRequest';
import UseItemList from '../../../hooks/UseItemList';
import UseStatusAction from '../../../hooks/UseStatusAction';

// main component
const InventoryTable = ({ state, setState, setShowProfile, showProfile }) => {
  const refillRequestState = useSelector(selectrefillRequest);
  const {
    forIssuancelogs,
    denyrequestedLogs,
    approverequestLogs,
    getRefillRequestlogs,
    denyrequesteditemLogs,
    approverequesteditemLogs,
  } = refillRequestState;
  const dispatch = useDispatch();
  const datafetch = useSelector(selectrefillRequestData);
  const groups = useSelector(selectGroups);

  let filterList = [
    { id: 1, key: 'all', name: 'All Open' },
    {
      id: 2,
      key: 'new',
      name: 'New',
    },
    {
      id: 3,
      key: 'approved',
      name: 'Approved',
    },
    {
      id: 5,
      key: 'for_issuance',
      name: 'For Issuance',
    },
    {
      id: 4,
      key: 'for_receiving',
      name: 'For Receiving',
    },

    {
      id: 6,
      key: 'completed',
      name: 'Completed',
    },
    {
      id: 7,
      key: 'denied',
      name: 'Cancelled',
    },
  ];

  const items = useSelector(selectrefillRequestItem);
  const [active, setActive] = useState('all');
  const [data, setData] = useState([]);
  const [trigger, setTrigger] = useState({
    forIssuancelogsTrigger: false,
    approverequesteditemTrigger: false,
    denyrequestedLogsTrigger: false,
    denyrequesteditemLogsTrigger: false,
    approverequestLogsTrigger: false,
  });

  const getRefillRequestList = () => {
    dispatch(getRefillRequest({ restock_type: 'refill' }));
    dispatch(getrefillGroups());
  };

  const stateChangeHandler = (key, value) => {
    setState((prev) => {
      return { ...prev, [key]: value };
    });
  };

  const showProfileHanlder = (key, value) => {
    setShowProfile((prevState) => {
      return { ...prevState, [key]: value };
    });
  };

  const forIssuanceHandler = () => {
    setShowProfile((prev) => {
      return { ...prev, isShowViewRequestModal: false, isShowForIssuanceModal: false };
    });
    setTrigger((prev) => {
      return { ...prev, forIssuancelogsTrigger: true };
    });
    dispatch(forIssuanceApprove({ restock_id: state?.itemID.id }));
  };

  // tablelist deny buton
  const denyRestockById = (restock_id, remark) => {
    const params = {
      id: restock_id,
      remarks: remark,
    };
    if (!remark) {
      toast.warning('Please dont leave remark empty');
      return;
    }
    setTrigger((prev) => {
      return { ...prev, denyrequestedLogsTrigger: true };
    });
    dispatch(denyRequest(params));
  };

  // tablelist approve buton
  const approveRestockById = (restock_id) => {
    const params = {
      id: restock_id,
    };
    setTrigger((prev) => {
      return { ...prev, approverequestLogsTrigger: true };
    });
    dispatch(approveRequest(params));
  };

  // on viewModal deny action button
  const denyRequestedItemHandler = (remark) => {
    const params = {
      restock_id: state?.itemID?.id,
      requested_item: state?.requested_item_id,
      remarks: remark,
    };
    setTrigger((prev) => {
      return { ...prev, denyrequesteditemLogsTrigger: true };
    });
    dispatch(denyrequesteditem(params));
  };

  const columnActionButton = (id, status) => (
    <div className='custom-actions-refill-request'>
      <Button onClick={() => handleTableAction('view', id)} className='view'>
        View
      </Button>
      {status === 'new' && (
        <>
          <Button onClick={() => handleTableAction('approve', id)} className='approve '>
            Approve
          </Button>
          <Button onClick={() => handleTableAction('deny', id)} className='deny '>
            Deny
          </Button>
        </>
      )}
    </div>
  );

  const handleTableAction = (eventKey, id) => {
    const row = data.find((dd) => dd.id === id);

    const action = {
      view: () => {
        showProfileHanlder('isShowViewRequestModal', true);
        stateChangeHandler('itemID', row);
        const { restockID } = UseItemList(row, items);
        stateChangeHandler('item_list', restockID);
        stateChangeHandler('filtered_item_list', restockID);
      },
      approve: () => {
        showProfileHanlder('isApproveRequestModal', true);
        stateChangeHandler('id', id);
      },
      deny: () => {
        showProfileHanlder('isShowDenyRequestModal', true);
        stateChangeHandler('id', id);
      },
    };

    action[eventKey]();
  };

  const handleOnClickFilter = (status) => {
    let filteredData = datafetch;
    if (status !== 'all') {
      filteredData = datafetch?.filter(
        (dd) => dd.attributes.status.replace(' ', '_').toLowerCase() === status,
      );
    }

    setData(filteredData);
    setActive(status);
  };

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Request Date/Time', accessor: 'requested_at' }, // accessor is the "key" in the data
      { Header: 'Request No.', accessor: 'request_number' },
      { Header: 'Requesting Department', accessor: 'requesting_department' },
      { Header: 'No. of Items', accessor: 'number_of_items' },
      { Header: 'Status', accessor: 'status' },
      { Header: 'Issued By', accessor: 'issued_by' },
      { Header: 'Action', accessor: 'action' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      data
        ? data?.map((dd) => ({
            id: dd?.id,
            requested_at: Moment(dd?.attributes?.requested_at).format('MMMM Do YYYY, h:mm:ss a'),
            request_number: dd?.attributes?.request_number,
            requesting_department: dd?.attributes?.requesting_department,
            number_of_items: dd?.attributes?.number_of_items,
            status: UseStatusAction(dd?.attributes?.status),
            issued_by: dd?.attributes?.issued_by || '-',
            action: columnActionButton(dd?.id, dd?.attributes?.status),
          }))
        : null,
    [data],
  );

  const filterlistContainer = filterList.map((list) => (
    <Button
      key={list.key}
      className={list.key === active ? 'active' : ''}
      onClick={() => {
        setState((prev) => {
          return { ...prev, table_active_status: list.key };
        });
        handleOnClickFilter(list.key);
      }}
    >
      {list.name}
    </Button>
  ));

  useEffect(() => {
    getRefillRequestList();
  }, []);

  useEffect(() => {
    setData(datafetch);

    if (state?.itemID) {
      const row = datafetch.find((dd) => dd.id === state.itemID?.id);
      stateChangeHandler('itemID', row);
    }

    handleOnClickFilter(state.table_active_status || 'all');
  }, [datafetch]);

  // modal for issuance
  useEffect(() => {
    const { isSuccess, error } = forIssuancelogs;

    if (isSuccess && trigger.forIssuancelogsTrigger) {
      dispatch(getRefillRequest({ restock_type: 'refill' }));
      toast.success('Successfully submitted!');
      setShowProfile((prev) => {
        return { ...prev, isShowViewRequestModal: false, isShowForIssuanceModal: false };
      });
      setTrigger((prev) => {
        return { ...prev, forIssuancelogsTrigger: false };
      });
    }

    if (error) {
      toast.warning(error);
      setShowProfile((prev) => {
        return { ...prev, isShowForIssuanceModal: false, isShowViewRequestModal: true };
      });
    }
  }, [forIssuancelogs]);

  // modal approve requested item
  useEffect(() => {
    const { isSuccess } = approverequesteditemLogs;

    if (isSuccess && trigger.approverequesteditemTrigger) {
      dispatch(getRefillRequest({ restock_type: 'refill' }));
      setTrigger((prev) => {
        return { ...prev, approverequesteditemTrigger: false };
      });
    }
  }, [approverequesteditemLogs]);

  // modal deny requested item
  useEffect(() => {
    if (denyrequesteditemLogs.isSuccess && trigger.denyrequesteditemLogsTrigger) {
      setTrigger((prev) => {
        return { ...prev, denyrequesteditemLogsTrigger: false };
      });
      dispatch(getRefillRequest({ restock_type: 'refill' }));
    }
  }, [denyrequesteditemLogs]);

  // tablelist deny requested no
  useEffect(() => {
    if (denyrequestedLogs.isSuccess && trigger.denyrequestedLogsTrigger) {
      setTrigger((prev) => {
        return { ...prev, denyrequestedLogsTrigger: false };
      });
      dispatch(getRefillRequest({ restock_type: 'refill' }));
    }
  }, [denyrequestedLogs]);

  // tablelist approve requested no
  useEffect(() => {
    if (approverequestLogs.isSuccess && trigger.approverequestLogsTrigger) {
      setTrigger((prev) => {
        return { ...prev, approverequestLogsTrigger: false };
      });
      dispatch(getRefillRequest({ restock_type: 'refill' }));
    }
  }, [approverequestLogs]);

  useEffect(() => {
    if (state.item_list) {
      const { restockID } = UseItemList(state.itemID, items);

      setState((prev) => {
        return { ...prev, item_list: restockID, filtered_item_list: restockID };
      });
    }
  }, [items]);

  useEffect(() => {
    // console.log('loggg', state);
  }, [state]);

  return (
    <>
      <div className='inventory-table'>
        <Table
          data={tableData}
          logs={getRefillRequestlogs}
          columns={tableColumns}
          filter={{
            top: (
              <>
                <div className='buttons flex-nowrap'>{filterlistContainer}</div>
                <Button
                  className='text-dark fw-bold'
                  style={{ background: '#FFD200', marginLeft: 'auto', border: 'none' }}
                >
                  Print Request Form
                </Button>
              </>
            ),
          }}
          pagination={['BOTTOM']}
        />
      </div>
      {/* Deny restock item modal  */}
      <DenyRequestModal
        showProfile={showProfile}
        setShowProfile={setShowProfile}
        denyRestockById={denyRestockById}
        state={state}
      />
      {/* View restock item modal  */}
      <ViewRequestModal
        showProfile={showProfile}
        setShowProfile={setShowProfile}
        setState={setState}
        state={state}
        items={items}
        groups={groups}
        setTrigger={setTrigger}
      />
      {/* Approve restock item modal  */}
      <ApproveModal
        showProfile={showProfile}
        setShowProfile={setShowProfile}
        approveRestockById={approveRestockById}
        state={state}
      />
      {/* deny requested item modal  */}
      <DenyItemModal
        showProfile={showProfile}
        setShowProfile={setShowProfile}
        denyRequestedItemHandler={denyRequestedItemHandler}
      />
      <ForIssuance
        showProfile={showProfile}
        setShowProfile={setShowProfile}
        forIssuanceHandler={forIssuanceHandler}
      />
    </>
  );
};

export default InventoryTable;
