/* eslint-disable */
/* eslint-disable camelcase */

// import core & vendor packages below
import moment from 'moment';
import PropTypes from 'prop-types';
import Status from 'constant/status';
import Table from 'components/ReactTable';
import Tabs from 'constant/medicalRecordTabs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showActivePatients, selectActivePatients } from 'reducers/medicalRecordReducer';

// import components below
import TableUI from 'components/UI/TableUI';
import TablePagination from './Pagination';
import ConditionalRender from 'components/ConditionalRender';

// main component
const OPDRecordsTable = ({ state, setState, setShowScreen, setShowProfile, isAppointment }) => {
  const tableColumns = React.useMemo(
    () => [
      { Header: 'Appointment ID', accessor: 'id' }, // accessor is the "key" in the data
      { Header: 'Patient ID', accessor: 'patientId' },
      { Header: 'Patient Name', accessor: 'name' },
      { Header: 'Appointment Date & Time', accessor: 'date' },
      { Header: 'Doctor', accessor: 'doctor' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      state &&
      state?.map((item) => ({
        id: item?.id,
        patientId: item?.patient?.id,
        name: item?.patient?.full_name,
        date: moment(item.appointment_date).format('MM/DD/YYYY | hh:mm A'),
        doctor: 'Dr. ' + item?.name_of_doctor,
      })),
    [state],
  );

  const handleTableAction = (eventKey, id) => {
    const row = state.find((dd) => dd.id === id);
    const action = {
      profile: () => {
        setShowProfile({
          visitNumber: row?.patient?.active_visit_number,
        });
        setShowScreen(2);
      },
      appointment: () => {
        setShowProfile({
          profileId: row?.patient?.id,
        });
        setShowScreen(1);
      },
    };

    action[eventKey]();
  };

  return (
    <div className='opd-records-table'>
      <h2 className='no-border'>Patient List</h2>
      <Table
        data={tableData}
        columns={tableColumns}
        // actions={{ hasProfile: true }}
        // onAction={handleTableAction}
        customAction={(id) => (
          <div className='custom-actions'>
            <span
              onClick={() => handleTableAction('profile', id)}
              className='badge'
              style={{ background: '#00A8FF', cursor: 'pointer' }}
            >
              Profile
            </span>{' '}
            &nbsp;&nbsp;
            <ConditionalRender
              condition={isAppointment}
              renderIf={
                <span
                  onClick={() => handleTableAction('appointment', id)}
                  className='badge'
                  style={{ background: '#02961C', cursor: 'pointer' }}
                >
                  Appointment
                </span>
              }
            />
          </div>
        )}
        pagination={['BOTTOM']}
      />
    </div>
  );
};

OPDRecordsTable.defaultProps = {
  state: [],
  setState: () => {},
  setShowScreen: () => {},
};

OPDRecordsTable.propTypes = {
  state: PropTypes.array,
  setState: PropTypes.func,
  setShowScreen: PropTypes.func,
};

export default OPDRecordsTable;
