/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';

import Filter from './components/Filter';
import EditItem from './components/EditItem';
import PriceLogs from './components/PriceLogs';
import seturlParams from 'helpers/seturlParams';
import InventoryTable from './components/Table';
import PriceUpdate from './components/PriceUpdate';
import ItemDetails from './components/ItemDetails';
import { useSelector, useDispatch } from 'react-redux';
import AddNewItemModal from './components/AddItemModal';
import AdjustmentModal from './components/AdjustmentModal';
import ConditionalRender from 'components/ConditionalRender';
import CreateNewItemModal from './components/CreateNewItemModal';
import IssueToDepartmentModal from './components/IssueToDepartment';
import { showInventoryItems, selectInventory } from 'reducers/inventoryReducer';

import './index.scss';

const InventoryItems = () => {
  const dispatch = useDispatch();
  const inventoryState = useSelector(selectInventory);
  const { storeInventoryData, storeInventoryItems, storeInventoryItemsLogs } = inventoryState;

  const [state, setState] = useState([]);
  const [search, setSearch] = useState({
    q: '',
    type: '',
  });
  const [showProfile, setShowProfile] = useState({
    profileId: null,
    showView: false,
    showIssue: false,
    showAdjust: false,
    showAddItem: false,
    isShowProfile: false,
    showCreateItem: false,
  });

  const isDefault = showProfile.isShowProfile === false;

  const getInventoryItems = (reset = false) => {
    dispatch(
      showInventoryItems(
        `?include[]=group&is_new=0${reset ? '' : seturlParams(search?.q, 'q')}${
          reset ? '' : seturlParams(search?.type, 'is_medicine')
        }`,
      ),
    );
  };

  useEffect(() => {
    getInventoryItems();
  }, []);

  // Migz: I think below useEffect block is not needed
  useEffect(() => {
    const { isLoading, isSuccess, isFailed, error } = storeInventoryItemsLogs;

    if (isSuccess) {
      setState(storeInventoryData);
    }
  }, [storeInventoryItemsLogs]);

  return (
    <>
      <div className='mt-3'>
        <ConditionalRender
          condition={isDefault}
          renderIf={
            <>
              <Filter
                search={search}
                setState={setState}
                setSearch={setSearch}
                setShowProfile={setShowProfile}
                getInventoryItems={getInventoryItems}
              />

              <InventoryTable
                state={storeInventoryData}
                search={search}
                setState={setState}
                setShowProfile={setShowProfile}
              />
            </>
          }
        />
      </div>
      {/* Modals */}
      <ItemDetails setShowProfile={setShowProfile} showProfile={showProfile} />

      <PriceLogs
        showProfile={showProfile}
        setShowProfile={setShowProfile}
        getInventoryItems={getInventoryItems}
      />

      <EditItem
        showProfile={showProfile}
        setShowProfile={setShowProfile}
        getInventoryItems={getInventoryItems}
      />
      <PriceUpdate
        showProfile={showProfile}
        setShowProfile={setShowProfile}
        getInventoryItems={getInventoryItems}
      />
      <AddNewItemModal
        showProfile={showProfile}
        setShowProfile={setShowProfile}
        getTableInventoryItems={getInventoryItems}
      />
      <AdjustmentModal
        showProfile={showProfile}
        setShowProfile={setShowProfile}
        getInventoryItems={getInventoryItems}
      />
      <CreateNewItemModal
        showProfile={showProfile}
        setShowProfile={setShowProfile}
        getInventoryItems={getInventoryItems}
      />
      <IssueToDepartmentModal setShowProfile={setShowProfile} showProfile={showProfile} />
    </>
  );
};

export default InventoryItems;
