/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { showUsers, getACLState, getRoles, getDepartments, getLogs } from 'reducers/aclReducer';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, FormControl, InputGroup, Tabs, Tab } from 'react-bootstrap';

import Tables from '../Tables';
import TabsUI from 'components/UI/TabsUI';

import { ReactComponent as MagnifyingGlassSVG } from 'assets/svg/magnifying_glass.svg';

import './index.scss';
import ConditionalRender from 'components/ConditionalRender';
import { setSelectionRange } from '@testing-library/user-event/dist/utils';

const UserAccount = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [roles, setRoles] = useState('');
  const [showResultsNumber, setShowResultsNumber] = useState(10);
  const [sortBy, setSortBy] = useState('');

  const searchAllTabs = () => {
    dispatch(
      showUsers({
        url: `q=${search}${roles ? `&roles[]=${roles}` : ''}${sortBy ? `&account_status=${sortBy}` : ''
        }&per_page=${showResultsNumber}`,
        department: 'All',
      }),
    );
    dispatch(
      showUsers({
        url: `q=${search}${roles ? `&roles[]=${roles}` : ''}&per_page=${showResultsNumber}${sortBy ? `&account_status=${sortBy}` : ''
        }&department[]=Patient Care Management`,
        department: 'Patient Care Management',
      }),
    );
    dispatch(
      showUsers({
        url: `q=${search}${roles ? `&roles[]=${roles}` : ''}&per_page=${showResultsNumber}${sortBy ? `&account_status=${sortBy}` : ''
        }&department[]=Property Management`,
        department: 'Property Management',
      }),
    );
    dispatch(
      showUsers({
        url: `q=${search}${roles ? `&roles[]=${roles}` : ''}&per_page=${showResultsNumber}${sortBy ? `&account_status=${sortBy}` : ''
        }&department[]=Accounting Management`,
        department: 'Accounting Management',
      }),
    );
    dispatch(
      showUsers({
        url: `q=${search}${roles ? `&roles[]=${roles}` : ''}&per_page=${showResultsNumber}${sortBy ? `&account_status=${sortBy}` : ''
        }&department[]=HR Management`,
        department: 'HR Management',
      }),
    );
    dispatch(
      showUsers({
        url: `q=${search}${roles ? `&roles[]=${roles}` : ''}&per_page=${showResultsNumber}${sortBy ? `&account_status=${sortBy}` : ''
        }&department[]=Hospital Management`,
        department: 'Hospital Management',
      }),
    );
  };

  const changePage = (page, type) => {
    dispatch(
      showUsers({
        url: `q=${search}${roles ? `&roles[]=${roles}` : ''}&per_page=${showResultsNumber}${
          type === 'All' ? '' : `&department[]=${type}`
        }&page=${page}`,
        department: type,
      }),
    );
  };

  useEffect(() => {
    searchAllTabs();
    dispatch(getRoles(''));
    dispatch(getLogs('?per_page=10'));
    dispatch(getDepartments(''));
  }, []);

  let timer = null;
  const delay = 500;

  useEffect(() => {
    timer = setTimeout(() => {
      searchAllTabs();
    }, delay);

    return () => clearTimeout(timer);
  }, [search, roles, showResultsNumber, sortBy]);

  const storeData = useSelector(getACLState);
  const allUsers = storeData?.allUsers;
  const patientCareUsers = storeData?.patientCareUsers;
  const propertyUsers = storeData?.propertyUsers;
  const accountingUsers = storeData?.accountingUsers;
  const hrUsers = storeData?.hrUsers;
  const hospitalUsers = storeData?.hospitalUsers;
  const allRoles = storeData?.roles;
  const haveRoles = allRoles && allRoles.length > 0;

  const tableTabs = [
    {
      id: 1,
      type: 'All',
      data: allUsers,
    },
    {
      id: 2,
      type: 'Patient Care Management',
      data: patientCareUsers,
    },
    {
      id: 3,
      type: 'Property Management',
      data: propertyUsers,
    },
    {
      id: 4,
      type: 'Accounting Management',
      data: accountingUsers,
    },
    {
      id: 5,
      type: 'HR Management',
      data: hrUsers,
    },
    {
      id: 6,
      type: 'Hospital Management',
      data: hospitalUsers,
    },
  ];

  return (
    <div className='mt-4 user-accounts'>
      <Row className='user-header'>
        <Col sm={6} className='p-0 text'>
          <h2>
            User Accounts <span>Find all your user account&apos;s and their associated roles.</span>
          </h2>
        </Col>
        <Col sm={6} className='p-0 search-cont'>
          <Form.Group className='basic-search'>
            <InputGroup>
              <FormControl
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                type='text'
                placeholder='Search'
                className='bg-grey'
              />
              <InputGroup.Text
                onClick={() => {
                  searchAllTabs();
                }}
              >
                <MagnifyingGlassSVG />
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>

          <Form.Group className='input-group-cont' style={{ width: '100%' }}>
            <Form.Select
              value={roles}
              onChange={(e) => {
                setRoles(e.target.value);
              }}
            >
              <option value=''>All</option>
              <ConditionalRender
                condition={haveRoles}
                renderIf={allRoles.map((data) => {
                  return (
                    <option key={data.id} value={data?.attributes?.name}>
                      {data?.attributes?.name}
                    </option>
                  );
                })}
              />
            </Form.Select>
          </Form.Group>

          <Form.Group className='input-group-cont' style={{ width: '100%' }}>
            <Form.Select
              value={sortBy}
              onChange={(e) => {
                setSortBy(e.target.value);
              }}
            >
              <option value=''>Sort By</option>
              <option value='1'>Active</option>
              <option value='0'>Inactive</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <Row className='user-tables'>
        <Col className='p-0 table-tabs'>
          <TabsUI>
            <Tabs defaultActiveKey='All'>
              {tableTabs.map((tabData) => {
                return (
                  <Tab key={tabData.id} eventKey={tabData.type} title={tabData.type}>
                    <Tables
                      type={tabData.type}
                      tableData={tabData.data}
                      changePage={changePage}
                      showResultsNumber={showResultsNumber}
                      setShowResultsNumber={setShowResultsNumber}
                      searchAllTabs={searchAllTabs}
                    />
                  </Tab>
                );
              })}
            </Tabs>
          </TabsUI>
        </Col>
      </Row>
    </div>
  );
};

export default UserAccount;
