/* eslint-disable */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
// import core & vendor packages below
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from 'components/ReactTable';
import moment from 'moment';
import axios from 'axios';

// import components below
import {
  setActiveTab,
  setAppointmentAction,
  showAppointment,
  selectAppointmentData,
  getAppointment,
} from 'reducers/appointmentReducer';

// import assets below
import './Table.scss';

// main component
const AppointmentListTable = ({
  state,
  setState,
  refreshTable,
  setShowProfile,
  setViewDetails,
}) => {
  const dispatch = useDispatch();
  const { appointments = [] } = useSelector(selectAppointmentData);

  useEffect(() => {
    dispatch(getAppointment());
  }, []);

  const handleViewDetails = (id) => {
    /**
    dispatch(showAppointment(id));
    dispatch(setActiveTab('Appointment'));
    dispatch(setAppointmentAction({ isView: true }));
     */
    dispatch(showAppointment(id));
    setViewDetails(true);
  };

  const tableColumns = React.useMemo(
    () => [
      { Header: 'Patient ID', accessor: 'patient_id' }, // accessor is the "key" in the data
      { Header: 'Patient Name', accessor: 'name' },
      { Header: 'Appointment Date', accessor: 'date' },
      { Header: 'Department', accessor: 'department' },
      { Header: 'Doctor / Resource', accessor: 'doctor_resource' },
      { Header: 'Status', accessor: 'status' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      appointments?.map((dd) => ({
        id: dd?.id,
        patient_id: dd?.patient?.id,
        name: dd?.patient?.full_name,
        date: moment(dd?.createdAt).format('MMMM DD, YYYY hh:mm A'),
        department: dd?.department,
        doctor_resource: dd?.name_of_doctor,
        status: (
          <span className={`${dd?.appointment_status} badge`}>
            {dd?.appointment_status === 'NEW' ? 'New Request' : 'Accepted'}
          </span>
        ),
      })),
    [appointments],
  );

  const handleTableAction = (eventKey, id) => {
    const action = {
      profile: () => {},
      view: () => {
        handleViewDetails(id);
      },
      edit: () => {},
      delete: () => {},
    };

    action[eventKey]();
  };

  return (
    <div className='outpatient-appointment-list-table'>
      <Table
        data={tableData}
        columns={tableColumns}
        actions={{ hasView: true }}
        onAction={handleTableAction}
        pagination={['BOTTOM']}
      />
    </div>
  );
};

export default AppointmentListTable;
