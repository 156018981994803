/* eslint-disable */
// import core & vendor packages below
import { useState } from 'react';
import PropTypes from 'prop-types';
// import { Tabs, Tab } from 'react-bootstrap';

// import components below
import Tabs from 'components/Tabs';
import Clearance from './Clearance';
import StockCheck from './StockCheck';
// import TabsUI from 'components/UI/TabsUI';
import ActivityLog from './ActivityLog';
import RefillRequest from './RefillRequest';
import GoodsReceipts from './GoodsReciepts';
import StoreItemSetup from './StoreItemSetup';
import PurchaseRequest from './PurchaseRequest';
import StockAdjustment from './StockAdjustment';
import StoreInventoryTabs from './StoreInventory';
import RepairMaintenance from './RepairMaintenance';
import ItemsForReplenishment from './ItemsForReplenishment';

import './Tabs.scss';

// main component
const InventoryTabs = ({ onChange }) => {
  const [activeTab, setActiveTab] = useState('Store Inventory');
  const [tabState, setTabState] = useState();

  const handleTabOnChange = (eventKey) => {
    setActiveTab(eventKey);
    onChange(eventKey);
  };

  const options = [
    {
      title: 'Store Inventory',
      eventKey: 'Store Inventory',
      className: 'p-4',
      component: StoreInventoryTabs,
    },
    {
      title: 'Refill Request',
      eventKey: 'Refill Request',
      className: 'p-4',
      component: RefillRequest,
    },
    {
      title: 'Items for Replenishment',
      eventKey: 'Items for Replenishment',
      className: 'p-4',
      component: ItemsForReplenishment,
      componentProps: {
        handleTabOnChange,
        setTabState,
      },
    },
    {
      className: 'p-4',
      title: 'Purchase Request',
      component: PurchaseRequest,
      eventKey: 'Purchase Request',
    },
    {
      className: 'p-4',
      title: 'Stock Adjustment',
      component: StockAdjustment,
      eventKey: 'Stock Adjustment',
    },
    {
      className: 'p-4',
      title: 'Goods Receipts',
      component: GoodsReceipts,
      eventKey: 'Goods Receipts',
    },
    {
      className: 'p-4',
      component: RepairMaintenance,
      title: 'Repair / Maintenance',
      eventKey: 'Repair / Maintenance',
    },
    {
      className: 'p-4',
      title: 'Activity Log',
      component: ActivityLog,
      eventKey: 'Activity Log',
    },
  ];

  const tabGroupName = 'InventoryTabs';
  const tabsProps = {
    activeKey: activeTab,
    defaultActiveKey: options[0].eventKey,
    onSelect: handleTabOnChange,
    tabKey: tabGroupName,
    options,
  };

  return (
    <div className={tabGroupName}>
      <Tabs {...tabsProps} />
    </div>
  );
};

InventoryTabs.defaultProps = {
  onChange: () => {},
};

InventoryTabs.propTypes = {
  onChange: PropTypes.func,
};

export default InventoryTabs;
