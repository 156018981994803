/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

// Packages
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Button, FormControl } from 'react-bootstrap';

import { getRestockItems } from 'reducers/departmentInventoryReducer';

// Components
import FormUI from 'components/UI/FormUI';
import StockAdjustmentFilterTable from './Table';

// Assets
import './Filter.scss';

const initialState = { adjustment_no: '', adjustment_date: '' };

// Main component
const StockAdjustmentFilter = ({ setState, setShowProfile }) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState(initialState);
  const [active, setActive] = useState('10');

  const fetchData = (statusReceived = null) => {
    if (statusReceived === null) {
      const params = {
        status: active,
        per_page: 100000,
        q: filter?.adjustment_no,
        created_at: filter?.adjustment_date,
        include: ['restock_items'],
      };

      dispatch(getRestockItems(params));
    } else {
      const params = {
        per_page: 100000,
        status: statusReceived,
        q: filter?.adjustment_no,
        created_at: filter?.adjustment_date,
        include: ['restock_items'],
      };

      dispatch(getRestockItems(params));
    }
  };

  const resetData = () => {
    const params = {
      status: active,
      per_page: 100000,
      q: '',
      created_at: '',
      include: ['restock_items'],
    };

    dispatch(getRestockItems(params));
  };

  const handleChange = (key) => (e) => {
    setFilter((prev) => {
      return {
        ...prev,
        [key]: e.target.value,
      };
    });
  };

  useEffect(() => {
    // console.log(filter);
  }, [filter]);

  return (
    <div className='StockAdjustmentFilter'>
      <FormUI>
        <Row>
          <Col sm={3}>
            <Form.Group className='mb-4'>
              <Form.Label>Adjustment No.</Form.Label>
              <FormControl
                type='text'
                className='bg-white'
                placeholder='Search Here'
                value={filter?.adjustment_no}
                onChange={handleChange('adjustment_no')}
              />
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group className='mb-4' style={{ width: '100%' }}>
              <Form.Label>Adjustment Date</Form.Label>
              <FormControl
                type='date'
                className='bg-white'
                placeholder='Search Here'
                value={filter?.adjustment_date}
                onChange={handleChange('adjustment_date')}
              />
            </Form.Group>
          </Col>
          <Col>
            <div className='buttonWrapper pb-4'>
              <Button
                variant='search'
                onClick={() => {
                  fetchData();
                }}
              >
                APPLY
              </Button>

              <Button
                variant='clear'
                onClick={() => {
                  setFilter(initialState);
                  resetData();
                }}
              >
                RESET
              </Button>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <StockAdjustmentFilterTable
              filter={filter}
              active={active}
              setState={setState}
              fetchData={fetchData}
              setActive={setActive}
              setFilter={setFilter}
              setShowProfile={setShowProfile}
            />
          </Col>
        </Row>
      </FormUI>
    </div>
  );
};

StockAdjustmentFilter.defaultProps = {
  setState: () => {},
  setShowProfile: () => {},
};

StockAdjustmentFilter.propTypes = {
  setState: PropTypes.func,
  setShowProfile: PropTypes.func,
};

export default StockAdjustmentFilter;
