/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import axios from 'axios';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Modal, Form, FormControl } from 'react-bootstrap';

// components
import FormUI from 'components/UI/FormUI';
import { selectPatientItem, showPatient } from 'reducers/patientReducer';

// import assets below
import './style.scss';
import { ReactComponent as UserSVG } from 'assets/svg/user.svg';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

// main component
const ModalLabRequest = ({ showProfile, setShowProfile }) => {
  const dispatch = useDispatch();

  const patientProfile = useSelector(selectPatientItem);

  const [list, setList] = useState([]);
  const [doctorList, setDoctorList] = useState([]);
  const [patientName, setPatientName] = useState('');
  const [userData, setUserData] = useState({
    type: '',
    visit: '',
    notes: '',
    price: '',
    userId: '',
    lab_type: '',
    lab_test: '',
    patientId: '',
    specimen_type: '',
  });

  const handleChange = (key) => (e) => {
    setUserData({
      ...userData,
      [key]: e.target.value,
    });
  };

  // Submit data here
  const handleSubmit = async () => {
    await axios
      .post('/requisition/laboratory', userData)
      .then((response) => {
        if (response.data.error) {
          alert(response.data.error.message);
        } else {
          // setOpenModal(true);
          toast.success('Successfully created Lab Request');
          setUserData({
            ...userData,
            visit: '',
            lab_type: '',
            type: '',
            notes: '',
            lab_test: '',
            specimen_type: '',
            patientId: patientProfile.id,
          });

          dispatch(showPatient(showProfile.profileId));
        }
      })
      .catch((err) => {
        err.response.data.errors.map((item) => {
          toast.warning(item?.msg);
        });
      });
  };

  const loadLabTests = (input) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterLabTests(input));
      }, 1000);
    });
  };

  // Doctors AsyncSelect
  const loadDoctors = (input) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterDoctors(input));
      }, 1000);
    });
  };

  const handleDoctor = (id) => {
    setUserData({ ...userData, userId: id });
  };

  const filterDoctors = (input) => {
    return doctorList.filter((i) => {
      return i.value.toLowerCase().includes(input.toLowerCase());
    });
  };

  const filterLabTests = (input) => {
    return list.filter((i) => {
      return i.value.toLowerCase().includes(input.toLowerCase());
    });
  };

  const handleLabTest = (id, label, value, type, price, internal_external) => {
    setUserData({
      ...userData,
      lab_type: value,
      lab_test: internal_external,
      labTestId: id,
      price: price,
      type: type,
    });
  };

  useEffect(() => {
    if (patientProfile) {
      setPatientName(patientProfile.full_name);
      setUserData({
        ...userData,
        patientId: patientProfile.id,
        visit: patientProfile?.patient_status,
      });
    }
  }, [patientProfile]);

  useEffect(() => {
    /** Get Patients for auto complete suggestion */
    axios
      .get(`${process.env.REACT_APP_API_BASE_ACL}/api/users?roles[]=Doctor`)
      .then((response) => {
        const options = response.data?.data?.map((data) => ({
          id: data?.id,
          value: data?.attributes.full_name,
          label: data?.attributes.full_name,
        }));
        setDoctorList(options);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, [userData]);

  /** Start
   * Display Lab Tests from database
   */
  useEffect(() => {
    axios.get('/lab_test').then((response) => {
      const options = response.data?.data?.map((data) => ({
        id: data?.id,
        value: data?.name,
        label: data?.name,
        price: data?.price,
        internal_external: data?.internal_external,
        type: data?.type,
      }));
      setList(options);
    });
  }, []);

  return (
    <Modal
      size='lg'
      show={showProfile.labRequest}
      onHide={() =>
        setShowProfile((prevState) => {
          return {
            ...prevState,
            labRequest: false,
          };
        })
      }
      aria-labelledby='Lab Request Modal'
      className='add-lab-request-modal'
    >
      <Modal.Body>
        <div className='add-lab-request'>
          <div className='d-flex header'>
            <p>DIAGNOSTIC REQUESTS</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() =>
                setShowProfile((prevState) => {
                  return { ...prevState, labRequest: false };
                })
              }
            />
          </div>
        </div>

        <FormUI>
          <Form className='lab-request-form'>
            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Patient Name</Form.Label>
                  <FormControl
                    type='text'
                    disabled
                    className='bg-white'
                    placeholder='Enter Here'
                    value={patientName}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Physician Name</Form.Label>
                  <div style={{ width: '100%', margin: '0 auto' }}>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      // value={diagnosis}
                      loadOptions={loadDoctors}
                      onChange={(e) => handleDoctor(e.id)}
                      placeholder='Search Doctor'
                      styles={selectStyles}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Lab Type</Form.Label>
                  <div style={{ width: '100%', margin: '0 auto' }}>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      // value={diagnosis}
                      loadOptions={loadLabTests}
                      onChange={(e) =>
                        handleLabTest(e.id, e.label, e.value, e.type, e.price, e.internal_external)
                      }
                      placeholder='Search Laboratory Test'
                      styles={selectStyles}
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Type</Form.Label>
                  <Form.Select onChange={handleChange('type')} value={userData.type} disabled>
                    <option hidden value=''>
                      -Select-
                    </option>
                    <option value='LAB_TEST'>LAB TEST</option>
                    <option value='IMAGING_TEST'>IMAGING TEST</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Internal/External Lab Test</Form.Label>
                  <Form.Select
                    disabled
                    onChange={handleChange('lab_test')}
                    value={userData.lab_test}
                  >
                    <option hidden>-Select-</option>
                    <option value='Internal'>Internal</option>
                    <option value='External'>External</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Visit</Form.Label>
                  <Form.Select onChange={handleChange('visit')} value={userData.visit} disabled>
                    <option hidden>-Select-</option>
                    <option value='new'>Outpatient</option>
                    <option value='emergency'>Emergency</option>
                    <option value='admitted'>Admitted</option>
                    <option value='for_discharge'>For Discharge</option>
                    <option value='awaiting bed'>Awaiting Bed</option>
                    <option value='absconded'>Absconded</option>
                    <option value='died'>Died</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={5}>
                <Form.Group className='mb-4'>
                  <Form.Label>Type of Specimen</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-grey'
                    placeholder='Enter Here'
                    value={userData.specimen_type}
                    onChange={handleChange('specimen_type')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className='mb-4'>
                  <Form.Label>Notes</Form.Label>
                  <FormControl
                    as='textarea'
                    rows={3}
                    className='bg-white'
                    placeholder='Enter Here'
                    value={userData.notes}
                    onChange={handleChange('notes')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-4 buttons-cont'>
                  <Button
                    className='add-button'
                    variant='primary'
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    Add
                  </Button>
                  <Button
                    className='cancel-button'
                    vvariant='secondary'
                    onClick={() =>
                      setShowProfile((prevState) => {
                        return {
                          ...prevState,
                          labRequest: false,
                        };
                      })
                    }
                  >
                    Cancel
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

ModalLabRequest.defaultProps = {
  showProfile: () => {},
  setShowProfile: () => {},
};

ModalLabRequest.propTypes = {
  showProfile: PropTypes.func,
  setShowProfile: PropTypes.func,
};

const selectStyles = {
  control: (styles) => ({
    ...styles,
    padding: '0.45em',
    color: '#404040',
    borderColor: '#c8c8c8',
    borderRadius: '10px',
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: '100',
  }),
};

export default ModalLabRequest;
