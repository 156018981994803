/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Form, FormControl, Button } from 'react-bootstrap';

import AddRecord from './AddRecord';
import ViewRecord from './ViewRecord';
import EditRecord from './EditRecord';
import ChargesTable from './ChargesTable';
import ConditionalRender from 'components/ConditionalRender';

import './index.scss';
import { ReactComponent as PlusSVG } from 'assets/svg/circle-plus-blue.svg';

const LeaveContainer = () => {
  const [showScreen, setShowScreen] = useState(1);
  const [requestData, setRequestData] = useState();

  return (
    <>
      {/* Default View */}
      <ConditionalRender
        condition={showScreen === 1}
        renderIf={
          <div className='charges-container shadow-sm'>
            <div className='box-container blue'>
              <Form.Group className='inline-group'>
                <Form.Label>LIST OF RECORDS</Form.Label>

                <FormControl className='blue-control' placeholder='Search Employee' />
              </Form.Group>

              <Button className='add-record-button' onClick={() => setShowScreen(3)}>
                <PlusSVG />
                Add Record
              </Button>
            </div>

            {/* Main Content */}
            <div className='box-container pt-0 px-0'>
              <ChargesTable setShowScreen={setShowScreen} setRequestData={setRequestData} />
            </div>
          </div>
        }
      />

      {/* View Record */}
      <ConditionalRender
        condition={showScreen === 2}
        renderIf={<ViewRecord requestData={requestData} setShowScreen={setShowScreen} />}
      />

      {/* Add Record */}
      <ConditionalRender
        condition={showScreen === 3}
        renderIf={<AddRecord setShowScreen={setShowScreen} />}
      />

      {/* Edit Record */}
      <ConditionalRender
        condition={showScreen === 4}
        renderIf={<EditRecord setShowScreen={setShowScreen} requestData={requestData} />}
      />
    </>
  );
};

export default LeaveContainer;
