/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React from 'react';
import { Row, Col, Form, FormControl, Button } from 'react-bootstrap';

const index = ({ requestData, setShowScreen }) => {
  const handleEditRecord = () => {
    setShowScreen(4);
  };

  return (
    <div className='charges-container shadow-sm'>
      <div className='box-container blue'>
        <Form.Group className='inline-group'>
          <Form.Label>VIEW RECORD</Form.Label>
        </Form.Group>
      </div>

      <div className='box-container'>
        <div className='view-record'>
          <div className='record-header'>
            <h4 className='mb-3'>Employee Details</h4>

            <Button onClick={handleEditRecord} className='edit-details'>
              EDIT DETAILS
            </Button>
          </div>

          <Row className='mb-3'>
            <Col>
              <Form.Group className='inline-group'>
                <Form.Label>Employee ID</Form.Label>

                <FormControl placeholder='DETAILS HERE' value={requestData?.id} />
              </Form.Group>

              <Form.Group className='inline-group'>
                <Form.Label>Employee Name</Form.Label>

                <FormControl placeholder='DETAILS HERE' value={requestData?.employee_name} />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className='inline-group'>
                <Form.Label>Department</Form.Label>

                <FormControl placeholder='DETAILS HERE' value={requestData?.department} />
              </Form.Group>

              <Form.Group className='inline-group'>
                <Form.Label>Designation</Form.Label>

                <FormControl placeholder='DETAILS HERE' value={requestData?.designation} />
              </Form.Group>
            </Col>
          </Row>

          <Row className='mb-3'>
            <Col xs={6}>
              <Form.Group className='inline-group'>
                <Form.Label>Penalties</Form.Label>

                <FormControl placeholder='DETAILS HERE' value={requestData?.penalties} />
              </Form.Group>
            </Col>
          </Row>

          <Row className='mb-5'>
            <Col xs={6} className='charges-list'>
              <h6>You are Hereby Charged with the following acts of Misconduct.</h6>

              <ol>
                {requestData?.charges &&
                  requestData?.charges.length > 0 &&
                  requestData?.charges.map((data) => {
                    return (
                      <li key={data?.id}>
                        <p>{data?.details}</p>
                      </li>
                    );
                  })}
              </ol>
            </Col>
          </Row>

          <Row>
            <Col className='content-footer'>
              <Button className='send-to-employee'>FORWARD TO EMPLOYEE</Button>

              <Button
                className='back-button'
                onClick={() => {
                  setShowScreen(1);
                }}
              >
                Back
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default index;
