/* eslint-disable multiline-comment-style */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

// import core & vendor packages below
import { Row, Col, Table, Button, Form } from 'react-bootstrap';

// import components below
import TableUI from 'components/UI/TableUI';

// import assets below
import './AddDoctorToPatientTable.scss';

import { useEffect, useState } from 'react';
import { ReactComponent as EditSVG } from 'assets/svg/edit.svg';
import { ReactComponent as XmarkSVG } from 'assets/svg/circle-xmark-red.svg';
import axios from 'axios';
import { toast } from 'react-toastify';

// main component
const AddDoctorToPatientTable = ({ state, setState, showModal }) => {
  const assignDoctor = async (id) => {
    const params = {
      userId: showModal.doctorId,
      patientId: id,
    };

    await axios
      .post('/patient/assign_doctor', params)
      .then((response) => {
        toast.success('Successfully assigned doctor to patient');
      })
      .catch((err) => {
        toast.warning('Doctor is already assigned to patient');
      });
  };

  return (
    <div className='add-doctor-to-patient-table'>
      <TableUI>
        <Table responsive>
          <thead>
            <tr>
              <th>Patient Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {state &&
              state.length > 0 ?
              state.map((list) => (
                <tr key={list?.id}>
                  <td>{list?.full_name}</td>
                  <td>
                    <span className='select-button'
                      onClick={() => assignDoctor(list.id)}
                    >
                      Select
                    </span>
                  </td>
                </tr>
              ))
              : <tr><td>No record found.</td></tr>
            }
          </tbody>
        </Table>
      </TableUI>
    </div>
  );
};

export default AddDoctorToPatientTable;
