/* eslint-disable */
// Import core & vendor packages below
import { Tabs, Tab } from 'react-bootstrap';

// Import components below
import TabsUI from 'components/UI/TabsUI';

// Tab components
import FormTab from './Forms';
import Archive from './Archive';
import OPDRecords from './OPDRecords';
import ActivePatient from './ActivePatient';
import DischargeSummary from './DischargeSummary';
import { TableFiltersPermission } from 'helpers/filteredPermissions';

// Main component
const MedicalRecordsTabs = (props) => {
  const { onChange, active } = props;

  const defaultTab = [
    {
      id: 5,
      title: 'Forms',
      component: FormTab,
    },
  ];

  const pageTabs = [
    {
      id: 1,
      title: 'Active Patient',
      component: ActivePatient,
      permission: 'Can View Patient List in Medical Records',
    },
    {
      id: 2,
      title: 'Discharge Summary',
      component: DischargeSummary,
      permission: 'Can View Discharge Summary in Medical Records',
    },
    {
      id: 3,
      title: 'OPD Records',
      component: OPDRecords,
      permission: 'Can View OPD Records ',
    },
    {
      id: 4,
      title: 'Archive',
      component: Archive,
      permission: 'Can View Archived Medical Records',
    },
  ];

  let tabs = TableFiltersPermission(defaultTab, pageTabs);

  tabs.sort(function (a, b) {
    return a.id - b.id;
  });

  return (
    <div className='MedicalRecordsTabs'>
      <TabsUI>
        <Tabs activeKey={active} onSelect={(eventKey) => onChange(eventKey)}>
          {tabs.map(({ title, component: Component }, idx) => (
            <Tab key={idx} eventKey={title} title={title}>
              <Component />
            </Tab>
          ))}
        </Tabs>
      </TabsUI>
    </div>
  );
};

export default MedicalRecordsTabs;
