/* eslint-disable space-before-function-paren */
/* eslint-disable prefer-const */
/* eslint-disable camelcase */

// import core & vendor packages 
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

// import components 
import Moment from 'moment';
import Table from 'components/ReactTable';
import { TableFiltersPermission } from 'helpers/filteredPermissions';
import {
  getGoodreceipt,
  selectGoodreceiptData,
  selectGoodreceiptItem,
  selectrefillRequest,
} from 'reducers/refillRequest';
import UseStatusAction from 'pages/Inventory/hooks/UseStatusAction';
import UseItemList from 'pages/Inventory/hooks/UseItemList';
import ViewRequestModal from './ViewRequestModal/ViewRequestModal';
import ViewItem from './Modals/ViewItem';

// import assets
import './Table.scss';

// main component
const GoodsRecieptsTable = ({ state, setState, setShowProfile, showProfile }) => {
  const dispatch = useDispatch();
  const datafetch = useSelector(selectGoodreceiptData);
  const inventoryState = useSelector(selectrefillRequest);
  const { getGoodreceiptlogs } = inventoryState;
  const logs = inventoryState.getGoodreceiptlogs;
  const items = useSelector(selectGoodreceiptItem);
  const [active, setActive] = useState('all');
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({ search: '', date: '' });

  const lists = [];

  const defaultList = [
    { id: 1, key: 'all', name: 'All Open' },
    {
      id: 2,
      key: 'partial',
      name: 'Partially Delivered',
      permission: '',
    },
    {
      id: 3,
      key: 'completed',
      name: 'Completed',
      permission: '',
    },
  ];
  let filterList = TableFiltersPermission(defaultList, lists);
  filterList.sort(function (a, b) {
    return a.id - b.id;
  });
  const tableColumns = React.useMemo(
    () => [
      { Header: 'Purchase Order No.', accessor: 'purchase_no' }, // accessor is the "key" in the data
      { Header: 'Purchase Order Date/Time', accessor: 'date_time' },
      { Header: 'Requesting By', accessor: 'requested_by' },
      { Header: 'No. of Items', accessor: 'number_of_items' },
      { Header: 'Po Status', accessor: 'status' },
      { Header: 'Action', accessor: 'action' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      data
        ? data?.map((dd) => ({
            id: dd?.id,
            date_time: Moment(dd?.attributes?.requested_at).format('MMMM Do YYYY, h:mm:ss a'),
            purchase_no: dd?.attributes?.request_number,
            requested_by: dd?.attributes?.requested_by,
            number_of_items: dd?.attributes?.number_of_items,
            status: UseStatusAction(dd?.attributes?.status),
            action: (
              <Button
                onClick={() => {
                  const row = data.find((id) => id.id === dd?.id);

                  setState((prev) => {
                    return { ...prev, itemID: row };
                  });
                  const { restockID } = UseItemList(row, items);

                  setState((prev) => {
                    return { ...prev, item_list: restockID, filtered_item_list: restockID };
                  });
                  setShowProfile((prevState) => {
                    return { ...prevState, isShowViewRequestModal: true };
                  });
                }}
                className='custom-actions-refill-request px-4 py-2'
                style={{ backgroundColor: '#00A8FF', border: 'none' }}
              >
                View
              </Button>
            ),
          }))
        : [],
    [data],
  );
  const handleOnClickFilter = (status) => {
    let filteredData = datafetch;
    if (status !== 'all') {
      filteredData = datafetch?.filter(
        (dd) => dd.attributes.status.replace(' ', '_').toLowerCase() === status,
      );
    }

    setData(filteredData);
    setActive(status);
  };
  const getGoodreceiptList = () => {
    dispatch(getGoodreceipt());
  };
  const resetfilter = () => {
    dispatch(getGoodreceipt());
  };
  const datefilter = () => {
    if (filter.search !== '' || filter.date !== '') {
      dispatch(
        getGoodreceipt({ restock_type: 'reorder', search: filter.search, date: filter.date }),
      );
      return;
    }
  };

  useEffect(() => {
    getGoodreceiptList();
  }, []);
  useEffect(() => {
    setData(datafetch);
    if (state?.itemID) {
      const row = datafetch.find((dd) => dd.id === state.itemID?.id);

      setState((prev) => {
        return { ...prev, itemID: row };
      });
    }
    handleOnClickFilter(state.table_active_status || 'all');
  }, [datafetch]);
  useEffect(() => {
    if (state.item_list) {
      const { restockID } = UseItemList(state.itemID, items);

      setState((prev) => {
        return { ...prev, item_list: restockID, filtered_item_list: restockID };
      });
    }
  }, [items]);

  useEffect(() => {
    const { isSuccess } = logs;
    if (isSuccess) {
      setData(datafetch);
    }
  }, [logs]);
  
  useEffect(() => {
    // console.log('loggg', state);
  }, [state]);
  
  return (
    <div className='inventory-table'>
      <Row className='mb-4'>
        <Col sm={2}>
          <Form.Group>
            <Form.Label>Request No.</Form.Label>
            <Form.Control
              type='text'
              className='bg-white'
              placeholder='Search Here'
              value={filter.search}
              onChange={(e) =>
                setFilter((prev) => {
                  return { ...prev, search: e.target.value };
                })
              }
            />
          </Form.Group>
        </Col>
        <Col sm={2}>
          <Form.Group>
            <Form.Label>Request Date</Form.Label>
            <Form.Control
              type='date'
              value={filter.date}
              className='bg-white'
              onChange={(e) =>
                setFilter((prev) => {
                  return { ...prev, date: e.target.value };
                })
              }
            />
          </Form.Group>
        </Col>
        <Col>
          <div className='buttonWrapper mb-4'>
            <Button variant='search' onClick={datefilter}>
              APPLY
            </Button>

            <Button onClick={resetfilter} variant='clear'>
              RESET
            </Button>
          </div>
        </Col>
      </Row>
      <Table
        data={tableData}
        columns={tableColumns}
        logs={getGoodreceiptlogs}
        filter={{
          top: (
            <div className='buttons' style={{ flex: 'none', whiteSpace: 'nowrap' }}>
              {filterList.map((list) => (
                <Button
                  key={list.key}
                  className={list.key === active ? 'active' : ''}
                  onClick={() => {
                    setState((prev) => {
                      return { ...prev, table_active_status: list.key };
                    });
                    handleOnClickFilter(list.key);
                  }}
                >
                  {list.name}
                </Button>
              ))}
            </div>
          ),
        }}
        pagination={['BOTTOM']}
      />
      <ViewRequestModal
        showProfile={showProfile}
        setShowProfile={setShowProfile}
        setState={setState}
        state={state}
        items={items}
      />

      <ViewItem state={state} showProfile={showProfile} setShowProfile={setShowProfile} />
    </div>
  );
};

GoodsRecieptsTable.defaultProps = {
  state: {},
  setState: () => {},
  setShowProfile: () => {},
};

GoodsRecieptsTable.propTypes = {
  state: PropTypes.object,
  setState: PropTypes.func,
  setShowProfile: PropTypes.func,
};

export default GoodsRecieptsTable;
