/* eslint-disable camelcase */
/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  Table,
  Accordion,
  Button,
  OverlayTrigger,
  Popover,
  Row,
  Col,
  Form,
  FormControl,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import ConditionalRender from 'components/ConditionalRender';
import { useDispatch, useSelector } from 'react-redux';
import { selectPatientData } from 'reducers/patientReducer';
import { getPatients } from 'reducers/thunks/patientThunks';
import { createOutpatientRecord, getMedicalFormsbyID } from 'reducers/thunks/medicalRecordThunks';
import { selectActivePatients } from 'reducers/medicalRecordReducer';
import useCreateRecordResponse from './hooks/useCreateRecordResponse';
import PatientSelection from './PatientSelection';
import PrintContent from './PrintComponents/OutPatientRecord';

const OutpatientRecord = ({ setShowScreen, setViewForm, viewForm, setIsEdit, isEdit }) => {
  const dispatch = useDispatch();
  const [isAdd, setIsAdd] = useState(false);
  const logs = useSelector(selectActivePatients);
  const [formData, setFormData] = useState({
    name_of_hospital: '',
    address: '',
    lname: '',
    fname: '',
    mname: '',
    hrn: '',
    age: '',
    sex: '',
    civil_status: '',
    religion: '',
    patientId: Math.random(),
    occupation: '',
    type: 'outpatient_record',
  });
  const [trigger, setTrigger] = useState({ trigger: false, patientID: false, recordID: false });

  const [validated, setValidated] = useState(false);
  const isEditing = isEdit === true;
  const isAdding = isAdd === true;
  const isNotEditing = isEdit === false;

  const [showPatientSelection, setShowPatientSelection] = useState(false);
  const patientSelection = useSelector(selectPatientData);

  const handleChange = (key) => (e) => {
    setFormData({
      ...formData,
      [key]: e.target.value,
    });

    return;
  };

  const textBox = (key) => {
    return (
      <FormControl
        type={`${key === 'age' ? 'number' : 'text'}`}
        disabled={!isEdit}
        style={{ width: `${isEdit ? '' : `${formData[key]?.length}ch`}` }}
        className={`${isEdit ? 'editable' : 'non-editable'} ${key === 'age' ? 'age' : ''}`}
        value={formData[key]}
        onChange={handleChange(key)}
      />
    );
  };
  // For Add
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (!form.checkValidity() === false) {
      setTrigger({ ...trigger, trigger: true });
      setIsEdit(false);
      dispatch(createOutpatientRecord({ ...formData, method: 'post' }));
      setIsAdd(false);
      return;
    }
    toast.warning('Fill up the  fields');
    setValidated(true);
  };

  // For Edit
  const saveChanges = () => {
    setTrigger({ ...trigger, trigger: true, recordID: true });
    dispatch(
      createOutpatientRecord({
        ...formData,
        method: 'put',
        type: 'outpatient_record',
      }),
    );

    setIsEdit(false);
    return;
  };
  useEffect(() => {
    if (viewForm?.patientID) {
      setTrigger({ ...trigger, patientID: true });
      const patientid = viewForm?.patientID;
      dispatch(getMedicalFormsbyID(patientid));
    }
  }, []);

  useEffect(() => {
    dispatch(getPatients());

    if (Object.keys(viewForm.data).length === 0) {
      setIsAdd(true);
      setIsEdit(true);
      return;
    }

    // for refactor: externalize below axios code in to thunk
    const recordId = viewForm?.data?.id;
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/medical_records/form/${recordId}`)
      .then((response) => {
        console.log('record info: ', response.data);
        setFormData({
          ...response.data?.data?.mr_outpatient_record,
          patientId: response.data?.data?.mr_outpatient_record?.id,
          type: 'outpatient_record',
        });
      })
      .catch((error) => console.log('error fetching record info: ', error));
  }, []);

  useEffect(() => {
    const {
      isSuccess,
      isFailed,
      isFetchViewSuccess,
      isFetchRecordSuccess,
      patientID,
      medicalRecordID,
      successData,
    } = logs;

    useCreateRecordResponse({
      isSuccess,
      isFailed,
      isFetchViewSuccess,
      isFetchRecordSuccess,
      patientID,
      medicalRecordID,
      trigger,
      setTrigger,
      setIsEdit,
      setShowScreen,
      dispatch,
    });

    /* if (isFetchRecordSuccess && trigger.recordID) {
      const data = medicalRecordID.mr_outpatient_record;
      setFormData(data);
    } */
  }, [logs]);

  const handleShowPatientSelection = () => {
    setShowPatientSelection(true);
    return;
  };

  const handleClosePatientSelection = () => {
    setShowPatientSelection(false);
    return;
  };

  const printRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current, 
    onBeforePrint: () => console.log('before printing'),
  });

  return (
    <>
      <div style={{ display: 'none' }}>
        <PrintContent ref={printRef} data={formData} viewFormType={viewForm?.type} />
      </div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className='mb-4'>
          <Col className='first-row'>
            <Form.Group className='input-group'>
              <FormControl
                placeholder='Enter Here'
                value={formData?.name_of_hospital}
                onChange={handleChange('name_of_hospital')}
                className='text-center'
                disabled={!isEdit}
              />
              <Form.Label>Name of Hospital</Form.Label>
            </Form.Group>

            <Form.Group className='input-group'>
              <FormControl
                placeholder='Enter Here'
                value={formData?.address}
                onChange={handleChange('address')}
                className='text-center'
                disabled={!isEdit}
              />
              <Form.Label>Address</Form.Label>
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col>
            <p className='small-header'>NAME ( Last, First, Middle )</p>

            <div className='row-group'>
              <Form.Group className='input-group big'>
                <Form.Label>Last Name</Form.Label>
                <FormControl
                  placeholder='Enter Here'
                  value={formData?.lname}
                  // onChange={handleChange('lname')}
                  onClick={handleShowPatientSelection}
                  required
                  disabled={!isEdit}
                />
              </Form.Group>

              <Form.Group className='input-group big'>
                <Form.Label>First Name</Form.Label>
                <FormControl
                  placeholder='Enter Here'
                  value={formData?.fname}
                  // onChange={handleChange('fname')}
                  onClick={handleShowPatientSelection}
                  required
                  disabled={!isEdit}
                />
              </Form.Group>

              <Form.Group className='input-group big'>
                <Form.Label>Middle Name</Form.Label>
                <FormControl
                  placeholder='Enter Here'
                  value={formData?.mname}
                  // onChange={handleChange('mname')}
                  onClick={handleShowPatientSelection}
                  required
                  disabled={!isEdit}
                />
              </Form.Group>

              <Form.Group className='input-group small'>
                <Form.Label>HRN</Form.Label>
                <FormControl
                  placeholder='Enter Here'
                  value={formData?.hrn}
                  onChange={handleChange('hrn')}
                  disabled={!isEdit}
                />
              </Form.Group>
            </div>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col>
            <div className='row-group'>
              <Form.Group className='input-group small'>
                <Form.Label>Age</Form.Label>
                <FormControl
                  placeholder='Enter Here'
                  value={formData?.age}
                  onChange={handleChange('age')}
                  disabled={!isEdit}
                />
              </Form.Group>

              <Form.Group className='input-group small'>
                <Form.Label>Sex</Form.Label>
                <FormControl
                  placeholder='Enter Here'
                  value={formData?.sex}
                  onChange={handleChange('sex')}
                  disabled={!isEdit}
                />
              </Form.Group>

              <Form.Group className='input-group small'>
                <Form.Label>Civil Status</Form.Label>
                <FormControl
                  placeholder='Enter Here'
                  value={formData?.civil_status}
                  onChange={handleChange('civil_status')}
                  disabled={!isEdit}
                />
              </Form.Group>

              <Form.Group className='input-group small'>
                <Form.Label>Religion</Form.Label>
                <FormControl
                  placeholder='Enter Here'
                  value={formData?.religion}
                  onChange={handleChange('religion')}
                  disabled={!isEdit}
                />
              </Form.Group>

              <Form.Group className='input-group big'>
                <Form.Label>Occupation</Form.Label>
                <FormControl
                  placeholder='Enter Here'
                  value={formData?.occupation}
                  onChange={handleChange('occupation')}
                  disabled={!isEdit}
                />
              </Form.Group>
            </div>
          </Col>
        </Row>

        <Row className='mb-5'>
          <Col>
            <div className='row-group'>
              <Form.Group className='input-group big'>
                <Form.Label>address</Form.Label>
                <FormControl
                  placeholder='Enter Here'
                  value={formData?.address}
                  onChange={handleChange('address')}
                  disabled={!isEdit}
                />
              </Form.Group>
            </div>
          </Col>
        </Row>

        <Row className='mb-5'>
          <Col className='third-row'>
            <h2>{viewForm?.type}</h2>
          </Col>
        </Row>

        <Row className=''>
          <Col className='form-buttons'>
            {/* Buttons to show for Add */}
            <ConditionalRender
              condition={isAdding}
              renderIf={
                <>
                  <Button type='submit' className='save' disabled={trigger.trigger}>
                    CREATE RECORD
                  </Button>
                  <Button
                    onClick={() => {
                      setIsEdit(false);
                      setShowScreen(0);
                    }}
                    className='cancel'
                  >
                    CANCEL
                  </Button>
                </>
              }
            />

            {/* Buttons to show for Edit/View */}
            <ConditionalRender
              condition={!isAdding}
              renderIf={
                <>
                  <ConditionalRender
                    condition={isNotEditing}
                    renderIf={
                      <>
                        <Button className='print' onClick={handlePrint}>PRINT CERTIFICATE</Button>
                        <Button
                          onClick={() => {
                            setIsEdit(true);
                          }}
                          className='edit'
                        >
                          EDIT CERTIFICATE
                        </Button>
                      </>
                    }
                  />

                  <ConditionalRender
                    condition={isEditing}
                    renderIf={
                      <>
                        <Button
                          onClick={() => {
                            saveChanges();
                          }}
                          className='save'
                          disabled={trigger.trigger}
                        >
                          SAVE CHANGES
                        </Button>
                        <Button
                          onClick={() => {
                            setIsEdit(false);
                          }}
                          className='cancel'
                        >
                          CANCEL
                        </Button>
                      </>
                    }
                  />
                </>
              }
            />
          </Col>
        </Row>
      </Form>
      <PatientSelection 
        showPatientSelection={showPatientSelection}
        handleClosePatientSelection={handleClosePatientSelection}
        patientSelection={patientSelection}
        fields={formData}
        setFields={setFormData}
      />
    </>
  );
};

export default OutpatientRecord;
