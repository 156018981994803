/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
/* eslint-disable react/jsx-indent-props */
// import core & vendor packages below
import { useEffect, useState } from 'react';
import { Row, Col, Accordion, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { Widget, FileInfo } from '@uploadcare/react-widget';
import { Country, State, City } from 'country-state-city';
import { regions, provinces, cities, barangays } from 'helpers/SelectAddress';
import FileUploadModal from './FileUpload';

import FormUI from 'components/UI/FormUI';
import AccordionUI from 'components/UI/AccordionUI';
import { ReactComponent as CirclePlusSVG } from 'assets/svg/circle-plus.svg';
import { ReactComponent as CircleMinusSVG } from 'assets/svg/circle-minus.svg';
import { ReactComponent as UserSVG } from 'assets/svg/user.svg';
import ConfirmationModal from './ConfirmationModal';
import { redirectBackToPreviousPage } from '../../../../helpers';
import { nationalityList } from 'helpers/nationality';

import { useNavigate } from 'react-router-dom';
import uuidv4 from 'react-uuid';

import { integerCharFieldRules } from 'helpers/fieldValidations';

const { inValidateInput } = integerCharFieldRules;

// main component
const FormCreateNewPatient = () => {
  const [countryList, setCountryList] = useState(Country.getAllCountries());
  const [cityList, setCityList] = useState(State.getStatesOfCountry('PH'));

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [hospitalService, setHospitalService] = useState(false);
  const [erStatus, setErStatus] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [aliasToggle, setAliasToggle] = useState(false);
  // const [patientEndorsement, setPatientEndorsement] = useState('');
  const [uploadedAvatar, setUploadedAvatar] = useState('');
  const navigate = useNavigate();
  const [MRN, setMRN] = useState(Math.floor(Math.random() * Date.now()));
  const [isNewBorn, setIsNewBorn] = useState(false);
  const [isStillBirth, setIsStillBirth] = useState(false);
  const [isDeadOnArrival, setIsDeadOnArrival] = useState(false);
  const [prefix, setPrefix] = useState('');
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [suffix, setSuffix] = useState('');
  const [sex, setSex] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [age, setAge] = useState('');
  const [religion, setReligion] = useState('');
  const [nationality, setNationality] = useState('');
  const [civilStatus, setCivilStatus] = useState('');
  const [fictitiousDateOfBirth, setFictitiousDateOfBirth] = useState('');
  const [aliasPrefix, setAliasPrefix] = useState('');
  const [aliasFirstName, setAliasFirstName] = useState('');
  const [aliasMiddleName, setAliasMiddleName] = useState('');
  const [aliasLastName, setAliasLastName] = useState('');
  const [aliasSuffix, setAliasSuffix] = useState('');
  /** Present address */
  const [presentCountry, setPresentCountry] = useState('Philippines');
  const [presentRegion, setPresentRegion] = useState('');
  const [presentProvince, setPresentProvince] = useState('');
  const [presentCity, setPresentCity] = useState('');
  const [presentZipCode, setPresentZipCode] = useState('');
  const [presentAddress1, setPresentAddress1] = useState('');
  const [presentAddress2, setPresentAddress2] = useState('');
  const [presentBrgy, setPresentBrgy] = useState('');
  /** permanent address */
  const [permanentCountry, setPermanentCountry] = useState('Philippines');
  const [permanentRegion, setPermanentRegion] = useState('');
  const [permanentProvince, setPermanentProvince] = useState('');
  const [permanentCity, setPermanentCity] = useState('');
  const [permanentZipCode, setPermanentZipCode] = useState('');
  const [permanentAddress1, setPermanentAddress1] = useState('');
  const [permanentAddress2, setPermanentAddress2] = useState('');
  const [permanentBrgy, setPermanentBrgy] = useState('');

  const [sameAsPresentAddress, setSameAsPresentAddress] = useState(true);
  const [emergencyContactPrefix, setEmergencyContactPrefix] = useState('');
  const [emergencyContactFirstName, setEmergencyContactFirstName] = useState('');
  const [emergencyContactMiddleName, setEmergencyContactMiddleName] = useState('');
  const [emergencyContactLastName, setEmergencyContactName] = useState('');
  const [emergencyContactSuffix, setEmergencyContactSuffix] = useState('');
  const [emergencyContactRelation, setEmergencyContactRelation] = useState('');
  const [emergencyContactPurpose, setEmergencyContactPurpose] = useState('');
  const [emergencyContactNumber, setEmergencyContacNumber] = useState('');
  const [occupation, setOccupation] = useState('');
  const [industry, setIndustry] = useState('');
  const [employer, setEmployer] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [MPIN, setMPIN] = useState('');
  const [philHealthFirstName, setPhilHealthFirstName] = useState('');
  const [philHealthMiddleName, setPhilHealthMiddleName] = useState('');
  const [philHealthLastName, setPhilHealthLastName] = useState('');
  const [philHealthSuffix, setPhilHealthSuffix] = useState('');
  const [philHealthMemberType, setPhilHealthMemberType] = useState('');
  const [philHealthIsOwnAccount, setPhilHealthIsOwnAccount] = useState(false);
  const [philHealthRelation, setPhilHealthRelation] = useState('');
  const [philHealthPatientFirstName, setPhilHealthPatientFirstName] = useState('');
  const [philHealthPatientMiddleName, setPhilHealthPatientMiddleName] = useState('');
  const [philHealthPatientLastName, setPhilHealthPatientLastName] = useState('');
  const [philHealthPatientSuffix, setPhilHealthPatientSuffix] = useState('');
  const [philHealthPatientDateOfBirth, setPhilHealthPatientDateOfBirth] = useState(null);
  const [philHealthPatientSex, setPhilHealthPatientSex] = useState('');
  const [philHealthPatientAdmissionDate, setPhilHealthPatientAdmissionDate] = useState(null);
  const [philHealthPatientDischargeDate, setPhilHealthPatientDischargeDate] = useState(null);
  const [philHealthPatientIcdCode, setPhilHealthPatientIcdCode] = useState('');
  const [philHealthTotalAmount, setPhilHealthTotalAmount] = useState(null);
  const [philHealthAmountClaimed, setPhilHealthAmountClaimed] = useState(null);
  const [philHealthIsFinal, setPhilHealthIsFinal] = useState(false);
  const [patientStatus, setPatientStatus] = useState('');
  const [patientOrigin, setPatientOrigin] = useState('emergency');
  const [patientOtherDetailsStatus, setPatientOtherDetailsStatus] = useState('');

  const savePatient = () => {
    if (isStillBirth) {
      setPatientStatus('still_birth');
    } else if (isDeadOnArrival) {
      setPatientStatus('dead_on_arrival');
    } else {
      setPatientStatus('emergency');
    }
    setOpenModal(true);
  };

  const saveAndAdmit = () => {
    if (isStillBirth) {
      setPatientStatus('still_birth');
    } else if (isDeadOnArrival) {
      setPatientStatus('dead_on_arrival');
    } else {
      setPatientStatus('emergency');
    }
    setOpenModal(true);
  };

  /** Patient Identity Form Start */
  const [identityDocumentDetails, setIdentityDocumentDetails] = useState([
    { uuid: uuidv4(), id_type: '', id_number: '', link: '', allow_remove: false },
  ]);

  const handleAddIdentityDocForm = () => {
    setIdentityDocumentDetails([
      ...identityDocumentDetails,
      {
        uuid: uuidv4(),
        id_type: '',
        id_number: '',
        link: '',
        allow_remove: true,
      },
    ]);
  };

  const handleRemoveIdentityDocForm = (uuid) => {
    const values = [...identityDocumentDetails];
    values.splice(
      values.findIndex((value) => value.uuid === uuid),
      1,
    );
    setIdentityDocumentDetails(values);
  };

  const handleChangeIdentifyDocFormInput = (uuid, event) => {
    identityDocumentDetails.map((i) => {
      if (uuid === i.uuid) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });

    setIdentityDocumentDetails(identityDocumentDetails);
  };

  const uploadFileChange = (value, info) => {
    switch (value.type) {
      case 'identityDoc':
        identityDocumentDetails.map((i) => {
          if (value.uuid === i.uuid) {
            i['file_uuid'] = info.uuid;
            i['link'] = info.cdnUrl;
          }
          return i;
        });

        setIdentityDocumentDetails(identityDocumentDetails);
        break;

      default:
        break;
    }
  };

  const uploadFileSelect = (value, file) => {
    if (!file) {
      switch (value.type) {
        case 'identityDoc':
          identityDocumentDetails.map((i) => {
            if (value.uuid === i.uuid) {
              i['link'] = '';
              deleteUploadcareFile(value.file_uuid);
            }
            return i;
          });

          setIdentityDocumentDetails(identityDocumentDetails);
          break;

        default:
          break;
      }
      return;
    }
  };

  const deleteUploadcareFile = (uuid) => {
    const URI = `${process.env.REACT_APP_UPLOADCARE_API_BASE_URL}/files/${uuid}/`;
    const options = {
      method: 'DELETE',
      headers: {
        Authorization: `Uploadcare.Simple ${process.env.REACT_APP_UPLOADCARE_API_PUBLIC_KEY}:${process.env.REACT_APP_UPLOADCARE_API_SECRET_KEY}`,
      },
    };

    fetch(URI, options);
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const fileSizeLimit = (bytes) => {
    return (fileInfo) => {
      if (fileInfo.size > bytes) {
        alert(`File is too big! File limit is ${formatBytes(bytes)}`);
        throw new Error(`File is too big! File limit is ${formatBytes(bytes)}`);
      }
    };
  };

  const validators = [fileSizeLimit(1024 * 1024 * 3)];

  /** Contact Details start */
  const [inputFields, setInputFields] = useState([{ uuid: uuidv4(), type: '', details: '' }]);

  const handleAddFields = () => {
    setInputFields([...inputFields, { uuid: uuidv4(), type: '', details: '' }]);
  };

  const handleRemoveFields = (uuid) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.uuid === uuid),
      1,
    );
    setInputFields(values);
  };

  const handleChangeInput = (uuid, event) => {
    const newInputFields = inputFields.map((i) => {
      if (uuid === i.uuid) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
  };

  /** Family Details start */
  const [familyDetails, setFamilyDetails] = useState([]);

  const handleAddFamilyForm = () => {
    setFamilyDetails([
      ...familyDetails,
      {
        uuid: uuidv4(),
        prefix: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        suffix: '',
        sex: '',
        relationship: '',
        purpose: '',
        contact_number: '',
        MRN: '',
      },
    ]);
  };

  const handleRemoveFamilyForm = (uuid) => {
    const values = [...familyDetails];
    values.splice(
      values.findIndex((value) => value.uuid === uuid),
      1,
    );
    setFamilyDetails(values);
  };

  const handleChangeFamilyFormInput = (uuid, event) => {
    const newFamilyDetails = familyDetails.map((i) => {
      if (uuid === i.uuid) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });

    setFamilyDetails(newFamilyDetails);
  };

  /** Allergies */
  const [allergies, setAllergies] = useState([
    {
      uuid: uuidv4(),
      allergy: '',
    },
  ]);

  const addFieldsAllergies = () => {
    setAllergies([
      ...allergies,
      {
        uuid: uuidv4(),
        allergy: '',
      },
    ]);
  };

  const removeFieldsAllergies = (uuid) => {
    const values = [...allergies];
    values.splice(
      values.findIndex((value) => value.uuid === uuid),
      1,
    );
    setAllergies(values);
  };

  const handleChangeAllergies = (uuid, event) => {
    const newFields = allergies.map((i) => {
      if (uuid === i.uuid) {
        i[event.target.name] = event.target.value.replace(/[^a-z]/gi, '');
      }
      return i;
    });
    setAllergies(newFields);
  };

  /** Other Details start */
  const [inputOtherFields, setInputOtherFields] = useState([
    { uuid: uuidv4(), endorsement: '', details: '' },
  ]);

  const handleAddOtherFields = () => {
    setInputOtherFields([...inputOtherFields, { uuid: uuidv4(), endorsement: '', details: '' }]);
  };

  const handleRemoveOtherFields = (uuid) => {
    const values = [...inputOtherFields];
    values.splice(
      values.findIndex((value) => value.uuid === uuid),
      1,
    );
    setInputOtherFields(values);
  };

  const handleChangeOtherInput = (uuid, event) => {
    const newInputOtherFields = inputOtherFields.map((i) => {
      if (uuid === i.uuid) {
        i[event.target.name] = event.target.value.replace(/[^a-z]/gi, '');
      }
      return i;
    });
    setInputOtherFields(newInputOtherFields);
  };

  const getAge = (dateString) => {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    setAge(age);
  };

  const handleCheckBoxValue = (event, field) => {
    switch (field) {
      case 'newBorn':
        setIsNewBorn(event.target.checked);
        break;

      case 'fictitiousDateOfBirth':
        setFictitiousDateOfBirth(event.target.checked);
        break;

      case 'sameAsPresentAddress':
        setSameAsPresentAddress(event.target.checked);
        break;

      case 'philHealthMemberType':
        setPhilHealthMemberType(event.target.checked);
        break;
      case 'doa':
        setIsDeadOnArrival(event.target.checked);
        break;
      case 'stillBirth':
        setIsStillBirth(event.target.checked);
        break;
    }
  };

  const handleDropdownValue = (event, field) => {
    switch (field) {
      case 'nationality':
        setNationality(event.target.value);
        break;
      case 'civil_status':
        setCivilStatus(event.target.value);
        break;
      case 'er_status':
        setErStatus(event.target.value);
        break;
      case 'hospital_service':
        setHospitalService(event.target.value);
        break;
      case 'prefix':
        setPrefix(event.target.value);
        break;

      case 'sex':
        setSex(event.target.value);
        break;

      case 'aliasPrefix':
        setAliasPrefix(event.target.value);
        break;

      case 'presentCountry':
        setPresentCountry(event.target.value);
        break;

      case 'presentRegion':
        setPresentRegion(event.target.value);
        break;

      case 'presentCity':
        setPresentCity(event.target.value);
        break;

      case 'presentBrgy':
        setPresentBrgy(event.target.value);
        break;

      case 'permanentCountry':
        setPermanentCountry(event.target.value);
        break;

      case 'permanentRegion':
        setPermanentRegion(event.target.value);
        break;

      case 'permanentCity':
        setPermanentCity(event.target.value);
        break;

      case 'permanentBrgy':
        setPermanentBrgy(event.target.value);
        break;

      case 'emergencyContactprefix':
        setEmergencyContactPrefix(event.target.value);
        break;

      case 'emergencyContactRelation':
        setEmergencyContactRelation(event.target.value);
        break;

      case 'emergencyContactPurpose':
        setEmergencyContactPurpose(event.target.value);
        break;

      case 'philHealthMemberType':
        setPhilHealthMemberType(event.target.value);
        break;

      case 'philHealthSex':
        setPhilHealthPatientSex(event.target.value);
        break;

      case 'philHealthIcdCode':
        setPhilHealthPatientIcdCode(event.target.value);
        break;

      case 'patientOtherDetailsStatus':
        setPatientOtherDetailsStatus(event.target.value);
        break;
    }
  };

  const occupationJson = [
    {
      occupation: occupation,
      industry: industry,
      employer: employer,
      employment_start_date: startDate,
      employment_end_date: endDate,
    },
  ];

  const emergencyContact = [
    {
      prefix: emergencyContactPrefix,
      first_name: emergencyContactFirstName,
      middle_name: emergencyContactMiddleName,
      last_name: emergencyContactLastName,
      suffix: emergencyContactSuffix,
      relationship: emergencyContactRelation,
      purpose: emergencyContactPurpose,
      contact_number: emergencyContactNumber,
      MRN: MRN,
    },
  ];

  const philHealth = [
    {
      MPIN: MPIN,
      first_name: philHealthFirstName,
      middle_name: philHealthMiddleName,
      last_name: philHealthLastName,
      suffix: philHealthSuffix,
      member_type: philHealthMemberType,
      is_own_account: philHealthIsOwnAccount,
      relation_to_member: philHealthRelation,
      patient_first_name: philHealthPatientFirstName,
      patient_middlename_name: philHealthPatientMiddleName,
      patient_last_name: philHealthPatientLastName,
      patient_suffix: philHealthPatientSuffix,
      patient_date_of_birth: philHealthPatientDateOfBirth,
      patient_sex: philHealthPatientSex,
      admission_date: philHealthPatientAdmissionDate,
      discharge_date: philHealthPatientDischargeDate ? philHealthPatientDischargeDate : null,
      icd_code: philHealthPatientIcdCode,
      actual_amount: philHealthTotalAmount,
      amount_claimed: philHealthAmountClaimed,
      is_final: philHealthIsFinal,
    },
  ];

  const params = {
    er_status: erStatus,
    hospital_service: hospitalService,
    MRN: MRN,
    patient_origin: patientOrigin,
    is_newborn: isNewBorn,
    is_dead_on_arrival: isDeadOnArrival,
    prefix: prefix,
    first_name: firstName,
    middle_name: middleName,
    last_name: lastName,
    suffix: suffix,
    sex: sex,
    date_of_birth: dateOfBirth,
    age: age,
    is_fictitious_date_of_birth: fictitiousDateOfBirth,
    nationality: nationality,
    civil_status: civilStatus,
    religion: religion,
    alias_prefix: aliasPrefix,
    alias_first_name: aliasFirstName,
    alias_middle_name: aliasMiddleName,
    alias_last_name: aliasLastName,
    alias_suffix: aliasSuffix,
    patient_status: patientStatus,
    patient_present_address: {
      present_country: presentCountry,
      present_region: presentRegion,
      patient_province: presentProvince,
      present_city: presentCity,
      present_zip_code: presentZipCode,
      present_address1: presentAddress1,
      present_address2: presentAddress2,
      present_brgy: presentBrgy,
    },
    patient_permanent_address: {
      permanent_country: sameAsPresentAddress ? presentCountry : permanentCountry,
      permanent_region: sameAsPresentAddress ? presentRegion : permanentRegion,
      patient_province: sameAsPresentAddress ? presentProvince : permanentProvince,
      permanent_city: sameAsPresentAddress ? presentCity : permanentCity,
      permanent_zip_code: sameAsPresentAddress ? presentZipCode : permanentZipCode,
      permanent_address1: sameAsPresentAddress ? presentAddress1 : permanentAddress1,
      permanent_address2: sameAsPresentAddress ? presentAddress2 : permanentAddress2,
      permanent_brgy: sameAsPresentAddress ? presentBrgy : permanentBrgy,
    },
    contact_details: inputFields,
    allergies: allergies,
    other_details: inputOtherFields,
    identity_documents: identityDocumentDetails,
    emergency_contact: emergencyContact,
    occupation: occupationJson,
    family: familyDetails,
    phil_health: philHealth,
  };

  const [regionData, setRegion] = useState([]);
  const [provinceData, setProvince] = useState([]);
  const [cityData, setCity] = useState([]);
  const [barangayData, setBarangay] = useState([]);

  const region = () => {
    const response = regions();
    setRegion(response);
  };

  const province = (e) => {
    setPresentRegion(e.target.selectedOptions[0].text);

    const response = provinces(e.target.value);
    setProvince(response);
    setCity([]);
    setBarangay([]);
  };

  const city = (e) => {
    setPresentProvince(e.target.selectedOptions[0].text);

    const response = cities(e.target.value);
    setCity(response);
    setBarangay([]);
  };

  const barangay = (e) => {
    setPresentCity(e.target.selectedOptions[0].text);

    const response = barangays(e.target.value);
    setBarangay(response);
  };

  const brgy = (e) => {
    setPresentBrgy(e.target.selectedOptions[0].text);
  };

  const [permRegionData, setPermRegion] = useState([]);
  const [permProvinceData, setPermProvince] = useState([]);
  const [permCityData, setPermCity] = useState([]);
  const [permBarangayData, setPermBarangay] = useState([]);

  const permRegion = () => {
    const response = regions();

    setPermRegion(response);
  };

  const permProvince = (e) => {
    setPermanentRegion(e.target.selectedOptions[0].text);

    const response = provinces(e.target.value);
    setPermProvince(response);
    setPermCity([]);
    setPermBarangay([]);
  };

  const permCity = (e) => {
    setPermanentProvince(e.target.selectedOptions[0].text);

    const response = cities(e.target.value);
    setPermBarangay([]);
    setPermCity(response);
  };

  const permBarangay = (e) => {
    setPermanentCity(e.target.selectedOptions[0].text);

    const response = barangays(e.target.value);
    setPermBarangay(response);
  };

  const permBrgy = (e) => {
    setPermanentBrgy(e.target.selectedOptions[0].text);
  };

  useEffect(() => {
    region();
    permRegion();
  }, []);

  const [validated, setValidated] = useState(false);

  return (
    <div className='formCreateNewPatient'>
      <FormUI>
        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => {
            e.preventDefault();
            const ButtonName = e.nativeEvent.submitter.name;
            const form = e.currentTarget;

            if (form.checkValidity() === true) {
              if (ButtonName === 'saveAdmit') {
                saveAndAdmit();
              }

              if (ButtonName === 'savePatient') {
                savePatient();
              }
            }

            setValidated(true);
          }}
        >
          <div className='formCreateNewPatient__header'>
            <h4>Create New Patient</h4>
            <Button
              onClick={() => setShowUploadModal(true)}
              className='btn btn-click me-3 '
              style={{
                marginLeft: 10,
              }}
            >
              <small>UPLOAD FILES</small>
            </Button>
          </div>
          <div className='avatar__container'>
            <div className='svg__container'>
              {!uploadedAvatar && <UserSVG />}
              {uploadedAvatar && (
                <div
                  className='uploadedAvatar'
                  style={{ backgroundImage: `url(${uploadedAvatar.url})` }}
                ></div>
              )}
            </div>
            {/* <Form.Group className='mt-3'>
              <Form.Label>
                <div className='fakeInput'>
                  {!uploadedAvatar && 'File'}
                  {uploadedAvatar && uploadedAvatar.fileName}
                  <span>Choose</span>
                </div>
                <Form.Control
                  type='file'
                  onChange={(e) =>
                    setUploadedAvatar({
                      url: URL.createObjectURL(e.target.files[0]),
                      fileName: e.target.value.split('C:\\fakepath\\').pop(),
                    })
                  }
                />
              </Form.Label>
            </Form.Group> */}
          </div>
          <AccordionUI>
            <Accordion defaultActiveKey='0'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Hospital Service</Accordion.Header>
                <Accordion.Body>
                  <FormUI>
                    <Row>
                      <Col md={4}>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Triage Categorization<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select
                            required
                            onChange={(event) => handleDropdownValue(event, 'er_status')}
                          >
                            <option>-Select-</option>
                            <option value='Resuscitation'>RESUSCITATION</option>
                            <option value='Emergent'>EMERGENT</option>
                            <option value='Urgent'>URGENT</option>
                            <option value='Less_Urgent'>LESS URGENT</option>
                            <option value='Non_Urgent'>NON-URGENT</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Hospital Service<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select
                            required
                            onChange={(event) => handleDropdownValue(event, 'hospital_service')}
                          >
                            <option value='all'>-Select-</option>
                            <option value='ent'>ENT</option>
                            <option value='medicine'>Medicine</option>
                            <option value='ob_gyne'>OB-GYNE</option>
                            <option value='urology'>Urology</option>
                            <option value='cardiology'>Cardiology</option>
                            <option value='pulmonary Medicine'>Pulmonary Medicine</option>
                            <option value='opthalmology'>Opthalmology</option>
                            <option value='surgery'>Surgery</option>
                            <option value='orthopedic Surgery'>Orthopedic Surgery</option>
                            <option value='endocrinology'>Endocrinology</option>
                            <option value='pediatrics'>Pediatrics</option>
                            <option value='dermatology'>Dermatology</option>
                            <option value='oncology'>Oncology</option>
                            <option value='neurology'>Neurology</option>
                            <option value='gastroenterology'>Gastroenterology</option>
                            <option value='internal_medicine'>Internal Medicine</option>
                            {/* <option value='medico_legal'>Medico Legal</option> */}
                            <option value='nephrology'>Nephrology</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                  </FormUI>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion defaultActiveKey='0'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Patient Demographics</Accordion.Header>
                <Accordion.Body>
                  <FormUI>
                    <Row className='d-flex align-items-center'>
                      <Col lg={2}>
                        <Form.Group className='mb-3'>
                          <Form.Label>MRN</Form.Label>
                          <Form.Control
                            style={{ maxWidth: '320px' }}
                            type='text'
                            readOnly
                            value={MRN}
                            placeholder='Enter Here'
                            onChange={(event) => {
                              setMRN(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      {/* <Col lg={2}>
                        <Form.Group className='mt-3 mb-3'>
                          <Form.Check
                            type='checkbox'
                            label='New Born'
                            onChange={(event) => handleCheckBoxValue(event, 'newBorn')}
                          />
                        </Form.Group>
                      </Col> */}
                      {/* {isNewBorn && (
                        <Col lg={2}>
                          <Form.Group className='mt-3 mb-3'>
                            <Form.Check
                              type='checkbox'
                              label='Stillbirth'
                              disabled={isDeadOnArrival}
                              onChange={(event) => handleCheckBoxValue(event, 'stillBirth')}
                            />
                          </Form.Group>
                        </Col>
                      )} */}
                      {/* <Col lg={2}>
                        <Form.Group className='mt-3 mb-3'>
                          <Form.Check
                            type='checkbox'
                            label='Dead on Arrival'
                            disabled={isStillBirth}
                            onChange={(event) => handleCheckBoxValue(event, 'doa')}
                          />
                        </Form.Group>
                      </Col> */}
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>Prefix/Title</Form.Label>
                          <Form.Select
                            onChange={(event) => handleDropdownValue(event, 'prefix')}
                            disabled={aliasToggle}
                          >
                            <option value=''>-</option>
                            <option value='mr'>Mr.</option>
                            <option value='ms'>Ms.</option>
                            <option value='mrs'>Mrs.</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            First Name<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter First Name'
                            value={firstName}
                            onChange={(event) => {
                              setFirstName(event.target.value.replace(/[^a-z]/gi, ''));
                            }}
                            disabled={aliasToggle}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Middle Name<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter Middle Name'
                            onChange={(event) => {
                              setMiddleName(event.target.value.replace(/[^a-z]/gi, ''));
                            }}
                            disabled={aliasToggle}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Last Name<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter Last Name'
                            value={lastName}
                            onChange={(event) => {
                              setLastName(event.target.value.replace(/[^a-z]/gi, ''));
                            }}
                            disabled={aliasToggle}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>Suffix</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter Suffix'
                            value={suffix}
                            onChange={(event) => {
                              setSuffix(event.target.value.replace(/[^a-z]/gi, ''));
                            }}
                            disabled={aliasToggle}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Sex<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select
                            disabled={aliasToggle}
                            required
                            onChange={(event) => handleDropdownValue(event, 'sex')}
                          >
                            <option value=''>-</option>
                            <option value='male'>Male</option>
                            <option value='female'>Female</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Date of Birth<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            disabled={aliasToggle}
                            required
                            type='date'
                            onChange={(event) => {
                              setDateOfBirth(event.target.value);
                              getAge(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Age<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            disabled={aliasToggle}
                            value={age}
                            onChange={(event) => {
                              setAge(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      {/* <Col>
                        <Form.Group className='d-flex h-100 mb-3'>
                          <Form.Check
                            type='checkbox'
                            label='Fictitious Date of Birth'
                            onChange={(event) =>
                              handleCheckBoxValue(event, 'fictitiousDateOfBirth')
                            }
                          />
                        </Form.Group>
                      </Col> */}
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Nationality<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select
                            required
                            onChange={(event) => handleDropdownValue(event, 'nationality')}
                          >
                            <option value=''>-</option>
                            {nationalityList.map((data) => {
                              return (
                                <option key={data.label} value={data.label}>
                                  {data.label}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Civil Status<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select
                            required
                            onChange={(event) => handleDropdownValue(event, 'civil_status')}
                          >
                            <option value=''>-</option>
                            <option value='Single'>Single</option>
                            <option value='Married'>Married</option>
                            <option value='Separated'>Separated</option>
                            <option value='Annulled'>Annulled</option>
                            <option value='Widowed'>Widowed</option>
                            <option value='Widower'>Widower</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Religion<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter Here'
                            value={religion}
                            onChange={(event) => {
                              setReligion(event.target.value.replace(/[^a-z]/gi, ''));
                            }}
                            disabled={aliasToggle}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className='d-flex nowrap align-items-center mb-3'>
                          <Button
                            className={aliasToggle && 'active'}
                            variant='alias'
                            onClick={() => setAliasToggle((prevState) => !prevState)}
                          >
                            <span className='aliasRound'></span>
                          </Button>
                          <span> Alias / Fictitious Name</span>
                        </div>
                      </Col>
                      {aliasToggle && (
                        <Row>
                          {/* <Col>
                                                        <Form.Group className='mb-3'>
                                                            <Form.Label>Prefix/Title</Form.Label>
                                                            <Form.Select
                                                                onChange={(event) => handleDropdownValue(event, 'aliasPrefix')}
                                                            >
                                                                <option value=''>-</option>
                                                                <option value='male'>Mr.</option>
                                                                <option value='female'>Mrs.</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col> */}
                          <Col>
                            <Form.Group className='mb-3'>
                              <Form.Label>
                                First Name<span className='asterisk'>*</span>
                              </Form.Label>
                              <Form.Control
                                required
                                type='text'
                                placeholder='Enter First Name'
                                value={aliasFirstName}
                                onChange={(event) => {
                                  setAliasFirstName(event.target.value.replace(/[^a-z]/gi, ''));
                                }}
                              />
                            </Form.Group>
                          </Col>
                          {/* <Col>
                                                        <Form.Group className='mb-3'>
                                                            <Form.Label>
                                                                Middle Name<span className='asterisk'>*</span>
                                                            </Form.Label>
                                                            <Form.Control requied
                                                                type='text'
                                                                placeholder='Enter Middle Name'
                                                                onChange={(event) => {
                                                                    setAliasMiddleName(event.target.value);
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col> */}
                          {/* <Col>
                            <Form.Group className='mb-3'>
                              <Form.Label>
                                Last Name<span className='asterisk'>*</span>
                              </Form.Label>
                              <Form.Control
                                required
                                type='text'
                                placeholder='Enter Last Name'
                                value={aliasLastName}
                                onChange={(event) => {
                                  setAliasLastName(event.target.value.replace(/[^a-z]/gi, ''));
                                }}
                              />
                            </Form.Group>
                          </Col> */}
                          {/* <Col>
                                                        <Form.Group className='mb-3'>
                                                            <Form.Label>Suffix</Form.Label>
                                                            <Form.Control
                                                                type='text'
                                                                placeholder='Enter Suffix'
                                                                onChange={(event) => {
                                                                    setAliasSuffix(event.target.value);
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col> */}
                        </Row>
                      )}
                    </Row>
                  </FormUI>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion defaultActiveKey='0'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Patient Address Details</Accordion.Header>
                <Accordion.Body>
                  <FormUI>
                    <Row className='d-flex align-items-center'>
                      <Col lg={2}>
                        <h6 className='mb-3 fw-bold'>Present Address</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Country<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select required value='Philippines'>
                            <option value=''>Select Country</option>
                            {countryList.map((item, key) => {
                              return (
                                <option key={key} value={item.name}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Region<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select
                            required
                            // onChange={(event) => handleDropdownValue(event, 'presentCity')}
                            onChange={province}
                            onSelect={region}
                          >
                            <option value=''>-</option>
                            {regionData &&
                              regionData.length > 0 &&
                              regionData.map((item, key) => {
                                return (
                                  <option key={item.region_code} value={item.region_code}>
                                    {item.region_name}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Province<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select
                            required
                            // onChange={(event) => handleDropdownValue(event, 'presentCity')}
                            onChange={city}
                          >
                            <option value=''>-</option>
                            {provinceData &&
                              provinceData.length > 0 &&
                              provinceData.map((item, key) => {
                                return (
                                  <option key={item.province_code} value={item.province_code}>
                                    {item.province_name}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            City<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select
                            required
                            // onChange={(event) => handleDropdownValue(event, 'presentCity')}
                            onChange={barangay}
                          >
                            <option value=''>-</option>
                            {cityData &&
                              cityData.length > 0 &&
                              cityData.map((item, key) => {
                                return (
                                  <option key={item.city_code} value={item.city_code}>
                                    {item.city_name}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Barangay<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select
                            required
                            // onChange={(event) => handleDropdownValue(event, 'presentCity')}
                            onChange={brgy}
                          >
                            <option value=''>-</option>
                            {barangayData &&
                              barangayData.length > 0 &&
                              barangayData.map((item, key) => {
                                return (
                                  <option key={item.brgy_code} value={item.brgy_code}>
                                    {item.brgy_name}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>Zip Code</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter Here'
                            onChange={(event) => {
                              setPresentZipCode(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Address Line 1<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            requied
                            type='text'
                            placeholder='Enter Here'
                            onChange={(event) => {
                              setPresentAddress1(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      {/* <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Subdivision/Village<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            requied
                            type='text'
                            placeholder='Enter Here'
                            onChange={(event) => {
                              setPresentAddress2(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col> */}
                    </Row>
                    {/* <Row className='d-flex align-items-center'>
                      <Col>
                        <div className='d-flex align-items-center my-3'>
                          <span className='me-3 fw-bold'>Permanent Address</span>
                          <Form.Check
                            type='checkbox'
                            label='Same as Present Address'
                            onChange={(event) => handleCheckBoxValue(event, 'sameAsPresentAddress')}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Country<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select requied value='Philippines'>
                            <option value=''>Select Country</option>
                            {countryList.map((item, key) => {
                              return (
                                <option key={key} className='' value={item.name}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Region<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select requied onChange={permProvince} onSelect={permRegion}>
                            <option value=''>{sameAsPresentAddress ? presentRegion : ''}</option>
                            {permRegionData &&
                              permRegionData.length > 0 &&
                              permRegionData.map((item, key) => {
                                return (
                                  <option key={item.region_code} value={item.region_code}>
                                    {item.region_name}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Province<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select
                            requied
                            // onChange={(event) => handleDropdownValue(event, 'presentCity')}
                            onChange={permCity}
                          >
                            <option value=''>{sameAsPresentAddress ? presentProvince : ''}</option>
                            {permProvinceData &&
                              permProvinceData.length > 0 &&
                              permProvinceData.map((item, key) => {
                                return (
                                  <option key={item.province_code} value={item.province_code}>
                                    {item.province_name}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            City<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select
                            requied
                            // onChange={(event) => handleDropdownValue(event, 'presentCity')}
                            onChange={permBarangay}
                          >
                            <option value=''>{sameAsPresentAddress ? presentCity : ''}</option>
                            {permCityData &&
                              permCityData.length > 0 &&
                              permCityData.map((item, key) => {
                                return (
                                  <option key={item.city_code} value={item.city_code}>
                                    {item.city_name}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Barangay<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select
                            requied
                            // onChange={(event) => handleDropdownValue(event, 'presentCity')}
                            onChange={permBrgy}
                          >
                            <option value=''>{sameAsPresentAddress ? presentBrgy : ''}</option>
                            {permBarangayData &&
                              permBarangayData.length > 0 &&
                              permBarangayData.map((item, key) => {
                                return (
                                  <option key={item.brgy_code} value={item.brgy_code}>
                                    {item.brgy_name}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>Zip Code</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter Here'
                            value={sameAsPresentAddress ? presentZipCode : permanentZipCode}
                            onChange={(event) => {
                              setPermanentZipCode(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Address Line 1<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            requied
                            type='text'
                            placeholder='Enter Here'
                            value={sameAsPresentAddress ? presentAddress1 : permanentAddress1}
                            onChange={(event) => {
                              setPermanentAddress1(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Subdivision/Village<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            requied
                            type='text'
                            placeholder='Enter Here'
                            value={sameAsPresentAddress ? presentAddress2 : permanentAddress2}
                            onChange={(event) => {
                              setPermanentAddress2(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row> */}
                  </FormUI>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Row>
              <Col>
                <Accordion defaultActiveKey='0'>
                  <Accordion.Item eventKey='0'>
                    <Accordion.Header>Patient&#39;s Contact Details</Accordion.Header>
                    <Accordion.Body>
                      <FormUI>
                        <Form.Group>
                          <Row>
                            <Col lg='5'>
                              <Form.Label>
                                Contact Type<span className='asterisk'>*</span>
                              </Form.Label>
                            </Col>
                            <Col lg='5'>
                              <Form.Label>
                                Contact Details<span className='asterisk'>*</span>
                              </Form.Label>
                            </Col>
                          </Row>
                        </Form.Group>
                        <Form.Group>
                          {inputFields.map((inputField, key, { length }) => (
                            <div key={inputField.uuid}>
                              <Row>
                                <Col lg='5'>
                                  <Form.Group className='mb-3'>
                                    <Form.Select
                                      required
                                      name='type'
                                      onChange={(event) =>
                                        handleChangeInput(inputField.uuid, event)
                                      }
                                    >
                                      <option value=''>- Select -</option>
                                      <option value='Mobile Number'>Mobile Number</option>
                                      <option value='Landline Number'>Landline Number</option>
                                    </Form.Select>
                                  </Form.Group>
                                </Col>

                                <Col lg='5'>
                                  <Form.Group className='mb-3'>
                                    <Form.Control
                                      required
                                      type='text'
                                      placeholder='Enter contact details'
                                      name='details'
                                      maxlength='11'
                                      // value={inputField.contactDetails}
                                      onChange={(event) =>
                                        handleChangeInput(inputField.uuid, event)
                                      }
                                      pattern='[0-9\b]{11}'
                                      isInvalid={inValidateInput(inputField.details)}
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                      {inputField.details === '' ? 'Contact details is required' : 'Contact details should be numeric and has 11 characters'}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Col>

                                <Col lg='auto' className='d-flex align-items-center'>
                                  {key + 1 === length ? (
                                    <Button
                                      className='mb-3'
                                      variant='transparent'
                                      onClick={handleAddFields}
                                    >
                                      <CirclePlusSVG />
                                    </Button>
                                  ) : (
                                    <Button
                                      className='mb-3'
                                      variant='transparent'
                                      onClick={handleRemoveFields}
                                    >
                                      <CircleMinusSVG />
                                    </Button>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          ))}
                        </Form.Group>
                      </FormUI>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
              <Col>
                <Accordion defaultActiveKey='0'>
                  <Accordion.Item eventKey='0'>
                    <Accordion.Header>Patient&#39;s Other Details</Accordion.Header>
                    <Accordion.Body>
                      <FormUI>
                        <Form.Group>
                          {inputOtherFields.map((inputOtherField, key, { length }) => (
                            <div key={inputOtherField.uuid}>
                              <Row>
                                <Col lg='5'>
                                  <Form.Group className='mb-3'>
                                    <Form.Label>
                                      Patient Endorsement<span className='asterisk'>*</span>
                                    </Form.Label>
                                    <Form.Control
                                      type='text'
                                      placeholder='Enter patient endorsement'
                                      name='endorsement'
                                      value={inputOtherField.endorsement}
                                      onChange={(event) =>
                                        handleChangeOtherInput(inputOtherField.uuid, event)
                                      }
                                    />
                                  </Form.Group>
                                </Col>

                                {/* <Col lg='5'>
                                  <Form.Group className='mb-3'>
                                    <Form.Label>
                                      Patient Details<span className='asterisk'>*</span>
                                    </Form.Label>
                                    <Form.Control
                                      type='text'
                                      placeholder='Enter patient details'
                                      name='details'
                                      value={inputOtherField.details}
                                      onChange={(event) =>
                                        handleChangeOtherInput(inputOtherField.uuid, event)
                                      }
                                    />
                                  </Form.Group>
                                </Col> */}

                                <Col lg='auto' className='d-flex align-items-center'>
                                  {key + 1 === length ? (
                                    <Button
                                      className='mb-3'
                                      variant='transparent'
                                      onClick={handleAddOtherFields}
                                    >
                                      <CirclePlusSVG />
                                    </Button>
                                  ) : (
                                    <Button
                                      className='mb-3'
                                      variant='transparent'
                                      onClick={handleRemoveOtherFields}
                                    >
                                      <CircleMinusSVG />
                                    </Button>
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col lg='10'>
                                  <Form.Group className='mb-3'>
                                    <Form.Label>
                                      Status<span className='asterisk'>*</span>
                                    </Form.Label>
                                    <Form.Select
                                      name='status'
                                      onChange={(event) =>
                                        handleChangeOtherInput(inputOtherField.uuid, event)
                                      }
                                    >
                                      <option value=''>-</option>
                                      <option value='walkin'>Walk-In</option>
                                      <option value='transferredFromOtherFacility'>
                                        Transferred from Other Facility
                                      </option>
                                      <option value='transferredToOtherFacility'>
                                        Transferred to Other Facility
                                      </option>
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                          ))}
                        </Form.Group>
                      </FormUI>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
            {/* <Row>
              <Col>
                <Accordion defaultActiveKey='0'>
                  <Accordion.Item eventKey='0'>
                    <Accordion.Header>Allergies</Accordion.Header>
                    <Accordion.Body>
                      <FormUI>
                        <Form.Group>
                          <Row>
                            <Col lg='4'>
                              <Form.Label>
                                Select Allergy<span className='asterisk'>*</span>
                              </Form.Label>
                            </Col>
                          </Row>
                        </Form.Group>
                        <Form.Group>
                          {allergies.map((inputField, key, { length }) => (
                            <div key={inputField.uuid}>
                              <Row>
                                <Col lg='5'>
                                  <Form.Group className='mb-3'>
                                    <Form.Control
                                      type='text'
                                      placeholder='Search...'
                                      name='allergy'
                                      value={inputField.allergy}
                                      onChange={(event) =>
                                        handleChangeAllergies(inputField.uuid, event)
                                      }
                                    />
                                  </Form.Group>
                                </Col>

                                <Col lg='auto' className='d-flex align-items-center'>
                                  {key + 1 === length ? (
                                    <Button
                                      className='mb-3'
                                      variant='transparent'
                                      onClick={addFieldsAllergies}
                                    >
                                      <CirclePlusSVG />
                                    </Button>
                                  ) : (
                                    <Button
                                      className='mb-3'
                                      variant='transparent'
                                      onClick={removeFieldsAllergies}
                                    >
                                      <CircleMinusSVG />
                                    </Button>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          ))}
                        </Form.Group>
                      </FormUI>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row> */}
            {/* <Accordion defaultActiveKey='0'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Patient&#39;s Identity Documents</Accordion.Header>
                <Accordion.Body>
                  <FormUI>
                    <div className='patientIdentity__container'>
                      {identityDocumentDetails &&
                        identityDocumentDetails.map((item, key, { length }) => {
                          return (
                            <div className='patientIdentity' key={item.uuid}>
                              <Row>
                                <Col>
                                  <Form.Group className='mb-3'>
                                    <Form.Label>
                                      ID Type<span className='asterisk'>*</span>
                                    </Form.Label>
                                    <Form.Select
                                      required
                                      name='id_type'
                                      onChange={(event) =>
                                        handleChangeIdentifyDocFormInput(item.uuid, event)
                                      }
                                    >
                                      <option value=''>- Select -</option>
                                      <option value='Barangay Certification'>
                                        Barangay Certification
                                      </option>
                                      <option value='Certification from NCWDP'>
                                        Certification from NCWDP
                                      </option>
                                      <option value='Company Identification'>
                                        Company Identification
                                      </option>
                                      <option value='Consular ID'>Consular ID</option>
                                      <option value='DSWD Certificaiton'>DSWD Certificaiton</option>
                                      <option value='Driver License'>Driver License</option>
                                      <option value='GSIS e-Card'>GSIS e-Card</option>
                                      <option value='GovtOffice/GOCC ID'>GovtOffice/GOCC ID</option>
                                      <option value='IBP ID'>IBP ID</option>
                                      <option value='NBI Clearance'>NBI Clearance</option>
                                      <option value='NSO Authenticated Birth Certification'>
                                        NSO Authenticated Birth Certification
                                      </option>
                                      <option value='OFW ID'>OFW ID</option>
                                      <option value='OWWA'>OWWA</option>
                                      <option value='POEA ID'>POEA ID</option>
                                      <option value='PRA Special Resident Retiree Visa ID'>
                                        PRA Special Resident Retiree Visa ID
                                      </option>
                                      <option value='PRC ID'>PRC ID</option>
                                      <option value='Passport'>Passport</option>
                                      <option value='Permit to Carry Firearms'>
                                        Permit to Carry Firearms
                                      </option>
                                      <option value='PhilHealth Card'>PhilHealth Card</option>
                                      <option value='Police Clearance'>Police Clearance</option>
                                      <option value='Postal ID'>Postal ID</option>
                                      <option value='SSS'>SSS</option>
                                      <option value='Seamans Book'>Seamans Book</option>
                                      <option value='Senior Citizen Card'>
                                        Senior Citizen Card
                                      </option>
                                      <option value='Student ID'>Student ID</option>
                                      <option value='TIN'>TIN</option>
                                      <option value='Visa'>Visa</option>
                                      <option value='Voters ID'>Voters ID</option>
                                      <option value='UMID'>UMID</option>
                                      <option value='National ID'>National ID</option>
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group className='mb-3'>
                                    <Form.Label>ID Number</Form.Label>
                                    <Form.Control
                                      type='text'
                                      placeholder='Enter Here'
                                      name='id_number'
                                      onChange={(event) =>
                                        handleChangeIdentifyDocFormInput(item.uuid, event)
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className='file-upload'>
                                    <Widget
                                      publicKey={process.env.REACT_APP_UPLOADCARE_API_PUBLIC_KEY}
                                      id='link'
                                      name='link'
                                      tabs='file camera url'
                                      imagesOnly='true'
                                      Clearable={true}
                                      onChange={(info) =>
                                        uploadFileChange(
                                          { type: 'identityDoc', uuid: item.uuid },
                                          info,
                                        )
                                      }
                                      onFileSelect={(file) =>
                                        uploadFileSelect(
                                          {
                                            type: 'identityDoc',
                                            uuid: item.uuid,
                                            file_uuid: item.file_uuid,
                                          },
                                          file,
                                        )
                                      }
                                      validators={validators}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              {key + 1 === length ? (
                                <Button
                                  className='add'
                                  variant='transparent'
                                  onClick={() => handleAddIdentityDocForm(item.uuid)}
                                >
                                  <CirclePlusSVG />
                                </Button>
                              ) : (
                                <Button
                                  className='remove'
                                  variant='transparent'
                                  onClick={() => handleRemoveIdentityDocForm()}
                                >
                                  <CircleMinusSVG />
                                </Button>
                              )}
                            </div>
                          );
                        })}
                      <div className='patientIdentity'>
                      <Row>
                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              ID Type<span className='asterisk'>*</span>
                            </Form.Label>
                            <Form.Select>
                              <option>PhilHealth</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              ID Number<span className='asterisk'>*</span>
                            </Form.Label>
                            <Form.Control type='text' placeholder='Enter Here' />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group className='identityDocuments'>
                            <Form.Label>
                              File Upload<span className='asterisk'>*</span>
                            </Form.Label>
                            <Form.Label>
                              <div className='fakeInput'>
                                Choose file
                                <span>Browse</span>
                              </div>
                              <Form.Control type='file' />
                            </Form.Label>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Button
                        variant='transparent'
                        onClick={() =>
                          setPatientIdentityDocumentDetails((prevState) => [
                            ...prevState,
                            prevState.length + 1,
                          ])
                        }
                      >
                        <CirclePlusSVG />
                      </Button>
                    </div>
                    </div>
                  </FormUI>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}
            <Accordion defaultActiveKey='0'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Contact in Case of Emergency</Accordion.Header>
                <Accordion.Body>
                  <FormUI>
                    <Row className='d-flex align-items-center'>
                      <Col lg={2}>
                        <h6 className='mb-3 fw-bold'>Contact Person Name</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>Prefix/Title</Form.Label>
                          <Form.Select
                            onChange={(event) =>
                              handleDropdownValue(event, 'emergencyContactprefix')
                            }
                          >
                            <option value=''>-</option>
                            <option value='mr'>Mr.</option>
                            <option value='ms'>Ms.</option>
                            <option value='mrs'>Mrs.</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            First Name<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter First Name'
                            value={emergencyContactFirstName}
                            onChange={(event) => {
                              setEmergencyContactFirstName(
                                event.target.value.replace(/[^a-z]/gi, ''),
                              );
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Middle Name<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter Middle Name'
                            value={emergencyContactMiddleName}
                            onChange={(event) => {
                              setEmergencyContactMiddleName(
                                event.target.value.replace(/[^a-z]/gi, ''),
                              );
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Last Name<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter Last Name'
                            value={emergencyContactLastName}
                            onChange={(event) => {
                              setEmergencyContactName(event.target.value.replace(/[^a-z]/gi, ''));
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>Suffix</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter Suffix'
                            value={emergencyContactSuffix}
                            onChange={(event) => {
                              setEmergencyContactSuffix(event.target.value.replace(/[^a-z]/gi, ''));
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className='d-flex align-items-center'>
                      <Col lg={2}>
                        <h6 className='mb-3 fw-bold'>Contact Details</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Relationship<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select
                            required
                            onChange={(event) =>
                              handleDropdownValue(event, 'emergencyContactRelation')
                            }
                          >
                            <option value=''>-</option>
                            <option value='spouse'>Spouse</option>
                            <option value='son'>Son</option>
                            <option value='daughter'>Daughter</option>
                            <option value='mother'>Mother</option>
                            <option value='father'>Father</option>
                            <option value='otherRelatives'>Other Relatives</option>
                            <option value='notRelative'>Not Relative</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      {/* <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Purpose<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select
                            required
                            onChange={(event) =>
                              handleDropdownValue(event, 'emergencyContactPurpose')
                            }
                          >
                            <option value=''>-</option>
                            <option value='financial'>Financial</option>
                            <option value='support'>Moral Support</option>
                            <option value='basicneeds'>Basic needs</option>
                          </Form.Select>
                        </Form.Group>
                      </Col> */}
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Contact Number<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            maxlength='11'
                            placeholder='Enter Contact Number'
                            onChange={(event) => {
                              setEmergencyContacNumber(event.target.value);
                            }}
                            pattern='[0-9\b]{11}'
                            isInvalid={inValidateInput(emergencyContactNumber)}
                          />
                          <Form.Control.Feedback type='invalid'>
                            {emergencyContactNumber === '' ? 'Emergency contact number is required.' : 'Emergency contact number should be numeric and has 11 characters.'}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                  </FormUI>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            {/* <Accordion defaultActiveKey='0'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Patient Occupation Details</Accordion.Header>
                <Accordion.Body>
                  <FormUI>
                    <Row className='d-flex align-items-center'>
                      <Col lg={2}>
                        <h6 className='mb-3 fw-bold'>Present Job</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Occupation<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter Here'
                            value={occupation}
                            onChange={(event) => {
                              setOccupation(event.target.value.replace(/[^a-z]/gi, ''));
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Industry<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter Here'
                            value={industry}
                            onChange={(event) => {
                              setIndustry(event.target.value.replace(/[^a-z]/gi, ''));
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Employer<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter Here'
                            onChange={(event) => {
                              setEmployer(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-4 '>
                          <Form.Label>
                            Employment Duration: <span className='text-danger'>*</span>
                          </Form.Label>
                          <div className='d-flex align-content-center'>
                            <Form.Control
                              type='date'
                              className='bg-white w-50 '
                              placeholder='Enter Here'
                              onChange={(event) => setStartDate(event.target.value)}
                            />

                            <Form.Control
                              type='date'
                              className='bg-white w-50 mx-2'
                              placeholder='Enter Here'
                              onChange={(event) => setEndDate(event.target.value)}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </FormUI>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}
            {/* <Accordion defaultActiveKey='0'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Family Details</Accordion.Header>
                <Accordion.Body>
                  <FormUI>
                    <div className='familyDetails__container'>
                      {familyDetails &&
                        familyDetails.map((item) => {
                          return (
                            <div className='familyDetails' key={item.uuid}>
                              <Row className='d-flex align-items-center'>
                                <Col lg={2}>
                                  <h6 className='mb-3 fw-bold'>Family Member Name</h6>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg='auto'>
                                  <Form.Group className='mb-3'>
                                    <Form.Label>Prefix/Title</Form.Label>

                                    <Form.Select
                                      name='prefix'
                                      onChange={(event) =>
                                        handleChangeFamilyFormInput(item.uuid, event)
                                      }
                                    >
                                      <option>-</option>
                                      <option>Mr.</option>
                                      <option>Ms.</option>
                                      <option>Mrs.</option>
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group className='mb-3'>
                                    <Form.Label>
                                      First Name<span className='asterisk'>*</span>
                                    </Form.Label>
                                    <Form.Control
                                      required
                                      type='text'
                                      placeholder='Enter First Name'
                                      name='first_name'
                                      onChange={(event) =>
                                        handleChangeFamilyFormInput(item.uuid, event)
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group className='mb-3'>
                                    <Form.Label>
                                      Middle Name<span className='asterisk'>*</span>
                                    </Form.Label>
                                    <Form.Control
                                      required
                                      type='text'
                                      placeholder='Enter Middle Name'
                                      name='middle_name'
                                      onChange={(event) =>
                                        handleChangeFamilyFormInput(item.uuid, event)
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group className='mb-3'>
                                    <Form.Label>
                                      Last Name<span className='asterisk'>*</span>
                                    </Form.Label>
                                    <Form.Control
                                      required
                                      type='text'
                                      placeholder='Enter Last Name'
                                      name='last_name'
                                      onChange={(event) =>
                                        handleChangeFamilyFormInput(item.uuid, event)
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group className='mb-3'>
                                    <Form.Label>Suffix</Form.Label>
                                    <Form.Control
                                      type='text'
                                      placeholder='Enter Suffix'
                                      name='suffix'
                                      onChange={(event) =>
                                        handleChangeFamilyFormInput(item.uuid, event)
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg='auto'>
                                  <Form.Group className='mb-3'>
                                    <Form.Label>Sex</Form.Label>
                                    <Form.Select
                                      name='sex'
                                      onChange={(event) =>
                                        handleChangeFamilyFormInput(item.uuid, event)
                                      }
                                    >
                                      <option value=''>- select -</option>
                                      <option value='male'>Male</option>
                                      <option value='femail'>Female</option>
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row className='d-flex align-items-center'>
                                <Col lg={2}>
                                  <h6 className='mb-3 fw-bold'>Contact Details</h6>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Form.Group className='mb-3'>
                                    <Form.Label>Relationship</Form.Label>
                                    <Form.Select
                                      name='relationship'
                                      onChange={(event) =>
                                        handleChangeFamilyFormInput(item.uuid, event)
                                      }
                                    >
                                      <option value=''>- select -</option>
                                      <option value=''>-</option>
                                      <option value='spouse'>Spouse</option>
                                      <option value='son'>Son</option>
                                      <option value='daughter'>Daughter</option>
                                      <option value='mother'>Mother</option>
                                      <option value='father'>Father</option>
                                      <option value='otherRelatives'>Other Relatives</option>
                                      <option value='notRelative'>Not Relative</option>
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group className='mb-3'>
                                    <Form.Label>
                                      Purpose<span className='asterisk'>*</span>
                                    </Form.Label>
                                    <Form.Select
                                      required
                                      name='purpose'
                                      onChange={(event) =>
                                        handleChangeFamilyFormInput(item.uuid, event)
                                      }
                                    >
                                      <option value=''>- select -</option>
                                      <option value='financial'>Financial</option>
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group className='mb-3'>
                                    <Form.Label>
                                      Contact Number<span className='asterisk'>*</span>
                                    </Form.Label>
                                    <Form.Control
                                      required
                                      type='text'
                                      placeholder='Enter Here'
                                      name='contact_number'
                                      onChange={(event) =>
                                        handleChangeFamilyFormInput(item.uuid, event)
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group className='mb-3'>
                                    <Form.Label>
                                      MRN / PIN<span className='asterisk'>*</span>
                                    </Form.Label>
                                    <Form.Control
                                      required
                                      type='text'
                                      placeholder='Enter Here'
                                      name='MRN'
                                      onChange={(event) =>
                                        handleChangeFamilyFormInput(item.uuid, event)
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Button
                                variant='transparent'
                                onClick={() => handleRemoveFamilyForm(item.uuid)}
                              >
                                <CircleMinusSVG />
                              </Button>
                            </div>
                          );
                        })}
                    </div>
                    <Row>
                      <Col>
                        <div className='wrapper'>
                          <Button variant='addField' onClick={() => handleAddFamilyForm()}>
                            Add field
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </FormUI>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}
            {/* <Accordion defaultActiveKey='0'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>PhilHealth Eligibility Form</Accordion.Header>
                <Accordion.Body>
                  <FormUI>
                    <Row className='d-flex align-items-center'>
                      <Col lg={3}>
                        <h6 className='mb-3 fw-bold'>Member&#39;s PhilHealth Information</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Member&#39;s PhilHealth Identification No
                            <span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter Here'
                            onChange={(event) => {
                              setMPIN(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            First Name<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter First Name'
                            onChange={(event) => {
                              setPhilHealthFirstName(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Middle Name<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter Middle Name'
                            onChange={(event) => {
                              setPhilHealthMiddleName(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Last Name<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter Last Name'
                            onChange={(event) => {
                              setPhilHealthLastName(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>Suffix</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter Suffix'
                            onChange={(event) => {
                              setPhilHealthSuffix(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg='3'>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Member Type<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select
                            required
                            onChange={(event) => handleDropdownValue(event, 'philHealthMemberType')}
                          >
                            <option value=''>-</option>
                            <option value='Member'>Member</option>
                            <option value='Not Member'>Not Member</option>
                            <option value='Not Active'>Not Active</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col lg='auto'>
                        <Form.Group className='mb-3'>
                          <Form.Label>Member is the Patient?</Form.Label>
                          <div className='d-flex flex-row nowrap align-items-center mt-2'>
                            <Form.Check
                              name='philHealthMemberType'
                              inline
                              label='Yes'
                              type='radio'
                              value='yes'
                              onChange={(e) => setPhilHealthIsOwnAccount(true)}
                            />
                            <Form.Check
                              name='philHealthMemberType'
                              inline
                              label='No'
                              type='radio'
                              value='no'
                              onChange={(e) => setPhilHealthIsOwnAccount(false)}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className='d-flex align-items-center'>
                      <Col lg={3}>
                        <h6 className='mt-3 mb-3 fw-bold'>Patient&#39;s Basic Information</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg='auto'>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Relationship to Member<span className='asterisk'>*</span>
                          </Form.Label>
                          <div className='d-flex flex-row nowrap align-items-center mt-2'>
                            <Form.Check
                              required
                              inline
                              label='Spouse'
                              name='relationship'
                              value='spouse'
                              type='radio'
                              onChange={(e) => setPhilHealthRelation('spouse')}
                            />
                            <Form.Check
                              inline
                              label='Child'
                              name='relationship'
                              value='child'
                              type='radio'
                              onChange={(e) => setPhilHealthRelation('child')}
                            />
                            <Form.Check
                              inline
                              label='Parent'
                              name='relationship'
                              value='parent'
                              type='radio'
                              onChange={(e) => setPhilHealthRelation('parent')}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            First Name<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            value={firstName}
                            disabled
                            placeholder='Enter First Name'
                            onChange={(event) => {
                              setPhilHealthPatientFirstName(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Middle Name<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter Middle Name'
                            value={middleName}
                            disabled
                            onChange={(event) => {
                              setPhilHealthPatientMiddleName(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Last Name<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Enter Last Name'
                            value={lastName}
                            disabled
                            onChange={(event) => {
                              setPhilHealthPatientLastName(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>Suffix</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter Suffix'
                            value={suffix}
                            disabled
                            onChange={(event) => {
                              setPhilHealthPatientSuffix(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg='4'>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Date of Birth<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type='date'
                            value={dateOfBirth}
                            disabled
                            onChange={(event) => {
                              setPhilHealthPatientDateOfBirth(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg='2'>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            Sex<span className='asterisk'>*</span>
                          </Form.Label>
                          <Form.Select
                            required
                            onChange={(event) => handleDropdownValue(event, 'philHealthSex')}
                          >
                            <option value=''>Select Gender</option>
                            <option value='male'>Male</option>
                            <option value='female'>Female</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className='d-flex align-items-center'>
                    <Col lg={3}>
                      <h6 className='mt-3 mb-3 fw-bold'>Confinement Information</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg='3'>
                      <Form.Group className='mb-3'>
                        <Form.Label>Admission Date</Form.Label>
                        <Form.Control
                          type='date'
                          onChange={(event) => {
                            setPhilHealthPatientAdmissionDate(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg='3'>
                      <Form.Group className='mb-3'>
                        <Form.Label>Discharge Date</Form.Label>
                        <Form.Control
                          type='date'
                          onChange={(event) => {
                            setPhilHealthPatientDischargeDate(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg='3'>
                      <Form.Group className='mb-3'>
                        <Form.Label>ICD - Codes</Form.Label>
                        <Form.Select
                          onChange={(event) => handleDropdownValue(event, 'philHealthIcdCode')}
                        >
                          <option value=''>-</option>
                          <option value='1'>1CD-1</option>
                          <option value='2'>ICD-2</option>
                          <option value='3'>ICD-3</option>
                          <option value='4'>ICD-4</option>
                          <option value='5'>ICD-5</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg='3'>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Total Amount Actual<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='number'
                          onChange={(event) => {
                            setPhilHealthTotalAmount(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg='3'>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          Total Amount Claimed<span className='asterisk'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='number'
                          onChange={(event) => {
                            setPhilHealthAmountClaimed(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg='3'>
                      <Form.Group className='mb-3'>
                        <Form.Label>Final?</Form.Label>
                        <div className='d-flex flex-row nowrap align-items-center mt-2'>
                          <h1>{philHealthIsFinal}</h1>
                          <Form.Check
                            inline
                            label='Yes'
                            value='yes'
                            name='final'
                            type='radio'
                            onChange={(e) => setPhilHealthIsFinal(true)}
                          />
                          <Form.Check
                            inline
                            label='No'
                            value='no'
                            name='final'
                            type='radio'
                            onChange={(e) => setPhilHealthIsFinal(false)}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  </FormUI>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}
          </AccordionUI>
          <div className='d-flex justify-content-end mt-5'>
            <Button className='me-3' type='submit' variant='save' name='savePatient'>
              SAVE TO ER
            </Button>
            <Button className='me-3' type='submit' variant='light-blue' name='saveAdmit'>
              SAVE AND ADMIT
            </Button>
            <Button className='' variant='clear' onClick={redirectBackToPreviousPage}>
              CANCEL
            </Button>
          </div>
        </Form>
      </FormUI>
      {openModal === true &&
        <ConfirmationModal
          setOpenModal={setOpenModal}
          params={params}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
        />
      }
      <FileUploadModal
        showUploadModal={showUploadModal}
        setShowUploadModal={setShowUploadModal}
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
      />
    </div>
  );
};

export default FormCreateNewPatient;
