/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import './DischargeSummary.scss';
import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Form, FormControl } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import ConditionalRender from 'components/ConditionalRender';
import { getPatients } from 'reducers/patientReducer';
import {
  createDischargeSummaryRecord,
  getMedicalFormsbyID,
  selectActivePatients,
} from 'reducers/medicalRecordReducer';
import useCreateRecordResponse from './hooks/useCreateRecordResponse';

const DischargeSummary = ({ setShowScreen, viewForm, setIsEdit, isEdit }) => {
  const dispatch = useDispatch();
  const logs = useSelector(selectActivePatients);
  const [isAdd, setIsAdd] = useState(false);
  const [formData, setFormData] = useState({
    mname: '',
    given_name: '',
    lname: '',
    address: '',
    name_of_hospital: '',
    discharge_diagnosis: '',
    admitting_diagnosis: '',
    date_discharge: '',
    date_admitted: '',
    operation_diagnosis: '',
    brief_clinical_history: '',
    pertinent_pe_bp: '',
    cr: '',
    dfc: '',
    aog: '',
    lmp: '',
    edc: '',
    g: '',
    p: '',
    abort: '',
    temperature: '',
    heent: '',
    chest_lungs: '',
    heart: '',
    abdomen: '',
    fh: '',
    fht: '',
    extremites: '',
    neurogical_examination: '',
    course_in_ward: '',
    laboratory_findings: '',
    medications: '',
    disposition: '',
    attending_physician: '',
    date_accomplished: '',
    patientId: Math.random(),
    type: 'discharge_summary',
  });

  const [validated, setValidated] = useState(false);
  const [trigger, setTrigger] = useState({ trigger: false, patientID: false, recordID: false });
  const isEditing = isEdit === true;
  const isAdding = isAdd === true;

  // For Add
  const addRecord = () => {
    return;
  };

  // For Edit
  const saveChanges = () => {
    setTrigger({ ...trigger, trigger: true, recordID: true });
    dispatch(
      createDischargeSummaryRecord({
        ...formData,
        method: 'put',
        type: 'discharge_summary',
      }),
    );
    // setViewForm({ ...viewForm, data: formData });
    setIsEdit(false);
    return;
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (!form.checkValidity() === false) {
      setTrigger(true);
      dispatch(createDischargeSummaryRecord({ ...formData }));
      return;
    }
    toast.warning('Fill up the required fields');
    setValidated(true);
  };

  const handleChange = (key) => (e) => {
    setFormData((prev) => {
      return { ...prev, [key]: e.target.value };
    });

    return;
  }; // For Add

  useEffect(() => {
    if (viewForm?.patientID) {
      setTrigger({ ...trigger, patientID: true });
      const patientid = viewForm?.patientID;
      dispatch(getMedicalFormsbyID(patientid));
    }
  }, []);

  useEffect(() => {
    dispatch(getPatients());
    if (Object.keys(viewForm.data).length === 0) {
      setIsAdd(true);
      setIsEdit(true);
    }
  }, [isEdit]);

  useEffect(() => {
    const {
      isSuccess,
      isFailed,
      isFetchViewSuccess,
      isFetchRecordSuccess,
      patientID,
      medicalRecordID,
      successData,
    } = logs;

    useCreateRecordResponse({
      isSuccess,
      isFailed,
      isFetchViewSuccess,
      isFetchRecordSuccess,
      patientID,
      medicalRecordID,
      trigger,
      setTrigger,
      setIsEdit,
      setShowScreen,
      dispatch,
    });

    if (isFetchRecordSuccess && trigger.recordID) {
      const data = medicalRecordID.mr_discharge_summary;
      setFormData(data);
    }
  }, [logs]);

  return (
    <Form className='discharge-summary' noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className='mb-5'>
        <Col className='first-row'>
          <Form.Group className='input-group'>
            <FormControl
              placeholder='Enter Here'
              value={formData?.name_of_hospital}
              onChange={handleChange('name_of_hospital')}
              required
            />
            <Form.Label>Name of Healthcare Facility</Form.Label>
          </Form.Group>

          <Form.Group className='input-group'>
            <FormControl
              placeholder='Enter Here'
              value={formData?.address}
              onChange={handleChange('address')}
              required
            />
            <Form.Label>Address</Form.Label>
          </Form.Group>
        </Col>
      </Row>

      <Row className='mb-5'>
        <Col>
          <Form.Group className='input-group'>
            <Form.Label>Last Name:</Form.Label>
            <FormControl
              placeholder='Enter Here'
              value={formData?.lname}
              onChange={handleChange('lname')}
              required
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className='input-group'>
            <Form.Label>Given Name:</Form.Label>
            <FormControl
              placeholder='Enter Here'
              value={formData?.given_name}
              onChange={handleChange('given_name')}
              required
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className='input-group'>
            <Form.Label>Middle Name:</Form.Label>
            <FormControl
              placeholder='Enter Here'
              value={formData?.mname}
              onChange={handleChange('mname')}
              required
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className='input-group'>
            <Form.Label>HRN:</Form.Label>
            <FormControl
              placeholder='Enter Here'
              value={formData?.hrn}
              onChange={handleChange('hrn')}
              required
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className='mb-5'>
        <Col>
          <Form.Group className='input-group'>
            <Form.Label>Age</Form.Label>
            <FormControl
              placeholder='Enter Here'
              value={formData?.age}
              onChange={handleChange('age')}
              required
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className='input-group'>
            <Form.Label>Gender</Form.Label>
            <Form.Select value={formData?.gender} onChange={handleChange('gender')}>
              <option hidden value=''>
                -Select-
              </option>
              <option value='male'>Male</option>
              <option value='female'>Female</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className='input-group'>
            <Form.Label>WARD / ROOM / BED</Form.Label>
            <FormControl
              placeholder='Enter Here'
              value={formData?.ward_room_bed}
              onChange={handleChange('ward_room_bed')}
              required
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className='mb-4'>
        <Col className='third-row'>
          <h2>{viewForm?.type}</h2>
        </Col>
      </Row>

      <Row className='mb-3'>
        <Col className='header-textbox p-0'>
          <Form.Group className='input-group'>
            <Form.Label>Date Admitted</Form.Label>
            <FormControl
              type='date'
              placeholder='Enter Here'
              value={formData?.date_admitted}
              onChange={handleChange('date_admitted')}
              required
            />
          </Form.Group>

          <Form.Group className='input-group'>
            <Form.Label>Date Discharge</Form.Label>
            <FormControl
              type='date'
              placeholder='Enter Here'
              value={formData?.date_discharge}
              onChange={handleChange('date_discharge')}
              required
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className='mb-4'>
        <Col className='main-box-container p-0'>
          <div className='box-cont'>
            <Row>
              <Col>
                <Form.Group className='mb-4 input-group inline-label-textarea'>
                  <Form.Label>ADMITTING DIAGNOSIS</Form.Label>
                  <FormControl
                    as='textarea'
                    rows={5}
                    placeholder='Enter Here'
                    value={formData?.admitting_diagnosis}
                    onChange={handleChange('admitting_diagnosis')}
                  />
                </Form.Group>

                <Form.Group className='mb-4 input-group inline-label-textarea'>
                  <Form.Label>DISCHARGE DIAGNOSIS</Form.Label>
                  <FormControl
                    as='textarea'
                    rows={5}
                    placeholder='Enter Here'
                    value={formData?.discharge_diagnosis}
                    onChange={handleChange('discharge_diagnosis')}
                  />
                </Form.Group>

                <Form.Group className='input-group inline-label-textarea'>
                  <Form.Label>OPERATION DIAGNOSIS</Form.Label>
                  <FormControl
                    as='textarea'
                    rows={5}
                    placeholder='Enter Here'
                    value={formData?.operation_diagnosis}
                    onChange={handleChange('operation_diagnosis')}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>

          <div className='box-cont'>
            <Row className='mb-3'>
              <Col sm={8}>
                <Form.Group className='mb-3 input-group'>
                  <Form.Label>BRIEF CLINICAL HISTORY</Form.Label>
                  <FormControl
                    as='textarea'
                    rows={5}
                    placeholder='Enter Here'
                    value={formData?.operation_diagnosis}
                    onChange={handleChange('operation_diagnosis')}
                  />
                </Form.Group>

                <div className='inline-row'>
                  <p className='inline-header'>PERTINENT PE</p>

                  <Form.Group className='input-group inline-label'>
                    <Form.Label>B.P.</Form.Label>
                    <FormControl
                      placeholder='Enter Here'
                      value={formData?.pertinent_pe_bp}
                      onChange={handleChange('pertinent_pe_bp')}
                    />
                  </Form.Group>

                  <Form.Group className='input-group inline-label'>
                    <Form.Label>C.R.</Form.Label>
                    <FormControl
                      placeholder='Enter Here'
                      value={formData?.cr}
                      onChange={handleChange('cr')}
                    />
                  </Form.Group>
                </div>
              </Col>
              <Col>
                <Form.Group className='mb-3 input-group inline-label'>
                  <Form.Label>LMP</Form.Label>
                  <FormControl
                    placeholder='Enter Here'
                    value={formData?.lmp}
                    onChange={handleChange('lmp')}
                  />
                </Form.Group>

                <Form.Group className='mb-3 input-group inline-label'>
                  <Form.Label>EDC</Form.Label>
                  <FormControl
                    placeholder='Enter Here'
                    value={formData?.edc}
                    onChange={handleChange('edc')}
                  />
                </Form.Group>

                <Form.Group className='mb-3 input-group inline-label'>
                  <Form.Label>AOG</Form.Label>
                  <FormControl
                    placeholder='Enter Here'
                    value={formData?.aog}
                    onChange={handleChange('aog')}
                  />
                </Form.Group>

                <Form.Group className='mb-3 two-input'>
                  <div className='cont'>
                    <Form.Group className='input-group inline-label'>
                      <Form.Label>G</Form.Label>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.g}
                        onChange={handleChange('g')}
                      />
                    </Form.Group>
                  </div>

                  <div className='cont'>
                    <Form.Group className='input-group inline-label'>
                      <Form.Label>P</Form.Label>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.p}
                        onChange={handleChange('p')}
                      />
                    </Form.Group>
                  </div>
                </Form.Group>

                <Form.Group className='mb-3 input-group inline-label'>
                  <Form.Label>ABORT</Form.Label>
                  <FormControl
                    placeholder='Enter Here'
                    value={formData?.abort}
                    onChange={handleChange('abort')}
                  />
                </Form.Group>

                <Form.Group className='mb-3 input-group inline-label'>
                  <Form.Label>TEMPERATURE</Form.Label>
                  <FormControl
                    placeholder='Enter Here'
                    value={formData?.temperature}
                    onChange={handleChange('temperature')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg={2}>
                <h3 className='mb-1'>GENERAL SURVEY</h3>
              </Col>
              <Col lg={10}>
                <Form.Group className='mb-3 right-cont inline-label input-group'>
                  <Form.Label>HEENT:</Form.Label>
                  <FormControl
                    placeholder='Enter Here'
                    value={formData?.heent}
                    onChange={handleChange('heent')}
                  />
                </Form.Group>
                <Form.Group className='mb-3 right-cont inline-label input-group'>
                  <Form.Label>CHEST / LUNGS:</Form.Label>
                  <FormControl
                    placeholder='Enter Here'
                    value={formData?.chest_lungs}
                    onChange={handleChange('chest_lungs')}
                  />
                </Form.Group>
                <Form.Group className='mb-3 right-cont inline-label input-group'>
                  <Form.Label>HEART:</Form.Label>
                  <FormControl
                    placeholder='Enter Here'
                    value={formData?.heart}
                    onChange={handleChange('heart')}
                  />
                </Form.Group>
                <Form.Group className='mb-3 right-cont inline-label input-group'>
                  <Form.Label>ABDOMEN:</Form.Label>
                  <FormControl
                    placeholder='Enter Here'
                    value={formData?.abdomen}
                    onChange={handleChange('abdomen')}
                  />
                </Form.Group>
                <Form.Group className='mb-3 right-cont inline-label input-group'>
                  <div className='cont'>
                    <Form.Group className='input-group inline-label'>
                      <Form.Label>FH</Form.Label>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.fh}
                        onChange={handleChange('fh')}
                      />
                    </Form.Group>
                  </div>

                  <div className='cont'>
                    <Form.Group className='input-group inline-label'>
                      <Form.Label>FHT</Form.Label>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.fht}
                        onChange={handleChange('fht')}
                      />
                    </Form.Group>
                  </div>
                </Form.Group>
                <Form.Group className='mb-3 right-cont inline-label input-group'>
                  <Form.Label>EXTREMITIES:</Form.Label>
                  <FormControl
                    placeholder='Enter Here'
                    value={formData?.extremites}
                    onChange={handleChange('extremites')}
                  />
                </Form.Group>
                <Form.Group className='mb-3 right-cont inline-label input-group'>
                  <Form.Label>NEUROLOGICAL EXAMINATION:</Form.Label>
                  <FormControl
                    placeholder='Enter Here'
                    value={formData?.neurogical_examination}
                    onChange={handleChange('neurogical_examination')}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>

          <div className='box-cont'>
            <Row>
              <Col>
                <Form.Group className='mb-4 input-group inline-label-textarea flex-label'>
                  <Form.Label>COURSE IN THE WARD: ( Include Medications )</Form.Label>
                  <FormControl
                    as='textarea'
                    rows={5}
                    placeholder='Enter Here'
                    value={formData?.course_in_ward}
                    onChange={handleChange('course_in_ward')}
                  />
                </Form.Group>

                <Form.Group className='mb-4 input-group inline-label-textarea flex-label'>
                  <Form.Label>
                    LABORATORY FINDINGS: ( Including EKG, X-ray and other diagnostic procedure )
                  </Form.Label>
                  <FormControl
                    as='textarea'
                    rows={5}
                    placeholder='Enter Here'
                    value={formData?.laboratory_findings}
                    onChange={handleChange('laboratory_findings')}
                  />
                </Form.Group>

                <Form.Group className='input-group inline-label-textarea flex-label'>
                  <Form.Label>MEDICATION:</Form.Label>
                  <FormControl
                    as='textarea'
                    rows={5}
                    placeholder='Enter Here'
                    value={formData?.medications}
                    onChange={handleChange('medications')}
                  />
                </Form.Group>

                <Form.Group className='input-group inline-label-textarea flex-label'>
                  <Form.Label>
                    DISPOSITION: ( Indicate home medication, special instruction and follow - up )
                  </Form.Label>
                  <FormControl
                    as='textarea'
                    rows={5}
                    placeholder='Enter Here'
                    value={formData?.disposition}
                    onChange={handleChange('disposition')}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row className='mb-5'>
        <Col>
          <Form.Group className='inline-label input-group'>
            <Form.Label>ATTENDING PHYSICIAN</Form.Label>

            <div className='signature'>
              <FormControl
                placeholder='Enter Here'
                value={formData?.attending_physician}
                onChange={handleChange('attending_physician')}
              />
              <p className='over-printed-name'>Signature Over Printed Name</p>
            </div>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className='justify-content-end inline-label input-group'>
            <Form.Label>DATE ACCOMPLISHED</Form.Label>
            <div className='signature'>
              <FormControl
                type='date'
                value={formData?.date_accomplished}
                onChange={handleChange('date_accomplished')}
              />
            </div>
          </Form.Group>
        </Col>
      </Row>

      <hr />

      <Row className=''>
        <Col className='form-buttons'>
          {/* Buttons to show for Add */}
          <ConditionalRender
            condition={isAdding}
            renderIf={
              <>
                <Button type='submit' className='save'>
                  CREATE RECORD
                </Button>
                <Button
                  onClick={() => {
                    setIsEdit(false);
                    setShowScreen(0);
                  }}
                  className='cancel'
                >
                  CANCEL
                </Button>
              </>
            }
          />

          {/* Buttons to show for Edit/View */}
          <ConditionalRender
            condition={!isAdding}
            renderIf={
              <>
                <ConditionalRender
                  condition={!isEditing}
                  renderIf={
                    <>
                      <Button className='print'>PRINT CERTIFICATE</Button>
                      <Button
                        onClick={() => {
                          setIsEdit(true);
                        }}
                        className='edit'
                      >
                        EDIT CERTIFICATE
                      </Button>
                    </>
                  }
                />

                <ConditionalRender
                  condition={isEditing}
                  renderIf={
                    <>
                      <Button onClick={saveChanges} disabled={trigger.trigger} className='save'>
                        SAVE CHANGES
                      </Button>
                      <Button
                        onClick={() => {
                          setIsEdit(false);
                        }}
                        className='cancel'
                      >
                        CANCEL
                      </Button>
                    </>
                  }
                />
              </>
            }
          />
        </Col>
      </Row>
    </Form>
  );
};

export default DischargeSummary;
