/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { Fragment, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col, Tabs, Tab, Table, Form, Button } from 'react-bootstrap';

// import components below
import TabsUI from 'components/UI/TabsUI';
import TableUI from 'components/UI/TableUI';
import StartDischargeModal from './StartDischargeModal';
import FinalizeDischargeModal from './FinalizeDischargeModal';

// import assets below
import './DischargePlanningTab.scss';
import AddNewServiceModal from './AddNewServiceModal';
import ClearanceRequestModal from './ClearanceRequestModal';
import PhysicalDischargeModal from './PhysicalDischargeModal';
import AbscondedModal from './AbscondedModal';

import { useSelector, useDispatch } from 'react-redux';
import { selectPatientItem, showPatient } from 'reducers/patientReducer';
import axios from 'axios';
import { toast } from 'react-toastify';

// main component
const DischargePlanningTab = ({ setShowProfile }) => {
  const [dischargeModalStatus, setDischargeModalStatus] = useState(false);
  const [startDischargeModalStatus, setStartDischargeModalStatus] = useState(false);
  const [finalizeDischargeModalStatus, setFinalizeDischargeModalStatus] = useState(false);
  const [addNewServiceModalStatus, setAddNewServiceModalStatus] = useState(false);
  const [clearanceRequestModalStatus, setClearanceRequestModalStatus] = useState(false);
  const [physicalDischargeModalStatus, setPhysicalDischargeModalStatus] = useState(false);
  const [abscondedModalStatus, setAbscondedModalStatus] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();
  const patientProfile = useSelector(selectPatientItem);

  const startDischarge = (id) => {
    axios
      .post(`/patient/start_discharge/${id}`)
      .then((response) => {
        toast.success('Successfully changed patient status!');
        dispatch(showPatient(id));
      })
      .catch((error) => {
        toast.error('ERROR!');
      });
  };

  const discharged = true;

  return (
    <Fragment>
      <div className='dischargePlanningTab'>
        <div className='dischargePlanningTab__header'>
          <TabsUI>
            <Tabs defaultActiveKey='Discharge Patient'>
              <Tab eventKey='Discharge Patient' title='Discharge Patient'>
                <h5>
                  Tagging a patient as physically discharged will officially end his inpatient
                  visit.
                </h5>
                <h5>The patient will also be removed from your Patient List.</h5>
                <div className='d-flex justify-content-end mt-4'>

                  {/* <Button
                    variant='discharge'
                    onClick={() => setAbscondedModalStatus((prevState) => !prevState)}
                  >
                    Mark as ABSCONDED
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    variant='discharge'
                    onClick={() => setPhysicalDischargeModalStatus((prevState) => !prevState)}
                  >
                    Confirm Physical Discharge
                  </Button> */}
                </div>
                {!(new RegExp('emergency-department').test(location.pathname)) && (
                  <>
                    <div className='patientProfile__header'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <h4>Physical May Go Home Monitoring</h4>
                        {/* {patientProfile?.patient_status === 'for_discharge' && (
                        <Button variant='dark-green'>Update MGH Monitoring</Button>
                      )} */}
                      </div>
                      <Row>
                        <Col>
                          <TableUI>
                            <Table className='medicationsTable'>
                              <thead>
                                <tr>
                                  <th>Physician Name</th>
                                  <th>Department</th>
                                  <th>Is Cleared?</th>
                                  {/* <th>MGH</th>
                              <th>PF Amount</th>
                              <th>Remarks</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {patientProfile?.assigned_doctors.length > 0 &&
                                  patientProfile?.assigned_doctors.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{item.user?.full_name}</td>
                                        <td>{item.user?.management}</td>
                                        <td>{item.isCleared ? 'Yes' : 'No'}</td>
                                        {/* <td>{item.mgh}</td>
                                    <td>{item.pfAmount}</td>
                                    <td>{item.remarks}</td> */}
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </Table>
                          </TableUI>
                        </Col>
                      </Row>
                    </div>
                    <div className='patientProfile__header'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <h4>Discharge Diagnosis</h4>
                        {/* {patientProfile?.patient_status === 'for_discharge' && (
                        <Button variant='dark-green'>Add New Diagnosis</Button>
                      )} */}
                      </div>
                      <Row>
                        <Col>
                          <TableUI>
                            <Table className='medicationsTable'>
                              <thead>
                                <tr>
                                  <th>ICD - 10 Code</th>
                                  <th>Discharge Diagnosis</th>
                                  <th>Final Diagnosis</th>
                                  <th>Diagnosis Type</th>
                                  <th>Remarks</th>
                                  {/* <th>Action</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {patientProfile?.discharge_diagnoses &&
                                  patientProfile?.discharge_diagnoses.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{item.ICD_10_code}</td>
                                        <td>{item.discharge_diagnosis}</td>
                                        <td>{item.final_diagnosis}</td>
                                        <td>{item.diagnosis_type}</td>
                                        <td>{item.remarks}</td>
                                        {/* <td>{item.action}</td> */}
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </Table>
                          </TableUI>
                        </Col>
                      </Row>
                    </div>
                    {/* <div className='patientProfile__header'>
                  <div className='patientProfile__content'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <h4>Outcome / Disposition</h4>
                      {startDischargeModalStatus && (
                        <Button variant='dark-green'>Update Outcome/Disposition</Button>
                      )}
                    </div>
                    <Row>
                      <Col>
                        <Row className='my-3'>
                          <Col lg='4'>
                            <div className='wrapper'>
                              <span>Patient Disposition</span>
                              <span>:</span>
                            </div>
                          </Col>
                          <Col lg='auto'>
                            <span className='value'>INSERT DATA</span>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row className='my-3'>
                          <Col lg='4'>
                            <div className='wrapper'>
                              <span>Patient&#39;s Health Status</span>
                              <span>:</span>
                            </div>
                          </Col>
                          <Col lg='auto'>
                            <span className='value'>INSERT DATA</span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div> */}
                    <div className='patientProfile__header'>
                      <div className='patientProfile__content'>
                        <div className='d-flex justify-content-between align-items-center'>
                          {/* <h4>Medical Discharge Completion Checklist</h4>
                      {startDischargeModalStatus && (
                        <Button variant='dark-green'>Update Checklist</Button>
                      )} */}
                        </div>
                        <Row>
                          {/* <Col>
                        <TableUI>
                          <Table className='medicationsTable'>
                            <thead>
                              <tr>
                                <th>
                                  <Form.Check type='checkbox' />
                                </th>
                                <th>Orders and Changes</th>
                              </tr>
                            </thead>
                            <tbody>
                              {{dischargeDummy &&
                                dischargeDummy.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <Form.Check type='checkbox' />
                                      </td>
                                      <td>{item.diagnosis}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </Table>
                        </TableUI>
                      </Col> */}
                          {/* <Col>
                        <TableUI>
                          <Table className='medicationsTable'>
                            <thead>
                              <tr>
                                <th>
                                  <Form.Check type='checkbox' />
                                </th>
                                <th>PhilHealth Rquirements</th>
                              </tr>
                            </thead>
                            <tbody>
                              {dischargeDummy &&
                                dischargeDummy.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <Form.Check type='checkbox' />
                                      </td>
                                      <td>{item.diagnosis}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </Table>
                        </TableUI>
                      </Col> */}
                        </Row>
                      </div>
                    </div>
                    {patientProfile?.patient_status === 'for_discharge' && (
                      <div className='d-flex justify-content-end'>
                        {/* <Button
                      className='me-3'
                      variant='light-blue'
                      onClick={() => setFinalizeDischargeModalStatus((prevState) => !prevState)}
                    >
                      SUBMIT CHECKLIST AND REQUEST CLEARANCE FROM UNITS
                    </Button> */}
                        <Button variant='cancel'>Cancel</Button>
                      </div>
                    )}
                  </>
                )}
                {!(new RegExp('emergency-department').test(location.pathname)) && (
                  <>
                    {patientProfile?.patient_status === 'for_discharge' && (
                      <>
                        <h6>
                          Please select the service units that will be notified the patient is for
                          discharge.
                        </h6>
                        <h6 className='text-danger fw-bold'>
                          NOTE: Units with charges/orders have been automatically notified.
                        </h6>
                        <div className='patientProfile__header mt-4'>
                          <div className='patientProfile__content'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <h4>Medical Discharge Completion Checklist</h4>
                              <div>
                                {/* <Button
                                className='me-3'
                                variant='light-blue'
                                onClick={() => setAddNewServiceModalStatus((prevState) => !prevState)}
                              >
                                Add new Service Unit
                              </Button> */}
                                <Button
                                  variant='light-blue'
                                  onClick={() => {
                                    setClearanceRequestModalStatus((prevState) => !prevState);
                                  }
                                  }
                                >
                                  NOTIFY UNITS FOR CLEARACE REQUEST
                                </Button>
                              </div>
                            </div>
                            <TableUI>
                              <Table className='medicalDischargeTable'>
                                <thead>
                                  <tr>
                                    {/* <th>
                                  <Form.Check type='checkbox' />
                                </th> */}
                                    <th>Service Unit</th>
                                    <th>Remarks</th>
                                    <th>Notified?</th>
                                    <th>Cleared?</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {patientProfile?.service_unit_clearance_requests &&
                                    patientProfile?.service_unit_clearance_requests.map((item, index) => {
                                      return (
                                        <tr key={index}>
                                          {/* <td>
                                        <Form.Check type='checkbox' />
                                      </td> */}
                                          <td><b>{item.service_unit}</b></td>
                                          <td>{item.remarks}</td>
                                          <td>Yes</td>
                                          <td>{item.isCleared ? 'Yes' : 'No'}</td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </Table>
                            </TableUI>
                          </div>
                        </div>
                      </>
                    )}
                    {patientProfile?.patient_status !== 'for_discharge' && <h5>Patient is not yet for discharge</h5>}
                  </>
                )}
              </Tab>
            </Tabs>
          </TabsUI>
          {patientProfile?.patient_status !== 'for_discharge' && (
            <Button
              variant='discharge'
              onClick={() => startDischarge(patientProfile?.id)}
            >
              Start Discharge
            </Button>
          )}
        </div>
      </div>
      {dischargeModalStatus && (
        <StartDischargeModal
          status={dischargeModalStatus}
          onToggle={setDischargeModalStatus}
          onConfirm={setStartDischargeModalStatus}
        />
      )}
      {finalizeDischargeModalStatus && (
        <FinalizeDischargeModal
          status={finalizeDischargeModalStatus}
          onToggle={setFinalizeDischargeModalStatus}
        />
      )}
      {addNewServiceModalStatus && (
        <AddNewServiceModal
          status={addNewServiceModalStatus}
          onToggle={setAddNewServiceModalStatus}
        />
      )}
      {clearanceRequestModalStatus && (
        <ClearanceRequestModal
          status={clearanceRequestModalStatus}
          onToggle={setClearanceRequestModalStatus}
        />
      )}
      {physicalDischargeModalStatus && (
        <PhysicalDischargeModal
          status={physicalDischargeModalStatus}
          onToggle={setPhysicalDischargeModalStatus}
          id={patientProfile?.id}
          setShowProfile={setShowProfile}
        />
      )}
      {abscondedModalStatus && (
        <AbscondedModal
          status={abscondedModalStatus}
          onToggle={setAbscondedModalStatus}
          id={patientProfile?.id}
          setShowProfile={setShowProfile}
        />
      )}
    </Fragment>
  );
};

export default DischargePlanningTab;
