// import components below

// import SearchPatientTabs from './Tabs';

// import assets below
import './style.scss';

// main component
const EligibilityCheck = () => {
    return (
        <div className='search-patient'>
            {/* <SearchPatientTabs /> */}
            Eligibility Check
        </div>
    );
};

export default EligibilityCheck;
