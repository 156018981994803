// import core & vendor packages below
import { Container, Row } from 'react-bootstrap';

// main components below
import Layout from 'components/Layout';
import CreateNewPatientHeader from './PageHeader';
import FormCreateNewPatient from 'components/FormCreateNewPatient';

// main component
const CreateNewPatient = () => {
  return (
    <Layout pageTitle='Admissions'>
      <CreateNewPatientHeader />
      <Container fluid>
        <Row>
          <FormCreateNewPatient />
        </Row>
      </Container>
    </Layout>
  );
};

export default CreateNewPatient;
