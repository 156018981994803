/* eslint-disable space-before-function-paren */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { Table } from 'react-bootstrap';
import { Button, Col, Form, Row, Accordion } from 'react-bootstrap';

import FormUI from 'components/UI/FormUI';
import TableUI from 'components/UI/TableUI';

import './index.scss';
import React, { useState } from 'react';
import PartialModal from './components/PartialModal';
import {
  createBillDeduction,
  selectBillingLogs,
  selectSoaData,
  showBillingByPatient,
} from 'reducers/billingReducer';
import { useDispatch, useSelector } from 'react-redux';
import ConditionalRender from 'components/ConditionalRender';
import DischargeModal from './DischargeModal';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { TableFiltersPermission } from 'helpers/filteredPermissions';
import { ReactComponent as PlusSVG } from 'assets/svg/circle-plus.svg';
import DeductionModal from './DeductionModal';
import CreateSOAModal from './CreateSOAModal';
import { useEffect } from 'react';

const payors = [
  {
    id: 1,
    payor: 'PhilHealth',
    payor_type: 'PhilHealth',
    coverage_amount: '9,200.00 (-)',
    allocated_payment: '9,200.00 (-)',
  },
  {
    id: 2,
    payor: 'Maxicare',
    payor_type: 'HMO',
    coverage_amount: '3,200.00 (-)',
    allocated_payment: '9,200.00 (-)',
  },
];

const SOA = () => {
  const dispatch = useDispatch();
  const [createDeductionTrigger, setCreateDeductionTrigger] = useState(false);
  const [showModal, setShowModal] = useState({
    isPartial: false,
    isDischarge: false,
    patientId: null,
    billingId: null,
    deduction: false,
    createSOA: false,
    modalTitle: '',
    deduction_type: '',
  });

  const discountHospitalBill = 0;
  let hospitalId = 0;

  const discountAdjustmentBill = 0;
  const grossAdjustmentBill = 0;
  const soaData = useSelector(selectSoaData);

  const [patientTag, setPatientTag] = useState('');

  const logs = useSelector(selectBillingLogs);
  const billingID = soaData?.bill.id;
  const paymentBtn = () => {
    setShowModal({
      isPartial: true,
      isDischarge: false,
      patientId: soaData?.bill.patientId,
      billingId: soaData?.bill.id,
      print: false,
    });
  };

  const dischargeBtn = () => {
    setShowModal({
      isPartial: false,
      isDischarge: true,
      visitNumber: soaData?.bill.visit_number,
      print: false,
    });
  };

  const checkTotal = soaData?.totalNetBill <= 0;

  const handleOnPrint = () => {
    toast.info('Printing SOA...');

    const id = soaData?.bill.patientId;

    axios
      .post(
        `billing/print-soa/${id}`,
        {},
        {
          responseType: 'blob',
        },
      )
      .then((response) => {
        const filename = response.headers['content-disposition']
          .split('filename=')[1]
          .split(';')[0];

        saveAs(response.data, filename);
      })
      .catch((err) => {
        toast.error('Error printing SOA!');
      });
  };
  const defaultDatas = [];

  const filter_grossHospitalBill = (type) => {
    if (!soaData?.grossHospitalBill?.length) {
      return [];
    }
    return soaData?.grossHospitalBill.filter((data) => data.department === type);
  };
  // deduction
  const filter_Deduction_type = (deduction_type) => {
    if (!soaData?.bill?.bill_deductions?.length) {
      return [];
    }
    return soaData?.bill?.bill_deductions.filter((data) => data.deduction_type === deduction_type);
  };
  const corporateDiscount = React.useMemo(
    () => filter_Deduction_type('corporate_discount'),
    [filter_Deduction_type],
  );
  const specialDiscount = React.useMemo(
    () => filter_Deduction_type('special_discount'),
    [filter_Deduction_type],
  );
  const otherGuarantor = React.useMemo(
    () => filter_Deduction_type('other_guarantor'),
    [filter_Deduction_type],
  );
  const otherDiscount = React.useMemo(
    () => filter_Deduction_type('other_discount'),
    [filter_Deduction_type],
  );
  const otherDeduction = React.useMemo(
    () => filter_Deduction_type('other_deduction'),
    [filter_Deduction_type],
  );
  // <- end

  // table adjustment data
  const adjustment = React.useMemo(() => {
    if (!soaData?.bill?.bill_adjustments?.length) {
      return [];
    }
    return soaData?.bill?.bill_adjustments;
  }, [filter_Deduction_type]);

  // table Room and charges data
  const roomCharges = soaData?.roomCharges;

  // table Laboratory Request data
  const laboratory = soaData?.bill?.patient?.laboratory_requests;

  // table Doctors data
  const Doctors = soaData?.doctors;

  // table Diagnosis data
  const diagnosis = soaData?.diagnosis;
  // Gross Bill per Table
  const LaboratoryGross = React.useMemo(
    () => filter_grossHospitalBill('Laboratory'),
    [filter_grossHospitalBill],
  );
  const roomAndBoardGross = React.useMemo(
    () => filter_grossHospitalBill('Admission'),
    [filter_grossHospitalBill],
  );
  const totalHospitalBill = soaData?.totalGrossBill;
  const totalAdjustmentBill = soaData?.totalAdjustments;
  const professionalsBill = soaData?.netProfessional;

  const createDeductionHandler = (inputedData) => {
    const params = {
      ...inputedData,

      deduction_type: showModal.deduction_type,
      patient_tagged_as: patientTag,
      billId: billingID,
    };
    setCreateDeductionTrigger(true);
    dispatch(createBillDeduction(params));
  };
  const tableDatas = [
    {
      id: 1,
      tabName: 'Corporate Discount',
      permission: 'Can Add/View Corporate Discount',
      deductiontype: 'corporate_discount',
      data: corporateDiscount,
    },
    {
      id: 2,
      tabName: 'Special Discount',
      permission: 'Can Add/View Special Discount',
      deductiontype: 'special_discount',
      data: specialDiscount,
    },
    {
      id: 3,
      tabName: 'Other Guarantor',
      permission: 'Can Add/View Other Guarantor',
      deductiontype: 'other_guarantor',
      data: otherGuarantor,
    },
    {
      id: 4,
      tabName: 'Other Discounts',
      permission: 'Can Add/View Discounts',
      deductiontype: 'other_discount',
      data: otherDiscount,
    },
    {
      id: 5,
      tabName: 'Other Deductions',
      permission: 'Can Add/View Discounts',
      deductiontype: 'other_deduction',
      data: otherDeduction,
    },
  ];

  const resultData = TableFiltersPermission(defaultDatas, tableDatas);
  resultData.sort(function (a, b) {
    return a.id - b.id;
  });

  useEffect(() => {
    const { isSuccess, isFailed } = logs;
    if (createDeductionTrigger && isSuccess) {
      toast.success('Success on creating deduction bill !');
      setCreateDeductionTrigger(false);
      dispatch(showBillingByPatient(soaData?.bill.patientId));
      setShowModal((prev) => {
        return { ...prev, deduction: false };
      });
    }
    if (createDeductionTrigger && isFailed) {
      toast.error('Error creating the deduction bill!');
      setCreateDeductionTrigger(false);
      setShowModal((prev) => {
        return { ...prev, deduction: false };
      });
    }
  }, [logs]);

  useEffect(() => {
    if (soaData?.bill) {
      setPatientTag(soaData?.bill?.patient_tagged_as);
    }
  }, [soaData]);

  return (
    <>
      <div className='soa-cont mb-3'>
        <PartialModal showModal={showModal} setShowModal={setShowModal} soaData={soaData} />

        <DischargeModal showModal={showModal} setShowModal={setShowModal} soaData={soaData} />

        <FormUI>
          <Row className='mb-3'>
            <Col className='header'>
              <h2>Status of Account (SOA)</h2>
            </Col>
          </Row>

          <Row className='mb-4'>
            <Col className='sub-header'>
              <div className='left-cont'>
                <Form.Group className='inline-group'>
                  <Form.Label>SOA No.:</Form.Label>
                  <Form.Control
                    className='no-border'
                    type='text'
                    placeholder='Enter Here'
                    value='2022123456789'
                  />
                </Form.Group>

                <Form.Group className='inline-group'>
                  <Form.Label>SOA Status:</Form.Label>
                  <Form.Control
                    className='no-border'
                    type='text'
                    placeholder='Enter Here'
                    value='DRAFT'
                  />
                </Form.Group>
              </div>

              <div className='right-cont'>
                <Button className='generate' onClick={handleOnPrint}>
                  GENERATE SOA PRINT OUT
                </Button>
              </div>
            </Col>
          </Row>

          <Row className='mb-3'>
            <Col className='hospital-bill'>
              <h4>Hospital Bill</h4>
              <TableUI>
                <Table>
                  <thead>
                    <tr>
                      <th>Billing Group</th>
                      <th>Particulars</th>
                      <th>Gross Price</th>
                      <th>Discounts</th>
                      <th>Net Amount Due</th>
                    </tr>
                  </thead>
                  <tbody>
                    {soaData?.grossHospitalBill?.length > 0 ? (
                      soaData?.grossHospitalBill.map((data) => {
                        hospitalId++;
                        return (
                          <tr key={hospitalId}>
                            <td>{data.department}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{data.total}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan='5' className='text-center'>
                          No record found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={2}>TOTAL HOSPITAL BILL</td>
                      <td className='red-bg'>Php {totalHospitalBill}</td>
                      <td className='red-bg'>Php {discountHospitalBill}</td>
                      <td className='red-bg'>Php {soaData?.totalGrossBill}</td>
                    </tr>
                  </tfoot>
                </Table>
              </TableUI>
            </Col>
          </Row>

          <Row className='mb-3'>
            <Col className='hospital-bill'>
              <TableUI>
                <Table>
                  <thead>
                    <tr>
                      <th>Adjustments</th>
                      <th>Particulars</th>
                      <th>Quantity</th>
                      <th>Unit Price</th>
                      <th>Total Adjusted</th>
                    </tr>
                  </thead>
                  <tbody>
                    {soaData?.bill?.bill_adjustments.length > 0 ? (
                      soaData?.bill?.bill_adjustments.map((data) => {
                        return (
                          <tr key={data.id}>
                            <td>{data.name_of_payor}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{data.amount}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan='5' className='text-center'>
                          No record found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={2}>TOTAL ADJUSTMENTS</td>
                      <td className='red-bg'>Php {totalAdjustmentBill}</td>
                      <td className='red-bg'>Php {discountAdjustmentBill}</td>
                      <td className='red-bg'>Php {soaData?.totalAdjustments}</td>
                    </tr>
                  </tfoot>
                </Table>
              </TableUI>
            </Col>
          </Row>

          <Row className='mb-3'>
            <Col className='hospital-bill'>
              <TableUI>
                <Table>
                  <thead>
                    <tr>
                      <th>Payor</th>
                      <th>Payor Type</th>
                      <th>Coverage Amount (%)</th>
                      <th>Allocated Payment</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!payors?.length > 0 ? (
                      payors.map((data) => {
                        return (
                          <tr key={data.id}>
                            <td>{data.payor}</td>
                            <td>{data.payor_type}</td>
                            <td>{data.coverage_amount}</td>
                            <td>{data.allocated_payment}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan='5' className='text-center'>
                          No record found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={2}>TOTAL PAYOR COVERAGE</td>
                      <td className='red-bg'>Php 0</td>
                      <td className='red-bg'>Php 0</td>
                    </tr>
                  </tfoot>
                </Table>
              </TableUI>
            </Col>
          </Row>

          <Row className='mb-3'>
            <Col className='hospital-bill'>
              <TableUI>
                <Table>
                  <thead>
                    <tr>
                      <th>Payments</th>
                      <th>Payment Type</th>
                      <th>Date of Payment</th>
                      <th>Reference / Reciept Number</th>
                      <th>Allocated Payment</th>
                    </tr>
                  </thead>
                  <tbody>
                    {soaData?.bill?.bills_payments.length > 0 ? (
                      soaData?.bill?.bills_payments.map((data) => {
                        return (
                          <tr key={data.id}>
                            <td>{data.name_of_payor}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{data.amount}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan='5' className='text-center'>
                          No record found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={4}>TOTAL PAYMENTS</td>
                      <td className='red-bg'>Php {soaData?.totalPayments}</td>
                    </tr>
                  </tfoot>
                </Table>
              </TableUI>
            </Col>
          </Row>

          <Row className='mb-3'>
            <Col className='hospital-bill'>
              <h4>PROFESSIONAL FEE ALLOCATION</h4>

              <TableUI>
                <Table>
                  <thead>
                    <tr>
                      <th>Doctor&apos;s Name (Code)</th>
                      <th>Professional Fee</th>
                      <th>Discounts</th>
                      <th>HMO</th>
                      <th>PhilHealth</th>
                      <th>Net Amount Due</th>
                    </tr>
                  </thead>
                  <tbody>
                    {soaData?.doctors.length > 0 ? (
                      soaData?.doctors.map((data) => {
                        return (
                          <tr key={data.id}>
                            <td>
                              {data.user?.first_name}, {data.user?.last_name}{' '}
                              {data.user?.middle_name}
                            </td>
                            <td>
                              {data?.professional_fee > 0
                                ? data?.professional_fee
                                : data?.default_professional_fee}
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              {data.is_negotiable === 'null' || !data.is_negotiable
                                ? data.default_professional_fee
                                : data.professional_fee}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan='5' className='text-center'>
                          No record found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td></td>
                      <td className='red-bg'></td>
                      <td className='red-bg'></td>
                      <td className='red-bg'></td>
                      <td className='red-bg'></td>
                      <td className='red-bg'></td>
                    </tr>
                  </tfoot>
                </Table>
              </TableUI>
            </Col>
          </Row>

          <Row className='mb-2'>
            <Col className='net-hospital-bill'>
              <div className='right-cont'>
                <Form.Group className='inline-group mb-3'>
                  <Form.Label>NET HOSPITAL BILL DUE TO PATIENT:
                    {soaData?.bill?.patient_tagged_as !== '' && (
                      <span style={{ color: 'red' }}>(-20%)</span>
                    )}

                  </Form.Label>
                  <Form.Control
                    className='blue-border'
                    type='text'
                    placeholder='Enter Here'
                    value={soaData?.totalGrossBill}
                  />
                </Form.Group>

                <Form.Group className='inline-group mb-3'>
                  <Form.Label>NET PROFESSIONAL FEES DUE TO PATIENT:
                    {soaData?.bill?.patient_tagged_as !== '' && (
                      <span style={{ color: 'red' }}>(-20%)</span>
                    )}
                  </Form.Label>
                  <Form.Control
                    className='blue-border'
                    type='text'
                    placeholder='Enter Here'
                    value={soaData?.netProfessional}
                  />
                </Form.Group>

                {soaData?.bill?.patient_tagged_as !== '' && (
                  <Form.Group className='inline-group mb-3'>
                    <Form.Label><small style={{ color: 'red' }}>Hospital bill + Professional Fees - 20% (less Php {soaData?.twentyPercentDiscount})</small></Form.Label>
                  </Form.Group>
                )}
                <Form.Group className='inline-group mb-3'>
                  <Form.Label>TOTAL FEES DUE TO PATIENT:</Form.Label>
                  <Form.Control
                    className='blue-border'
                    type='text'
                    placeholder='Enter Here'
                    value={soaData?.totalDisbursible > 0 ? 0 : soaData?.totalNetBill}
                  />
                </Form.Group>

                {soaData?.totalDisbursible > 0 &&
                  <Form.Group className='inline-group mb-3'>
                    <Form.Label>DISBURSIBLE:</Form.Label>
                    <Form.Control
                      className='blue-border'
                      type='text'
                      placeholder='Enter Here'
                      value={soaData?.totalDisbursible}
                    />
                  </Form.Group>}
              </div>
            </Col>
          </Row>
          <div>
            <h5>DEDUCTIONS</h5>
            <div className='d-flex mb-2'>
              <p style={{ margin: '0' }}>tag patient as:</p>
              <Form.Group className='d-flex'>
                <Form.Check
                  name='tag_patient'
                  className='mx-2'
                  type='radio'
                  label='PWD'
                  value='PWD'
                  checked={patientTag === 'PWD'}
                  onChange={(e) => setPatientTag(e.target.value)}
                />
                <Form.Check
                  name='tag_patient'
                  className='mx-2'
                  type='radio'
                  value='Senior Citizen'
                  label='Senior Citizen'
                  checked={patientTag === 'Senior Citizen'}
                  onChange={(e) => setPatientTag(e.target.value)}
                />
              </Form.Group>
            </div>
            {soaData?.bill?.patient_tagged_as !== '' && (
              <h6>
                <b>
                  NOTE: Patient is: {soaData?.bill?.patient_tagged_as} (20% discount will be
                  deducted from total fee due to patient)
                </b>
              </h6>
            )}
          </div>
          <div className='form-tab-table-billing'>
            <TableUI>
              <Table responsive>
                <thead>
                  <div className='d-flex justify-content-between accordion-header-custom p-3'>
                    <div>Deduction From</div>
                    <div>Amount</div>
                  </div>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      <Accordion className='form-columns' defaultActiveKey='1'>
                        {tableDatas.map((data) => {
                          return (
                            <Accordion.Item eventKey={data.id} key={data.id}>
                              <Accordion.Header>{data.tabName}</Accordion.Header>
                              <Accordion.Body>
                                <Row className='mb-4 p-3'>
                                  <Col>
                                    <Button
                                      onClick={() => {
                                        setShowModal((prev) => {
                                          return {
                                            ...prev,
                                            deduction: true,
                                            modalTitle: data.tabName,
                                            deduction_type: data?.deductiontype,
                                          };
                                        });
                                      }}
                                      className='add-record'
                                    >
                                      Add
                                    </Button>
                                  </Col>
                                </Row>
                                <Row></Row>
                                <Row>
                                  <Col>
                                    {Array.isArray(data.data)
                                      ? data?.data?.map((listData) => {
                                        return (
                                          <div
                                            key={listData.id}
                                            style={{ padding: '0.5rem 1rem' }}
                                            className='d-flex justify-content-between'
                                          >
                                            <div>{listData.details}</div>
                                            <div className='text-start'>
                                              <p>{listData.amount}</p>
                                            </div>
                                          </div>
                                        );
                                      })
                                      : null}
                                  </Col>
                                </Row>
                              </Accordion.Body>
                            </Accordion.Item>
                          );
                        })}
                      </Accordion>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </TableUI>
          </div>
          <div className='total-deduction mt-4'>
            <div>
              <h5>PHP {soaData?.totalDiscounts}</h5>
              <h6>TOTAL DISCOUNT DUE TO PATIENT</h6>
            </div>
          </div>
          <Row className='mt-5'>
            <div className='d-flex justify-content-center'>
              <ConditionalRender
                condition={checkTotal}
                renderIf={
                  <Button onClick={dischargeBtn} className='btn-light-green'>
                    Discharge
                  </Button>
                }
                renderElse={
                  <Button onClick={paymentBtn} className='btn-light-green'>
                    Confirm Payment
                  </Button>
                }
              />
            </div>
          </Row>
        </FormUI>
      </div>
      <DeductionModal
        createDeductionHandler={createDeductionHandler}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <CreateSOAModal
        checkTotal={checkTotal}
        dischargeBtn={dischargeBtn}
        showModal={showModal}
        setShowModal={setShowModal}
        paymentBtn={paymentBtn}
        roomCharges={roomCharges}
        laboratory={laboratory}
        LaboratoryGross={LaboratoryGross}
        roomAndBoardGross={roomAndBoardGross}
        totalHospitalBill={totalHospitalBill}
        adjustment={adjustment}
        totalAdjustmentBill={totalAdjustmentBill}
        Doctors={Doctors}
        professionalsBill={professionalsBill}
        diagnosis={diagnosis}
        soaData={soaData}
      />
    </>
  );
};

export default SOA;
