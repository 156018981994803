/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// Packages
import axios from 'axios';
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';

// Components
import Filter from './Filter';
import Profile from './Profile';
import Status from 'constant/status';
import DischargeSummaryTable from './Table';
import ConditionalRender from 'components/ConditionalRender';
import FormPatientProfile from 'components/FormPatientProfile';
import { checkStringPermission } from 'helpers/filteredPermissions';

// Style
import './index.scss';

// Main Component
const DischargeSummary = () => {
  const [state, setState] = useState([]);
  const [showScreen, setShowScreen] = useState(0);
  const [showProfile, setShowProfile] = useState({
    isModalShow: false,
    isShowView: false,
    profileId: null,
    setActive: '',
  });

  useEffect(() => {
    axios
      .get('/patient', {
        params: {
          patient_status: [Status.DISCHARGED],
        },
      })
      .then((response) => {
        setState(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  const isDefault = showScreen === 0;
  const isViewProfile = showScreen === 1;
  const isEditProfile = showScreen === 2;

  const isFilterEnabled = checkStringPermission('Can Basic Search  via Patient ID & Name in Medical Records Discharge Summary');

  return (
    <div className='discharge-summary'>
      <ConditionalRender
        condition={isDefault}
        renderIf={
          <>
            <ConditionalRender
              condition={isFilterEnabled}
              renderIf={<Filter state={state} setState={setState} />}
            />

            <DischargeSummaryTable
              state={state}
              setState={setState}
              setShowScreen={setShowScreen}
              setShowProfile={setShowProfile}
            />
          </>
        }
      />

      <ConditionalRender
        condition={isViewProfile}
        renderIf={<Profile showProfile={showProfile} setShowScreen={setShowScreen} />}
      />

      <ConditionalRender
        condition={isEditProfile}
        renderIf={<FormPatientProfile showProfile={showProfile} setShowScreen={setShowScreen} />}
      />
    </div>
  );
};

export default DischargeSummary;
