/* eslint-disable no-unused-vars */
// Packages
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import Filter from './Filter';
import ViewForm from './Form';
import FormTabTable from './Table';
import ConditionalRender from 'components/ConditionalRender';
import { checkStringPermission } from 'helpers/filteredPermissions';

// Style
import './index.scss';
import { getMedicalForms } from 'reducers/thunks/medicalRecordThunks';
import { selectConfinementRecords, selectMedicalForms } from 'reducers/medicalRecordReducer';

// Main Component
const FormTab = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState([]);
  const [search, setSearch] = useState('');
  const [showScreen, setShowScreen] = useState(0);
  const [viewForm, setViewForm] = useState({
    type: '',
    data: {},
    patientID: '',
  });

  const isView = showScreen === 1;
  const isDefault = showScreen === 0;

  const isFilterEnabled = checkStringPermission('Can Basic Search for Forms');

  const records = useSelector(selectMedicalForms);

  // get records by record type
  const getRecordByType = (recordType, typeLabel) => {
    const recordByType = _.filter(
      records,
      (record) => record?.type === recordType && record?.patientId !== null,
    );

    const recordDates = _.chain(recordByType)
      .uniqBy((record) => getDate(record?.updatedAt))
      .map((record) => getDate(record?.updatedAt))
      .value();

    return _.map(recordDates, (date, index) => ({
      id: index + 1,
      type: typeLabel,
      date,
      tableData: _.filter(recordByType, (record) => getDate(record?.updatedAt) === date),
    }));
  };

  // date entries
  const getDate = (date) => {
    return moment(date).format('YYYY-DD-MM');
  };

  const baseRecords = [
    {
      id: 1,
      tabLabel: 'CERTIFICATE OF CONFINEMENT',
      permission: 'Can Add/View Certficate of Confinement in Medical Records',
      // data: useSelector(selectConfinementRecords),
      data: records && getRecordByType('certificate_of_confinement', 'CERTIFICATE OF CONFINEMENT'),
    },
    {
      id: 2,
      tabLabel: 'MEDICAL CERTIFICATE',
      permission: 'Can Add/View Medical Certificate in Medical Records',
      data: records && getRecordByType('medical_cert', 'MEDICAL CERTIFICATE'),
    },
    {
      id: 3,
      tabLabel: 'MEDICO - LEGAL CERTIFICATE',
      permission: 'Can Add/View Medico-Legal Certificate in Medical Records',
      data: getRecordByType('medico_legal_cert', 'MEDICO - LEGAL CERTIFICATE'),
    },
    {
      id: 4,
      tabLabel: 'CERTIFICATE OF DISMEMBERED BODY PART',
      permission: 'Can Add/View Certficate of Dismembered Body Part in Medical Records',
      data: getRecordByType(
        'cert_of_dismembered_body_part',
        'CERTIFICATE OF DISMEMBERED BODY PART',
      ),
    },
    {
      id: 5,
      tabLabel: 'SUBPOENA AD TESTIFICANDUM',
      permission: 'Can Add/View Subpoena Duces Tecum in Medical Records',
      data: getRecordByType('subpoena_ad_testificandum', 'SUBPOENA AD TESTIFICANDUM'),
    },
    {
      id: 6,
      tabLabel: 'SUBPOENA DUCES TECUM',
      permission: 'Can Add/View Subpoena Duces Tecum in Medical Records',
      data: getRecordByType('subpoena_duces_tecum', 'SUBPOENA DUCES TECUM'),
    },
    {
      id: 7,
      tabLabel: 'MEDICAL RECORDS',
      permission: 'Can Add/View Medical Records in Medical Records',
      data: getRecordByType('medical_record', 'MEDICAL RECORDS'),
    },
    {
      id: 8,
      tabLabel: 'OUTPATIENT RECORDS',
      permission: 'Can Add/View Outpatient Records in Medical Records',
      data: getRecordByType('outpatient_record', 'OUTPATIENT RECORDS'),
    },
    {
      id: 9,
      tabLabel: 'EMERGENCY TREATMENT RECORD',
      permission: 'Can Add/View Emergency Treatment Records in Medical Records',
      data: getRecordByType('emergency_treatment', 'EMERGENCY TREATMENT RECORD'),
    },
    {
      id: 10,
      tabLabel: 'ADMISSION AND DISCHARGE RECORD',
      permission: 'Can Add/View Admission and Discharge Records in Medical Records',
      data: getRecordByType('admission_and_discharge', 'ADMISSION AND DISCHARGE RECORD'),
    },
    {
      id: 11,
      tabLabel: 'DISCHARGE SUMMARY',
      permission: 'Can Add/View Discharge Summary in Medical Records',
      data: getRecordByType('discharge_summary', 'DISCHARGE SUMMARY'),
    },
    {
      id: 12,
      tabLabel: 'HISTORY AND PHYSICAL EXAMINATION',
      permission: 'Can Add/View History and Physical Examination in Medical Records',
      data: getRecordByType('history_and_physical_examination', 'HISTORY AND PHYSICAL EXAMINATION'),
    },
    {
      id: 13,
      tabLabel: 'LIVE BIRTH FORM',
      permission: 'Can Add/View Live Birth Forms in Medical Records',
      data: getRecordByType('live_birth_form', 'LIVE BIRTH FORM'),
    },
    {
      id: 14,
      tabLabel: 'DEATH FORM',
      permission: 'Can Add/View Death Forms in Medical Records',
      data: getRecordByType('cert_of_death', 'DEATH FORM'),
    },
    {
      id: 15,
      tabLabel: 'FETAL DEATH FORM',
      permission: 'Can Add/View Fetal Death Forms in Medical Records',
      data: getRecordByType('cert_of_fetal_death', 'FETAL DEATH FORM'),
    },
  ];

  const handleSearch = () => {
    if (search === '') {
      setState(baseRecords);
    } else {
      const newFilter = baseRecords.filter((value) => {
        return value.tabLabel.toLowerCase().includes(search.toLowerCase());
      });

      setState(newFilter);
    }
  };

  useEffect(() => {
    dispatch(getMedicalForms());
  }, []);

  useEffect(() => {
    console.log('state', state);
  }, [state]);

  useEffect(() => {
    if (state && state.length === 0 && records) {
      setState(baseRecords);
    }
  }, [state, records]);

  useEffect(() => {
    console.log('viewForm', viewForm);
  }, [viewForm]);

  return (
    <div className='form-tab'>
      <ConditionalRender
        condition={isDefault}
        renderIf={
          <>
            <ConditionalRender
              condition={isFilterEnabled}
              renderIf={<Filter setSearch={setSearch} handleSearch={handleSearch} />}
            />

            <FormTabTable
              state={state}
              setState={setState}
              setViewForm={setViewForm}
              setShowScreen={setShowScreen}
            />
          </>
        }
      />

      <ConditionalRender
        condition={isView}
        renderIf={
          <ViewForm setViewForm={setViewForm} viewForm={viewForm} setShowScreen={setShowScreen} />
        }
      />
    </div>
  );
};

export default FormTab;
