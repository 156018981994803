// import core & vendor packages below
import { Link } from 'react-router-dom';

// Components
import { checkStringPermission } from 'helpers/filteredPermissions';

// import assets below
import './Card.scss';

// main component
const DashboardCard = (props) => {
  const { link, svg, title, permission } = props;
  const result = checkStringPermission(permission);

  if (!result) {
    return null;
  }

  return (
    <div className='dashboardCard' data-toggle='tooltip' data-placement='top' title={title}>
      {svg}
      <h5>{title}</h5>
      <Link to={link}></Link>
    </div>
  );
};

export default DashboardCard;
