/* eslint-disable */
import { Container, Row, Col, Button, FormControl, InputGroup } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';

// import components below
import Layout from 'components/Layout';
import ReportsHeader from '../PageHeader';
import SummaryPatient from './components/SummaryPatient';
import DischargePatient from './components/dischargePatient';
import DeliveriesTable from './components/DeliveriesTable';
import MorbilityTable from './components/MorbilityTable';
import MobilityAgeSexTable from './components/MorbilityAgeSexTable';
import OutPatientTable from './components/OutPatientTable';
import TestingTable from './components/TestingTable';
import DeathTable from './components/DeathTable';
import MortalityDeathTable from './components/MortalityDeathTable';
import CausedMorbilityTable from './components/CausedMorbilityTable';
import MajorOperationTable from './components/MajorOperationTable';
import MinorOperationTable from './components/MinorOperationTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  getHospitalOperation,
  selectHospitalOperationData,
} from 'reducers/hospitalOperationReportReducer';
import { updateDoh } from 'reducers/reportReducer';
import { toast } from 'react-toastify';
import FormUI from '../../../../components/UI/FormUI';
import ModalUpdateDoh from '../ModalUpdateDoh';
import ViewTransactionModal from '../ViewTransactions';

//import assets below
import './style.scss';

// main component
const HospitalOperation = () => {
  const input = useParams();

  const [activeTab] = useState('Reports');
  const [showModal, setModal] = useState(false);
  const [isUpdateDohModalVisible, setIsUpdateDohModalVisible] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHospitalOperation(typeof input.id === 'undefined' ? '' : input.id));
  }, []);

  const reportList = useSelector(selectHospitalOperationData);

  const generate = () => {
    alert('generate');
  };

  const updateDohButton = () => {
    setIsUpdateDohModalVisible(true);
  };

  //return (<SummaryPatient item={reportList.hospOptSummaryOfPatients} />);
  return (
    <Layout pageTitle='Reports'>
      <ViewTransactionModal
        show={isUpdateDohModalVisible}
        onHide={() => setIsUpdateDohModalVisible(false)}
        onClose={() => setIsUpdateDohModalVisible(false)}
      />
      <ModalUpdateDoh showModal={showModal} setModal={setModal} input={input} />
      <ReportsHeader active={activeTab} />
      <Container fluid>
        <div className='operation-report pt-3 pb-3 ps-3 pe-3'>
          <Row className='body'>
            <Row className='mb-3 mt-3'>
              <SummaryPatient item={reportList.hospOptSummaryOfPatients} />
              <DischargePatient items={reportList.hospOptDischargesSpecialty} />

              <label className='fs-6 fw-bold'>
                1. Average Length of Stay (ALOS) of Admitted Patients
              </label>
              <Row className='mt-3 mb-8'>
                <Col md='6' className=''>
                  <InputGroup className='mb-1 mt-1'>
                    <FormControl
                      placeholder='Total length of stay of discharged patients (includingDeaths) in the period'
                      disabled
                    />
                  </InputGroup>

                  <InputGroup className='mb-1 mt-1 center-position'>
                    <FormControl placeholder='Total discharges and deaths in the period' disabled />
                  </InputGroup>
                </Col>
                <Col>
                  <div className='mt-4 fs-4'>
                    <span>=</span> <span>Total</span>
                  </div>
                </Col>
              </Row>

              <label className='fs-7 mt-3'>
                {'\u2022' + ' '} Average length of stay: Average number of days each inpatient stays
                in the hospital for each episode of care.
              </label>
              <label className='fs-7'>
                (Annual Census Period-January 1, 12:01to December 31, 12:00)
              </label>

              <label className='fs-6 fw-bold mt-3'>
                2. Ten Leading causes of Morbidity based on final discharge diagnosis
              </label>
              <label className='fs-7'>
                {'\u2022' + ' '} For each category listed below, please report the total number of
                cases for the top 10 illnesses/injury.
              </label>

              <MorbilityTable items={reportList.leading_cause_of_morbidity} />

              <label className='fs-7 mt-3'>
                {'\u2022' + ' '} Kindly accomplish the“Ten Leading Causes of Morbidity/Diseases
                Disaggregated as to Age and Sex” in the table below.
              </label>
              <MobilityAgeSexTable items={reportList.hospOptDischargesMorbidity} />

              <label className='fs-6 fw-bold mt-3'>3. Total Number of Deliveries</label>
              <label className='fs-7'>
                {'\u2022' + ' '} For each category of delivery listed below, please report the total
                number of deliveries.
              </label>
              <DeliveriesTable item={reportList.hospOptDischargesNumberDeliveries} />

              <label className='fs-6 fw-bold mt-3'>
                4. Outpatient Visits, includingEmergency Care, Testing and Other Services
              </label>
              <label className='fs-7'>
                {'\u2022' + ' '} For each category of visit of service listed below, please report
                the total number of patients receiving the care.
              </label>
              <OutPatientTable item={reportList} />

              <label className='fs-6 fw-bold'>TESTING</label>
              <TestingTable item={reportList} />

              <label className='fs-5 fw-bold mb-1'>C. Deaths</label>
              <label className='fs-7 fw-bold'>
                For each category of death listed below, please report the total number of deaths.
              </label>
              <DeathTable item={reportList.hospitalOperationsDeaths} />

              <Row className='mt-3 mb-8'>
                <Col md='2' className='fw-bold mt-2'>
                  1. Gross Death Rate:{' '}
                </Col>
                <Col md='6' className='w-25'>
                  <InputGroup className='mb-1 mt-1'>
                    <FormControl
                      placeholder='Enter Here'
                      disabled
                      // value={reportList?.hospitalOperationsDeaths[0]?.grossdeathrate}
                    />
                    <span className='mt-2 fw-bold ms-2'> %</span>
                  </InputGroup>
                </Col>
              </Row>

              <Row className='mt-3 mb-8'>
                <Col md='2' className='fw-bold mt-2'>
                  Gross Death Rate:{' '}
                </Col>
                <Col md='6'>
                  <InputGroup className='mb-1 mt-1'>
                    <FormControl
                      placeholder='Total Deaths (including newborn for a given period)'
                      disabled
                      // value={reportList?.hospitalOperationsDeaths[0]?.ndrnumerator}
                    />
                  </InputGroup>
                  <InputGroup className='mb-1 mt-1'>
                    <FormControl
                      placeholder='Total Discharges and Deaths for the same period'
                      disabled
                      // value={reportList?.hospitalOperationsDeaths[0]?.ndrdenominator}
                    />
                  </InputGroup>
                </Col>
                <Col md='6' className='w-25'>
                  <div className='mt-4 fs-4'>X Total</div>
                </Col>
              </Row>

              <Row className='mt-3 mb-8'>
                <Col md='2' className='fw-bold mt-2'>
                  2. Net Death Rate:{' '}
                </Col>
                <Col md='6' className='w-25'>
                  <InputGroup className='mb-1 mt-1'>
                    <FormControl
                      placeholder='Enter Here'
                      disabled
                      // value={reportList?.hospitalOperationsDeaths[0].netdeathrate}
                    />
                    <span className='mt-2 fw-bold ms-2'> %</span>
                  </InputGroup>
                </Col>
              </Row>

              <Row className='mt-3 mb-8'>
                <Col md='2' className='fw-bold mt-2'>
                  Net Death Rate:{' '}
                </Col>
                <Col md='6'>
                  <InputGroup className='mb-1 mt-1'>
                    <FormControl
                      placeholder='Total Deaths (including newborn for a given period)'
                      disabled
                      // value={reportList?.hospitalOperationsDeaths[0]?.ndrnumerator}
                    />
                    <span className='mt-2 ms-2 me-2'> - </span>
                    <FormControl
                      placeholder='death <48 hours for the period'
                      disabled
                      // value={reportList?.hospitalOperationsDeaths[0]?.ndrdenominator}
                    />
                  </InputGroup>

                  <InputGroup className='mb-1 mt-1'>
                    <FormControl
                      placeholder='Total Deaths (including newborn for a given period)'
                      disabled
                      // value={reportList?.hospitalOperationsDeaths[0]?.ndrnumerator}
                    />
                    <span className='mt-2 ms-2 me-2'> - </span>
                    <FormControl
                      placeholder='death <48 hours for the period'
                      disabled
                      // value={reportList?.hospitalOperationsDeaths[0]?.ndrdenominator}
                    />
                  </InputGroup>
                </Col>
                <Col md='6' className='w-25'>
                  <div className='mt-4 fs-4'>X Total</div>
                </Col>
              </Row>

              <label className='fs-6 mt-3 fw-bold'>
                3. Ten Leading Causes of Mortality/Deathsand Total Number of Mortality/Deaths.
              </label>
              <MortalityDeathTable results={reportList.ten_leading_mortality_deaths} />

              <label className='fs-7 mt-3'>
                {'\u2022' + ' '} Kindly accomplish the“Ten Leading Causes of Morbidity/Diseases
                Disaggregated as to Age and Sex” in the table below.
              </label>
              <CausedMorbilityTable data={reportList.hospitalOperationsMortalityDeaths} />

              <label className='fs-5 fw-bold mb-1 mt-3'>
                D. Healthcare Associated Infections (HAI)
              </label>

              <label className='fs-6'>
                HAI are infections that patients acquire as a result of healthcare interventions.
                For purposes of Licensing, the four (4) vmajor HAI would suffice.
              </label>

              <label className='fs-6 fw-bold'>A. Device Related Infections</label>
              <label className='fs-6 fw-bold'>B. Non-Device Related Infections</label>

              <label className='fs-5 fw-bold mb-1 mt-3'>E. Surgical Operations</label>
              <label className='fs-6 mt-3 fw-bold'>
                1. Major Operation refers to surgical procedures requiring anesthesia/ spinal
                anesthesia to be performed in an operating theatre.
              </label>
              <label className='fs-6 mt-3 text-center font-italic font-size-normal'>
                (When in doubt, OR nurses shall refer to different cutting specialties when in doubt
                as to major when local anesthesia is used or minor when general or spinal anesthesia
                is used .)
              </label>
              <label className='fs-6 mt-3 fw-bold'>
                2. Minor Operation refers to surgical procedures requiring only local anesthesia/ no
                OR needed, example suturing. (Refer to different cutting specialties)
              </label>

              <MajorOperationTable results={reportList.hospitalOperationsMajorOpt} />
              <MinorOperationTable results={reportList.hospitalOperationsMinorOpt} />

              <div className='d-flex justify-content-end mt-3'>
                <Button className='btn-light-green' onClick={generate}>
                  Generate PDF
                </Button>
                <Button className='btn-light-green' onClick={updateDohButton}>
                  Update DOH
                </Button>
                <Link to='/dashboard/reports'>
                  <Button variant='secondary ms-4'>Cancel</Button>
                </Link>
              </div>
            </Row>
          </Row>
        </div>
      </Container>
    </Layout>
  );
};

export default HospitalOperation;
