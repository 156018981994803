/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Form, FormControl } from 'react-bootstrap';
import { DropdownDate, DropdownComponent } from 'react-dropdown-date';
import Moment from 'moment';
import ConditionalRender from 'components/ConditionalRender';
import { toast } from 'react-toastify';
import './LiveBirthForm.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  createLiveBirthFormRecord,
  getMedicalFormsbyID,
  selectActivePatients,
} from 'reducers/medicalRecordReducer';
import useCreateRecordResponse from './hooks/useCreateRecordResponse';

const LiveBirthForm = ({ setShowScreen, setViewForm, viewForm, setIsEdit, isEdit }) => {
  const dispatch = useDispatch();
  const logs = useSelector(selectActivePatients);
  const [isAdd, setIsAdd] = useState(false);
  const [validated, setValidated] = useState(false);
  const [trigger, setTrigger] = useState({ trigger: false, patientID: false, recordID: false });
  const [date, setDate] = useState({ selectedDate: '2012-11-05', date: null });
  const [dateofDeath, setDateofDeath] = useState({ selectedDate: '2012-11-05', date: null });
  const [formData, setFormData] = useState({
    province: '',
    city: '',
    register_no: '',
    fname: '',
    mname: '',
    lname: '',
    sex: '',
    approximate_age_when_found: '',
    day_when_found: '',
    month_when_found: '',
    year_when_found: '',
    time_when_found: '',
    approximate_day_of_birth: '',
    approximate_month_of_birth: '',
    approximate_year_of_birth: '',
    place_where_found: '',
    city_where_found: '',
    province_where_found: '',
    color_of_the_eyes: '',
    color_of_the_hair: '',
    distinct_body_features: '',
    condition_of_child_when_found: '',
    citizenship_of_child: '',
    finder_fname: '',
    finder_lname: '',
    finder_mname: '',
    finder_sex: '',
    finder_age: '',
    finder_phone_or_email: '',
    finder_occupation: '',
    finder_residence: '',
    finder_city: '',
    finder_province: '',
    informat_fname: '',
    informat_lname: '',
    informat_mname: '',
    informat_sex: '',
    informat_age: '',
    informat_phone_or_email: '',
    informat_occupation: '',
    informat_residence: '',
    informat_city: '',
    informat_province: '',
    signature_of_informat: '',
    day: '',
    month: '',
    year: '',
    place: '',
    id_type: '',
    id_issued_on: '',
    id_issued_at: '',
    prepared_by: '',
    prepared_by_title_or_position: '',
    prepared_date: '',
    signature_of_administering_officer: '',
    name_in_print: '',
    position_or_title_or_designation: '',
    address: '',
    received_by: '',
    received_by_title_or_position: '',
    received_date: '',
    registrar_signature_over_printed_name: '',
    registrar_title_or_position: '',
    registrar_date: '',
    remarks: '',
    patientId: Math.random(),
    type: 'live_birth_form',
  });

  const isEditing = isEdit === true;
  const isAdding = isAdd === true;

  // For Add
  const addRecord = () => {
    return;
  };

  // For Edit
  const saveChanges = () => {
    setTrigger({ ...trigger, trigger: true, recordID: true });
    dispatch(
      createLiveBirthFormRecord({
        ...formData,
        method: 'put',
        type: 'live_birth_form',
      }),
    );
    setIsEdit(false);
    return;
  };

  const handleChange = (key) => (e) => {
    setFormData((prev) => {
      return { ...prev, [key]: e.target.value };
    });

    return;
  }; // For Add

  const textBox = (key) => {
    return (
      <FormControl
        type={`${key === 'age' ? 'number' : 'text'}`}
        disabled={!isEdit}
        style={{ width: `${isEdit ? '' : `${formData[key]?.length}ch`}` }}
        className={`${isEdit ? 'editable' : 'non-editable'} ${key === 'age' ? 'age' : ''}`}
        value={formData[key]}
        onChange={handleChange(key)}
      />
    );
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (!form.checkValidity() === false) {
      setTrigger(true);
      dispatch(createLiveBirthFormRecord({ ...formData }));
      toast.success('Successfully created record.');
      return;
    }
    toast.warning('Fill up the required fields');
    setValidated(true);
  };

  useEffect(() => {
    if (viewForm?.patientID) {
      setTrigger({ ...trigger, patientID: true });
      const patientid = viewForm?.patientID;
      dispatch(getMedicalFormsbyID(patientid));
    }
  }, []);

  useEffect(() => {
    if (Object.keys(viewForm.data).length === 0) {
      setIsAdd(true);
      setIsEdit(true);
    }
  }, [isEdit]);

  useEffect(() => {
    const {
      isSuccess,
      isFailed,
      isFetchViewSuccess,
      isFetchRecordSuccess,
      patientID,
      medicalRecordID,
    } = logs;
    useCreateRecordResponse({
      isSuccess,
      isFailed,
      isFetchViewSuccess,
      isFetchRecordSuccess,
      patientID,
      medicalRecordID,
      trigger,
      setTrigger,
      setIsEdit,
      setShowScreen,
      dispatch,
    });

    if (isFetchRecordSuccess && trigger.recordID) {
      const data = medicalRecordID.mr_live_birth_form;
      setFormData(data);
    }
  }, [logs]);

  return (
    <Form className='live-birth-form' noValidate validated={validated} onSubmit={handleSubmit}>
      <div className='mb-4 black-border-container'>
        <div className='black-row'>
          <div className='header'>
            <p className='left-header'>
              OCRG Form No. 101 <span>( Revised August 2021 )</span>
            </p>

            <div className='center-header'>
              <p>Republic of the Philippines</p>
              <p>OFFICE OF THE CIVIL REGISTRAT GENERAL</p>
              <h2>CERTIFICATE LIVE BIRTH</h2>
            </div>

            <p className='right-header'>(To be accomplished in triplicate using black ink )</p>
          </div>
        </div>

        <div className='black-row'>
          <Row className='m-0'>
            <Col sm={8} className='block-container right-border'>
              <Form.Group className='mb-3 input-group inline-label p-0'>
                <Form.Label>Province</Form.Label>
                <FormControl
                  placeholder='Enter Here'
                  value={formData?.province}
                  onChange={handleChange('province')}
                />
              </Form.Group>

              <Form.Group className='input-group inline-label p-0'>
                <Form.Label>City / Municipality</Form.Label>
                <FormControl
                  placeholder='Enter Here'
                  value={formData?.city}
                  onChange={handleChange('city')}
                />
              </Form.Group>
            </Col>
            <Col className='block-container'>
              <Form.Group className='input-group p-0'>
                <Form.Label>Register No.</Form.Label>
                <FormControl
                  placeholder='Enter Here'
                  value={formData?.register_no}
                  onChange={handleChange('register_no')}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>

        <div className='black-row'>
          <div className='block-with-sidebar'>
            <div className='side-header right-border'>
              <h3>CHILD</h3>
            </div>
            <div className='main-content'>
              <div className='black-row'>
                <Row className='m-0'>
                  <Col className='block-container'>
                    <h4 className='mb-2'>1. NAME</h4>

                    <div className='inline-group'>
                      <Form.Group className='input-group p-0'>
                        <Form.Label>( First )</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.fname}
                          onChange={handleChange('fname')}
                        />
                      </Form.Group>

                      <Form.Group className='input-group p-0'>
                        <Form.Label>( Middle )</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.mname}
                          onChange={handleChange('mname')}
                        />
                      </Form.Group>

                      <Form.Group className='input-group p-0'>
                        <Form.Label>( Last )</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.lname}
                          onChange={handleChange('lname')}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className='black-row'>
                <Row className='m-0'>
                  <Col sm={1} className='block-container right-border'>
                    <h4 className='mb-2'>2. SEX</h4>

                    <div className='selections'>
                      <Form.Check
                        type='radio'
                        name='sex'
                        label='Male'
                        value={formData?.sex}
                        onChange={handleChange('sex')}
                      />
                      <Form.Check
                        type='radio'
                        name='sex'
                        label='Female'
                        value={formData?.sex}
                        onChange={handleChange('sex')}
                      />
                    </div>
                  </Col>

                  <Col sm={3} className='block-container right-border'>
                    <h4 className='mb-2'>3. APPROXIMATE AGE WHEN FOUND</h4>

                    <Form.Group className='input-group p-0'>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.approximate_age_when_found}
                        onChange={handleChange('approximate_age_when_found')}
                      />
                    </Form.Group>
                  </Col>

                  <Col sm={3} className='block-container right-border'>
                    <h4 className='mb-2'>4. DATE WHEN FOUND</h4>
                    <div className='inline-group mb-2'>
                      <Form.Group className='input-group p-0'>
                        <Form.Label>( Day )</Form.Label>
                      </Form.Group>

                      <Form.Group className='input-group p-0'>
                        <Form.Label>( Month )</Form.Label>
                      </Form.Group>

                      <Form.Group className='input-group p-0'>
                        <Form.Label>( Year )</Form.Label>
                      </Form.Group>
                    </div>
                    <div className='inline-group'>
                      <DropdownDate
                        classes={{
                          dateContainer: 'date-container',
                          yearContainer: 'year-container',
                          monthContainer: 'month-container',
                          dayContainer: 'day-container',
                          year: 'dropdown-year',
                          month: 'dropdown-month',
                          day: 'dropdown-day',
                        }}
                        order={[
                          DropdownComponent.day,
                          DropdownComponent.month,
                          DropdownComponent.year,
                        ]}
                        selectedDate={date.selectedDate}
                        options={{
                          yearReverse: true,
                          monthShort: true,
                          monthCaps: true,
                        }}
                        onDateChange={(date) => {
                          const dateconfig = Moment(date).format('YYYY-MM-DD');
                          const date_separete = dateconfig.split('-');
                          setFormData((prev) => {
                            return {
                              ...prev,
                              day_when_found: date_separete[2],
                              month_when_found: date_separete[1],
                              year_when_found: date_separete[0],
                            };
                          });
                          setDate((prev) => {
                            return { ...prev, selectedDate: Moment(date).format('YYYY-MM-DD') };
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col sm={2} className='block-container right-border'>
                    <h4 className='mb-2'>5. TIME WHEN FOUND</h4>
                    <Form.Group className='input-group p-0'>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.time_when_found}
                        onChange={handleChange('time_when_found')}
                      />
                    </Form.Group>
                  </Col>

                  <Col sm={3} className='block-container'>
                    <h4 className='mb-2'>6. APPROXIMATE DATE OF BIRTH</h4>

                    <div className='inline-group mb-2'>
                      <Form.Group className='input-group p-0'>
                        <Form.Label>( Day )</Form.Label>
                      </Form.Group>

                      <Form.Group className='input-group p-0'>
                        <Form.Label>( Month )</Form.Label>
                      </Form.Group>

                      <Form.Group className='input-group p-0'>
                        <Form.Label>( Year )</Form.Label>
                      </Form.Group>
                    </div>
                    <div className='inline-group'>
                      <DropdownDate
                        classes={{
                          dateContainer: 'date-container',
                          yearContainer: 'year-container',
                          monthContainer: 'month-container',
                          dayContainer: 'day-container',
                          year: 'dropdown-year',
                          month: 'dropdown-month',
                          day: 'dropdown-day',
                        }}
                        order={[
                          DropdownComponent.day,
                          DropdownComponent.month,
                          DropdownComponent.year,
                        ]}
                        selectedDate={dateofDeath.selectedDate}
                        options={{
                          yearReverse: true,
                          monthShort: true,
                          monthCaps: true,
                        }}
                        // value={formData?.approximate_day_of_birth}
                        onDateChange={(date) => {
                          const dateconfig = Moment(date).format('YYYY-MM-DD');
                          const date_separete = dateconfig.split('-');
                          setFormData((prev) => {
                            return {
                              ...prev,
                              approximate_day_of_birth: date_separete[2],
                              approximate_month_of_birth: date_separete[1],
                              approximate_year_of_birth: date_separete[0],
                            };
                          });
                          setDateofDeath((prev) => {
                            return { ...prev, selectedDate: Moment(date).format('YYYY-MM-DD') };
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>

              <div className='black-row'>
                <Row className='m-0'>
                  <Col className='block-container'>
                    <h4 className='mb-2'>7. PLACE WHERE FOUND</h4>

                    <div className='inline-group'>
                      <Form.Group className='input-group p-0'>
                        <Form.Label>( House No., St., Barangay )</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.place_where_found}
                          onChange={handleChange('place_where_found')}
                        />
                      </Form.Group>

                      <Form.Group className='input-group p-0'>
                        <Form.Label>( City / Municipality )</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.city_where_found}
                          onChange={handleChange('city_where_found')}
                        />
                      </Form.Group>

                      <Form.Group className='input-group p-0'>
                        <Form.Label>( Province )</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.province_where_found}
                          onChange={handleChange('province_where_found')}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className='black-row'>
                <Row className='m-0'>
                  <Col className='block-container right-border'>
                    <h4 className='mb-2'>8. COLOR OF THE EYES</h4>

                    <Form.Group className='input-group p-0'>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.color_of_the_eyes}
                        onChange={handleChange('color_of_the_eyes')}
                      />
                    </Form.Group>
                  </Col>

                  <Col className='block-container right-border'>
                    <h4 className='mb-2'>9. COLOR OF THE HAIR</h4>

                    <Form.Group className='input-group p-0'>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.color_of_the_hair}
                        onChange={handleChange('color_of_the_hair')}
                      />
                    </Form.Group>
                  </Col>

                  <Col className='block-container'>
                    <h4 className='mb-2'>10. DISTINCT BODY FEATURES OR MARKS</h4>

                    <Form.Group className='input-group p-0'>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.distinct_body_features}
                        onChange={handleChange('distinct_body_features')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <div className='black-row last-row'>
                <Row className='m-0'>
                  <Col className='block-container right-border'>
                    <h4 className='mb-2'>11. CONDITION OF CHILD WHEN FOUND</h4>

                    <div className='selections inline'>
                      <Form.Check
                        type='radio'
                        name='condition'
                        label='Healthy'
                        value={formData?.condition_of_child_when_found}
                        onChange={handleChange('condition_of_child_when_found')}
                      />
                      <Form.Check
                        type='radio'
                        name='condition'
                        label='with Health Issues'
                        value={formData?.condition_of_child_when_found}
                        onChange={handleChange('condition_of_child_when_found')}
                      />
                    </div>
                  </Col>

                  <Col className='block-container'>
                    <h4 className='mb-2'>12. CITIZENSHIP OF THE CHILD</h4>

                    <Form.Group className='input-group p-0'>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.citizenship_of_child}
                        onChange={handleChange('citizenship_of_child')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>

        <div className='black-row'>
          <div className='block-with-sidebar'>
            <div className='side-header right-border'>
              <h3>FINDER</h3>
            </div>
            <div className='main-content'>
              <div className='black-row'>
                <Row className='m-0'>
                  <Col className='block-container'>
                    <h4 className='mb-2'>13. NAME</h4>

                    <div className='inline-group'>
                      <Form.Group className='input-group p-0'>
                        <Form.Label>( First )</Form.Label>
                        <FormControl
                          required
                          placeholder='Enter Here'
                          value={formData?.finder_fname}
                          onChange={handleChange('finder_fname')}
                        />
                      </Form.Group>

                      <Form.Group className='input-group p-0'>
                        <Form.Label>( Middle )</Form.Label>
                        <FormControl
                          required
                          placeholder='Enter Here'
                          value={formData?.finder_mname}
                          onChange={handleChange('finder_mname')}
                        />
                      </Form.Group>

                      <Form.Group className='input-group p-0'>
                        <Form.Label>( Last )</Form.Label>
                        <FormControl
                          required
                          placeholder='Enter Here'
                          value={formData?.finder_lname}
                          onChange={handleChange('finder_lname')}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className='black-row'>
                <Row className='m-0'>
                  <Col sm={2} className='block-container right-border'>
                    <h4 className='mb-2'>14. SEX</h4>

                    <div className='selections'>
                      <Form.Check
                        type='radio'
                        name='sex'
                        label='Male'
                        value={formData?.finder_sex}
                        onChange={handleChange('finder_sex')}
                      />
                      <Form.Check
                        type='radio'
                        name='sex'
                        label='Female'
                        value={formData?.finder_sex}
                        onChange={handleChange('finder_sex')}
                      />
                    </div>
                  </Col>

                  <Col sm={3} className='block-container right-border'>
                    <h4 className='mb-2'>15.AGE ( completed years )</h4>

                    <Form.Group className='input-group p-0'>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.finder_age}
                        onChange={handleChange('finder_age')}
                      />
                    </Form.Group>
                  </Col>

                  <Col sm={3} className='block-container right-border'>
                    <h4 className='mb-2'>16. PHONE / EMAIL</h4>

                    <Form.Group className='input-group p-0'>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.finder_phone_or_email}
                        onChange={handleChange('finder_phone_or_email')}
                      />
                    </Form.Group>
                  </Col>

                  <Col sm={4} className='block-container'>
                    <h4 className='mb-2'>17. OCCUPATION</h4>

                    <Form.Group className='input-group p-0'>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.finder_occupation}
                        onChange={handleChange('finder_occupation')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <div className='black-row last-row'>
                <Row className='m-0'>
                  <Col className='block-container'>
                    <h4 className='mb-2'>18. RESIDENCE</h4>

                    <div className='inline-group'>
                      <Form.Group className='input-group p-0'>
                        <Form.Label>( House No., St., Barangay )</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.finder_residence}
                          onChange={handleChange('finder_residence')}
                        />
                      </Form.Group>

                      <Form.Group className='input-group p-0'>
                        <Form.Label>( City / Municipality )</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.finder_city}
                          onChange={handleChange('finder_city')}
                        />
                      </Form.Group>

                      <Form.Group className='input-group p-0'>
                        <Form.Label>( Province )</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.finder_province}
                          onChange={handleChange('finder_province')}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>

        <div className='black-row'>
          <div className='block-with-sidebar'>
            <div className='side-header right-border'>
              <h3>INFORMAT</h3>
            </div>
            <div className='main-content'>
              <div className='black-row'>
                <Row className='m-0'>
                  <Col className='block-container'>
                    <h4 className='mb-2'>19. NAME</h4>

                    <div className='inline-group'>
                      <Form.Group className='input-group p-0'>
                        <Form.Label>( First )</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.informat_fname}
                          onChange={handleChange('informat_fname')}
                        />
                      </Form.Group>

                      <Form.Group className='input-group p-0'>
                        <Form.Label>( Middle )</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.informat_mname}
                          onChange={handleChange('informat_mname')}
                        />
                      </Form.Group>

                      <Form.Group className='input-group p-0'>
                        <Form.Label>( Last )</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.informat_lname}
                          onChange={handleChange('informat_lname')}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className='black-row'>
                <Row className='m-0'>
                  <Col sm={2} className='block-container right-border'>
                    <h4 className='mb-2'>20. SEX</h4>

                    <div className='selections'>
                      <Form.Check
                        type='radio'
                        name='sex'
                        label='Male'
                        value={formData?.informat_sex}
                        onChange={handleChange('informat_sex')}
                      />
                      <Form.Check
                        type='radio'
                        name='sex'
                        label='Female'
                        value={formData?.informat_sex}
                        onChange={handleChange('informat_sex')}
                      />
                    </div>
                  </Col>

                  <Col sm={3} className='block-container right-border'>
                    <h4 className='mb-2'>21.AGE ( completed years )</h4>

                    <Form.Group className='input-group p-0'>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.informat_age}
                        onChange={handleChange('informat_age')}
                      />
                    </Form.Group>
                  </Col>

                  <Col sm={3} className='block-container right-border'>
                    <h4 className='mb-2'>22. PHONE / EMAIL</h4>

                    <Form.Group className='input-group p-0'>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.informat_phone_or_email}
                        onChange={handleChange('informat_phone_or_email')}
                      />
                    </Form.Group>
                  </Col>

                  <Col sm={4} className='block-container'>
                    <h4 className='mb-2'>23. OCCUPATION</h4>

                    <Form.Group className='input-group p-0'>
                      <FormControl
                        placeholder='Enter Here'
                        value={formData?.informat_occupation}
                        onChange={handleChange('informat_occupation')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <div className='black-row last-row'>
                <Row className='m-0'>
                  <Col className='block-container'>
                    <h4 className='mb-2'>24. RESIDENCE</h4>

                    <div className='inline-group'>
                      <Form.Group className='input-group p-0'>
                        <Form.Label>( House No., St., Barangay )</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.informat_residence}
                          onChange={handleChange('informat_residence')}
                        />
                      </Form.Group>

                      <Form.Group className='input-group p-0'>
                        <Form.Label>( City / Municipality )</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.informat_city}
                          onChange={handleChange('informat_city')}
                        />
                      </Form.Group>

                      <Form.Group className='input-group p-0'>
                        <Form.Label>( Province )</Form.Label>
                        <FormControl
                          placeholder='Enter Here'
                          value={formData?.informat_province}
                          onChange={handleChange('informat_province')}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>

        <div className='black-row'>
          <Row className='m-0 mb-3'>
            <Col className='block-container'>
              <h4 className='mb-2'>25. CERTIFICATE OF INFORMAT</h4>

              <p className='certify mb-3'>
                <b>THIS IS TO CERTIFY</b> that the information given above are true and correct to
                my own knowledge and belief.
              </p>

              <div className='mb-5 signature'>
                <FormControl
                  placeholder='Enter Here'
                  value={formData?.signature_of_informat}
                  onChange={handleChange('signature_of_informat')}
                />
                <p className='over-printed-name'>Signature Over Printed Name of the Informat</p>
              </div>

              <p className='certify mb-3'>
                <b>SUBSCRIBED AND SWORN</b> to before me this {textBox('day')} day of{' '}
                {textBox('month')}, {textBox('year')} at {textBox('place')}, Philippines, affiant
                who exhibited to me his/her Commumity Tax Certificate or any government issued ID{' '}
                {textBox('id_type')} issued on {textBox('id_issued_on')} at{' '}
                {textBox('id_issued_at')}
              </p>
            </Col>
          </Row>

          <Row className='m-0'>
            <Col className='block-container bordered-top-right'>
              <h4 className='mb-2'>26. PREPARED BY</h4>

              <div className='mb-5 signature m-auto'>
                <FormControl
                  placeholder='Enter Here'
                  value={formData?.prepared_by}
                  onChange={handleChange('prepared_by')}
                />
                <p className='over-printed-name'>Signature Over Printed Name</p>
              </div>

              <div className='mb-3 signature m-auto'>
                <Form.Group className='input-group inline-label'>
                  <Form.Label>Title or Position</Form.Label>
                  <FormControl
                    type='text'
                    placeholder='Enter Here'
                    value={formData?.prepared_by_title_or_position}
                    onChange={handleChange('prepared_by_title_or_position')}
                  />
                </Form.Group>
              </div>

              <div className='mb-3 signature m-auto'>
                <Form.Group className='input-group inline-label'>
                  <Form.Label>Date</Form.Label>
                  <FormControl
                    type='date'
                    placeholder='Enter Here'
                    value={formData?.prepared_date}
                    onChange={handleChange('prepared_date')}
                  />
                </Form.Group>
              </div>
            </Col>
            <Col className='block-container'>
              <div className='mb-5 signature m-auto'>
                <FormControl
                  placeholder='Enter Here'
                  value={formData?.signature_of_administering_officer}
                  onChange={handleChange('signature_of_administering_officer')}
                />
                <p className='over-printed-name'>Signature of the Administering Officer</p>
              </div>

              <div className='mb-5 signature m-auto'>
                <FormControl
                  placeholder='Enter Here'
                  value={formData?.name_in_print}
                  onChange={handleChange('name_in_print')}
                />
                <p className='over-printed-name'>Name in Print</p>
              </div>

              <div className='mb-5 signature m-auto'>
                <FormControl
                  placeholder='Enter Here'
                  value={formData?.position_or_title_or_designation}
                  onChange={handleChange('position_or_title_or_designation')}
                />
                <p className='over-printed-name'>Position / Title / Designation</p>
              </div>

              <div className='mb-5 signature m-auto'>
                <FormControl
                  placeholder='Enter Here'
                  value={formData?.address}
                  onChange={handleChange('address')}
                />
                <p className='over-printed-name'>Address</p>
              </div>
            </Col>
          </Row>
        </div>

        <div className='black-row'>
          <Row className='m-0'>
            <Col className='block-container right-border'>
              <h4 className='mb-2'>27. RECEIVED BY</h4>

              <div className='mb-5 signature m-auto'>
                <FormControl
                  placeholder='Enter Here'
                  value={formData?.received_by}
                  onChange={handleChange('received_by')}
                />
                <p className='over-printed-name'>Signature Over Printed Name</p>
              </div>

              <div className='mb-3 signature m-auto'>
                <Form.Group className='input-group inline-label'>
                  <Form.Label>Title or Position</Form.Label>
                  <FormControl
                    type='text'
                    placeholder='Enter Here'
                    value={formData?.received_by_title_or_position}
                    onChange={handleChange('received_by_title_or_position')}
                  />
                </Form.Group>
              </div>

              <div className='mb-3 signature m-auto'>
                <Form.Group className='input-group inline-label'>
                  <Form.Label>Date</Form.Label>
                  <FormControl
                    type='date'
                    placeholder='Enter Here'
                    value={formData?.received_date}
                    onChange={handleChange('received_date')}
                  />
                </Form.Group>
              </div>
            </Col>
            <Col>
              <h4 className='mb-2'>28. REGISTERED AT THE OFFICE OF THE CIVIL REGISTRAR</h4>

              <div className='mb-5 signature m-auto'>
                <FormControl
                  placeholder='Enter Here'
                  value={formData?.registrar_signature_over_printed_name}
                  onChange={handleChange('registrar_signature_over_printed_name')}
                />
                <p className='over-printed-name'>Signature Over Printed Name</p>
              </div>

              <div className='mb-3 signature m-auto'>
                <Form.Group className='input-group inline-label'>
                  <Form.Label>Title or Position</Form.Label>
                  <FormControl
                    type='text'
                    placeholder='Enter Here'
                    value={formData?.registrar_title_or_position}
                    onChange={handleChange('registrar_title_or_position')}
                  />
                </Form.Group>
              </div>

              <div className='mb-3 signature m-auto'>
                <Form.Group className='input-group inline-label'>
                  <Form.Label>Date</Form.Label>
                  <FormControl
                    type='date'
                    placeholder='Enter Here'
                    value={formData?.registrar_date}
                    onChange={handleChange('registrar_date')}
                  />
                </Form.Group>
              </div>
            </Col>
          </Row>
        </div>

        <div className='black-row remarks-row'>
          <Row className='m-0'>
            <Col className='block-container'>
              <h2>REMARKS / ANNOTATIONS ( For LCRO / OCRG Use Only )</h2>
            </Col>
          </Row>
        </div>
      </div>

      <hr />

      <Row className=''>
        <Col className='form-buttons'>
          {/* Buttons to show for Add */}
          <ConditionalRender
            condition={isAdding}
            renderIf={
              <>
                <Button type='submit' className='save' disabled={trigger.trigger}>
                  CREATE RECORD
                </Button>
                <Button
                  onClick={() => {
                    setIsEdit(false);
                    setShowScreen(0);
                  }}
                  className='cancel'
                >
                  CANCEL
                </Button>
              </>
            }
          />

          {/* Buttons to show for Edit/View */}
          <ConditionalRender
            condition={!isAdding}
            renderIf={
              <>
                <ConditionalRender
                  condition={!isEditing}
                  renderIf={
                    <>
                      <Button className='print'>PRINT CERTIFICATE</Button>
                      <Button
                        onClick={() => {
                          setIsEdit(true);
                        }}
                        className='edit'
                      >
                        EDIT CERTIFICATE
                      </Button>
                    </>
                  }
                />

                <ConditionalRender
                  condition={isEditing}
                  renderIf={
                    <>
                      <Button
                        onClick={() => {
                          saveChanges();
                        }}
                        className='save'
                      >
                        SAVE CHANGES
                      </Button>
                      <Button
                        onClick={() => {
                          setIsEdit(false);
                        }}
                        className='cancel'
                      >
                        CANCEL
                      </Button>
                    </>
                  }
                />
              </>
            }
          />
        </Col>
      </Row>
    </Form>
  );
};

export default LiveBirthForm;
