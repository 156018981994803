/* eslint-disable */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

// import components below
import ModalUI from 'components/UI/ModalUI';

// import assets below
import './PhysicalDischargeModal.scss';
import { ReactComponent as CloseSVG } from 'assets/svg/circle-xmark.svg';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// main componet
const PhysicalDischargeModal = (props) => {
  const { status, onToggle, id, onConfirm, setShowProfile } = props;
  const [password, setPassword] = useState('');
  const [condition, setCondition] = useState('');
  const [causeOfDeath, setCauseOfDeath] = useState('');
  const [icdCode, setIcdCode] = useState('');
  const [icdCat, setIcdCat] = useState('');
  const [causeOfDeathOptions, setCauseOfDeathOptions] = useState([]);
  const [button, setButton] = useState(true);
  const navigate = useNavigate();

  const passwordCheck = () => {
    axios.post(`/user/auth/check`, { password: password })
      .then((response) => {

        const params = { condition, causeOfDeath, icdCode, icdCat };
        axios.post(`/patient/discharge/${id}`, params)
          .then((response) => {
            if (response.data.error) {
              toast.warning('Patient have pending clearance request');
            } else {
              setTimeout(() => {
                setShowProfile(false);
                setShowProfile({ isShowProfile: false, profileId: null });
              }, 2000);
              toast.success('Successfully discharged Patient');

              onToggle(!status);
              onConfirm((prevState) => !prevState);
            }
          });

      })
      .catch((error) => {
        toast.error('Invalid Password');
      });
  };

  useEffect(() => {
    if ((password.length > 3 && condition && condition !== 'D') || (condition === 'D' && causeOfDeath)) {
      setButton(false);
    } else {
      setButton(true);
    }
  }, [password, condition, causeOfDeath]);

  useEffect(() => {
    axios.get('/public/ricd10')
      .then((response) => {
        const options = response.data?.map(data => ({
          id: data.id,
          value: data.icd10_code,
          label: data.icd10_desc,
          category: data.icd10_cat,
        }));
        setCauseOfDeathOptions(options);
      });
  }, []);

  const filterOptions = (input) => {
    return causeOfDeathOptions.splice(0, 100).filter((i) => {
      return i.value.toLowerCase().includes(input.toLowerCase()) || i.label.toLowerCase().includes(input.toLowerCase());
    });
  };

  const loadOptions = (input) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterOptions(input));
      }, 1000);
    });
  }

  const handleInputSelectChange = (input, type) => {
    const res = input.replace(/\W/g, '');
    if (type === 'conditions') setCondition(res);
    if (type === 'diagnosis') setCauseOfDeath(input);
    return res;
  };

  const handleCauseOfDeath = (icddesc, icdcode, icdcat) => {
    setCauseOfDeath(icddesc);
    setIcdCode(icdcode);
    setIcdCat(icdcat);
  };

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      padding: '0.45em',
      color: '#404040',
      borderColor: '#c8c8c8',
      borderRadius: '10px'
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: '100'
    })
  };

  const conditionsOptions = [
    { value: 'RI', label: 'Recovered / Improved' },
    { value: 'H', label: 'Home Against Medical Advice' },
    { value: 'T', label: 'Transferred' },
    { value: 'A', label: 'Absconded' },
    { value: 'U', label: 'Unimproved' },
    { value: 'D', label: 'Died' }
  ]


  return (
    <div className='physicalDischargeModal'>
      <ModalUI>
        <Modal.Dialog>
          <Modal.Header>
            <h4>PATIENT PHYSICAL DISCHARGE</h4>
            <Button onClick={() => onToggle(!status)} variant='transparent'>
              <CloseSVG />
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className='physicalDischargeModal__content'>
              <div className='physicalDischargeModal__header'>
                <h1>Warning!</h1>
              </div>
              <div className='physicalDischargeModal__body'>
                <h6>You are about to officially discharge this patient from the system.</h6>
                {/* <h6>
                  Once discharged, the room will be tagged as <b>For Cleaning</b> and the
                  patient will be removed for your unit&#39;s Patient List.
                </h6> */}
                <Form.Group className='confirmation'>
                  <Form.Label>Condition on Discharge</Form.Label>
                  <div style={{ width: '70%', margin: '0 auto' }}>
                    <Select
                      options={conditionsOptions}
                      onChange={e => (handleInputSelectChange(e.value, 'conditions'))}
                      placeholder="Select condition"
                      isSearchable={false}
                      styles={selectStyles}
                    />
                  </div>
                  {/* <Form.Select onChange={(e) => setCondition(e.target.value)} defaultValue=''>
                    <option value='' disabled>Select condition</option>
                    <option value='RI'>Recovered / Improved</option>
                    <option value='H'>Home Against Medical Advice</option>
                    <option value='T'>Transferred</option>
                    <option value='A'>Absconded</option>
                    <option value='U'>Unimproved</option>
                    <option value='D'>Died</option>
                  </Form.Select> */}
                </Form.Group>
                {condition === 'D' && (
                  <Form.Group className='confirmation'>
                    <Form.Label>Cause of Death</Form.Label>
                    <div style={{ width: '70%', margin: '0 auto' }}>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        loadOptions={loadOptions}
                        onChange={e => (handleCauseOfDeath(e.label, e.value, e.category))}
                        placeholder="Search Diagnosis"
                        styles={selectStyles}
                      />
                    </div>
                    {/* <Form.Control type='text' onChange={(e) => setCauseOfDeath(e.target.value)} /> */}
                  </Form.Group>
                )}
                <Form.Group className='confirmation'>
                  <Form.Label>Please enter your Password to proceed.</Form.Label>
                  <Form.Control type='password' onChange={(e) => setPassword(e.target.value)} />
                </Form.Group>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='light-red'
              disabled={button}
              onClick={() => {
                passwordCheck();
              }}
            >
              DISCHARGE PATIENT
            </Button>
            <Button onClick={() => onToggle(!status)} variant='cancel'>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </ModalUI>
    </div>
  );
};

export default PhysicalDischargeModal;
