/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
// import core & vendor packages below
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Table from 'components/ReactTable';
import { Button, Form } from 'react-bootstrap';

// import components below
import { selectPatientData } from 'reducers/patientReducer';

// import assets below
import './Table.scss';

// main component
const AddDoctorTable = ({ setShowModal, state, setState }) => {
  const data = useSelector(selectPatientData);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_ACL}/api/users?roles[]=Doctor`)
      .then((response) => {
        setState(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  const tableColumns = React.useMemo(
    () => [
      { Header: "Physician's ID", accessor: 'id' }, // accessor is the "key" in the data
      { Header: "Physician's Name", accessor: 'physicians_name' }, // accessor is the "key" in the data
      { Header: 'Specialty / Sub-Specialty', accessor: 'specialty_sub_specialty' },
      { Header: 'Status', accessor: 'status' },
    ],
    [],
  );

  const tableData = React.useMemo(
    () =>
      state?.map((dd) => ({
        id: dd?.id,
        physicians_name: dd?.attributes?.full_name,
        specialty_sub_specialty: dd?.attributes?.specialty,
        status: (
          <span
            className={`${
              dd?.attributes?.employment?.employment_status === 'Active' ? `bg-success` : `bg-info`
            } `}
          >
            {dd?.attributes?.employment?.employment_status}
          </span>
        ),
      })),
    [state],
  );

  // const handleTableAction = (eventKey, id) => {
  //   const row = state.find(dd => dd.id === id);
  //   const action = {
  //     profile: () => { },
  //     view: () => { },
  //     edit: () => { },
  //     delete: () => { }
  //   }

  //   action[eventKey]()
  // }

  return (
    <div className='nursing-add-doctor-table'>
      <Table
        data={tableData}
        columns={tableColumns}
        // actions={{ hasView: true }}
        // onAction={handleTableAction}
        customAction={(id) => (
          <span
            onClick={() => {
              setShowModal((prevState) => {
                return {
                  ...prevState,
                  isAddDoctor: true,
                  profileData: state.find((dd) => dd.id === id),
                  doctorId: id,
                };
              });
            }}
            className='select-button'
          >
            Select
          </span>
        )}
        filter={{
          top: (
            <div className='buttons'>
              <Button className='active'>All Doctors</Button>
              <Form.Group className='specialty'>
                <Form.Select defaultValue={''}>
                  <option value='' hidden>
                    Specialty
                  </option>
                  <option value='1'>One</option>
                  <option value='2'>Two</option>
                  <option value='3'>Three</option>
                </Form.Select>
              </Form.Group>
            </div>
          ),
        }}
        pagination={['BOTTOM']}
      />
    </div>
  );
};

AddDoctorTable.defaultProps = {
  state: [],
  setState: () => {},
  setShowModal: () => {},
};

AddDoctorTable.propTypes = {
  state: PropTypes.array,
  setState: PropTypes.func,
  setShowModal: PropTypes.func,
};

export default AddDoctorTable;
