/* eslint-disable newline-per-chained-call */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Modal, Form, FormControl, Pagination, Table } from 'react-bootstrap';

// import assets below
import CartModal from './CartModal';
import AddOrderTable from './Table';
import FormUI from 'components/UI/FormUI';
import { selectPatientItem, showPatient } from 'reducers/patientReducer';
import { getOrderListItems, addOrder, selectnurse, resetLogState } from 'reducers/nurseReducer';

// CSS
import './style.scss';
import cartLogo from 'assets/svg/cart-shopping.svg';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';
import { toast } from 'react-toastify';

// main component
const ModalAddOrder = ({ showProfile, setShowProfile }) => {
  const dispatch = useDispatch();

  const nurseState = useSelector(selectnurse);
  const patientProfile = useSelector(selectPatientItem);

  const { addOrderlogs } = nurseState;

  const [active, setActive] = useState('1');
  const [cartData, setCartData] = useState([]);
  const [openCart, setOpenCart] = useState(false);
  const [tableState, setTableState] = useState([]);

  const [search, setSearch] = useState({
    search: '',
    handleSearch: false,
  });

  const [userData, setUserData] = useState({
    age: '',
    sex: '',
    MRN: '',
    full_name: '',
    patient_id: '',
    date_of_birth: '',
  });

  const getItems = (status = null) => {
    // eslint-disable-next-line prefer-const
    let params = {
      id: '3',
      payload: {
        q: userData?.code,
        per_page: 100000,
        include: ['item'],
        is_new: false,
      },
    };

    if (status) {
      params.payload.is_medicine = status;
    }

    dispatch(getOrderListItems(params));
  };

  const closeModal = () => {
    setSearch({
      search: '',
      handleSearch: false,
    });

    setUserData({
      age: '',
      sex: '',
      MRN: '',
      full_name: '',
      patient_id: '',
      date_of_birth: '',
    });

    setCartData([]);

    setShowProfile((prevState) => {
      return {
        ...prevState,
        addOrder: false,
      };
    });
  };

  const refreshData = () => {
    if (showProfile?.profileId && showProfile?.isShowProfile) {
      dispatch(showPatient(showProfile.profileId));
    }
  };

  const handleSubmit = () => {
    const params = {
      patient_id: userData?.patient_id,
      patient_name: userData?.full_name,
      department_id: '3', // 3 is for Nursing Department
      items: cartData.map((ddd) => {
        return {
          item_id: ddd?.itemData?.id,
          quantity: ddd?.qty,
          unit: ddd?.selectedUnit,
        };
      }),
    };

    dispatch(addOrder(params));
  };

  setStateFromStore(patientProfile, setUserData, showProfile);

  getOrderResults(addOrderlogs, closeModal, dispatch, refreshData);

  useEffect(() => {
    // console.log('userData', userData);
  }, [userData]);

  return (
    <>
      <Modal
        size='lg'
        show={showProfile.addOrder}
        onHide={() => {
          closeModal();
        }}
        aria-labelledby='ORDER REQUEST TO PHARMACY Modal'
        className='order-request-modal'
      >
        <Modal.Body>
          <div className='order-request'>
            <div className='d-flex header'>
              <p>ORDER REQUEST TO PHARMACY</p>

              <CircleXMark
                style={{ width: '2em', cursor: 'pointer' }}
                onClick={() => {
                  closeModal();
                }}
              />
            </div>
          </div>

          <FormUI>
            <Form className='order-request-form'>
              <Row>
                <Col>
                  <Form.Group className='patient-profile mb-4'>
                    <div className='first-row'>
                      <h3>{userData?.full_name}</h3>
                      <p>{`${userData?.age} / ${userData?.sex}`}</p>
                    </div>
                    <div className='second-row'>
                      <h3>{userData?.MRN}</h3>
                      <p>{userData?.date_of_birth}</p>
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col sm={3}>
                  <Form.Group className='mb-4'>
                    <Form.Label>Code</Form.Label>
                    <FormControl
                      value={search?.search}
                      onChange={(e) => {
                        setSearch((prev) => {
                          return {
                            ...prev,
                            search: e.target.value,
                          };
                        });
                      }}
                      placeholder='Enter Code'
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='pb-4 category-buttons'>
                    <Button
                      onClick={() => {
                        setSearch((prev) => {
                          return {
                            ...prev,
                            handleSearch: true,
                          };
                        });
                      }}
                      className='add-button'
                      variant='primary'
                    >
                      Go
                    </Button>
                    <Button
                      className='cancel-button'
                      vvariant='secondary'
                      onClick={() =>
                        setSearch((prevState) => {
                          return {
                            ...prevState,
                            search: '',
                            handleSearch: true,
                          };
                        })
                      }
                    >
                      Reset
                    </Button>
                  </Form.Group>
                </Col>
              </Row>

              <h2 className='mb-3 header'>Order Item List</h2>

              <Row>
                <Col sm={8}>
                  {/* Table */}
                  <AddOrderTable
                    active={active}
                    search={search}
                    getItems={getItems}
                    cartData={cartData}
                    setActive={setActive}
                    tableState={tableState}
                    setCartData={setCartData}
                    setTableState={setTableState}
                  />
                </Col>
                <Col sm={4}>
                  <div className='cart-cont'>
                    <div className='header'>
                      <p>Your Cart</p>

                      <div className='cart-logo-cont'>
                        {cartData && cartData.length > 0 && (
                          <div className='notification'>{cartData.length}</div>
                        )}
                        <img
                          src={cartLogo}
                          onClick={() => {
                            setOpenCart(true);
                            setShowProfile((prevState) => {
                              return { ...prevState, addOrder: false };
                            });
                          }}
                          className='cart-logo'
                        />
                      </div>
                    </div>
                    <div className='body'>
                      {cartData &&
                        cartData.length > 0 &&
                        cartData.map((data) => {
                          return (
                            <div key={data?.id} className='list-data'>
                              <p>{`(${data?.code}) ${data?.name}`}</p>
                              <p>{`${data?.qty} QTY. Price: ${setPriceByType(data)}`}</p>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className='mt-4 buttons-cont'>
                    <Button
                      disabled={cartData.length === 0}
                      className='add-button'
                      variant='primary'
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      ADD ORDER REQUEST
                    </Button>
                    <Button
                      className='cancel-button'
                      vvariant='secondary'
                      onClick={() => {
                        closeModal();
                      }}
                    >
                      Cancel
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </FormUI>
        </Modal.Body>
      </Modal>

      <CartModal
        openCart={openCart}
        setOpenCart={setOpenCart}
        cartData={cartData}
        setCartData={setCartData}
        setShowProfile={setShowProfile}
      />
    </>
  );
};

const setPriceByType = (data) => {
  const { selectedUnit } = data;

  if (selectedUnit === 'piece') {
    return data?.unit_price;
  }

  return data?.box_price;
};

const getOrderResults = (addOrderlogs, closeModal, dispatch, refreshData) => {
  useEffect(() => {
    const { isSuccess, isFailed } = addOrderlogs;

    if (isSuccess) {
      closeModal();
      dispatch(resetLogState());
      toast.success('Successfully added order');
      refreshData();
    }

    if (isFailed) {
      toast.error('Something went wrong.');
      dispatch(resetLogState());
    }
  }, [addOrderlogs]);
};

const setStateFromStore = (patientProfile, setUserData, showProfile) => {
  useEffect(() => {
    if (patientProfile && showProfile?.addOrder) {
      setUserData((prevState) => {
        return {
          ...prevState,
          patient_id: patientProfile?.id,
          full_name: patientProfile?.full_name,
          age: patientProfile?.age,
          sex: patientProfile?.sex,
          MRN: patientProfile?.MRN,
          date_of_birth: new Date(patientProfile?.date_of_birth).toUTCString().slice(0, -13),
        };
      });
    }
  }, [patientProfile, showProfile]);
};

export default ModalAddOrder;
