/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-const-assign */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable multiline-comment-style */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import axios from 'axios';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { Row, Col, Button, Modal, Form, FormControl, InputGroup, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import TableUI from 'components/UI/TableUI';
import { selectPatientItem, showPatient, selectPatient } from 'reducers/patientReducer';
import moment from 'moment';

// components
import FormUI from 'components/UI/FormUI';

import './Modal.scss';

// import assets below
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

// main component
const PhilhealthModal = ({ showProfile, setShowProfile, setState }) => {
  const dispatch = useDispatch();

  const patientProfile = useSelector(selectPatientItem);

  const [totalAdjustments, setTotalAdjustments] = useState(0);
  const [userData, setUserData] = useState({
    name_of_payor: '',
    amount: '',
    billId: '',
    patientId: '',
  });
  const handleChange = (key) => (e) => {
    setUserData({
      ...userData,
      [key]: e.target.value,
      billId: showProfile?.billId,
      patientId: showProfile?.profileId,
    });
  };

  const calculateTotal = () => {
    let sum = 0;
    patientProfile?.bills[0]?.bill_adjustments &&
      patientProfile?.bills[0]?.bill_adjustments?.map((item, index) => {
        sum += item?.amount;
      });
    setTotalAdjustments(sum);
  };

  const handleSubmit = () => {
    axios
      .post(`/bill_adjustment`, userData)
      .then((response) => {
        // setShowProfile((prevState) => {
        //   return { ...prevState, isShowProfile: false };
        // });
        setUserData({
          ...userData,
          name_of_payor: '',
          amount: '',
          billId: '',
          patientId: '',
        });
        toast.success('Success!');
        dispatch(showPatient(showProfile?.profileId));
        calculateTotal();
      })
      .catch((error) => {
        toast.warning(error.response.data.errors[0].msg);
      });
  };

  useEffect(() => {
    dispatch(showPatient(showProfile?.profileId));
  }, [showProfile]);

  useEffect(() => {
    calculateTotal();
  }, [calculateTotal]);

  return (
    <Modal
      size='lg'
      show={showProfile.isShowProfile}
      onHide={() =>
        setShowProfile((prevState) => {
          return {
            ...prevState,
            isShowProfile: false,
          };
        })
      }
      aria-labelledby='Lab Request Modal'
      className='add-procedure-modal'
    >
      <Modal.Body>
        <div className='add-procedure-request'>
          <div className='d-flex header'>
            <p>Add Discount Payor</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => {
                setShowProfile((prevState) => {
                  return { ...prevState, isShowProfile: false };
                });
              }}
            />
          </div>
        </div>

        <FormUI>
          <Form className='procedure-form'>
            <Row>
              <Col md={8}>
                <Form.Group className='mb-4'>
                  <Form.Label>Name of Payor</Form.Label>
                  <FormControl
                    type='text'
                    className='bg-white'
                    placeholder='Enter Here'
                    required={true}
                    value={userData.name_of_payor}
                    onChange={handleChange('name_of_payor')}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className='mb-4'>
                  <Form.Label>Amount</Form.Label>
                  <FormControl
                    type='number'
                    required={true}
                    className='bg-white'
                    value={userData.amount}
                    placeholder='Enter Here'
                    onChange={handleChange('amount')}
                    onKeyDown={(evt) =>
                      (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '.') &&
                      evt.preventDefault()
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className='mt-4 buttons-cont'>
                  <Button variant='primary' className='add-button' onClick={() => handleSubmit()}>
                    Confirm
                  </Button>
                  <Button
                    className='cancel-button'
                    vvariant='secondary'
                    onClick={() => {
                      setShowProfile((prevState) => {
                        return { ...prevState, isShowProfile: false };
                      });
                    }}
                  >
                    Cancel
                  </Button>
                </Form.Group>
              </Col>
            </Row>

            <div className='adjustments'>
              <Row>
                <Col>
                  <TableUI>
                    <Table className=''>
                      <thead>
                        <tr>
                          <th>Name of Payor</th>
                          <th>Amount</th>
                          <th>Timestamp</th>
                        </tr>
                      </thead>
                      <tbody>
                        {patientProfile?.bills[0]?.bill_adjustments &&
                          patientProfile?.bills[0]?.bill_adjustments?.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td> {item?.name_of_payor}</td>
                                <td>Php {item?.amount}</td>
                                <td> {moment(item?.createdAt).format('MMMM-DD-YYYY hh:mm A')}</td>
                              </tr>
                            );
                          })}
                        <tr>
                          <td id='blue-bg' colSpan={2}>
                            <b>TOTAL ADJUSTMENTS</b>
                          </td>
                          <td id='red-bg'>
                            <b>Php {totalAdjustments}</b>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </TableUI>
                </Col>
              </Row>
            </div>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

PhilhealthModal.defaultProps = {
  showProfile: () => {},
  setShowProfile: () => {},
};

PhilhealthModal.propTypes = {
  showProfile: PropTypes.func,
  setShowProfile: PropTypes.func,
};

export default PhilhealthModal;
