/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

// Packages
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Modal, Form, FormControl, Table } from 'react-bootstrap';

// Components
import FormUI from 'components/UI/FormUI';
import TableUI from 'components/UI/TableUI';

// Assets
import './style.scss';
import { ReactComponent as CircleXMark } from 'assets/svg/circle-xmark.svg';

// main component
const IssueToDepartmentModal = ({ showProfile, setShowProfile }) => {
  const [userData, setUserData] = useState([
    {
      id: 1,
      item_code: 'OFF000111',
      item_name: 'Bond Paper Short 1 Ream',
      OUM: 'Ream',
      quantity_issue: 1,
    },
    {
      id: 2,
      item_code: 'OFF000111',
      item_name: 'Bond Paper Short 1 Ream',
      OUM: 'Ream',
      quantity_issue: 2,
    },
  ]);

  const handleChange = (key) => (e) => {
    setUserData({
      ...userData,
      [key]: e.target.value,
    });
  };

  const handleQuantity = (id, e) => {
    const newData = userData.map((data) => {
      if (data.id === id) {
        return { ...data, quantity_issue: e.target.value };
      }

      return data;
    });

    setUserData(newData);
  };

  return (
    <Modal
      size='lg'
      show={showProfile.showIssue}
      onHide={() =>
        setShowProfile((prevState) => {
          return {
            ...prevState,
            showIssue: false,
          };
        })
      }
      aria-labelledby='ISSUE TO DEPARTMENT'
      className='issue-modal'
    >
      <Modal.Body>
        <div className='issue-request'>
          <div className='d-flex header'>
            <p>ISSUE TO DEPARTMENT</p>

            <CircleXMark
              style={{ width: '2em', cursor: 'pointer' }}
              onClick={() => {
                setShowProfile((prevState) => {
                  return { ...prevState, showIssue: false };
                });
              }}
            />
          </div>
        </div>

        <FormUI>
          <Form className='issue-form'>
            <Row className='mb-3'>
              <Col>
                <div className='issue-table'>
                  <TableUI>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Item Code</th>
                          <th>Item Name</th>
                          <th>OUM</th>
                          <th>Quantity Issue</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userData &&
                          userData.length > 0 &&
                          userData.map((data) => {
                            return (
                              <tr key={data.id}>
                                <td>{data.item_code}</td>
                                <td>{data.item_name}</td>
                                <td>{data.OUM}</td>
                                <td>
                                  <FormControl
                                    type='number'
                                    className='bg-white'
                                    value={data.quantity_issue}
                                    onChange={(e) => handleQuantity(data.id, e)}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </TableUI>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mt-4 buttons-cont'>
                  <Button className='add-button' variant='primary'>
                    ISSUE TO DEPARTMENT
                  </Button>
                  <Button
                    className='cancel-button'
                    onClick={() => {
                      setShowProfile((prevState) => {
                        return { ...prevState, showIssue: false };
                      });
                    }}
                  >
                    Cancel
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormUI>
      </Modal.Body>
    </Modal>
  );
};

IssueToDepartmentModal.defaultProps = {
  showProfile: {},
  setShowProfile: () => {},
};

IssueToDepartmentModal.propTypes = {
  showProfile: PropTypes.object,
  setShowProfile: PropTypes.func,
};

export default IssueToDepartmentModal;
