/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
// import core & vendor packages below
import { useEffect, useState } from 'react';
import {
  Table,
  Button,
  Dropdown,
  OverlayTrigger,
  Popover,
  Row,
  Col,
  Form,
  Pagination,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import moment from 'moment';

// import components below
import TableUI from 'components/UI/TableUI';
import { selectPatientData } from 'reducers/patientReducer';
// import { selectAppointmentData } from 'reducers/appointmentReducer';
import PaginationUI from 'components/UI/PaginationUI';

// import assets below
import './Table.scss';
import { ReactComponent as ThreeDotsSVG } from 'assets/svg/three-dots-svgrepo-com.svg';
import { ReactComponent as ArrowLeftSVG } from 'assets/svg/angle-left.svg';
import { ReactComponent as ArrowRightSVG } from 'assets/svg/angle-right.svg';
import { ReactComponent as EyeIcon } from 'assets/svg/eye-icon.svg';
import { ReactComponent as CartIcon } from 'assets/svg/cart-plus.svg';
import axios from 'axios';

// main component
const WorklistTable = ({ state, setState, setShowProfile }) => {
  const data = useSelector(selectPatientData);
  // const appointment = useSelector(selectAppointmentData);
  // const medication = useSelector(selectMedicationData);

  const [lengthOfStay, setLengthOfStay] = useState('');

  const calculateDayDiff = (admission_date) => {
    console.log(admission_date);
    if (admission_date && admission_date.length > 0) {
      const date = new Date();
      date.setDate(date.getDate());

      const date1 = new Date(admission_date);
      const date2 = new Date(date);
      const diffTime = Math.abs(date2 - date1);
      setLengthOfStay(Math.ceil(diffTime / (1000 * 60 * 60 * 24)));
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }

  };

  useEffect(() => {
    axios
      .get('/medication')
      .then((response) => {
        setState(response.data.data);
      }, [])
      .catch((err) => {
        console.log('AXIOS ERROR: ', err);
      });
  }, []);

  return (
    <div className='worklist-action-table'>
      <TableUI>
        <Row>
          <Col sm={7}>
            <div className='buttons mt-3'>
              <Button className='active'><small>All Patients</small></Button>
              <Button className=''><small>Triage</small></Button>
              <Button className=''><small>Awaiting Bed</small></Button>
              <Button className=''><small>For Admission</small></Button>
              <Button className=''><small>For Discharge</small></Button>
            </div>
          </Col>
          <Col sm={5}>
            <div className='assesment-pagination mt-3'>
              <PaginationUI>
                <span className='summary'>{2} Result</span>
                <div className='selectWrapper'>
                  <Form.Label>Show: </Form.Label>
                  <Form.Select>
                    <option>10</option>
                    <option>15</option>
                    <option>20</option>
                    <option>25</option>
                    <option>30</option>
                  </Form.Select>
                </div>
                <Pagination>
                  <Pagination.Prev>
                    <ArrowLeftSVG />
                  </Pagination.Prev>
                  <Pagination.Item active>{1}</Pagination.Item>
                  <Pagination.Item>{2}</Pagination.Item>
                  <Pagination.Item>{3}</Pagination.Item>
                  <Pagination.Item>{4}</Pagination.Item>
                  <Pagination.Item>{5}</Pagination.Item>
                  <Pagination.Next>
                    <ArrowRightSVG />
                  </Pagination.Next>
                </Pagination>
              </PaginationUI>
            </div>
          </Col>
        </Row>

        <Table responsive>
          <thead>
            <tr>
              <th>Room Bed</th>
              <th>Patient ID</th>
              <th>Patient Name</th>
              <th>Date of Birth</th>
              <th>Age / Sex</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {state?.map((item) => {

              return (
                <tr key={item.id}>
                  <td>{item?.assigned_room?.room?.bed ? item?.assigned_room?.room?.bed : '-'}</td>
                  <td>{item?.id}</td>
                  <td>{item?.full_name}</td>
                  <td>{`${item?.date_of_birth}`}</td>
                  <td>{`${item?.age} / ${item?.sex}`}</td>
                  {/* <td>
                    <span
                      className={`status ${item?.patient_status === 'admitted' ? 'admitted' : ''}`}
                    >
                      {item.patient_status}
                    </span>
                  </td> */}
                  <td>
                    <OverlayTrigger
                      trigger='focus'
                      placement='bottom'
                      overlay={
                        <Popover id='popover-positioned-bottom'>
                          <Popover.Body className='d-flex justify-content-between p-0'>
                            <CartIcon
                              className='p-1 text-success'
                              style={{ width: '2em', cursor: 'pointer' }}
                              onClick={() => {
                                setShowProfile((prevState) => {
                                  return {
                                    ...prevState,
                                    showWorkList: false,
                                    showAddOrder: true,
                                    showProfile: false,
                                    profileData: item,
                                    profileId: item.id,
                                  };
                                });
                              }}
                            />
                            <EyeIcon
                              className='p-1 text-success'
                              style={{ width: '2em', cursor: 'pointer' }}
                              onClick={() => {

                                setShowProfile((prevState) => {
                                  return {
                                    ...prevState,
                                    showWorkList: false,
                                    showAddOrder: false,
                                    showProfile: true,
                                    profileData: item,
                                    profileId: item.id,
                                    admissionDays: calculateDayDiff((item?.admission_date)),
                                  };
                                });
                              }}
                            />
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <Button
                        variant='secondary'
                        className='popoverButton bg-transparent p-0 border-0'
                      >
                        <ThreeDotsSVG style={{ width: '1.5em' }} />
                      </Button>
                    </OverlayTrigger>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </TableUI>
    </div>
  );
};

export default WorklistTable;
