/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { patientDisbursementStatus, patientClass } from 'helpers';
import Table from 'components/ReactTable';

// import components below
import moment from 'moment';

// import assets below
import './Table.scss';
import { getDisbursedPatient, selectDisbursedPatientData } from 'reducers/billingReducer';

// main component
const PatientDisbursedTable = () => {
  const state = useSelector(selectDisbursedPatientData);
  const tableColumns = useMemo(
    () => [
      { Header: 'Date / Time Disburse', accessor: 'createdAt' },
      { Header: 'Patient ID', accessor: 'patientId' },
      { Header: 'Patient Name', accessor: 'full_name' },
      { Header: 'Patient Class', accessor: 'patient_class' },
      { Header: 'Status', accessor: 'patient_status' },
      { Header: 'Name of Receiver', accessor: 'name_of_receiver' },
      { Header: 'Disburse Amount', accessor: 'amount_disbursed' },
      { Header: 'Balance', accessor: 'remaining_balance' },
    ],
    [],
  );

  const tableData = useMemo(
    () =>
      state?.map((dd) => ({
        createdAt: moment(dd?.createdAt).format('MMM-DD-YYYY hh:mm A'),
        patientId: dd?.patientId,
        full_name: dd?.full_name,
        patient_class: patientClass(dd?.patient_class),
        patient_status: patientDisbursementStatus(dd?.patient_status),
        name_of_receiver: dd?.name_of_receiver,
        amount_disbursed: dd?.amount_disbursed,
        remaining_balance: dd?.remaining_balance,
      })),
    [state],
  );
  const handleTableAction = (eventKey, row) => {
    const { laboratory_result } = row;

    const action = {
      complete: () => {},
      view: () => {},
      remove: () => {},
      cancel: () => {},
    };

    action[eventKey]();
  };

  return (
    <div className='patientDisbursedTable'>
      <Table data={tableData} columns={tableColumns} pagination={['BOTTOM']} />
    </div>
  );
};

export default PatientDisbursedTable;
