/* eslint-disable prefer-const */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import Select from 'react-select';
import { toast } from 'react-toastify';
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Button, Form, FormControl } from 'react-bootstrap';

import OtherDetails from './components/OtherDetails';
import blankProfile from 'assets/images/blank-profile.png';
import ConditionalRender from 'components/ConditionalRender';
import RecentActivities from './components/RecentActivities';
import { getProfile, getACLState, updateProfile } from 'reducers/aclReducer';
import {
  getAllRoles,
  selectSignup,
  getAllSpecialties,
  getAllDepartments,
  getSubSpecialties,
} from 'reducers/signupReducer';

import styles from './index.module.scss';
import { ReactComponent as UpdateProfileSVG } from 'assets/svg/update-profile-icon.svg';
import axios from 'axios';

const Profile = () => {
  const dispatch = useDispatch();
  const profileState = useSelector(getACLState);
  const signupStore = useSelector(selectSignup);

  const { data, subData } = signupStore;
  const { updateProfileLogs } = profileState;

  // Form Options
  const [isEdit, setIsEdit] = useState(false);
  const [specialties, setSpecialties] = useState({
    parentSpecialty: [],
    subSpecialty: [],
  });

  // Profile Data
  const [profileData, setProfileData] = useState({});
  const [roleOptions, setRoleOptions] = useState([]);

  const isReadOnly = () => {
    if (isEdit) {
      return false;
    }

    return true;
  };

  const getRoles = () => {
    dispatch(getAllRoles({ per_page: 100000 }));
  };

  const handleSave = async () => {
    const specialtyIds = profileData?.specialty?.map((data) => parseInt(data?.data?.id));
    const subSpecialtyIds = profileData?.subSpecialty?.map((data) => parseInt(data?.data?.id));

    setProfileData({
      ...profileData,
      sub_specialty_id: subSpecialtyIds,
      specialty_id: specialtyIds,
    });
    
    dispatch(
      updateProfile({
        payload: profileData,
        id: profileData?.id,
      }),
    );

    axios.put(`${process.env.REACT_APP_API_BASE_ACL}/api/users/${profileData?.id}/role`, {
      role: profileData?.role,
    });

    setIsEdit(false);
    toast.success('Profile successfully updated.');
  };

  const handleChange = (e) => {
    const { value, name, type } = e.target;

    setProfileData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const getDepartments = () => {
    dispatch(getAllDepartments({ per_page: 100000 }));
  };

  const getSpecialties = () => {
    dispatch(getAllSpecialties({ per_page: 100000 }));
  };

  const handleImageUpload = (e) => {
    const { value, name, files } = e.target;

    const [file] = e.target.files;

    setProfileData((prev) => {
      return {
        ...prev,
        [name]: files[0],
        imagePreview: URL.createObjectURL(file),
      };
    });
  };

  const handleImageChange = () => {
    if (profileData?.profile_picture) {
      return profileData?.profile_picture;
    }

    return blankProfile;
  };

  const apiSubSpecialties = () => {
    dispatch(getSubSpecialties({ per_page: 100000, is_sub_position: true }));
  };

  const handleEnableUpdate = () => {
    setIsEdit(true);
  };

  const handleDisableUpdate = () => {
    setIsEdit(false);
  };

  const handleSpecialtyChange = (selectedData) => {
    setProfileData({
      ...profileData,
      specialty: selectedData,
    });
  };

  const handleSubSpecialtyChange = (selectedData) => {
    setProfileData({
      ...profileData,
      subSpecialty: selectedData,
    });
  };

  const getSubSpecialtyOptions = () => {
    console.log(profileData?.specialty);
    if (!profileData?.specialty || profileData?.specialty.length === 0) return;

    const newData = specialties?.subSpecialty.filter((data) => {
      let isIncluded = false;

      profileData?.specialty.forEach((d) => {
        if (+data?.attributes?.parent_position_id === +d?.id) {
          isIncluded = true;
        }
      });

      return isIncluded === true;
    });

    return newData?.map((data) => {
      return {
        data: data,
        id: data?.id,
        value: data?.id,
        label: data?.attributes?.description,
      };
    });
  };

  renderOnFirstRun(
    getRoles,
    getDepartments,
    getSpecialties,
    dispatch,
    getProfile,
    apiSubSpecialties,
  );
  // renderAfterSubmit(updateProfileLogs, setIsEdit);
  renderWhenProfileIsPresent(profileState, setProfileData);
  renderSpecialties(specialties, profileData, setProfileData);
  renderWhenDataIsChanged(data, subData, setSpecialties);

  console.log('profile data change', profileData);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_BASE_ACL}/api/roles`)
      .then((response) => {
        setRoleOptions(response.data.data);
      })
      .catch((error) => console.log('Error getting roles: ', error));
  }, []);

  return (
    <div className={styles['profile']}>
      <Row className='mb-4'>
        <Col>
          <div className={`${styles['user-profile']} shadow`}>
            <div className={styles['image-container']}>
              <img src={profileData?.imagePreview || handleImageChange()} alt='profile picture' />

              <div className={styles['profile-update']}>
                <label>
                  <input
                    type='file'
                    name='profile_picture'
                    style={{ display: 'none' }}
                    onChange={handleImageUpload}
                    accept='image/png, image/jpeg'
                  />
                  <UpdateProfileSVG />
                </label>
              </div>
            </div>
            <div className={styles['profile-container']}>
              <div className={styles['header']}>
                <h3>User Profile</h3>

                <div className={styles['buttons-container']}>
                  <ConditionalRender
                    condition={isEdit === false}
                    renderIf={<Button variant='outline-primary' onClick={handleEnableUpdate}>Update</Button>}
                  />

                  <ConditionalRender
                    condition={isEdit === true}
                    renderIf={
                      <>
                        <Button variant='outline-success' onClick={handleSave}>Save</Button>

                        <Button variant='outline-secondary' className={styles['cancel-button']} onClick={handleDisableUpdate}>
                          Cancel
                        </Button>
                      </>
                    }
                  />
                </div>
              </div>

              <Form.Group className={styles['inline-group']}>
                <Form.Label>User Role :</Form.Label>
                {/* <FormControl
                  name='role'
                  readOnly={isReadOnly()}
                  value={profileData?.role}
                  onChange={handleChange}
                /> */}
                <Form.Select
                  name='role'
                  defaultValue={profileData?.role}
                  disabled={isReadOnly()}
                  value={profileData?.role}
                  onChange={handleChange}
                >
                  {roleOptions?.map((role) => (
                    <option key={role?.id} value={role?.attributes?.name}>{role?.attributes?.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className={styles['inline-group']}>
                <Form.Label>First Name :</Form.Label>
                <FormControl
                  name='first_name'
                  readOnly={isReadOnly()}
                  value={profileData?.first_name}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className={styles['inline-group']}>
                <Form.Label>Middle Name :</Form.Label>
                <FormControl
                  name='middle_name'
                  readOnly={isReadOnly()}
                  value={profileData?.middle_name}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className={styles['inline-group']}>
                <Form.Label>Last Name :</Form.Label>
                <FormControl
                  name='last_name'
                  readOnly={isReadOnly()}
                  value={profileData?.last_name}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className={styles['inline-group']}>
                <Form.Label>Specialty :</Form.Label>
                {/* <FormControl
                  name='specialty'
                  readOnly={isReadOnly()}
                  value={profileData?.specialty}
                  onChange={handleChange}
                /> */}
                <div className={styles['multiple-select']}>
                  <Select
                    isMulti
                    isSearchable={false}
                    styles={selectStyles}
                    isDisabled={isReadOnly()}
                    value={profileData?.specialty}
                    placeholder='Select Specialty'
                    onChange={handleSpecialtyChange}
                    options={specialties?.parentSpecialty.map((data) => {
                      return {
                        data: data,
                        id: data?.id,
                        // label: `${data?.attributes?.position} (${data?.id})`,
                        label: `${data?.attributes?.description} ${data?.id}`,
                        value: data?.id,
                      };
                    })}
                  />
                </div>
              </Form.Group>

              <Form.Group className={styles['inline-group']}>
                <Form.Label>Sub Specialty :</Form.Label>
                {/* <FormControl
                  name='subSspecialty'
                  readOnly={isReadOnly()}
                  value={profileData?.subSspecialty}
                  onChange={handleChange}
                /> */}

                <div className={styles['multiple-select']}>
                  <Select
                    isMulti
                    isSearchable={false}
                    styles={selectStyles}
                    isDisabled={isReadOnly()}
                    value={profileData?.subSpecialty}
                    placeholder='Select Sub Specialty'
                    options={specialties?.subSpecialty.map((data) => {
                      return {
                        data: data,
                        id: data?.id,
                        // label: `${data?.attributes?.position} (${data?.id})`,
                        label: `${data?.attributes?.description} ${data?.id}`,
                        value: data?.id,
                      };
                    })}
                    onChange={handleSubSpecialtyChange}
                  />
                </div>
              </Form.Group>

              {/* <Form.Group className={styles['inline-group']}>
                <Form.Label>Age :</Form.Label>
                <FormControl
                  type='number'
                  name='age'
                  readOnly={isReadOnly()}
                  value={profileData?.age}
                  onChange={handleChange}
                />
              </Form.Group> */}

              {/* <Form.Group className={styles['inline-group']}>
                <Form.Label>Gender :</Form.Label>
                <Form.Select
                  type='number'
                  name='gender'
                  readOnly={isReadOnly()}
                  onChange={handleChange}
                  value={profileData?.gender}
                >
                  <option value='' hidden>
                    Select Gender
                  </option>
                  <option value='Male'>Male</option>
                  <option value='Female'>Female</option>
                </Form.Select>
              </Form.Group> */}

              <Form.Group className={styles['inline-group']}>
                <Form.Label>Email :</Form.Label>
                <FormControl
                  type='email'
                  name='email'
                  readOnly={isReadOnly()}
                  value={profileData?.email}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className={styles['inline-group']}>
                <Form.Label>Contact Number :</Form.Label>
                <FormControl
                  type='number'
                  name='contact_number'
                  readOnly={isReadOnly()}
                  value={profileData?.contact_number}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>
          </div>
        </Col>
        <Col>
          <RecentActivities />
        </Col>
      </Row>

      <Row>
        <Col>
          <OtherDetails />
        </Col>
      </Row>
    </div>
  );
};

const renderOnFirstRun = (
  getRoles,
  getDepartments,
  getSpecialties,
  dispatch,
  getProfile,
  apiSubSpecialties,
) => {
  useEffect(() => {
    getRoles();
    getDepartments();
    getSpecialties();
    apiSubSpecialties();
    dispatch(getProfile());
  }, []);
};

const renderSpecialties = (specialties, profileData, setProfileData) => {
  useEffect(() => {
    if (specialties && profileData?.specialty_id && !profileData?.specialty) {
      const specialty = specialties.parentSpecialty.filter(
        (d) => profileData?.specialty_id.indexOf(parseInt(d?.id)) !== -1,
      );

      const finalSpecialty = specialty.map((data) => {
        return {
          data: data,
          value: data?.id,
          label: data?.attributes?.description,
        };
      });

      const subSpecialty = specialties.subSpecialty.filter(
        (d) => profileData?.sub_specialty_id.indexOf(parseInt(d?.id)) !== -1,
      );

      const finalSubSpecialty = subSpecialty.map((data) => {
        return {
          data: data,
          value: data?.id,
          label: data?.attributes?.description,
        };
      });

      setProfileData((prev) => {
        return {
          ...prev,
          specialty: finalSpecialty,
          subSpecialty: finalSubSpecialty,
        };
      });
    }
  }, [specialties, profileData]);
};

const renderAfterSubmit = (updateProfileLogs, setIsEdit) => {
  useEffect(() => {
    const { isSuccess, isFailed, isLoading } = updateProfileLogs;

    if (isSuccess) {
      setIsEdit(false);
      toast.success('Profile successfully updated.');
      return;
    }

    if (isFailed) {
      toast.error('Something went wrong.');
      return;
    }
  }, [updateProfileLogs]);
};

const renderWhenDataIsChanged = (data, subData, setSpecialties) => {
  useEffect(() => {
    if (data && data.length > 0) {
      setSpecialties((prev) => {
        return {
          ...prev,
          parentSpecialty: data,
        };
      });
    }

    if (subData && subData.length > 0) {
      setSpecialties((prev) => {
        return {
          ...prev,
          subSpecialty: subData,
        };
      });
    }
  }, [data, subData]);
};

const renderWhenProfileIsPresent = (profileState, setProfileData) => {
  useEffect(() => {
    if (profileState?.profile) {
      setProfileData({
        ...profileState?.profile?.attributes,
        id: profileState?.profile?.id,
      });
    }
  }, [profileState.profile]);
};

const selectStyles = {
  control: (styles) => ({
    ...styles,
    padding: 0,
    width: '100%',
    borderRadius: 0,
    fontSize: '14px',
   /*  borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none', */
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: '100',
  }),
};

export default Profile;
